import { Component, Input, OnInit, TemplateRef, Type, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'dialog-overlay', standalone: false,
    encapsulation: ViewEncapsulation.None,
    template: `
<div class="modal-header text-end">
    <button type="button" class="btn-close text-reset" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body text-center">
    <h3 *ngIf="template"><i class="fa fa-spin fa-pulse fa-spinner"></i> Loading...</h3>
    <ng-container *ngTemplateOutlet="template; context: {$implicit: params}"></ng-container>
</div>`
})
export class OverlayDialog implements OnInit {
    constructor(protected activeModal: NgbActiveModal) { }

    @Input() template: TemplateRef<any>;
    @Input() params: any;

    ngOnInit() { }
    close() { this.activeModal.close() }
}