export const Tables = {
	"achizitii": {
		"Program": "achizitii.Program",
		"ProgramPozitieReferatNecesitateDetaliu": "achizitii.ProgramPozitieReferatNecesitateDetaliu",
		"ProgramPozitieReferatPropunereDetaliu": "achizitii.ProgramPozitieReferatPropunereDetaliu",
		"ProgramPozitieSursaFinantare": "achizitii.ProgramPozitieSursaFinantare",
		"Versiune": "achizitii.Versiune",
		"AchizitieDetaliu": "achizitii.AchizitieDetaliu",
		"DocumentReferatNecesitateDetaliu": "achizitii.DocumentReferatNecesitateDetaliu",
		"DocumentReferatPropunereDetaliu": "achizitii.DocumentReferatPropunereDetaliu",
		"ProgramPozitie": "achizitii.ProgramPozitie",
		"Achizitie": "achizitii.Achizitie",
		"AchizitieOferta": "achizitii.AchizitieOferta",
		"AchizitieSursaFinantare": "achizitii.AchizitieSursaFinantare",
		"AnBlocarePropuneri": "achizitii.AnBlocarePropuneri",
		"Criteriu": "achizitii.Criteriu",
		"DocumentAchizitieAtasat": "achizitii.DocumentAchizitieAtasat",
		"DocumentReferatNecesitate": "achizitii.DocumentReferatNecesitate",
		"DocumentReferatNecesitateAtasat": "achizitii.DocumentReferatNecesitateAtasat",
		"DocumentReferatNecesitateSursaFinantare": "achizitii.DocumentReferatNecesitateSursaFinantare",
		"DocumentReferatPropunere": "achizitii.DocumentReferatPropunere",
		"FisierTemplate": "achizitii.FisierTemplate",
		"ProceduraTipEtapa": "achizitii.ProceduraTipEtapa",
		"ProceduraTipEtapaDocumentTip": "achizitii.ProceduraTipEtapaDocumentTip",
		"ProceduraTipEtapaDocumentTipAtasat": "achizitii.ProceduraTipEtapaDocumentTipAtasat",
		"ProceduraTipEtapaDocumentTipModel": "achizitii.ProceduraTipEtapaDocumentTipModel"
	},
	"achizitiiIstoric": {
		"DocumentReferatPropunereDetaliu": "achizitiiistoric.DocumentReferatPropunereDetaliu",
		"ProgramPozitie": "achizitiiistoric.ProgramPozitie",
		"DocumentReferatNecesitateDetaliu": "achizitiiistoric.DocumentReferatNecesitateDetaliu",
		"AchizitieDetaliu": "achizitiiistoric.AchizitieDetaliu"
	},
	"admin": {
		"RelatiiTabele": "admin.RelatiiTabele",
		"SetareUnitate": "admin.SetareUnitate",
		"ReportImage": "admin.ReportImage",
		"RolAcces": "admin.RolAcces",
		"BackupConfig": "admin.BackupConfig",
		"BackupConfigInstance": "admin.BackupConfigInstance",
		"Setare": "admin.Setare",
		"RefactorColumn": "admin.RefactorColumn",
		"RefactorTable": "admin.RefactorTable",
		"ResursaControl": "admin.ResursaControl",
		"BackupInterval": "admin.BackupInterval",
		"RefreshToken": "admin.RefreshToken",
		"Rol": "admin.Rol",
		"Test": "admin.Test",
		"SetareUnitateUser": "admin.SetareUnitateUser",
		"Client": "admin.Client",
		"UserAcces": "admin.UserAcces",
		"UserClaim": "admin.UserClaim",
		"RandomNumberView": "admin.RandomNumberView",
		"UserLogin": "admin.UserLogin",
		"UserUnitate": "admin.UserUnitate",
		"LinkedObject": "admin.LinkedObject",
		"RelatiiTabeleIerarhie": "admin.RelatiiTabeleIerarhie",
		"UserCid": "admin.UserCid",
		"BackupFolder": "admin.BackupFolder",
		"BackupPlan": "admin.BackupPlan",
		"ReportSemnaturaDefinireLegatura": "admin.ReportSemnaturaDefinireLegatura",
		"Task": "admin.Task",
		"BackupFile": "admin.BackupFile",
		"Parametri": "admin.Parametri",
		"User": "admin.User",
		"GetNewGuid": "admin.GetNewGuid",
		"TablePropertiesList": "admin.TablePropertiesList",
		"NotificareUser": "admin.NotificareUser",
		"MailQueue": "admin.MailQueue",
		"BackupSchedule": "admin.BackupSchedule",
		"ReportSemnaturaDefinire": "admin.ReportSemnaturaDefinire",
		"QueryError": "admin.QueryError",
		"Resursa": "admin.Resursa",
		"MailTemplates": "admin.MailTemplates",
		"RelatiiTabeleUnitate": "admin.RelatiiTabeleUnitate",
		"LinkedDatabaseTable": "admin.LinkedDatabaseTable",
		"SetareUser": "admin.SetareUser",
		"UserRol": "admin.UserRol",
		"BackupMessage": "admin.BackupMessage",
		"HelpPageTemplate": "admin.HelpPageTemplate",
		"Notificare": "admin.Notificare",
		"BackupUpdateStatus": "admin.BackupUpdateStatus",
		"DatabaseAlias": "admin.DatabaseAlias"
	},
	"adrese": {
		"ParcelaZonaFiscala": "adrese.ParcelaZonaFiscala",
		"ParcelaZonaFiscala1": "adrese.ParcelaZonaFiscala1",
		"Parcela": "adrese.Parcela",
		"NumarPostalZona": "adrese.NumarPostalZona",
		"Tarla": "adrese.Tarla",
		"NumarPostalZonaFiscala": "adrese.NumarPostalZonaFiscala",
		"StradaTip": "adrese.StradaTip",
		"Tara": "adrese.Tara",
		"StradaNumarPostalRegula": "adrese.StradaNumarPostalRegula",
		"Bloc": "adrese.Bloc",
		"Apartament": "adrese.Apartament",
		"LocatieSpatiu": "adrese.LocatieSpatiu",
		"Locatie": "adrese.Locatie",
		"Judet": "adrese.Judet",
		"Localitate": "adrese.Localitate",
		"LocalitateTip": "adrese.LocalitateTip",
		"Strada": "adrese.Strada",
		"Scara": "adrese.Scara",
		"NumarPostalZonaTip": "adrese.NumarPostalZonaTip",
		"NumarPostalZonaNumarPostal": "adrese.NumarPostalZonaNumarPostal",
		"NumarPostal": "adrese.NumarPostal",
		"Regiune": "adrese.Regiune"
	},
	"adreseIstoric": {
		"Localitate": "adreseistoric.Localitate",
		"Apartament": "adreseistoric.Apartament",
		"Bloc": "adreseistoric.Bloc",
		"LocatieSpatiu": "adreseistoric.LocatieSpatiu",
		"NumarPostalZona": "adreseistoric.NumarPostalZona",
		"Scara": "adreseistoric.Scara",
		"Parcela": "adreseistoric.Parcela",
		"Judet": "adreseistoric.Judet",
		"NumarPostal": "adreseistoric.NumarPostal",
		"Tara": "adreseistoric.Tara",
		"Tarla": "adreseistoric.Tarla",
		"Strada": "adreseistoric.Strada",
		"ParcelaZonaFiscala": "adreseistoric.ParcelaZonaFiscala",
		"Locatie": "adreseistoric.Locatie"
	},
	"asisoc": {
		"HandicapTip": "asisoc.HandicapTip",
		"Versiune": "asisoc.Versiune",
		"DocumentVinieta": "asisoc.DocumentVinieta",
		"DocumentSolicitareFacilitatiVinieta": "asisoc.DocumentSolicitareFacilitatiVinieta",
		"DocumentSolicitareFacilitatiScutireImpozitProprietate": "asisoc.DocumentSolicitareFacilitatiScutireImpozitProprietate",
		"DocumentSolicitareFacilitatiIndemnizatie": "asisoc.DocumentSolicitareFacilitatiIndemnizatie",
		"DocumentSolicitareFacilitatiCertificatHandicap": "asisoc.DocumentSolicitareFacilitatiCertificatHandicap",
		"DocumentSolicitareFacilitatiScutireImpozit": "asisoc.DocumentSolicitareFacilitatiScutireImpozit",
		"DocumentSolicitareFacilitatiScutireImpozitAuto": "asisoc.DocumentSolicitareFacilitatiScutireImpozitAuto",
		"DocumentCertificatHandicap": "asisoc.DocumentCertificatHandicap",
		"HandicapGrad": "asisoc.HandicapGrad",
		"DocumentSolicitareFacilitatiCardParcare": "asisoc.DocumentSolicitareFacilitatiCardParcare",
		"DocumentSolicitareFacilitati": "asisoc.DocumentSolicitareFacilitati",
		"DocumentSolicitareCertificatInmatriculare": "asisoc.DocumentSolicitareCertificatInmatriculare",
		"DocumentSolicitareExtrasCarteFunciara": "asisoc.DocumentSolicitareExtrasCarteFunciara",
		"DocumentDecizieScutireImpozit": "asisoc.DocumentDecizieScutireImpozit",
		"DocumentExtrasCarteFunciara": "asisoc.DocumentExtrasCarteFunciara",
		"DocumentCardParcare": "asisoc.DocumentCardParcare",
		"TipRelatieRudenie": "asisoc.TipRelatieRudenie",
		"Familii": "asisoc.Familii"
	},
	"asisocIstoric": {
		"HandicapGrad": "asisocistoric.HandicapGrad",
		"DocumentCertificatHandicap": "asisocistoric.DocumentCertificatHandicap",
		"HandicapTip": "asisocistoric.HandicapTip"
	},
	"bilant": {
		"Formular": "bilant.Formular"
	},
	"buget": {
		"ClasificatieFunctionalaTree": "buget.ClasificatieFunctionalaTree",
		"ClasificatieFunctionala": "buget.ClasificatieFunctionala",
		"CodProgramBugetar": "buget.CodProgramBugetar",
		"FormularBuget": "buget.FormularBuget",
		"BugetClasificatieFunctionalaFormulaPersonalizare": "buget.BugetClasificatieFunctionalaFormulaPersonalizare",
		"BugetClasificatieFunctionalaPersonalizare": "buget.BugetClasificatieFunctionalaPersonalizare",
		"ClasificatieEconomica": "buget.ClasificatieEconomica",
		"BugetClasificatieFunctionalaTree": "buget.BugetClasificatieFunctionalaTree",
		"FormularBugetFormula": "buget.FormularBugetFormula",
		"FormularBugetTree": "buget.FormularBugetTree",
		"Buget": "buget.Buget",
		"BugetClasificatieFunctionalaFormula": "buget.BugetClasificatieFunctionalaFormula",
		"BugetClasificatieFunctionala": "buget.BugetClasificatieFunctionala",
		"Versiune": "buget.Versiune",
		"SumaExecutie": "buget.SumaExecutie",
		"DocumentOrdonantare": "buget.DocumentOrdonantare",
		"DocumentPropunereCheltuiala": "buget.DocumentPropunereCheltuiala",
		"AsociereCapitolSectorSursa": "buget.AsociereCapitolSectorSursa",
		"DocumentAngajamentLegal": "buget.DocumentAngajamentLegal",
		"DocumentAngajamentBugetar": "buget.DocumentAngajamentBugetar",
		"DocumentAngajamentBugetarSuplimentare": "buget.DocumentAngajamentBugetarSuplimentare",
		"DocumentCerereCredite": "buget.DocumentCerereCredite",
		"DocumentAngajamentLegalSuplimentare": "buget.DocumentAngajamentLegalSuplimentare",
		"DocumentDeschidereCrediteSuma": "buget.DocumentDeschidereCrediteSuma",
		"DocumentCerereCrediteSuma": "buget.DocumentCerereCrediteSuma",
		"DocumentDeschidereCredite": "buget.DocumentDeschidereCredite",
		"DocumentLichidare": "buget.DocumentLichidare",
		"CodAngajament": "buget.CodAngajament",
		"BugetClasificatieFunctionalaSumaPersonalizare": "buget.BugetClasificatieFunctionalaSumaPersonalizare",
		"BugetRectificare": "buget.BugetRectificare",
		"BugetSuma": "buget.BugetSuma",
		"BugetClasificatieFunctionalaSuma": "buget.BugetClasificatieFunctionalaSuma",
		"FormularClasificatieEconomicaFormula": "buget.FormularClasificatieEconomicaFormula",
		"FormularClasificatieEconomicaPersonalizare": "buget.FormularClasificatieEconomicaPersonalizare",
		"FormularClasificatieEconomica": "buget.FormularClasificatieEconomica",
		"FormularClasificatieEconomicaTree": "buget.FormularClasificatieEconomicaTree",
		"SectorBugetar": "buget.SectorBugetar",
		"SursaFinantare": "buget.SursaFinantare"
	},
	"bugetIstoric": {
		"SectorBugetar": "bugetistoric.SectorBugetar",
		"FormularClasificatieEconomicaTree": "bugetistoric.FormularClasificatieEconomicaTree",
		"FormularClasificatieEconomicaPersonalizare": "bugetistoric.FormularClasificatieEconomicaPersonalizare",
		"FormularBugetTree": "bugetistoric.FormularBugetTree",
		"FormularClasificatieEconomicaFormula": "bugetistoric.FormularClasificatieEconomicaFormula",
		"FormularClasificatieEconomica": "bugetistoric.FormularClasificatieEconomica",
		"SursaFinantare": "bugetistoric.SursaFinantare",
		"AsociereCapitolSectorSursa": "bugetistoric.AsociereCapitolSectorSursa",
		"BugetClasificatieFunctionala": "bugetistoric.BugetClasificatieFunctionala",
		"Buget": "bugetistoric.Buget",
		"FormularBugetFormula": "bugetistoric.FormularBugetFormula",
		"FormularBuget": "bugetistoric.FormularBuget",
		"BugetClasificatieFunctionalaFormulaPersonalizare": "bugetistoric.BugetClasificatieFunctionalaFormulaPersonalizare",
		"BugetClasificatieFunctionalaPersonalizare": "bugetistoric.BugetClasificatieFunctionalaPersonalizare",
		"BugetClasificatieFunctionalaTree": "bugetistoric.BugetClasificatieFunctionalaTree",
		"BugetClasificatieFunctionalaFormula": "bugetistoric.BugetClasificatieFunctionalaFormula",
		"CodProgramBugetar": "bugetistoric.CodProgramBugetar",
		"ClasificatieFunctionalaTree": "bugetistoric.ClasificatieFunctionalaTree",
		"ClasificatieEconomica": "bugetistoric.ClasificatieEconomica",
		"ClasificatieFunctionala": "bugetistoric.ClasificatieFunctionala"
	},
	"cantina": {
		"CategorieBeneficiarOrganizareInstitutionala": "cantina.CategorieBeneficiarOrganizareInstitutionala",
		"SoldInitial": "cantina.SoldInitial",
		"Versiune": "cantina.Versiune",
		"OraServire": "cantina.OraServire",
		"OraServireCategorieBeneficiar": "cantina.OraServireCategorieBeneficiar",
		"MeniuZiPreparat": "cantina.MeniuZiPreparat",
		"Preparat": "cantina.Preparat",
		"MeniuZi": "cantina.MeniuZi",
		"MeniuZiCategorieBeneficiar": "cantina.MeniuZiCategorieBeneficiar",
		"PreparatCategorieBeneficiar": "cantina.PreparatCategorieBeneficiar",
		"SemnaturaListaZilnicaAlimente": "cantina.SemnaturaListaZilnicaAlimente",
		"PreparatAliment": "cantina.PreparatAliment",
		"PreparatCategorie": "cantina.PreparatCategorie",
		"MeniuPlanificatDetaliu": "cantina.MeniuPlanificatDetaliu",
		"ImaginiMeniuPlanificat": "cantina.ImaginiMeniuPlanificat",
		"MasaTip": "cantina.MasaTip",
		"MeniuPlanificat": "cantina.MeniuPlanificat",
		"AlimentContinutNutritional": "cantina.AlimentContinutNutritional",
		"AlimentAlergen": "cantina.AlimentAlergen",
		"Categorie": "cantina.Categorie",
		"CategorieBeneficiar": "cantina.CategorieBeneficiar",
		"Aliment": "cantina.Aliment",
		"Alergen": "cantina.Alergen"
	},
	"cantinaIstoric": {
		"CategorieBeneficiarOrganizareInstitutionala": "cantinaistoric.CategorieBeneficiarOrganizareInstitutionala",
		"OraServire": "cantinaistoric.OraServire"
	},
	"casierie": {
		"GrupVenit": "casierie.GrupVenit",
		"debit_fiz": "casierie.debit_fiz",
		"debit_jur": "casierie.debit_jur",
		"RamasitaJur": "casierie.RamasitaJur",
		"DebitJur": "casierie.DebitJur",
		"RamasitaFiz": "casierie.RamasitaFiz",
		"IncasareFiz": "casierie.IncasareFiz",
		"IncasareJur": "casierie.IncasareJur",
		"DebitFiz": "casierie.DebitFiz",
		"AmenziFiz": "casierie.AmenziFiz"
	},
	"companie": {
		"Repfisc": "companie.Repfisc"
	},
	"comune": {
		"FormaDetinere": "comune.FormaDetinere"
	},
	"contab": {
		"AsociereNotaDetaliuDocument": "contab.AsociereNotaDetaliuDocument",
		"Balanta": "contab.Balanta",
		"BalantaValuta": "contab.BalantaValuta",
		"GenerareNotaContTemplate": "contab.GenerareNotaContTemplate",
		"ForexeBugAnexa37": "contab.ForexeBugAnexa37",
		"ForexeBugAnexa35_c": "contab.ForexeBugAnexa35_c",
		"DocumentFurnizorClientSoldCont": "contab.DocumentFurnizorClientSoldCont",
		"ForexeBugFormular34": "contab.ForexeBugFormular34",
		"ContGrupTemplate": "contab.ContGrupTemplate",
		"ContGrupTemplateCod": "contab.ContGrupTemplateCod",
		"DocumentFurnizorClientDetaliuTipCont": "contab.DocumentFurnizorClientDetaliuTipCont",
		"ForexeBugAnexa35_a": "contab.ForexeBugAnexa35_a",
		"JurnalGeneral": "contab.JurnalGeneral",
		"AsociereActivitateEconomicaArticol": "contab.AsociereActivitateEconomicaArticol",
		"AsociereClasificatieEconomicaCont": "contab.AsociereClasificatieEconomicaCont",
		"AsociereContCrediteBugetare": "contab.AsociereContCrediteBugetare",
		"LunaInchisa": "contab.LunaInchisa",
		"PosturiAnexa30": "contab.PosturiAnexa30",
		"SoldInitialPartener": "contab.SoldInitialPartener",
		"SoldParteneriAn": "contab.SoldParteneriAn",
		"TipInchidere": "contab.TipInchidere",
		"AlgoritmInchidereConturi": "contab.AlgoritmInchidereConturi",
		"Versiune": "contab.Versiune",
		"GenerareNotaContTemplateDefault": "contab.GenerareNotaContTemplateDefault",
		"GenerareNotaOperatiuneContTemplate": "contab.GenerareNotaOperatiuneContTemplate",
		"AsociereOperatiuneDocFurnizorJurnal": "contab.AsociereOperatiuneDocFurnizorJurnal",
		"Cont": "contab.Cont",
		"ContInchidere": "contab.ContInchidere",
		"GenerareNotaAlgoritm": "contab.GenerareNotaAlgoritm",
		"ForexeBugAnexa40": "contab.ForexeBugAnexa40",
		"Nota": "contab.Nota",
		"Jurnal": "contab.Jurnal",
		"GenerareNotaOperatiuneContTemplateDefault": "contab.GenerareNotaOperatiuneContTemplateDefault",
		"NotaPropusa": "contab.NotaPropusa"
	},
	"contabIstoric": {
		"Jurnal": "contabistoric.Jurnal",
		"GenerareNotaOperatiuneContTemplate": "contabistoric.GenerareNotaOperatiuneContTemplate",
		"GenerareNotaOperatiuneContTemplateDefault": "contabistoric.GenerareNotaOperatiuneContTemplateDefault",
		"ContInchidere": "contabistoric.ContInchidere",
		"Cont": "contabistoric.Cont",
		"AsociereOperatiuneDocFurnizorJurnal": "contabistoric.AsociereOperatiuneDocFurnizorJurnal",
		"AsociereContCrediteBugetare": "contabistoric.AsociereContCrediteBugetare",
		"GenerareNotaContTemplateDefault": "contabistoric.GenerareNotaContTemplateDefault",
		"GenerareNotaAlgoritm": "contabistoric.GenerareNotaAlgoritm",
		"AsociereClasificatieEconomicaCont": "contabistoric.AsociereClasificatieEconomicaCont",
		"AlgoritmInchidereConturi": "contabistoric.AlgoritmInchidereConturi",
		"AsociereActivitateEconomicaArticol": "contabistoric.AsociereActivitateEconomicaArticol",
		"GenerareNotaContTemplate": "contabistoric.GenerareNotaContTemplate"
	},
	"contracte": {
		"GrupDebitareTest": "contracte.GrupDebitareTest"
	},
	"dareSeama": {
		"Formular": "dareseama.Formular",
		"AIndex": "dareseama.AIndex"
	},
	"dataService": {
		"RegistruImportDate": "dataservice.RegistruImportDate",
		"DataImportState": "dataservice.DataImportState",
		"DataUpdateLog": "dataservice.DataUpdateLog"
	},
	"deploy": {
		"TabeleNomenclator": "deploy.TabeleNomenclator",
		"Validare": "deploy.Validare",
		"ValoareMemorata": "deploy.ValoareMemorata",
		"Release": "deploy.Release",
		"CheckinConversie": "deploy.CheckinConversie",
		"Status": "deploy.Status",
		"JurnalActualizare": "deploy.JurnalActualizare",
		"Checkin": "deploy.Checkin"
	},
	"documente": {
		"DocumentContractClasificatieBugetaraAnSume": "documente.DocumentContractClasificatieBugetaraAnSume",
		"OperatiuneCFPClasificatieEconomica": "documente.OperatiuneCFPClasificatieEconomica",
		"DocumentGarantieBunaExecutie": "documente.DocumentGarantieBunaExecutie",
		"DocumentBorderouIncasareDetaliu": "documente.DocumentBorderouIncasareDetaliu",
		"DocumentCentralizatorIncasareNumerarContabilitate": "documente.DocumentCentralizatorIncasareNumerarContabilitate",
		"DocumentPlataIncasareCheltuiala": "documente.DocumentPlataIncasareCheltuiala",
		"DocumentCentralizatorOPIncasareDetaliu": "documente.DocumentCentralizatorOPIncasareDetaliu",
		"DocumentDispozitieIncasare": "documente.DocumentDispozitieIncasare",
		"DocumentReglareNotaContabila": "documente.DocumentReglareNotaContabila",
		"DocumentStare": "documente.DocumentStare",
		"DocumentMiscareInventarOrganizareInstitutionalaPrimire": "documente.DocumentMiscareInventarOrganizareInstitutionalaPrimire",
		"DocumentFlow": "documente.DocumentFlow",
		"DocumentDispozitieIncasareDistribuireSume": "documente.DocumentDispozitieIncasareDistribuireSume",
		"DocumentMiscareInventar": "documente.DocumentMiscareInventar",
		"DocumentOrdinDeplasareSold": "documente.DocumentOrdinDeplasareSold",
		"DocumentSoldCasierie": "documente.DocumentSoldCasierie",
		"DocumentObligatieSalariiDetaliu": "documente.DocumentObligatieSalariiDetaliu",
		"DocumentStatPlataNumerarDetaliu": "documente.DocumentStatPlataNumerarDetaliu",
		"DocumentOrdinDeplasareVirament": "documente.DocumentOrdinDeplasareVirament",
		"DocumentPlataOrdonantareSuma": "documente.DocumentPlataOrdonantareSuma",
		"DocumentAvansSpreDecontare": "documente.DocumentAvansSpreDecontare",
		"DocumentContract": "documente.DocumentContract",
		"DocumentFurnizorClientGenerareExecutieBugetara": "documente.DocumentFurnizorClientGenerareExecutieBugetara",
		"DocumentGarantieParticipareLicitatie": "documente.DocumentGarantieParticipareLicitatie",
		"DocumentPlataOPME": "documente.DocumentPlataOPME",
		"DocumentOperatiuneProprie": "documente.DocumentOperatiuneProprie",
		"DocumentJustificativ": "documente.DocumentJustificativ",
		"DocumentFurnizorClient": "documente.DocumentFurnizorClient",
		"DocumentFoaieVarsamantIncasareCheltuiala": "documente.DocumentFoaieVarsamantIncasareCheltuiala",
		"DocumentComanda": "documente.DocumentComanda",
		"DocumentObligatieSalarii": "documente.DocumentObligatieSalarii",
		"DocumentAvansSpreDecontareVirament": "documente.DocumentAvansSpreDecontareVirament",
		"OperatiuneCFPDocumentSubTip": "documente.OperatiuneCFPDocumentSubTip",
		"DocumentPlata": "documente.DocumentPlata",
		"RegistruVizaCFP": "documente.RegistruVizaCFP",
		"DocumentNumarSters": "documente.DocumentNumarSters",
		"DocumentOrdinDeplasare": "documente.DocumentOrdinDeplasare",
		"OperatiuneCFP": "documente.OperatiuneCFP",
		"DocumentFurnizorClientDetaliu": "documente.DocumentFurnizorClientDetaliu",
		"DocumentStatPlataNumerar": "documente.DocumentStatPlataNumerar",
		"DocumentOperatiuneProprieDetaliu": "documente.DocumentOperatiuneProprieDetaliu",
		"DocumentSursaTipData": "documente.DocumentSursaTipData",
		"DocumentContractMunca": "documente.DocumentContractMunca",
		"DocumentFurnizorRefacturareUtilitati": "documente.DocumentFurnizorRefacturareUtilitati",
		"DocumentDebitNota": "documente.DocumentDebitNota",
		"SetareNumarUrmatorAn": "documente.SetareNumarUrmatorAn",
		"DocumentNotaContabilaCorectieCABPdf417": "documente.DocumentNotaContabilaCorectieCABPdf417",
		"DocumentPlataPdf417": "documente.DocumentPlataPdf417",
		"DocumentDispozitiePlataDistribuireSume": "documente.DocumentDispozitiePlataDistribuireSume",
		"DocumentObligatieSalariiDetaliuPlata": "documente.DocumentObligatieSalariiDetaliuPlata",
		"DocumentListaZilnicaAlimente": "documente.DocumentListaZilnicaAlimente",
		"DocumentDecontCheltuiala": "documente.DocumentDecontCheltuiala",
		"DocumentFoaieVarsamant": "documente.DocumentFoaieVarsamant",
		"DocumentAvansSpreDecontareSold": "documente.DocumentAvansSpreDecontareSold",
		"DocumentSursa": "documente.DocumentSursa",
		"DocumentNotaContabilaCorectieCAB": "documente.DocumentNotaContabilaCorectieCAB",
		"DocumentFurnizorClientDetaliuClasificatieBugetara": "documente.DocumentFurnizorClientDetaliuClasificatieBugetara",
		"DocumentOrganizareInstitutionala": "documente.DocumentOrganizareInstitutionala",
		"DocumentCentralizatorVenitDebitareContabilitate": "documente.DocumentCentralizatorVenitDebitareContabilitate",
		"DocumentFurnizorClientDetaliuSursa": "documente.DocumentFurnizorClientDetaliuSursa",
		"DocumentSursaTip": "documente.DocumentSursaTip",
		"DocumentFurnizorClientDetaliuSursaDocument": "documente.DocumentFurnizorClientDetaliuSursaDocument",
		"DocumentDispozitiePlata": "documente.DocumentDispozitiePlata",
		"SetareNumarUrmator": "documente.SetareNumarUrmator",
		"DocumentReevaluare": "documente.DocumentReevaluare",
		"DocumentMiscareInventarOrganizareInstitutionalaPredare": "documente.DocumentMiscareInventarOrganizareInstitutionalaPredare",
		"DocumentProcesVerbalObiectivInCurs": "documente.DocumentProcesVerbalObiectivInCurs",
		"DocumentNotaContabilaCorectieCABDetaliu": "documente.DocumentNotaContabilaCorectieCABDetaliu",
		"aTstCsv": "documente.aTstCsv",
		"DocumentCecRidicareNumerar": "documente.DocumentCecRidicareNumerar",
		"Document": "documente.Document"
	},
	"documenteIstoric": {
		"OperatiuneCFP": "documenteistoric.OperatiuneCFP",
		"DocumentContractMunca": "documenteistoric.DocumentContractMunca",
		"OperatiuneCFPDocumentSubTip": "documenteistoric.OperatiuneCFPDocumentSubTip",
		"OperatiuneCFPClasificatieEconomica": "documenteistoric.OperatiuneCFPClasificatieEconomica"
	},
	"eFactura": {
		"MonedaBt5": "efactura.MonedaBt5",
		"InstructiunePlataBg16": "efactura.InstructiunePlataBg16",
		"TvaCategorieBt151": "efactura.TvaCategorieBt151",
		"TaraBt40": "efactura.TaraBt40",
		"TvaMotivScutireBt121": "efactura.TvaMotivScutireBt121",
		"Fisier": "efactura.Fisier",
		"DiscountMotivCodBT140": "efactura.DiscountMotivCodBT140",
		"eFactura": "efactura.eFactura",
		"UnitateMasuraBt130": "efactura.UnitateMasuraBt130",
		"FacturaTipBt3": "efactura.FacturaTipBt3"
	},
	"epay": {
		"DovadaPlataOnlineContinut": "epay.DovadaPlataOnlineContinut",
		"DovadaPlataOnline": "epay.DovadaPlataOnline",
		"PersoanaOnline": "epay.PersoanaOnline"
	},
	"ghiseul": {
		"BorderouIncasareDetalii": "ghiseul.BorderouIncasareDetalii",
		"BorderouIncasare": "ghiseul.BorderouIncasare"
	},
	"importData": {
		"LegaturaScara": "importdata.LegaturaScara",
		"LegaturaStrada": "importdata.LegaturaStrada",
		"LegaturaTipVenit": "importdata.LegaturaTipVenit",
		"LegaturaNumarPostal": "importdata.LegaturaNumarPostal",
		"LegaturaBloc": "importdata.LegaturaBloc",
		"DateAfectateStergereUnitate": "importdata.DateAfectateStergereUnitate",
		"StandardMiFix": "importdata.StandardMiFix",
		"LegaturaApartament": "importdata.LegaturaApartament",
		"LegaturaPersoana": "importdata.LegaturaPersoana",
		"StandardStoc": "importdata.StandardStoc",
		"ConversieTemplate": "importdata.ConversieTemplate",
		"ConversiePasStatus": "importdata.ConversiePasStatus",
		"DateNeafectateStergereUnitate": "importdata.DateNeafectateStergereUnitate"
	},
	"impotax": {
		"SpectacolValoare": "impotax.SpectacolValoare",
		"RamasitaSimulat": "impotax.RamasitaSimulat",
		"TaxaFirmaAfisajValoare": "impotax.TaxaFirmaAfisajValoare",
		"SarbatoareLegala": "impotax.SarbatoareLegala",
		"TaxaFirmaAfisaj": "impotax.TaxaFirmaAfisaj",
		"Penalitate": "impotax.Penalitate",
		"Procentmajorari2013": "impotax.Procentmajorari2013",
		"RamasitaInsolvabil": "impotax.RamasitaInsolvabil",
		"Ramasita": "impotax.Ramasita",
		"Teren": "impotax.Teren",
		"TerenCategorie": "impotax.TerenCategorie",
		"TerenIntravilanAgricolValoare": "impotax.TerenIntravilanAgricolValoare",
		"TerenExtravilanValoare": "impotax.TerenExtravilanValoare",
		"TipAlteDebite": "impotax.TipAlteDebite",
		"TerenValoareCorectieRang": "impotax.TerenValoareCorectieRang",
		"TerenSubCategorie": "impotax.TerenSubCategorie",
		"TerenIntravilanCurteValoare": "impotax.TerenIntravilanCurteValoare",
		"LegaturaPersoanaBanca": "impotax.LegaturaPersoanaBanca",
		"MarcaAuto": "impotax.MarcaAuto",
		"NotaContabilaSablon": "impotax.NotaContabilaSablon",
		"IncasareTipIntrare": "impotax.IncasareTipIntrare",
		"Incasare": "impotax.Incasare",
		"IncasareInsolvabil": "impotax.IncasareInsolvabil",
		"DocumentListat": "impotax.DocumentListat",
		"DocumentListatDetaliu": "impotax.DocumentListatDetaliu",
		"DeclaratiePeOre": "impotax.DeclaratiePeOre",
		"DebitDetaliu": "impotax.DebitDetaliu",
		"DebitScadenta": "impotax.DebitScadenta",
		"Dobanda": "impotax.Dobanda",
		"DeclaratieTipOperatie": "impotax.DeclaratieTipOperatie",
		"CladireValoareProcentMansarda": "impotax.CladireValoareProcentMansarda",
		"CladireValoareRangZona": "impotax.CladireValoareRangZona",
		"CladireValoareReducereAnConstructie": "impotax.CladireValoareReducereAnConstructie",
		"CladireValoareTipCladireJuridica": "impotax.CladireValoareTipCladireJuridica",
		"CursValuta": "impotax.CursValuta",
		"Debit": "impotax.Debit",
		"AlocareNrChitanta": "impotax.AlocareNrChitanta",
		"Amenda": "impotax.Amenda",
		"AlteDebite": "impotax.AlteDebite",
		"AutoRegistru": "impotax.AutoRegistru",
		"AutoVanzare": "impotax.AutoVanzare",
		"AutoValoareImpozitare": "impotax.AutoValoareImpozitare",
		"Cladire": "impotax.Cladire",
		"Banca": "impotax.Banca",
		"CladirePFValoareImpozitare": "impotax.CladirePFValoareImpozitare",
		"CladirePJValoareImpozitare": "impotax.CladirePJValoareImpozitare",
		"CladireValoareImpozitMajorat": "impotax.CladireValoareImpozitMajorat",
		"AutoLenteValoareImpozitare": "impotax.AutoLenteValoareImpozitare",
		"AutoRetro": "impotax.AutoRetro",
		"AutoLente": "impotax.AutoLente",
		"Auto": "impotax.Auto",
		"ValoareDebitCheltuialaExecutareSilita": "impotax.ValoareDebitCheltuialaExecutareSilita",
		"ValoareDebitPoprire": "impotax.ValoareDebitPoprire",
		"ValoareTaxaLunara": "impotax.ValoareTaxaLunara",
		"VenitDetaliuVariabila": "impotax.VenitDetaliuVariabila",
		"Venit": "impotax.Venit",
		"TipValuta": "impotax.TipValuta",
		"ValoareCoeficientMajorareConsiliuLocal": "impotax.ValoareCoeficientMajorareConsiliuLocal",
		"ValoareDataScadenta": "impotax.ValoareDataScadenta",
		"TipStarePosta": "impotax.TipStarePosta",
		"TipSursa": "impotax.TipSursa",
		"TipScutire": "impotax.TipScutire",
		"TipAutovehicul": "impotax.TipAutovehicul",
		"TipAutoLente": "impotax.TipAutoLente",
		"TipCertificatFiscal": "impotax.TipCertificatFiscal",
		"TipAutovehiculRetro": "impotax.TipAutovehiculRetro",
		"TipOperatie": "impotax.TipOperatie",
		"TipOperatieLegatura": "impotax.TipOperatieLegatura",
		"TipOperatieGrup": "impotax.TipOperatieGrup",
		"TipDocumentDeclaratie": "impotax.TipDocumentDeclaratie",
		"TipCladire": "impotax.TipCladire",
		"TipEmitent": "impotax.TipEmitent",
		"Versiune": "impotax.Versiune",
		"VenitVariabila": "impotax.VenitVariabila",
		"VenitGrup": "impotax.VenitGrup"
	},
	"invatamant": {
		"GrupaCopilAbsente_DeSters": "invatamant.GrupaCopilAbsente_DeSters",
		"ValoareDebit": "invatamant.ValoareDebit",
		"GrupaCopil": "invatamant.GrupaCopil",
		"Versiune": "invatamant.Versiune",
		"Luna": "invatamant.Luna",
		"GrupaCopilPrezenta": "invatamant.GrupaCopilPrezenta",
		"Vacanta": "invatamant.Vacanta",
		"MaskZilePrezenta": "invatamant.MaskZilePrezenta"
	},
	"invatamantIstoric": {
		"ValoareDebit": "invatamantistoric.ValoareDebit",
		"GrupaCopil": "invatamantistoric.GrupaCopil"
	},
	"inventare": {
		"ProdusCaracteristici": "inventare.ProdusCaracteristici",
		"Amortizare": "inventare.Amortizare",
		"DocumentConsumAlimenteSuplimentare": "inventare.DocumentConsumAlimenteSuplimentare",
		"AmortizareEstimare": "inventare.AmortizareEstimare",
		"DocumentPropunereCasare": "inventare.DocumentPropunereCasare",
		"DocumentPropunereCasareProdus": "inventare.DocumentPropunereCasareProdus",
		"ProdusInfluentaValoare": "inventare.ProdusInfluentaValoare",
		"ProdusTipFinantare": "inventare.ProdusTipFinantare",
		"DocumentReevaluarePropunere": "inventare.DocumentReevaluarePropunere",
		"ProdusBalanta": "inventare.ProdusBalanta",
		"ProdusCategorie": "inventare.ProdusCategorie",
		"ProdusIesire": "inventare.ProdusIesire",
		"ProdusIntrare": "inventare.ProdusIntrare",
		"Versiune": "inventare.Versiune",
		"ProdusModOrganizare": "inventare.ProdusModOrganizare",
		"Produs": "inventare.Produs"
	},
	"inventareIstoric": {
		"Produs": "inventareistoric.Produs",
		"ProdusCaracteristici": "inventareistoric.ProdusCaracteristici"
	},
	"investitii": {
		"Obiectiv": "investitii.Obiectiv",
		"Versiune": "investitii.Versiune"
	},
	"investitiiIstoric": {
		"Obiectiv": "investitiiistoric.Obiectiv"
	},
	"log": {
		"Versiune": "log.Versiune",
		"ProblemaUnitate": "log.ProblemaUnitate",
		"Resursa": "log.Resursa",
		"OprireDeclansatori": "log.OprireDeclansatori",
		"ThirdAppRequestService": "log.ThirdAppRequestService",
		"AuditDDL": "log.AuditDDL",
		"ImportCrediteBugetareErrorReport": "log.ImportCrediteBugetareErrorReport",
		"StoredProcedure": "log.StoredProcedure",
		"Query": "log.Query",
		"Request": "log.Request",
		"Report": "log.Report"
	},
	"nomenclatoare": {
		"TipInchidere": "nomenclatoare.TipInchidere",
		"TipModalitateAchizitie": "nomenclatoare.TipModalitateAchizitie",
		"CAEN": "nomenclatoare.CAEN",
		"PlataNumerarTip": "nomenclatoare.PlataNumerarTip",
		"CategorieVenit": "nomenclatoare.CategorieVenit",
		"UtilitatiTip": "nomenclatoare.UtilitatiTip",
		"ProdusGrupTip": "nomenclatoare.ProdusGrupTip",
		"ProdusTip": "nomenclatoare.ProdusTip",
		"TipProceduraAchizitie": "nomenclatoare.TipProceduraAchizitie",
		"GenerareContabValoareTip": "nomenclatoare.GenerareContabValoareTip",
		"DocumentFurnizorClientDetaliuTip": "nomenclatoare.DocumentFurnizorClientDetaliuTip",
		"AsociereContBancaTipBuget": "nomenclatoare.AsociereContBancaTipBuget",
		"CrediteBugetareTip": "nomenclatoare.CrediteBugetareTip",
		"RetinereSalariiTipPersonalizare": "nomenclatoare.RetinereSalariiTipPersonalizare",
		"DocumentTip": "nomenclatoare.DocumentTip",
		"NaturaPlata": "nomenclatoare.NaturaPlata",
		"Venit": "nomenclatoare.Venit",
		"ProdusDetaliuTip": "nomenclatoare.ProdusDetaliuTip",
		"CotaTva": "nomenclatoare.CotaTva",
		"CodProblemaUnitateTip": "nomenclatoare.CodProblemaUnitateTip",
		"TipObiectAchizitie": "nomenclatoare.TipObiectAchizitie",
		"SarbatoareLegala": "nomenclatoare.SarbatoareLegala",
		"UnitateMasura": "nomenclatoare.UnitateMasura",
		"Valuta": "nomenclatoare.Valuta",
		"ComisiaTip": "nomenclatoare.ComisiaTip",
		"DocumentSubTip": "nomenclatoare.DocumentSubTip",
		"RetinereSalariiTip": "nomenclatoare.RetinereSalariiTip",
		"NumarTextSuteZeciUnitati": "nomenclatoare.NumarTextSuteZeciUnitati",
		"NumarTextZeci": "nomenclatoare.NumarTextZeci",
		"DocumentOperatiuneTip": "nomenclatoare.DocumentOperatiuneTip",
		"NumarTextUnitati": "nomenclatoare.NumarTextUnitati",
		"TipTva": "nomenclatoare.TipTva",
		"PersoanaIerarhieTip": "nomenclatoare.PersoanaIerarhieTip",
		"DocumentStareTip": "nomenclatoare.DocumentStareTip",
		"GenerareContabOperatiuneProdusValoareTip": "nomenclatoare.GenerareContabOperatiuneProdusValoareTip",
		"DocumentSursaTemplate": "nomenclatoare.DocumentSursaTemplate",
		"CPV": "nomenclatoare.CPV",
		"NumarTextSute": "nomenclatoare.NumarTextSute",
		"NumarTextNivele": "nomenclatoare.NumarTextNivele",
		"ServiciuTip": "nomenclatoare.ServiciuTip"
	},
	"nomenclatoareIstoric": {
		"UtilitatiTip": "nomenclatoareistoric.UtilitatiTip",
		"TipTva": "nomenclatoareistoric.TipTva",
		"RetinereSalariiTip": "nomenclatoareistoric.RetinereSalariiTip",
		"CPV": "nomenclatoareistoric.CPV",
		"PlataNumerarTip": "nomenclatoareistoric.PlataNumerarTip",
		"AsociereContBancaTipBuget": "nomenclatoareistoric.AsociereContBancaTipBuget",
		"ServiciuTip": "nomenclatoareistoric.ServiciuTip",
		"CAEN": "nomenclatoareistoric.CAEN",
		"CotaTva": "nomenclatoareistoric.CotaTva",
		"RetinereSalariiTipPersonalizare": "nomenclatoareistoric.RetinereSalariiTipPersonalizare",
		"DocumentFurnizorClientDetaliuTip": "nomenclatoareistoric.DocumentFurnizorClientDetaliuTip"
	},
	"partener": {
		"Partener": "partener.Partener",
		"Regiune": "partener.Regiune",
		"Localitate": "partener.Localitate"
	},
	"persoane": {
		"Comisia": "persoane.Comisia",
		"ComisiaPersoana": "persoane.ComisiaPersoana",
		"PersoanaNationalitate": "persoane.PersoanaNationalitate",
		"PersoanaReligie": "persoane.PersoanaReligie",
		"PersoanaEtnie": "persoane.PersoanaEtnie",
		"CuiSincronizareAnaf": "persoane.CuiSincronizareAnaf",
		"PersoanaBancaIban": "persoane.PersoanaBancaIban",
		"PersoanaNivelStudii": "persoane.PersoanaNivelStudii",
		"PersoanaDocumentAtasat": "persoane.PersoanaDocumentAtasat",
		"PersoanaOcupatie": "persoane.PersoanaOcupatie",
		"PersoanaUtilitati": "persoane.PersoanaUtilitati",
		"PersoanaIerarhie": "persoane.PersoanaIerarhie",
		"PersoanaFormaOrganizare": "persoane.PersoanaFormaOrganizare",
		"ControlorCFP": "persoane.ControlorCFP",
		"Persoana": "persoane.Persoana",
		"PersoanaDocument": "persoane.PersoanaDocument",
		"PersoanaCategorieVenit": "persoane.PersoanaCategorieVenit"
	},
	"persoaneIstoric": {
		"Persoana": "persoaneistoric.Persoana",
		"ControlorCFP": "persoaneistoric.ControlorCFP",
		"PersoanaIerarhie": "persoaneistoric.PersoanaIerarhie",
		"PersoanaCategorieVenit": "persoaneistoric.PersoanaCategorieVenit",
		"PersoanaDocument": "persoaneistoric.PersoanaDocument",
		"PersoanaBancaIban": "persoaneistoric.PersoanaBancaIban",
		"ComisiaPersoana": "persoaneistoric.ComisiaPersoana"
	},
	"produs": {
		"ProdusRaportare": "produs.ProdusRaportare"
	},
	"raportariFinanciare": {
		"Anexa_19_rows_old": "raportarifinanciare.Anexa_19_rows_old",
		"Anexa30SintezaPlatiRest": "raportarifinanciare.Anexa30SintezaPlatiRest",
		"Anexa30SintezaPlatiArierate": "raportarifinanciare.Anexa30SintezaPlatiArierate",
		"ExtrasContTrezo": "raportarifinanciare.ExtrasContTrezo",
		"ExtrasContTrezoDetalii": "raportarifinanciare.ExtrasContTrezoDetalii",
		"Anexa_19_rows": "raportarifinanciare.Anexa_19_rows",
		"ExtrasContTrezoSoldInitial": "raportarifinanciare.ExtrasContTrezoSoldInitial",
		"Formular": "raportarifinanciare.Formular",
		"ExtrasContTrezoDetaliiMisc": "raportarifinanciare.ExtrasContTrezoDetaliiMisc",
		"ExtrasContTrezoLichidari": "raportarifinanciare.ExtrasContTrezoLichidari",
		"ExtrasContTrezoSumaCredit": "raportarifinanciare.ExtrasContTrezoSumaCredit",
		"FormularStatus": "raportarifinanciare.FormularStatus",
		"RegistruSchemaXSD": "raportarifinanciare.RegistruSchemaXSD",
		"LogGenerareXML": "raportarifinanciare.LogGenerareXML"
	},
	"raportariFinanciareIstoric": {
		"ExtrasContTrezoSumaCredit": "raportarifinanciareistoric.ExtrasContTrezoSumaCredit"
	},
	"registre": {
		"OperatiuneProprieTip": "registre.OperatiuneProprieTip",
		"VenitDetaliu": "registre.VenitDetaliu",
		"IndicePretConsum": "registre.IndicePretConsum",
		"CursValutar": "registre.CursValutar",
		"OperatiuneProprieTipDetaliu": "registre.OperatiuneProprieTipDetaliu",
		"LocatieSpatiuTip": "registre.LocatieSpatiuTip",
		"PostNume": "registre.PostNume",
		"LocatieTip": "registre.LocatieTip",
		"ProdusClasificare": "registre.ProdusClasificare",
		"PlataNumerarTipDetaliu": "registre.PlataNumerarTipDetaliu"
	},
	"registreIstoric": {
		"PlataNumerarTipDetaliu": "registreistoric.PlataNumerarTipDetaliu",
		"ProdusClasificare": "registreistoric.ProdusClasificare",
		"OperatiuneProprieTip": "registreistoric.OperatiuneProprieTip",
		"VenitDetaliu": "registreistoric.VenitDetaliu"
	},
	"resal": {
		"Versiune": "resal.Versiune",
		"Functia": "resal.Functia"
	},
	"resalIstoric": {
		"Functia": "resalistoric.Functia"
	},
	"salarii": {
		"SurseFinantareTest": "salarii.SurseFinantareTest"
	},
	"sief": {
		"DeclaratieSerieFactura": "sief.DeclaratieSerieFactura",
		"DeclaratieOp2": "sief.DeclaratieOp2",
		"Setare": "sief.Setare",
		"JurnalAnalitic": "sief.JurnalAnalitic",
		"TipDocument": "sief.TipDocument",
		"TipTva": "sief.TipTva",
		"Declaratie": "sief.Declaratie",
		"DeclaratieFactura": "sief.DeclaratieFactura"
	},
	"situatiiPersonal": {
		"Formular": "situatiipersonal.Formular"
	},
	"unitati": {
		"OrganizareInstitutionalaBugetClasificatieFunctionala": "unitati.OrganizareInstitutionalaBugetClasificatieFunctionala",
		"OperatiuneActivitateEconomicaClasificatieBugetaraDetaliu": "unitati.OperatiuneActivitateEconomicaClasificatieBugetaraDetaliu",
		"OrganizareInstitutionala": "unitati.OrganizareInstitutionala",
		"OperatiuneActivitateEconomicaClasificatieBugetara": "unitati.OperatiuneActivitateEconomicaClasificatieBugetara",
		"Functie": "unitati.Functie",
		"UnitateSectorBugetarOpcOsc": "unitati.UnitateSectorBugetarOpcOsc",
		"Post": "unitati.Post",
		"UnitateSetare": "unitati.UnitateSetare"
	},
	"unitatiIstoric": {
		"UnitateSetare": "unitatiistoric.UnitateSetare",
		"Post": "unitatiistoric.Post",
		"OrganizareInstitutionala": "unitatiistoric.OrganizareInstitutionala"
	},
	"vehicol": {
		"AutoCategorieRAR": "vehicol.AutoCategorieRAR",
		"AutoModel": "vehicol.AutoModel",
		"AutoCategorieFiscal": "vehicol.AutoCategorieFiscal",
		"AutoPersoana": "vehicol.AutoPersoana",
		"AutoMarca": "vehicol.AutoMarca",
		"test": "vehicol.test",
		"Auto": "vehicol.Auto"
	},
	"vehicoldb": {
		"Auto": "vehicoldb.Auto"
	},
	"w_achizitii": {
		"Program": "w_achizitii.Program",
		"DocumentReferatPropunere": "w_achizitii.DocumentReferatPropunere",
		"AnBlocarePropuneri": "w_achizitii.AnBlocarePropuneri",
		"DocumentReferatNecesitateSursaFinantare": "w_achizitii.DocumentReferatNecesitateSursaFinantare",
		"ProceduraTipEtapaDocumentTipModel": "w_achizitii.ProceduraTipEtapaDocumentTipModel",
		"ProceduraTipEtapaDocumentTip": "w_achizitii.ProceduraTipEtapaDocumentTip",
		"ProceduraTipEtapa": "w_achizitii.ProceduraTipEtapa",
		"AchizitieOferta": "w_achizitii.AchizitieOferta",
		"DocumentReferatNecesitate": "w_achizitii.DocumentReferatNecesitate",
		"AchizitieSursaFinantare": "w_achizitii.AchizitieSursaFinantare",
		"Achizitie": "w_achizitii.Achizitie",
		"ProgramPozitieReferatNecesitateDetaliu": "w_achizitii.ProgramPozitieReferatNecesitateDetaliu",
		"ProgramPozitieSursaFinantare": "w_achizitii.ProgramPozitieSursaFinantare",
		"ProgramPozitieReferatPropunereDetaliu": "w_achizitii.ProgramPozitieReferatPropunereDetaliu",
		"Criteriu": "w_achizitii.Criteriu"
	},
	"w_achizitiiIstoric": {
		"AchizitieDetaliu": "w_achizitiiistoric.AchizitieDetaliu",
		"DocumentReferatNecesitateDetaliu": "w_achizitiiistoric.DocumentReferatNecesitateDetaliu",
		"DocumentReferatPropunereDetaliu": "w_achizitiiistoric.DocumentReferatPropunereDetaliu",
		"ProgramPozitie": "w_achizitiiistoric.ProgramPozitie"
	},
	"w_admin": {
		"User": "w_admin.User",
		"RelatiiTabele": "w_admin.RelatiiTabele",
		"Setare": "w_admin.Setare",
		"Notificare": "w_admin.Notificare",
		"UserAcces": "w_admin.UserAcces",
		"Rol": "w_admin.Rol",
		"UserUnitate": "w_admin.UserUnitate",
		"ResursaRol": "w_admin.ResursaRol",
		"UserCid": "w_admin.UserCid",
		"RolAcces": "w_admin.RolAcces",
		"SetareUser": "w_admin.SetareUser",
		"Resursa": "w_admin.Resursa",
		"RefreshToken": "w_admin.RefreshToken",
		"HelpPageTemplate": "w_admin.HelpPageTemplate",
		"ResursaControl": "w_admin.ResursaControl",
		"SetareUnitateUser": "w_admin.SetareUnitateUser",
		"SetareUnitate": "w_admin.SetareUnitate"
	},
	"w_adrese": {
		"Regiune": "w_adrese.Regiune",
		"LocalitateTip": "w_adrese.LocalitateTip",
		"StradaTip": "w_adrese.StradaTip"
	},
	"w_adreseIstoric": {
		"Localitate": "w_adreseistoric.Localitate",
		"NumarPostalZona": "w_adreseistoric.NumarPostalZona",
		"Bloc": "w_adreseistoric.Bloc",
		"NumarPostal": "w_adreseistoric.NumarPostal",
		"Scara": "w_adreseistoric.Scara",
		"Tara": "w_adreseistoric.Tara",
		"Judet": "w_adreseistoric.Judet",
		"Strada": "w_adreseistoric.Strada",
		"Apartament": "w_adreseistoric.Apartament"
	},
	"w_asisocIstoric": {
		"HandicapGrad": "w_asisocistoric.HandicapGrad",
		"HandicapTip": "w_asisocistoric.HandicapTip"
	},
	"w_bilant": {
		"Formular": "w_bilant.Formular"
	},
	"w_buget": {
		"DocumentDeschidereCrediteSuma": "w_buget.DocumentDeschidereCrediteSuma",
		"DocumentCerereCrediteSuma": "w_buget.DocumentCerereCrediteSuma",
		"DocumentDeschidereCredite": "w_buget.DocumentDeschidereCredite",
		"DocumentPropunereCheltuiala": "w_buget.DocumentPropunereCheltuiala",
		"DocumentCerereCredite": "w_buget.DocumentCerereCredite",
		"BugetSuma": "w_buget.BugetSuma",
		"DocumentAngajamentLegalSuplimentare": "w_buget.DocumentAngajamentLegalSuplimentare",
		"DocumentAngajamentBugetarSuplimentare": "w_buget.DocumentAngajamentBugetarSuplimentare",
		"SumaExecutie": "w_buget.SumaExecutie",
		"DocumentOrdonantare": "w_buget.DocumentOrdonantare",
		"DocumentLichidare": "w_buget.DocumentLichidare",
		"DocumentAngajamentLegal": "w_buget.DocumentAngajamentLegal",
		"BugetRectificare": "w_buget.BugetRectificare",
		"BugetClasificatieFunctionalaSuma": "w_buget.BugetClasificatieFunctionalaSuma",
		"DocumentAngajamentBugetar": "w_buget.DocumentAngajamentBugetar"
	},
	"w_bugetIstoric": {
		"ClasificatieFunctionala": "w_bugetistoric.ClasificatieFunctionala",
		"ClasificatieEconomica": "w_bugetistoric.ClasificatieEconomica",
		"SursaFinantare": "w_bugetistoric.SursaFinantare",
		"SectorBugetar": "w_bugetistoric.SectorBugetar",
		"ClasificatieFunctionalaTree": "w_bugetistoric.ClasificatieFunctionalaTree",
		"Buget": "w_bugetistoric.Buget",
		"AsociereCapitolSectorSursa": "w_bugetistoric.AsociereCapitolSectorSursa",
		"BugetClasificatieFunctionalaPersonalizare": "w_bugetistoric.BugetClasificatieFunctionalaPersonalizare",
		"BugetClasificatieFunctionalaTree": "w_bugetistoric.BugetClasificatieFunctionalaTree",
		"FormularClasificatieEconomica": "w_bugetistoric.FormularClasificatieEconomica",
		"FormularBugetTree": "w_bugetistoric.FormularBugetTree",
		"FormularClasificatieEconomicaFormula": "w_bugetistoric.FormularClasificatieEconomicaFormula",
		"FormularClasificatieEconomicaTree": "w_bugetistoric.FormularClasificatieEconomicaTree",
		"CodProgramBugetar": "w_bugetistoric.CodProgramBugetar",
		"BugetClasificatieFunctionala": "w_bugetistoric.BugetClasificatieFunctionala",
		"FormularBuget": "w_bugetistoric.FormularBuget",
		"BugetClasificatieFunctionalaFormulaPersonalizare": "w_bugetistoric.BugetClasificatieFunctionalaFormulaPersonalizare",
		"FormularBugetFormula": "w_bugetistoric.FormularBugetFormula"
	},
	"w_cantina": {
		"SoldInitial": "w_cantina.SoldInitial",
		"PreparatCategorie": "w_cantina.PreparatCategorie",
		"PreparatCategorieBeneficiar": "w_cantina.PreparatCategorieBeneficiar",
		"PreparatAliment": "w_cantina.PreparatAliment",
		"Preparat": "w_cantina.Preparat",
		"CategorieBeneficiar": "w_cantina.CategorieBeneficiar",
		"MeniuPlanificat": "w_cantina.MeniuPlanificat",
		"MeniuPlanificatDetaliu": "w_cantina.MeniuPlanificatDetaliu",
		"MeniuZiCategorieBeneficiar": "w_cantina.MeniuZiCategorieBeneficiar",
		"MeniuZi": "w_cantina.MeniuZi",
		"MeniuZiPreparat": "w_cantina.MeniuZiPreparat",
		"OraServireCategorieBeneficiar": "w_cantina.OraServireCategorieBeneficiar",
		"Alergen": "w_cantina.Alergen",
		"SemnaturaListaZilnicaAlimente": "w_cantina.SemnaturaListaZilnicaAlimente",
		"Aliment": "w_cantina.Aliment",
		"MasaTip": "w_cantina.MasaTip"
	},
	"w_cantinaIstoric": {
		"CategorieBeneficiarOrganizareInstitutionala": "w_cantinaistoric.CategorieBeneficiarOrganizareInstitutionala"
	},
	"w_contab": {
		"Balanta": "w_contab.Balanta",
		"TipInchidere": "w_contab.TipInchidere",
		"Nota": "w_contab.Nota",
		"NotaPropusa": "w_contab.NotaPropusa",
		"DocumentFurnizorClientSoldCont": "w_contab.DocumentFurnizorClientSoldCont"
	},
	"w_contabIstoric": {
		"GenerareNotaContTemplate": "w_contabistoric.GenerareNotaContTemplate",
		"AsociereContCrediteBugetare": "w_contabistoric.AsociereContCrediteBugetare",
		"AlgoritmInchidereConturi": "w_contabistoric.AlgoritmInchidereConturi",
		"Cont": "w_contabistoric.Cont",
		"GenerareNotaContTemplateDefault": "w_contabistoric.GenerareNotaContTemplateDefault",
		"Jurnal": "w_contabistoric.Jurnal",
		"AsociereActivitateEconomicaArticol": "w_contabistoric.AsociereActivitateEconomicaArticol",
		"GenerareNotaOperatiuneContTemplate": "w_contabistoric.GenerareNotaOperatiuneContTemplate",
		"GenerareNotaAlgoritm": "w_contabistoric.GenerareNotaAlgoritm",
		"GenerareNotaOperatiuneContTemplateDefault": "w_contabistoric.GenerareNotaOperatiuneContTemplateDefault"
	},
	"w_dareSeama": {
		"Formular": "w_dareseama.Formular",
		"AIndex": "w_dareseama.AIndex"
	},
	"w_dataService": {
		"DataUpdateLog": "w_dataservice.DataUpdateLog"
	},
	"w_documente": {
		"DocumentDispozitieIncasareDistribuireSume": "w_documente.DocumentDispozitieIncasareDistribuireSume",
		"DocumentDebitNota": "w_documente.DocumentDebitNota",
		"DocumentDispozitiePlata": "w_documente.DocumentDispozitiePlata",
		"DocumentFoaieVarsamantIncasareCheltuiala": "w_documente.DocumentFoaieVarsamantIncasareCheltuiala",
		"DocumentReglareNotaContabila": "w_documente.DocumentReglareNotaContabila",
		"DocumentPlataIncasareCheltuiala": "w_documente.DocumentPlataIncasareCheltuiala",
		"DocumentReevaluare": "w_documente.DocumentReevaluare",
		"DocumentSursa": "w_documente.DocumentSursa",
		"DocumentGarantieBunaExecutie": "w_documente.DocumentGarantieBunaExecutie",
		"DocumentContractClasificatieBugetaraAnSume": "w_documente.DocumentContractClasificatieBugetaraAnSume",
		"DocumentCentralizatorOPIncasareDetaliu": "w_documente.DocumentCentralizatorOPIncasareDetaliu",
		"DocumentDispozitieIncasare": "w_documente.DocumentDispozitieIncasare",
		"DocumentAvansSpreDecontare": "w_documente.DocumentAvansSpreDecontare",
		"DocumentFoaieVarsamant": "w_documente.DocumentFoaieVarsamant",
		"DocumentAvansSpreDecontareVirament": "w_documente.DocumentAvansSpreDecontareVirament",
		"DocumentGarantieParticipareLicitatie": "w_documente.DocumentGarantieParticipareLicitatie",
		"DocumentContract": "w_documente.DocumentContract",
		"DocumentFlow": "w_documente.DocumentFlow",
		"DocumentFurnizorClientGenerareExecutieBugetara": "w_documente.DocumentFurnizorClientGenerareExecutieBugetara",
		"DocumentDispozitiePlataDistribuireSume": "w_documente.DocumentDispozitiePlataDistribuireSume",
		"DocumentDocumentPlata": "w_documente.DocumentDocumentPlata",
		"DocumentOperatiuneProprieDetaliu": "w_documente.DocumentOperatiuneProprieDetaliu",
		"DocumentProcesVerbalObiectivInCurs": "w_documente.DocumentProcesVerbalObiectivInCurs",
		"DocumentDecontCheltuiala": "w_documente.DocumentDecontCheltuiala",
		"DocumentOrdinDeplasare": "w_documente.DocumentOrdinDeplasare",
		"DocumentOperatiuneProprie": "w_documente.DocumentOperatiuneProprie",
		"DocumentOrdinDeplasareVirament": "w_documente.DocumentOrdinDeplasareVirament",
		"DocumentBorderouIncasareDetaliu": "w_documente.DocumentBorderouIncasareDetaliu",
		"RegistruVizaCFP": "w_documente.RegistruVizaCFP",
		"DocumentFurnizorClient": "w_documente.DocumentFurnizorClient",
		"DocumentObligatieSalarii": "w_documente.DocumentObligatieSalarii",
		"DocumentStare": "w_documente.DocumentStare",
		"DocumentStatPlataNumerar": "w_documente.DocumentStatPlataNumerar",
		"DocumentFurnizorClientDetaliu": "w_documente.DocumentFurnizorClientDetaliu",
		"DocumentCecRidicareNumerar": "w_documente.DocumentCecRidicareNumerar",
		"DocumentComanda": "w_documente.DocumentComanda",
		"SetareNumarUrmator": "w_documente.SetareNumarUrmator",
		"DocumentFurnizorRefacturareUtilitati": "w_documente.DocumentFurnizorRefacturareUtilitati",
		"DocumentObligatieSalariiDetaliuPlata": "w_documente.DocumentObligatieSalariiDetaliuPlata",
		"SetareNumarUrmatorAn": "w_documente.SetareNumarUrmatorAn",
		"DocumentMiscareInventar": "w_documente.DocumentMiscareInventar",
		"DocumentListaZilnicaAlimente": "w_documente.DocumentListaZilnicaAlimente",
		"DocumentSoldCasierie": "w_documente.DocumentSoldCasierie",
		"DocumentJustificativ": "w_documente.DocumentJustificativ",
		"DocumentPlata": "w_documente.DocumentPlata",
		"DocumentStatPlataNumerarDetaliu": "w_documente.DocumentStatPlataNumerarDetaliu",
		"Document": "w_documente.Document",
		"DocumentNotaContabilaCorectieCABDetaliu": "w_documente.DocumentNotaContabilaCorectieCABDetaliu",
		"DocumentNotaContabilaCorectieCAB": "w_documente.DocumentNotaContabilaCorectieCAB",
		"DocumentObligatieSalariiDetaliu": "w_documente.DocumentObligatieSalariiDetaliu"
	},
	"w_documenteIstoric": {
		"DocumentContractMunca": "w_documenteistoric.DocumentContractMunca",
		"OperatiuneCFPDocumentSubTip": "w_documenteistoric.OperatiuneCFPDocumentSubTip",
		"OperatiuneCFPClasificatieEconomica": "w_documenteistoric.OperatiuneCFPClasificatieEconomica",
		"OperatiuneCFP": "w_documenteistoric.OperatiuneCFP"
	},
	"w_eFactura": {
		"Fisier": "w_efactura.Fisier",
		"eFactura": "w_efactura.eFactura",
		"UnitateMasuraBt130": "w_efactura.UnitateMasuraBt130",
		"TvaCategorieBt151": "w_efactura.TvaCategorieBt151",
		"TvaMotivScutireBt121": "w_efactura.TvaMotivScutireBt121",
		"DiscountMotivCodBT140": "w_efactura.DiscountMotivCodBT140",
		"TaraBt40": "w_efactura.TaraBt40",
		"InstructiunePlataBg16": "w_efactura.InstructiunePlataBg16",
		"MonedaBt5": "w_efactura.MonedaBt5",
		"FacturaTipBt3": "w_efactura.FacturaTipBt3"
	},
	"w_ghiseul": {
		"BorderouIncasareDetalii": "w_ghiseul.BorderouIncasareDetalii",
		"BorderouIncasare": "w_ghiseul.BorderouIncasare"
	},
	"w_Impotax": {
		"Incasare": "w_impotax.Incasare",
		"Ramasita": "w_impotax.Ramasita",
		"DebitDetaliu": "w_impotax.DebitDetaliu",
		"DebitScadenta": "w_impotax.DebitScadenta",
		"TipSursa": "w_impotax.TipSursa",
		"TipOperatie": "w_impotax.TipOperatie",
		"TipAutovehicul": "w_impotax.TipAutovehicul",
		"TipOperatieLegatura": "w_impotax.TipOperatieLegatura",
		"Debit": "w_impotax.Debit",
		"TipOperatieGrup": "w_impotax.TipOperatieGrup"
	},
	"w_invatamant": {
		"GrupaCopilPrezenta": "w_invatamant.GrupaCopilPrezenta",
		"Luna": "w_invatamant.Luna",
		"ValoareDebit": "w_invatamant.ValoareDebit"
	},
	"w_invatamantIstoric": {
		"ValoareDebit": "w_invatamantistoric.ValoareDebit",
		"GrupaCopil": "w_invatamantistoric.GrupaCopil"
	},
	"w_inventare": {
		"ProdusCategorie": "w_inventare.ProdusCategorie",
		"DocumentConsumAlimenteSuplimentare": "w_inventare.DocumentConsumAlimenteSuplimentare",
		"DocumentReevaluarePropunere": "w_inventare.DocumentReevaluarePropunere",
		"DocumentPropunereCasare": "w_inventare.DocumentPropunereCasare",
		"ProdusIesire": "w_inventare.ProdusIesire",
		"ProdusModOrganizare": "w_inventare.ProdusModOrganizare",
		"ProdusTipFinantare": "w_inventare.ProdusTipFinantare",
		"DocumentPropunereCasareProdus": "w_inventare.DocumentPropunereCasareProdus",
		"ProdusInfluentaValoare": "w_inventare.ProdusInfluentaValoare",
		"ProdusIntrare": "w_inventare.ProdusIntrare"
	},
	"w_inventareIstoric": {
		"ProdusCaracteristici": "w_inventareistoric.ProdusCaracteristici",
		"Produs": "w_inventareistoric.Produs"
	},
	"w_investitiiIstoric": {
		"Obiectiv": "w_investitiiistoric.Obiectiv"
	},
	"w_log": {
		"ProblemaUnitate": "w_log.ProblemaUnitate",
		"OprireDeclansatori": "w_log.OprireDeclansatori"
	},
	"w_nomenclatoare": {
		"DocumentSubTip": "w_nomenclatoare.DocumentSubTip",
		"DocumentStareTip": "w_nomenclatoare.DocumentStareTip",
		"ComisiaTip": "w_nomenclatoare.ComisiaTip",
		"DocumentSursaTemplate": "w_nomenclatoare.DocumentSursaTemplate",
		"GenerareContabValoareTip": "w_nomenclatoare.GenerareContabValoareTip",
		"NumarTextNivele": "w_nomenclatoare.NumarTextNivele",
		"NumarTextZeci": "w_nomenclatoare.NumarTextZeci",
		"NumarTextUnitati": "w_nomenclatoare.NumarTextUnitati",
		"NumarTextSuteZeciUnitati": "w_nomenclatoare.NumarTextSuteZeciUnitati",
		"NumarTextSute": "w_nomenclatoare.NumarTextSute",
		"TipModalitateAchizitie": "w_nomenclatoare.TipModalitateAchizitie",
		"ProdusTip": "w_nomenclatoare.ProdusTip",
		"ProdusDetaliuTip": "w_nomenclatoare.ProdusDetaliuTip",
		"ProdusGrupTip": "w_nomenclatoare.ProdusGrupTip",
		"DocumentOperatiuneTip": "w_nomenclatoare.DocumentOperatiuneTip",
		"CrediteBugetareTip": "w_nomenclatoare.CrediteBugetareTip",
		"UnitateMasura": "w_nomenclatoare.UnitateMasura",
		"GenerareContabOperatiuneProdusValoareTip": "w_nomenclatoare.GenerareContabOperatiuneProdusValoareTip",
		"Valuta": "w_nomenclatoare.Valuta",
		"NaturaPlata": "w_nomenclatoare.NaturaPlata",
		"Venit": "w_nomenclatoare.Venit",
		"SarbatoareLegala": "w_nomenclatoare.SarbatoareLegala",
		"PersoanaIerarhieTip": "w_nomenclatoare.PersoanaIerarhieTip",
		"CategorieVenit": "w_nomenclatoare.CategorieVenit",
		"CodProblemaUnitateTip": "w_nomenclatoare.CodProblemaUnitateTip",
		"DocumentTip": "w_nomenclatoare.DocumentTip",
		"TipProceduraAchizitie": "w_nomenclatoare.TipProceduraAchizitie",
		"TipObiectAchizitie": "w_nomenclatoare.TipObiectAchizitie"
	},
	"w_nomenclatoareIstoric": {
		"UtilitatiTip": "w_nomenclatoareistoric.UtilitatiTip",
		"CPV": "w_nomenclatoareistoric.CPV",
		"DocumentFurnizorClientDetaliuTip": "w_nomenclatoareistoric.DocumentFurnizorClientDetaliuTip",
		"RetinereSalariiTip": "w_nomenclatoareistoric.RetinereSalariiTip",
		"ServiciuTip": "w_nomenclatoareistoric.ServiciuTip",
		"TipTva": "w_nomenclatoareistoric.TipTva",
		"AsociereContBancaTipBuget": "w_nomenclatoareistoric.AsociereContBancaTipBuget",
		"PlataNumerarTip": "w_nomenclatoareistoric.PlataNumerarTip",
		"CAEN": "w_nomenclatoareistoric.CAEN",
		"CotaTva": "w_nomenclatoareistoric.CotaTva"
	},
	"w_persoane": {
		"PersoanaIerarhie": "w_persoane.PersoanaIerarhie",
		"Comisia": "w_persoane.Comisia"
	},
	"w_persoaneIstoric": {
		"ComisiaPersoana": "w_persoaneistoric.ComisiaPersoana",
		"PersoanaIerarhie": "w_persoaneistoric.PersoanaIerarhie",
		"PersoanaBancaIban": "w_persoaneistoric.PersoanaBancaIban",
		"Persoana": "w_persoaneistoric.Persoana"
	},
	"w_produs": {
		"ProdusRaportare": "w_produs.ProdusRaportare"
	},
	"w_raportariFinanciare": {
		"ExtrasContTrezoSoldInitial": "w_raportarifinanciare.ExtrasContTrezoSoldInitial",
		"ExtrasContTrezoLichidari": "w_raportarifinanciare.ExtrasContTrezoLichidari",
		"ExtrasContTrezoDetalii": "w_raportarifinanciare.ExtrasContTrezoDetalii",
		"Formular": "w_raportarifinanciare.Formular",
		"FormularStatus": "w_raportarifinanciare.FormularStatus",
		"ExtrasContTrezoDetaliiMisc": "w_raportarifinanciare.ExtrasContTrezoDetaliiMisc",
		"LogGenerareXML": "w_raportarifinanciare.LogGenerareXML",
		"RegistruSchemaXSD": "w_raportarifinanciare.RegistruSchemaXSD",
		"ExtrasContTrezo": "w_raportarifinanciare.ExtrasContTrezo"
	},
	"w_raportariFinanciareIstoric": {
		"ExtrasContTrezoSumaCredit": "w_raportarifinanciareistoric.ExtrasContTrezoSumaCredit"
	},
	"w_registre": {
		"OperatiuneProprieTipDetaliu": "w_registre.OperatiuneProprieTipDetaliu"
	},
	"w_registreIstoric": {
		"OperatiuneProprieTip": "w_registreistoric.OperatiuneProprieTip",
		"VenitDetaliu": "w_registreistoric.VenitDetaliu",
		"PlataNumerarTipDetaliu": "w_registreistoric.PlataNumerarTipDetaliu",
		"ProdusClasificare": "w_registreistoric.ProdusClasificare"
	},
	"w_resalIstoric": {
		"Functia": "w_resalistoric.Functia"
	},
	"w_sief": {
		"TipTva": "w_sief.TipTva"
	},
	"w_situatiiPersonal": {
		"Formular": "w_situatiipersonal.Formular"
	},
	"w_unitati": {
		"OperatiuneActivitateEconomicaClasificatieBugetaraDetaliu": "w_unitati.OperatiuneActivitateEconomicaClasificatieBugetaraDetaliu",
		"OperatiuneActivitateEconomicaClasificatieBugetara": "w_unitati.OperatiuneActivitateEconomicaClasificatieBugetara",
		"UnitateSectorBugetarOpcOsc": "w_unitati.UnitateSectorBugetarOpcOsc",
		"OrganizareInstitutionalaBugetClasificatieFunctionala": "w_unitati.OrganizareInstitutionalaBugetClasificatieFunctionala"
	},
	"w_unitatiIstoric": {
		"UnitateSetare": "w_unitatiistoric.UnitateSetare",
		"OrganizareInstitutionala": "w_unitatiistoric.OrganizareInstitutionala"
	}
}