import { Injectable } from '@angular/core';
import {
    Router as ngRouter, NavigationExtras, ActivatedRoute, NavigationEnd
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, share, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RouteCommands } from '../controls/routeCommand';

interface IRouteQueryParams { [key: string]: any; returnUrl?: string; }
type IRouteParams = Array<string | number | boolean | Date | {
    [key: string]: string | number | Date
}>;

interface ActiveRouteExtended extends ActivatedRoute, RouteCommands { }

@Injectable({ providedIn: 'root' })
export class RoutingService {
    constructor(private windowTitle: Title, private ngRouter: ngRouter, activeRoute: ActivatedRoute) {
        this.onRouteChange = this.ngRouter.events.pipe(
            filter(e => e instanceof NavigationEnd),
            map(() => activeRoute),
            map(route => {
                while (route.firstChild) { route = route.firstChild }
                return route;
            }),
            tap(route => {
                this.activatedRoute = route;
                const param = route.snapshot.paramMap.get('returnUrl') || route.snapshot.queryParamMap.get('returnUrl');
                try {
                    this._routeUrl = param ? decodeURIComponent(window.atob(param)) : ngRouter.url.split(/\;|\?/)[0]
                } catch (error) { this._routeUrl = param }
            }),
            share()
        )
    }

    private params = {};
    private history = [];
    private _title: string;
    private _routeUrl: string;

    /** set by <router-outlet> */
    activeRoute: ActiveRouteExtended;
    /** set by NgRouter events */
    activatedRoute: ActivatedRoute;
    /** IF you `.subscribe()`, DON'T FORGET to `.unsubscribe()` */
    onRouteChange: Observable<ActivatedRoute>;

    get title() { return this._title }
    set returnUrl(value: string) { this._routeUrl = value }

    /** 
    * @BeCarefull with this, active route might not be updated yet
    * @consider using the `getParam2` with explicit activeRoute
    */
    getParam(key: string) { return this.getParam2(this.activatedRoute, key) }
    getParam2(activeRoute: ActivatedRoute, key: string) {
        try {
            const param = activeRoute.snapshot.paramMap.get(key);
            // return param === null ? null : decodeURIComponent(window.atob(decodeURIComponent(param)));
            return param === null ? null : decodeURIComponent(param);
        } catch (error) {
            return activeRoute.snapshot.paramMap.get(key)
        }
    }

    /** set window title */
    setTitle(title?: string) {
        this._title = title;
        this.windowTitle.setTitle(`Sigma${(title ? ` - ${title}` : '')}`);
    }

    getUrlTree(
        route: Array<string | number | Date | boolean | { [key: string]: string | number | Date }>,
        queryParams?: { [key: string]: string | number | Date }, extras?: NavigationExtras,
    ): string {
        extras = extras || {};
        if (!extras.queryParams) { extras.queryParams = queryParams || {} }
        if (!extras.queryParams.returnUrl) {
            extras.queryParams.returnUrl = this._routeUrl
        }
        const res = this.encryptOld(route, extras);
        return this.ngRouter.createUrlTree(res[0], res[1]).toString();
    }

    /** same as goNext, angular router uses navigate */
    navigate(
        route: Array<string | number | boolean | Date | { [key: string]: string | number | Date }>,
        queryParams?: { [key: string]: string | number | Date }, extras?: NavigationExtras,
    ): Promise<boolean> { return this.goNext(route, queryParams, extras) }

    goNext(
        route: Array<string | number | boolean | Date | { [key: string]: string | number | Date }>,
        queryParams?: { [key: string]: string | number | Date }, extras?: NavigationExtras,
    ): Promise<boolean> {
        extras = extras || {};
        if (this._routeUrl) {
            if (!extras.queryParams) { extras.queryParams = queryParams || {} }
            if (!extras.queryParams.returnUrl) {
                extras.queryParams.returnUrl = this._routeUrl
            }
        }
        const nav = this.encryptOld(route, extras);
        return this.ngRouter.navigate(nav[0], nav[1]);
    }

    goBack(
        params?: IRouteParams | { [key: string]: string | number | Date },
        queryParams?: IRouteQueryParams, extras?: NavigationExtras
    ): Promise<boolean> {
        if (queryParams) {
            if (!extras) { extras = { queryParams: queryParams } }
            else { extras.queryParams = queryParams }
        }
        let route: any[] = [this._routeUrl];
        if (params) {
            if (Array.isArray(params)) {
                route = route.concat(params)
            } else { route.push(params) }
        }
        const nav = this.encryptOld(route, extras);
        return this.ngRouter.navigate(nav[0], nav[1]);
    }

    private encrypt(params: IRouteParams, extras: NavigationExtras) {
        for (var i = 1; i < params.length; i++) {
            if (typeof params[i] === 'object' && params[i] !== null) {
                for (var key in <{ [key: string]: any }>params[i]) {
                    params[i][key] = window.btoa(encodeURIComponent(`${params[i][key]}`))
                }
            } else { params[i] = window.btoa(encodeURIComponent(`${params[i]}`)) }
        }
        if (extras && extras.queryParams) {
            for (var key in extras.queryParams)
                extras.queryParams[key] = window.btoa(encodeURIComponent(`${extras.queryParams[key]}`))
        }
        return [<any>params, extras]
    }

    /**
     * @deprecated va fi eliminat cand se renunta la angularjs
     */
    private encryptOld(params: IRouteParams, extras: NavigationExtras) {
        for (var i = 1; i < params.length; i++) {
            if (typeof params[i] === 'object' && params[i] !== null) {
                for (var key in <{ [key: string]: any }>params[i]) {
                    params[i][key] = encodeURIComponent(`${params[i][key]}`)
                }
            } else { params[i] = encodeURIComponent(`${params[i]}`) }
        }
        if (extras && extras.queryParams) {
            for (var key in extras.queryParams)
                extras.queryParams[key] = encodeURIComponent(`${extras.queryParams[key]}`)
        }
        if (params.length > 1) {
            params[0][0] = params[0][0] + encodeURIComponent(JSON.stringify(params[1]));
        }
        return [<any>params, extras]
    }
}