import { Injectable } from "@angular/core";
import { filter, mergeMap } from "rxjs";

import { DataService } from './DataService';
import { UserService } from './UserService';
import { RoutingService } from "./RouterService";
import { Query } from '../constants/Query';
import { ICommand } from "../controls/routeCommand";

export class RouteBtn implements ICommand {
    constructor(cmd: RouteBtn) {
        this.parentId = cmd.parentId && cmd.parentId.trim();
        this.hierarchyId = cmd.hierarchyId && cmd.hierarchyId.trim();
        this.type = cmd.type || 'cmd';
        this.isHidden = cmd.isHidden;
        this.caption = cmd.caption && cmd.caption.trim();
        this.cod = cmd.cod && cmd.cod.trim();
        this.icon = cmd.icon && cmd.icon.trim() || 'fa-square-minus-o'; //'fa-navicon';
        this.class = cmd.class && cmd.class.trim() || 'btn-primary';
        this.splitCaret = cmd.splitCaret || false;
        this.tooltip = cmd.tooltip || '';
        this.nivelAcces = cmd.nivelAcces;
        this.btns = cmd.btns;
    }

    parentId?: string;
    hierarchyId?: string;
    isHidden?: boolean;
    caption?: string;
    cod?: string;
    icon?: string;
    class?: string;
    tooltip?: string;
    splitCaret?: boolean;
    type?: 'div' | 'cmd';
    nivelAcces?: 0 | 1 | 2;
    btns?: RouteBtn[];
}

@Injectable({ providedIn: 'root' })
export class ControlsService {
    constructor(user: UserService, data: DataService, private routing: RoutingService) {
        user.onLoginComplete.subscribe(currentUser => {
            if (currentUser) {
                data.executeQueryNew(Query.user.getUserBtn()).then(d => this.mapBtns(d))
            } else {
                this.commands = {};
                this.customCmd = [];
            }
        });
        routing.onRouteChange.pipe(
            filter(route => route.outlet === 'primary'),
            mergeMap(route => route.data)
        ).subscribe(data => {
            this.customCmd = [];
            this.curentPageId = data.access;
            this.searchText = this.search[this.curentPageId];
            delete this.search[this.curentPageId];
            routing.activeRoute.searchText = this.searchText;
        });
    }

    private tabs = {};
    private search: { [key: string]: string } = {};
    private commands: { [key: string]: RouteBtn[] } = {};
    private customCmd: RouteBtn[] = [];
    private curentPageId: string;

    searchText: string = "";

    addButton(btns: RouteBtn | RouteBtn[]) {
        this.customCmd = this.customCmd.concat(Array.isArray(btns) ? btns : [btns])
    }
    getButtons(hierarchyId: string) {
        return (this.commands[hierarchyId] || []).concat(this.customCmd).filter(btn => !btn.isHidden)
    }
    getRouteButtons() { return this.getButtons(this.curentPageId) }
    hasRouteBtns(): boolean { return this.getRouteButtons().length > 0 }

    getTabs() { return this.tabs[this.curentPageId] || [] }

    keepSearch() {
        this.search[this.curentPageId] = this.searchText
    }
    getSearchOptions() {
        return {}
    }
    /** set searchText and call onSearch on route if present */
    setSearchText(value: string) {
        this.searchText = value;
        if (this.routing.activeRoute.onSearch)
            this.routing.activeRoute.onSearch(this.searchText || '');
    }

    private mapBtns(buttons?: any[]) {
        // empty first => no duplicates after
        // for (var key in this.commands) { delete this.commands[key] }
        this.commands = {};

        for (var i = 0; i < buttons.length; i++) {
            // if (buttons[i].nivelAcces != 2) { continue }
            // if (![1, 2].includes(buttons[i].nivelAcces)) { continue }

            if (!this.commands[buttons[i].parentId]) {
                this.commands[buttons[i].parentId] = []
            }
            this.commands[buttons[i].parentId].push(new RouteBtn(buttons[i]));
            const childButtons: any[] = [];
            for (var k = 0; k < buttons.length; k++) {
                if (buttons[k].parentId == buttons[i].hierarchyId)
                    childButtons.push(new RouteBtn(buttons[k]))
            }
            if (childButtons.length) {
                for (var j = 0; j < this.commands[buttons[i].parentId].length; j++) {
                    if (this.commands[buttons[i].parentId][j].hierarchyId == childButtons[0].parentId)
                        this.commands[buttons[i].parentId][j].btns = childButtons
                }
            }
        }
    }
}