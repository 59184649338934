import { AutoGeneratedKeyType } from "breeze-client";

export const metadata = {
		"achizitii.Achizitie": {
			shortName: "Achizitie",
			namespace: "achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				numarAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipProceduraAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStartProcedura: { dataType: "DateTime" },
				dataStopProcedura: { dataType: "DateTime" },
				idPersoanaResponsabila: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCPV: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anuntParticipare: { dataType: "Boolean", isNullable: false },
				publicatSite: { dataType: "Boolean", isNullable: false },
				publicatSEAP: { dataType: "Boolean", isNullable: false },
				publicatSEAPNr: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				publicatSEAPData: { dataType: "DateTime" },
				idProgramPozitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipObiectAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutarEstimat: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareEstimataValuta: { dataType: "Decimal", defaultValue: 0 },
				valoareEstimataLei: { dataType: "Decimal", defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareValuta: { dataType: "Decimal", defaultValue: 0 },
				valoareLei: { dataType: "Decimal", defaultValue: 0 },
				idCriteriu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				proceduraFinalizata: { dataType: "Boolean", isNullable: false },
				idValuta: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				procentGarantieParticipareLicitatie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareValutaGarantieParticipareLicitatie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareLeiGarantieParticipareLicitatie: { dataType: "Decimal", defaultValue: 0 },
			}
		},
		"achizitii.AchizitieDetaliu": {
			shortName: "AchizitieDetaliu",
			namespace: "achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentReferatNecesitateDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCodCPV: { dataType: "Int32", defaultValue: 0 },
				descriereProdus: { maxLength: 150, defaultValue: "" },
				caracteristiciProdus: { maxLength: 250, defaultValue: "" },
				idUnitateMasura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarLei: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", defaultValue: 0 },
				pretUnitarValuta: { dataType: "Decimal", defaultValue: 0 },
				valoareLei: { dataType: "Decimal", defaultValue: 0 },
				valoareValuta: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"achizitii.AchizitieOferta": {
			shortName: "AchizitieOferta",
			namespace: "achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaOfertant: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaReprezentant: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valoareLei: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				punctaj: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isCastigator: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"achizitii.AchizitieSursaFinantare": {
			shortName: "AchizitieSursaFinantare",
			namespace: "achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				procent: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"achizitii.AnBlocarePropuneri": {
			shortName: "AnBlocarePropuneri",
			namespace: "achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUtilizatorBlocare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataBlocare: { dataType: "DateTime", isNullable: false },
				isBlocat: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"achizitii.Criteriu": {
			shortName: "Criteriu",
			namespace: "achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"achizitii.DocumentReferatNecesitate": {
			shortName: "DocumentReferatNecesitate",
			namespace: "achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCPV: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				rezolutie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentTipJustificativ: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipModalitateAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaOfertant: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				apareSite: { dataType: "Boolean", isNullable: false },
				apareSeap: { dataType: "Boolean", isNullable: false },
				idProgramPozitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"achizitii.DocumentReferatNecesitateDetaliu": {
			shortName: "DocumentReferatNecesitateDetaliu",
			namespace: "achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentReferatNecesitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProgramPozitieReferatPropunereDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				descriereProdus: { maxLength: 150, defaultValue: "" },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCPV: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idUnitateMasura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				caracteristici: { maxLength: 250, defaultValue: "" },
				justificare: { maxLength: 250, defaultValue: "" },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarLei: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarValuta: { dataType: "Decimal", defaultValue: 0 },
				valoareLei: { dataType: "Decimal", defaultValue: 0 },
				valoareValuta: { dataType: "Decimal", defaultValue: 0 },
				rezolutie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"achizitii.DocumentReferatNecesitateSursaFinantare": {
			shortName: "DocumentReferatNecesitateSursaFinantare",
			namespace: "achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentReferatNecesitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				procent: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"achizitii.DocumentReferatPropunere": {
			shortName: "DocumentReferatPropunere",
			namespace: "achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", defaultValue: 0 },
				idOrganizareInstitutionalaEmitent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaEmitent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataAdaugare: { dataType: "DateTime" },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dataStartProcedura: { dataType: "DateTime" },
				dataStopProcedura: { dataType: "DateTime" },
				isCerereProcedura: { dataType: "Boolean", isNullable: false },
				numarZileProcedura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", defaultValue: 0 },
				titlu: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				isContract: { dataType: "Boolean", isNullable: false },
				sumaCumpararilorDirecte: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idPersoanaVerificare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaIncadrareBugetara: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCPV: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"achizitii.DocumentReferatPropunereDetaliu": {
			shortName: "DocumentReferatPropunereDetaliu",
			namespace: "achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentReferatPropunere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				descriere: { maxLength: 250, defaultValue: "" },
				idProdusCategorie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCPV: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idUnitateMasura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarLei: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarValuta: { dataType: "Decimal", defaultValue: 0 },
				valoareLei: { dataType: "Decimal", defaultValue: 0 },
				valoareValuta: { dataType: "Decimal", defaultValue: 0 },
				dataNecesitate: { dataType: "DateTime", isNullable: false },
				dataStartProcedura: { dataType: "DateTime" },
				dataStopProcedura: { dataType: "DateTime" },
				isCerereProcedura: { dataType: "Boolean", isNullable: false },
				numarZileProcedura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				justificare: { defaultValue: "" },
				modDeCalculAlValoriiEstimate: { defaultValue: "" },
				idBuget: { dataType: "Int32", defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"achizitii.ProceduraTipEtapa": {
			shortName: "ProceduraTipEtapa",
			namespace: "achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idTipProceduraAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 50, defaultValue: "" },
				numarOrdine: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isOptionala: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"achizitii.ProceduraTipEtapaDocumentTip": {
			shortName: "ProceduraTipEtapaDocumentTip",
			namespace: "achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				idProceduraTipEtapa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"achizitii.ProceduraTipEtapaDocumentTipModel": {
			shortName: "ProceduraTipEtapaDocumentTipModel",
			namespace: "achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProceduraTipEtapaDocumentTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"achizitii.Program": {
			shortName: "Program",
			namespace: "achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaProiect: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataProgram: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"achizitii.ProgramPozitie": {
			shortName: "ProgramPozitie",
			namespace: "achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 255, defaultValue: "" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipObiectAchizitie: { dataType: "Int32", defaultValue: 0 },
				idTipProceduraAchizitie: { dataType: "Int32", defaultValue: 0 },
				idCPV: { dataType: "Int32", defaultValue: 0 },
				idPersoanaResponsabil: { dataType: "Int32", defaultValue: 0 },
				idObiectivInvestitie: { dataType: "Int32", defaultValue: 0 },
				numarCurent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codTipLimitareSuma: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isAnuntIntentie: { dataType: "Boolean", isNullable: false },
				isVerificareProcedurala: { dataType: "Boolean", isNullable: false },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareLei: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareEuro: { dataType: "Decimal", defaultValue: 0 },
				userEmitent: { maxLength: 100, defaultValue: "" },
				idProgram: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStartProcedura: { dataType: "DateTime" },
				dataStopProcedura: { dataType: "DateTime" },
				modDesfasurareProcedura: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"achizitii.ProgramPozitieReferatNecesitateDetaliu": {
			shortName: "ProgramPozitieReferatNecesitateDetaliu",
			namespace: "achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProgramPozitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentReferatNecesitateDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"achizitii.ProgramPozitieReferatPropunereDetaliu": {
			shortName: "ProgramPozitieReferatPropunereDetaliu",
			namespace: "achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProgramPozitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentReferatPropunereDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dataStartProcedura: { dataType: "DateTime" },
				dataStopProcedura: { dataType: "DateTime" },
				isCerereProcedura: { dataType: "Boolean", isNullable: false },
				numarZileProcedura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"achizitii.ProgramPozitieSursaFinantare": {
			shortName: "ProgramPozitieSursaFinantare",
			namespace: "achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProgramPozitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				procent: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"achizitii.Versiune": {
			shortName: "Versiune",
			namespace: "achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				version: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stamp: { dataType: "DateTime", isNullable: false },
			}
		},
		"achizitiiistoric.AchizitieDetaliu": {
			shortName: "AchizitieDetaliu",
			namespace: "achizitiiistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentReferatNecesitateDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCodCPV: { dataType: "Int32", defaultValue: 0 },
				descriereProdus: { maxLength: 150, defaultValue: "" },
				caracteristiciProdus: { maxLength: 250, defaultValue: "" },
				idUnitateMasura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarLei: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", defaultValue: 0 },
				pretUnitarValuta: { dataType: "Decimal", defaultValue: 0 },
				valoareLei: { dataType: "Decimal", defaultValue: 0 },
				valoareValuta: { dataType: "Decimal", defaultValue: 0 },
				idAchizitieDetaliu: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"achizitiiistoric.DocumentReferatNecesitateDetaliu": {
			shortName: "DocumentReferatNecesitateDetaliu",
			namespace: "achizitiiistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentReferatNecesitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProgramPozitieReferatPropunereDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				descriereProdus: { maxLength: 150, defaultValue: "" },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCPV: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idUnitateMasura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				caracteristici: { maxLength: 250, defaultValue: "" },
				justificare: { maxLength: 250, defaultValue: "" },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarLei: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarValuta: { dataType: "Decimal", defaultValue: 0 },
				valoareLei: { dataType: "Decimal", defaultValue: 0 },
				valoareValuta: { dataType: "Decimal", defaultValue: 0 },
				idDocumentReferatNecesitateDetaliu: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				rezolutie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"achizitiiistoric.DocumentReferatPropunereDetaliu": {
			shortName: "DocumentReferatPropunereDetaliu",
			namespace: "achizitiiistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentReferatPropunere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				descriere: { maxLength: 250, defaultValue: "" },
				idProdusCategorie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCPV: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idUnitateMasura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarLei: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarValuta: { dataType: "Decimal", defaultValue: 0 },
				valoareLei: { dataType: "Decimal", defaultValue: 0 },
				valoareValuta: { dataType: "Decimal", defaultValue: 0 },
				dataNecesitate: { dataType: "DateTime", isNullable: false },
				idDocumentReferatPropunereDetaliu: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				dataStartProcedura: { dataType: "DateTime" },
				dataStopProcedura: { dataType: "DateTime" },
				isCerereProcedura: { dataType: "Boolean", isNullable: false },
				numarZileProcedura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				justificare: { defaultValue: "" },
				modDeCalculAlValoriiEstimate: { defaultValue: "" },
				idBuget: { dataType: "Int32", defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"achizitiiistoric.ProgramPozitie": {
			shortName: "ProgramPozitie",
			namespace: "achizitiiistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 255, defaultValue: "" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipObiectAchizitie: { dataType: "Int32", defaultValue: 0 },
				idTipProceduraAchizitie: { dataType: "Int32", defaultValue: 0 },
				idCPV: { dataType: "Int32", defaultValue: 0 },
				idPersoanaResponsabil: { dataType: "Int32", defaultValue: 0 },
				idObiectivInvestitie: { dataType: "Int32", defaultValue: 0 },
				numarCurent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codTipLimitareSuma: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isAnuntIntentie: { dataType: "Boolean", isNullable: false },
				isVerificareProcedurala: { dataType: "Boolean", isNullable: false },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareLei: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareEuro: { dataType: "Decimal", defaultValue: 0 },
				idProgramPozitie: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userEmitent: { maxLength: 100, defaultValue: "" },
				idProgram: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStartProcedura: { dataType: "DateTime" },
				dataStopProcedura: { dataType: "DateTime" },
				modDesfasurareProcedura: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"admin.BackupConfig": {
			shortName: "BackupConfig",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				readOnly: { dataType: "Boolean", isNullable: false },
				hidden: { dataType: "Boolean", isNullable: false },
				nume: { maxLength: 128, defaultValue: "" },
				descriere: { maxLength: 1024, defaultValue: "" },
				tip: { maxLength: 30, defaultValue: "" },
				sursa: { maxLength: 1024, defaultValue: "" },
				valoare: { defaultValue: "" },
				stamp: { dataType: "DateTime" },
				categorie: { maxLength: 128, defaultValue: "" },
			}
		},
		"admin.BackupConfigInstance": {
			shortName: "BackupConfigInstance",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				instanceType: { maxLength: 250, defaultValue: "" },
				instanceHost: { maxLength: 250, defaultValue: "" },
				instanceName: { maxLength: 250, defaultValue: "" },
				port: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isSqlExpress: { dataType: "Boolean", isNullable: false },
				username: { maxLength: 250, defaultValue: "", custom: { MS_Description: "''" } },
				password: { maxLength: 250, defaultValue: "" },
				backupPath: { defaultValue: "" },
				databases: { defaultValue: "" },
			}
		},
		"admin.BackupFile": {
			shortName: "BackupFile",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBackupConfigInstance: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				fileExtension: { maxLength: 16, defaultValue: "" },
				filename: { maxLength: 1024, defaultValue: "" },
				filepath: { defaultValue: "" },
				backupType: { maxLength: 50, defaultValue: "" },
				backupPlanned: { dataType: "DateTime", custom: { MS_Description: "Momentul de backup planificat " } },
				databaseName: { maxLength: 100, defaultValue: "" },
				backupStart: { dataType: "DateTime" },
				backupStop: { dataType: "DateTime" },
				zipStart: { dataType: "DateTime" },
				zipStop: { dataType: "DateTime" },
				uploadStart: { dataType: "DateTime" },
				uploadStop: { dataType: "DateTime" },
				message: { defaultValue: "" },
				idState: { dataType: "Int32", defaultValue: 0 },
				completed: { dataType: "Boolean", isNullable: false },
				failCount: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				storage: { maxLength: 20, defaultValue: "" },
				stamp: { dataType: "DateTime" },
				lastBackupFull: { dataType: "DateTime" },
				dataStergereFisier: { dataType: "DateTime", custom: { MS_Description: "Data la care a fost sters fisi" } },
			}
		},
		"admin.BackupFolder": {
			shortName: "BackupFolder",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				fullPath: { maxLength: 4000, defaultValue: "" },
				relativePath: { maxLength: 4000, defaultValue: "" },
				lastModified: { dataType: "DateTime" },
				lastUploaded: { dataType: "DateTime" },
				isFolder: { dataType: "Boolean", isNullable: false },
				details: { maxLength: 1000, defaultValue: "" },
				uploadError: { defaultValue: "" },
			}
		},
		"admin.BackupInterval": {
			shortName: "BackupInterval",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idSchedule: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 50, defaultValue: "" },
			}
		},
		"admin.BackupMessage": {
			shortName: "BackupMessage",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				message: { defaultValue: "" },
				tip: { maxLength: 1024, defaultValue: "" },
				stamp: { dataType: "DateTime" },
				sent: { dataType: "Boolean", isNullable: false },
			}
		},
		"admin.BackupPlan": {
			shortName: "BackupPlan",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				backupType: { maxLength: 50, defaultValue: "" },
				schedule: { maxLength: 50, defaultValue: "" },
				interval: { maxLength: 50, defaultValue: "" },
			}
		},
		"admin.BackupSchedule": {
			shortName: "BackupSchedule",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
			}
		},
		"admin.BackupUpdateStatus": {
			shortName: "BackupUpdateStatus",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				info: { maxLength: 100, defaultValue: "" },
				retry: { dataType: "Boolean", isNullable: false },
			}
		},
		"admin.Client": {
			shortName: "Client",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { isPartOfKey: true, maxLength: 128 },
				secret: { defaultValue: "" },
				name: { maxLength: 100, defaultValue: "" },
				applicationType: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				active: { dataType: "Boolean", isNullable: false },
				refreshTokenLifeTime: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				allowedOrigin: { maxLength: 100, defaultValue: "" },
			}
		},
		"admin.DatabaseAlias": {
			shortName: "DatabaseAlias",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				databaseName: { maxLength: 100, defaultValue: "" },
				databaseAlias: { maxLength: 100, defaultValue: "" },
			}
		},
		"admin.HelpPageTemplate": {
			shortName: "HelpPageTemplate",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				template: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"admin.LinkedDatabaseTable": {
			shortName: "LinkedDatabaseTable",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				dataBaseName: { maxLength: 128, defaultValue: "" },
				schemaName: { maxLength: 128, defaultValue: "" },
				tableName: { maxLength: 128, defaultValue: "" },
				isVersionedData: { dataType: "Boolean" },
			}
		},
		"admin.LinkedObject": {
			shortName: "LinkedObject",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				sourceDbase: { maxLength: 128, defaultValue: "", custom: { MS_Description: "Baza de date sursa care contin" } },
				sourceSchema: { maxLength: 128, defaultValue: "", custom: { MS_Description: "Schema sursa care contine obie" } },
				sourceObject: { maxLength: 128, defaultValue: "", custom: { MS_Description: "Numele obiectului la care se f" } },
				targetSchema: { maxLength: 128, defaultValue: "", custom: { MS_Description: "Numele schemei destinatie din " } },
				targetObject: { maxLength: 128, defaultValue: "", custom: { MS_Description: "Numele dat obiectului in baza " } },
				customDefinition: { defaultValue: "", custom: { MS_Description: "Aici se poate scrie o comanda " } },
				customWhere: { defaultValue: "", custom: { MS_Description: "Daca este necesara o filtrare " } },
			}
		},
		"admin.MailQueue": {
			shortName: "MailQueue",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				sender: { maxLength: 1024, defaultValue: "" },
				receiver: { maxLength: 2048, defaultValue: "" },
				titlu: { maxLength: 350, defaultValue: "" },
				mesaj: { defaultValue: "" },
				stamp: { dataType: "DateTime", isNullable: false },
				sent: { dataType: "Boolean", isNullable: false },
				tryCount: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				error: { defaultValue: "" },
				isNotify: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Specifica daca mailul e o noti" } },
				stampInLucru: { dataType: "DateTime" },
			}
		},
		"admin.MailTemplates": {
			shortName: "MailTemplates",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				htmlText: { defaultValue: "" },
				htmlButtons: { defaultValue: "" },
				descriere: { maxLength: 1024, defaultValue: "" },
			}
		},
		"admin.Notificare": {
			shortName: "Notificare",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				mesaj: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"admin.NotificareUser": {
			shortName: "NotificareUser",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idNotificare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameExpeditor: { maxLength: 256, defaultValue: "" },
				dataCreare: { dataType: "DateTime", isNullable: false },
				userNameDestinatar: { maxLength: 256, defaultValue: "" },
				dataCitire: { dataType: "DateTime" },
				status: { maxLength: 1000, defaultValue: "" },
			}
		},
		"admin.Parametri": {
			shortName: "Parametri",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				valoare: { defaultValue: "" },
			}
		},
		"admin.QueryError": {
			shortName: "QueryError",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				stamp: { dataType: "DateTime", isNullable: false },
				userName: { maxLength: 50, defaultValue: "" },
				number: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				message: { defaultValue: "" },
				query: { defaultValue: "" },
			}
		},
		"admin.RefactorColumn": {
			shortName: "RefactorColumn",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				oldTableName: { maxLength: 150, defaultValue: "" },
				oldColumnName: { maxLength: 150, defaultValue: "" },
				newColumnName: { maxLength: 150, defaultValue: "" },
				isCustom: { dataType: "Boolean" },
				sters: { dataType: "Boolean", isNullable: false },
			}
		},
		"admin.RefactorTable": {
			shortName: "RefactorTable",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				oldTableName: { maxLength: 150, defaultValue: "" },
				newTableName: { maxLength: 150, defaultValue: "" },
				isCustom: { dataType: "Boolean" },
				tabelaPereche: { maxLength: 150, defaultValue: "" },
				sters: { dataType: "Boolean", isNullable: false },
				revizuit: { dataType: "Boolean", isNullable: false },
			}
		},
		"admin.RefreshToken": {
			shortName: "RefreshToken",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				uUId: { maxLength: 128, defaultValue: "" },
				subject: { maxLength: 50, defaultValue: "" },
				idClient: { maxLength: 50, defaultValue: "" },
				issuedUtc: { dataType: "DateTime", isNullable: false },
				expiresUtc: { dataType: "DateTime", isNullable: false },
				protectedTicket: { defaultValue: "" },
				refreshTicket: { defaultValue: "" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul comun al persoanei unita" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"admin.RelatiiTabele": {
			shortName: "RelatiiTabele",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				relationType: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				relationTypeUpdateIdUnic: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Relatie intre tabelele cu isto" } },
				databaseNameFK: { maxLength: 200, defaultValue: "" },
				schemaNameFK: { maxLength: 200, defaultValue: "" },
				tableNameFK: { maxLength: 200, defaultValue: "" },
				fieldNameFK: { maxLength: 200, defaultValue: "" },
				databaseNamePK: { maxLength: 200, defaultValue: "" },
				schemaNamePK: { maxLength: 200, defaultValue: "" },
				tableNamePK: { maxLength: 200, defaultValue: "" },
				value: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				errorMessage: { maxLength: 2000, defaultValue: "", custom: { MS_Description: "Mesaj de eroare in caz ca ster" } },
				whereClause: { maxLength: 500, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"admin.RelatiiTabeleIerarhie": {
			shortName: "RelatiiTabeleIerarhie",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				node: { maxLength: 892, defaultValue: "" },
				databaseName: { maxLength: 200, defaultValue: "" },
				schemaName: { maxLength: 200, defaultValue: "" },
				tableName: { maxLength: 200, defaultValue: "" },
				fieldName: { maxLength: 200, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"admin.RelatiiTabeleUnitate": {
			shortName: "RelatiiTabeleUnitate",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				parentId: { maxLength: 892, defaultValue: "" },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				treeLevel: { dataType: "Int32", defaultValue: 0 },
				databaseName: { maxLength: 100, defaultValue: "" },
				schemaName: { maxLength: 100, defaultValue: "" },
				tableName: { maxLength: 100, defaultValue: "" },
				fieldName: { maxLength: 100, defaultValue: "" },
				fieldStatus: { maxLength: 100, defaultValue: "" },
				fieldDescription: { maxLength: 1000, defaultValue: "" },
				parentDatabaseName: { maxLength: 100, defaultValue: "" },
				parentSchemaName: { maxLength: 100, defaultValue: "" },
				parentTableName: { maxLength: 100, defaultValue: "" },
				parentFieldName: { maxLength: 100, defaultValue: "" },
				parentFieldStatus: { maxLength: 100, defaultValue: "" },
				tableRelationRelative: { maxLength: 1000, defaultValue: "" },
				tableRelationFull: { defaultValue: "" },
				scriptDelete: { defaultValue: "" },
			}
		},
		"admin.ReportImage": {
			shortName: "ReportImage",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Unitatea {Sigma.persoaneIstori" } },
				image: { defaultValue: "" },
				isAntet: { dataType: "Boolean", isNullable: false },
				fileName: { maxLength: 100, defaultValue: "", custom: { MS_Description: "Numele fisierului du unde s-a " } },
				filePath: { defaultValue: "", custom: { MS_Description: "Calea relativa spre folderul d" } },
				isLandscape: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Orientarea paginii: 0 - portet" } },
			}
		},
		"admin.ReportSemnaturaDefinire": {
			shortName: "ReportSemnaturaDefinire",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				userName: { maxLength: 50, defaultValue: "" },
				idResursa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Unitatea de lucru: {Sigma.pers" } },
				idReportImage: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Imaginea obtinuta in urma rand" } },
				nume: { maxLength: 50, defaultValue: "" },
				continut: { defaultValue: "" },
				isPublic: { dataType: "Boolean", isNullable: false },
				codZona: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "0- antet, 1- subsol pagina, 2-" } },
			}
		},
		"admin.ReportSemnaturaDefinireLegatura": {
			shortName: "ReportSemnaturaDefinireLegatura",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idRaportSemnaturaDefinire: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				reportPath: { defaultValue: "", custom: { MS_Description: "Path-ul raportului din tabela " } },
			}
		},
		"admin.Resursa": {
			shortName: "Resursa",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				parentId: { maxLength: 892, defaultValue: "" },
				level: { dataType: "Int32", defaultValue: 0 },
				idResursaControl: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusSief: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 200, defaultValue: "" },
				nivelAcces: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				customAcces: { dataType: "Boolean", isNullable: false },
				cod: { maxLength: 50, defaultValue: "" },
				type: { maxLength: 3, defaultValue: "" },
				icon: { maxLength: 50, defaultValue: "" },
				url: { maxLength: 200, defaultValue: "" },
				html: { maxLength: 200, defaultValue: "" },
				options: { defaultValue: "" },
				tooltip: { maxLength: 200, defaultValue: "" },
				searchQuery: { defaultValue: "" },
				helpPage: { defaultValue: "", custom: { MS_Description: "Contine o pagina de Help in fo" } },
				devOnly: { dataType: "Boolean" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				configReadOnly: { dataType: "Boolean", isNullable: false },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				numeComponenta: { maxLength: 50, defaultValue: "" },
				urlDefaultParams: { maxLength: 200, defaultValue: "" },
				tabOptions: { defaultValue: "" },
				searchOptions: { defaultValue: "" },
				isStartMenu: { dataType: "Boolean", isNullable: false },
				isSplitCaret: { dataType: "Boolean", isNullable: false },
			}
		},
		"admin.ResursaControl": {
			shortName: "ResursaControl",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 50, defaultValue: "" },
				type: { maxLength: 3, defaultValue: "" },
				nume: { maxLength: 50, defaultValue: "" },
				definition: { defaultValue: "" },
				caption: { maxLength: 50, defaultValue: "" },
				icon: { maxLength: 50, defaultValue: "" },
				html: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"admin.Rol": {
			shortName: "Rol",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				_Id: { maxLength: 128, defaultValue: "" },
				nume: { maxLength: 256, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				isPredefinit: { dataType: "Boolean", isNullable: false },
			}
		},
		"admin.RolAcces": {
			shortName: "RolAcces",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idRol: { maxLength: 128, defaultValue: "" },
				idResursa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nivelAcces: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"admin.Setare": {
			shortName: "Setare",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idAplicatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idResursa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "", custom: { MS_Description: "nume setare, trebuie sa fie ca" } },
				codTmp: { maxLength: 50, defaultValue: "" },
				descriere: { maxLength: 250, defaultValue: "", custom: { MS_Description: "descriere mai detaliata a seta" } },
				valoare: { defaultValue: "", custom: { MS_Description: "valoare implicita a setarii" } },
				valoareTip: { maxLength: 50, defaultValue: "", custom: { MS_Description: "number, character, date, datet" } },
				valoareSursa: { defaultValue: "", custom: { MS_Description: "Sursa de valori pentru lista( " } },
				template: { defaultValue: "", custom: { MS_Description: "Template-ul de afisare daca es" } },
				nivelSetare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "0-predefinita, 1-nivel unitate" } },
				categorie: { maxLength: 50, defaultValue: "", custom: { MS_Description: "categoriile ar putea fi : Rapo" } },
				cod: { maxLength: 250, defaultValue: "" },
				isUserEditable: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Determina daca utilizatorul va" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"admin.SetareUnitate": {
			shortName: "SetareUnitate",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSetare: { dataType: "Int32", defaultValue: 0 },
				valoare: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"admin.SetareUnitateUser": {
			shortName: "SetareUnitateUser",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idSetare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userName: { maxLength: 50, defaultValue: "" },
				valoare: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"admin.SetareUser": {
			shortName: "SetareUser",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idSetare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userName: { maxLength: 50, defaultValue: "" },
				valoare: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"admin.TablePropertiesList": {
			shortName: "TablePropertiesList",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				dataBaseName: { maxLength: 128, defaultValue: "" },
				schemaName: { maxLength: 128, defaultValue: "" },
				tableName: { maxLength: 128, defaultValue: "" },
				uniqueKeyFieldList: { maxLength: 400, defaultValue: "" },
				isWatched: { dataType: "Boolean", isNullable: false },
			}
		},
		"admin.Task": {
			shortName: "Task",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idAplicatie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Aplicatia aferenta" } },
				nume: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Nume task" } },
				dataStart: { dataType: "DateTime", custom: { MS_Description: "Data start task" } },
				procentStatus: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Procent realizare" } },
				explicatie: { defaultValue: "", custom: { MS_Description: "Alte mentiuni" } },
			}
		},
		"admin.Test": {
			shortName: "Test",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				nodeId: { maxLength: 50, defaultValue: "" },
			}
		},
		"admin.User": {
			shortName: "User",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				_Id: { maxLength: 128, defaultValue: "" },
				nume: { maxLength: 100, defaultValue: "" },
				email: { maxLength: 256, defaultValue: "" },
				emailConfirmed: { dataType: "Boolean", isNullable: false },
				passwordHash: { defaultValue: "" },
				securityStamp: { defaultValue: "" },
				phoneNumber: { defaultValue: "" },
				phoneNumberConfirmed: { dataType: "Boolean" },
				twoFactorEnabled: { dataType: "Boolean", isNullable: false },
				lockoutEndDateUtc: { maxLength: 50, defaultValue: "" },
				lockoutEnabled: { dataType: "Boolean", isNullable: false },
				accessFailedCount: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userName: { maxLength: 256, defaultValue: "" },
				idPersoana: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Id comun al persoanei asociate" } },
				passwordReset: { dataType: "Boolean" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"admin.UserAcces": {
			shortName: "UserAcces",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idUser: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu tabela User: {Sigm" } },
				userName: { maxLength: 50, defaultValue: "" },
				idResursa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nivelAcces: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"admin.UserCid": {
			shortName: "UserCid",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				userName: { maxLength: 256, defaultValue: "" },
				idUser: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id user sistem {Sigma.admin.Us" } },
				idUserCid: { dataType: "Int32", isNullable: false, custom: { MS_Description: "Id user din CID pentru userul " } },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id persoana comun pentru Unita" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"admin.UserClaim": {
			shortName: "UserClaim",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idUser: { maxLength: 128, defaultValue: "" },
				claimType: { defaultValue: "" },
				claimValue: { defaultValue: "" },
			}
		},
		"admin.UserLogin": {
			shortName: "UserLogin",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				loginProvider: { maxLength: 128, defaultValue: "" },
				providerKey: { maxLength: 128, defaultValue: "" },
				userName: { maxLength: 128, defaultValue: "" },
				name: { maxLength: 250, defaultValue: "" },
				email: { maxLength: 250, defaultValue: "" },
			}
		},
		"admin.UserRol": {
			shortName: "UserRol",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idUser: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idRol: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userName: { maxLength: 50, defaultValue: "" },
			}
		},
		"admin.UserUnitate": {
			shortName: "UserUnitate",
			namespace: "admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idUser: { dataType: "Int32", defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id persoana comun pentru Unita" } },
				idOrganizareInstitutionala: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Departamentul/Compartimentul {" } },
				nivelAcces: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Nivel acces :  1-readonly , 2-" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"adrese.Apartament": {
			shortName: "Apartament",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idScara: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 10, defaultValue: "" },
				etaj: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"adrese.Bloc": {
			shortName: "Bloc",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idNumarPostal: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 10, defaultValue: "" },
				numarEtaje: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarApartamente: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"adrese.Judet": {
			shortName: "Judet",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idTara: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 50, defaultValue: "" },
				simbol: { maxLength: 3, defaultValue: "" },
				cod: { maxLength: 20, defaultValue: "" },
				gUId: { defaultValue: null },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"adrese.Localitate": {
			shortName: "Localitate",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idJudet: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 50, defaultValue: "" },
				idLocalitateRang: { dataType: "Int32", defaultValue: 0 },
				idLocalitateUAT: { dataType: "Int32", defaultValue: 0 },
				idLocalitateTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idRegiune: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				rang: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				gUId: { defaultValue: null },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				import_LocCod: { maxLength: 250, defaultValue: "" },
				import_LocJudCod: { maxLength: 250, defaultValue: "" },
				import_CodPolitie: { maxLength: 250, defaultValue: "" },
				import_CodPolitieTata: { maxLength: 250, defaultValue: "" },
				import_CodSiruta: { maxLength: 250, defaultValue: "" },
				import_CodSirutaTata: { maxLength: 250, defaultValue: "" },
			}
		},
		"adrese.LocalitateTip": {
			shortName: "LocalitateTip",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				mediu: { maxLength: 20, defaultValue: "", custom: { MS_Description: "Capitala -> Sat" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"adrese.Locatie": {
			shortName: "Locatie",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idTara: { dataType: "Int32", defaultValue: 0 },
				idJudet: { dataType: "Int32", defaultValue: 0 },
				idLocalitate: { dataType: "Int32", defaultValue: 0 },
				idStrada: { dataType: "Int32", defaultValue: 0 },
				idNumarPostal: { dataType: "Int32", defaultValue: 0 },
				idBloc: { dataType: "Int32", defaultValue: 0 },
				idScara: { dataType: "Int32", defaultValue: 0 },
				idApartament: { dataType: "Int32", defaultValue: 0 },
				adresa: { maxLength: 150, defaultValue: "" },
				idLocatieTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipStareNationalizata: { dataType: "Int32", defaultValue: 0 },
				idTipConstructie: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 200, defaultValue: "" },
				explicatie: { maxLength: 250, defaultValue: "" },
				coordonateGPS: { defaultValue: "" },
				dinMaterialeInferioare: { dataType: "Boolean", isNullable: false },
				faraInstalatii: { dataType: "Boolean", isNullable: false },
				camereLaSubsol: { dataType: "Boolean", isNullable: false },
				numarCamere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarDepedinte: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				esteLocuinta: { dataType: "Boolean", isNullable: false },
				esteLocuintaSociala: { dataType: "Boolean", isNullable: false },
				zonaComerciala: { maxLength: 2, defaultValue: "" },
				numarInventar: { maxLength: 50, defaultValue: "" },
				valoareInventar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dataIntrareAdministrare: { dataType: "DateTime" },
				dataIesireAdministrare: { dataType: "DateTime" },
				idTipIesireAdminstrare: { dataType: "Int32", defaultValue: 0 },
				suprafataTotala: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				suprafataLocuibila: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				suprafataDependinte: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				suprafataAnexe: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				suprafetaAnexeRural: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				suprafataGaraj: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				suprafataSpeciale: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				suprafataCurte: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
			}
		},
		"adrese.LocatieSpatiu": {
			shortName: "LocatieSpatiu",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idLocatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idLocatieSpatiuTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				suprafata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				explicatieIntrareAdministrare: { maxLength: 250, defaultValue: "" },
				explicatieIesireAdministrare: { maxLength: 250, defaultValue: "" },
			}
		},
		"adrese.NumarPostal": {
			shortName: "NumarPostal",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idStrada: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 30, defaultValue: "" },
				codPostal: { maxLength: 10, defaultValue: "" },
				gUId: { defaultValue: null },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				userNameActualizareGIS: { maxLength: 256, defaultValue: "" },
				dataActualizareGIS: { dataType: "DateTime" },
				serverActualizareGIS: { maxLength: 256, defaultValue: "" },
				idImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"adrese.NumarPostalZona": {
			shortName: "NumarPostalZona",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				idNumarPostalZonaTip: { dataType: "Int32", defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"adrese.NumarPostalZonaFiscala": {
			shortName: "NumarPostalZonaFiscala",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idNumarPostal: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "{Sigma.adreseIstoric.NumarPost" } },
				zonaFiscala: { maxLength: 1, defaultValue: "" },
				isZonaFiscalaContracte: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"adrese.NumarPostalZonaNumarPostal": {
			shortName: "NumarPostalZonaNumarPostal",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idNumarPostalZona: { dataType: "Int32", defaultValue: 0 },
				idNumarPostal: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"adrese.NumarPostalZonaTip": {
			shortName: "NumarPostalZonaTip",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"adrese.Parcela": {
			shortName: "Parcela",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idTarla: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"adrese.ParcelaZonaFiscala": {
			shortName: "ParcelaZonaFiscala",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idParcela: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				zonaFiscala: { maxLength: 3, defaultValue: "" },
			}
		},
		"adrese.ParcelaZonaFiscala1": {
			shortName: "ParcelaZonaFiscala1",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idParcela: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				zonaFiscala: { maxLength: 3, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"adrese.Regiune": {
			shortName: "Regiune",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				siruta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"adrese.Scara": {
			shortName: "Scara",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBloc: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 10, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"adrese.Strada": {
			shortName: "Strada",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idLocalitate: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 50, defaultValue: "" },
				cod: { maxLength: 20, defaultValue: "" },
				idStradaTip: { dataType: "Int32", defaultValue: 0 },
				gUId: { defaultValue: null },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				import_LocCod: { maxLength: 250, defaultValue: "" },
				import_LocJudCod: { maxLength: 250, defaultValue: "" },
				import_CodPolitie: { maxLength: 250, defaultValue: "" },
				import_CodPolitieLoc: { maxLength: 250, defaultValue: "" },
				numeSearchText: { maxLength: 2000, defaultValue: "" },
				idImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"adrese.StradaNumarPostalRegula": {
			shortName: "StradaNumarPostalRegula",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idStrada: { dataType: "Int32", defaultValue: 0 },
				numarPostalStart: { maxLength: 20, defaultValue: "" },
				numarPostalStop: { maxLength: 20, defaultValue: "" },
				tipRegula: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "0 - Toata strada; 1 - Numere p" } },
				idNumarPostalZona: { dataType: "Int32", defaultValue: 0 },
				zonaImpozitare: { maxLength: 10, defaultValue: "" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"adrese.StradaTip": {
			shortName: "StradaTip",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 30, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				descriere: { defaultValue: "" },
				descriereFormaArticulata: { defaultValue: "" },
			}
		},
		"adrese.Tara": {
			shortName: "Tara",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				cetatenie: { maxLength: 50, defaultValue: "" },
				codTara: { maxLength: 2, defaultValue: "" },
				codISO: { maxLength: 3, defaultValue: "" },
				uE: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"adrese.Tarla": {
			shortName: "Tarla",
			namespace: "adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idLocalitate: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"adreseistoric.Apartament": {
			shortName: "Apartament",
			namespace: "adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idScara: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 10, defaultValue: "" },
				etaj: { dataType: "Decimal", defaultValue: 0 },
				idApartament: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				idImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"adreseistoric.Bloc": {
			shortName: "Bloc",
			namespace: "adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idNumarPostal: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 10, defaultValue: "" },
				numarEtaje: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarApartamente: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBloc: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				idImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"adreseistoric.Judet": {
			shortName: "Judet",
			namespace: "adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idTara: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 50, defaultValue: "" },
				simbol: { maxLength: 3, defaultValue: "" },
				cod: { maxLength: 20, defaultValue: "" },
				idJudet: { dataType: "Int32" },
				gUId: { defaultValue: null, custom: { MS_Description: "Identificator unic pentru geor" } },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"adreseistoric.Localitate": {
			shortName: "Localitate",
			namespace: "adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idJudet: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 50, defaultValue: "" },
				idLocalitateRang: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "NU se mai utilizeaza !!!" } },
				idLocalitateUAT: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "" } },
				idLocalitateTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "legatura cu tipul de localitat" } },
				idRegiune: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "legatura cu regiunile strazi{S" } },
				rang: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				gUId: { defaultValue: null, custom: { MS_Description: "Identificator unic pentru geor" } },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				idLocalitate: { dataType: "Int32" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				import_LocCod: { maxLength: 250, defaultValue: "" },
				import_LocJudCod: { maxLength: 250, defaultValue: "" },
				import_CodPolitie: { maxLength: 250, defaultValue: "" },
				import_CodPolitieTata: { maxLength: 250, defaultValue: "" },
				import_CodSiruta: { maxLength: 250, defaultValue: "" },
				import_CodSirutaTata: { maxLength: 250, defaultValue: "" },
			}
		},
		"adreseistoric.Locatie": {
			shortName: "Locatie",
			namespace: "adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idTara: { dataType: "Int32", defaultValue: 0 },
				idJudet: { dataType: "Int32", defaultValue: 0 },
				idLocalitate: { dataType: "Int32", defaultValue: 0 },
				idStrada: { dataType: "Int32", defaultValue: 0 },
				idNumarPostal: { dataType: "Int32", defaultValue: 0 },
				idBloc: { dataType: "Int32", defaultValue: 0 },
				idScara: { dataType: "Int32", defaultValue: 0 },
				idApartament: { dataType: "Int32", defaultValue: 0 },
				adresa: { maxLength: 150, defaultValue: "" },
				idLocatieTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipStareNationalizata: { dataType: "Int32", defaultValue: 0 },
				idTipConstructie: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 200, defaultValue: "" },
				explicatie: { maxLength: 250, defaultValue: "" },
				coordonateGPS: { defaultValue: "" },
				dinMaterialeInferioare: { dataType: "Boolean", isNullable: false },
				faraInstalatii: { dataType: "Boolean", isNullable: false },
				camereLaSubsol: { dataType: "Boolean", isNullable: false },
				numarCamere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarDepedinte: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				esteLocuinta: { dataType: "Boolean", isNullable: false },
				esteLocuintaSociala: { dataType: "Boolean", isNullable: false },
				zonaComerciala: { maxLength: 2, defaultValue: "" },
				numarInventar: { maxLength: 50, defaultValue: "" },
				valoareInventar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dataIntrareAdministrare: { dataType: "DateTime" },
				dataIesireAdministrare: { dataType: "DateTime" },
				idTipIesireAdminstrare: { dataType: "Int32", defaultValue: 0 },
				suprafataTotala: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				suprafataLocuibila: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				suprafataDependinte: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				suprafataAnexe: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				suprafetaAnexeRural: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				suprafataGaraj: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				suprafataSpeciale: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				suprafataCurte: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idLocatie: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"adreseistoric.LocatieSpatiu": {
			shortName: "LocatieSpatiu",
			namespace: "adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idLocatie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Locatia de care apartine spati" } },
				idLocatieSpatiuTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de spatiu: registre.Loca" } },
				nume: { maxLength: 250, defaultValue: "" },
				suprafata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				explicatieIntrareAdministrare: { maxLength: 250, defaultValue: "" },
				explicatieIesireAdministrare: { maxLength: 250, defaultValue: "" },
				idLocatieSpatiu: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"adreseistoric.NumarPostal": {
			shortName: "NumarPostal",
			namespace: "adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idStrada: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 30, defaultValue: "" },
				codPostal: { maxLength: 10, defaultValue: "" },
				idNumarPostal: { dataType: "Int32" },
				gUId: { defaultValue: null, custom: { MS_Description: "Identificator unic pentru geor" } },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				userNameActualizareGIS: { maxLength: 256, defaultValue: "", custom: { MS_Description: "Utilizatorul din GIS care a fa" } },
				dataActualizareGIS: { dataType: "DateTime", custom: { MS_Description: "Data la care adresa a fost mod" } },
				serverActualizareGIS: { maxLength: 256, defaultValue: "", custom: { MS_Description: "IP-ul serverului GIS care a fa" } },
				idImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"adreseistoric.NumarPostalZona": {
			shortName: "NumarPostalZona",
			namespace: "adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				idNumarPostalZonaTip: { dataType: "Int32", defaultValue: 0 },
				idNumarPostalZona: { dataType: "Int32" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"adreseistoric.Parcela": {
			shortName: "Parcela",
			namespace: "adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idTarla: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 50, defaultValue: "" },
				idParcela: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"adreseistoric.ParcelaZonaFiscala": {
			shortName: "ParcelaZonaFiscala",
			namespace: "adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idParcela: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "{Sigma.adreseIstoric.Parcela.I" } },
				zonaFiscala: { maxLength: 3, defaultValue: "" },
				idParcelaZonaFiscala: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"adreseistoric.Scara": {
			shortName: "Scara",
			namespace: "adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBloc: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 10, defaultValue: "", custom: { MS_Description: "cei care nu folosesc scari vor" } },
				idScara: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				idImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"adreseistoric.Strada": {
			shortName: "Strada",
			namespace: "adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idLocalitate: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 50, defaultValue: "" },
				cod: { maxLength: 20, defaultValue: "" },
				idStradaTip: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "adrese.stradatip.id" } },
				idStrada: { dataType: "Int32" },
				gUId: { defaultValue: null, custom: { MS_Description: "Identificator unic pentru geor" } },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				import_LocCod: { maxLength: 250, defaultValue: "" },
				import_LocJudCod: { maxLength: 250, defaultValue: "" },
				import_CodPolitie: { maxLength: 250, defaultValue: "" },
				import_CodPolitieLoc: { maxLength: 250, defaultValue: "" },
				numeSearchText: { maxLength: 2000, defaultValue: "", custom: { MS_Description: "Camp folosit pentru cautare cu" } },
				idImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"adreseistoric.Tara": {
			shortName: "Tara",
			namespace: "adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				cetatenie: { maxLength: 50, defaultValue: "" },
				idTara: { dataType: "Int32" },
				codTara: { maxLength: 2, defaultValue: "" },
				codISO: { maxLength: 3, defaultValue: "" },
				uE: { dataType: "Boolean", isNullable: false },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"adreseistoric.Tarla": {
			shortName: "Tarla",
			namespace: "adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				idTarla: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				idLocalitate: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"asisoc.DocumentCardParcare": {
			shortName: "DocumentCardParcare",
			namespace: "asisoc",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentSolicitareFacilitatiCardParcare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarCard: { maxLength: 50, defaultValue: "" },
				dataEmitere: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"asisoc.DocumentCertificatHandicap": {
			shortName: "DocumentCertificatHandicap",
			namespace: "asisoc",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentSolicitareFacilitatiCertificatHandicap: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idHandicapGrad: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idHandicapTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				data: { dataType: "DateTime" },
				dataExpirareCertificat: { dataType: "DateTime" },
				dataReevaluareStart: { dataType: "DateTime" },
				dataReevaluareStop: { dataType: "DateTime" },
				dataInceputCertificat: { dataType: "DateTime" },
			}
		},
		"asisoc.DocumentDecizieScutireImpozit": {
			shortName: "DocumentDecizieScutireImpozit",
			namespace: "asisoc",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				numarDecizie: { maxLength: 50, defaultValue: "" },
				dataDecizie: { dataType: "DateTime" },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentSolicitareFacilitatiScutireImpozitProprietate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"asisoc.DocumentExtrasCarteFunciara": {
			shortName: "DocumentExtrasCarteFunciara",
			namespace: "asisoc",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentSolicitareFacilitatiScutireImpozitProprietate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarCarteFunciara: { maxLength: 50, defaultValue: "" },
				numarCadastral: { maxLength: 50, defaultValue: "" },
				dataEmitereExtras: { dataType: "DateTime" },
			}
		},
		"asisoc.DocumentSolicitareCertificatInmatriculare": {
			shortName: "DocumentSolicitareCertificatInmatriculare",
			namespace: "asisoc",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", defaultValue: 0 },
				idDocumentSolicitareFacilitatiScutireImpozitAuto: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"asisoc.DocumentSolicitareExtrasCarteFunciara": {
			shortName: "DocumentSolicitareExtrasCarteFunciara",
			namespace: "asisoc",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentSolicitareFacilitatiScutireImpozitProprietate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"asisoc.DocumentSolicitareFacilitati": {
			shortName: "DocumentSolicitareFacilitati",
			namespace: "asisoc",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIbanSolicitant: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIbanReprezentant: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaReprezentant: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarSolicitare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataSolicitare: { dataType: "DateTime" },
			}
		},
		"asisoc.DocumentSolicitareFacilitatiCardParcare": {
			shortName: "DocumentSolicitareFacilitatiCardParcare",
			namespace: "asisoc",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentSolicitareFacilitati: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"asisoc.DocumentSolicitareFacilitatiCertificatHandicap": {
			shortName: "DocumentSolicitareFacilitatiCertificatHandicap",
			namespace: "asisoc",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentSolicitareFacilitati: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentCertificatHandicapAnterior: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"asisoc.DocumentSolicitareFacilitatiIndemnizatie": {
			shortName: "DocumentSolicitareFacilitatiIndemnizatie",
			namespace: "asisoc",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				isIndemnizatieBuget: { dataType: "Boolean", isNullable: false },
				isAsistentPersonal: { dataType: "Boolean", isNullable: false },
				isIndemnizatieInsotitor: { dataType: "Boolean", isNullable: false },
				idDocumentSolicitareFacilitati: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idHandicapGrad: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				observatie: { maxLength: 250, defaultValue: "" },
			}
		},
		"asisoc.DocumentSolicitareFacilitatiScutireImpozit": {
			shortName: "DocumentSolicitareFacilitatiScutireImpozit",
			namespace: "asisoc",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentSolicitareFacilitati: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"asisoc.DocumentSolicitareFacilitatiScutireImpozitAuto": {
			shortName: "DocumentSolicitareFacilitatiScutireImpozitAuto",
			namespace: "asisoc",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				serieSasiu: { maxLength: 50, defaultValue: "" },
				numarInmatriculare: { maxLength: 50, defaultValue: "" },
				marcaAutovechicul: { maxLength: 50, defaultValue: "" },
				idDocumentSolicitareScutireImpozit: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"asisoc.DocumentSolicitareFacilitatiScutireImpozitProprietate": {
			shortName: "DocumentSolicitareFacilitatiScutireImpozitProprietate",
			namespace: "asisoc",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				numarCarteFunciara: { maxLength: 50, defaultValue: "" },
				numarCadastral: { maxLength: 50, defaultValue: "" },
				idLocalitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentSolicitareScutireImpozit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"asisoc.DocumentSolicitareFacilitatiVinieta": {
			shortName: "DocumentSolicitareFacilitatiVinieta",
			namespace: "asisoc",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", defaultValue: 0 },
				idDocumentSolicitareFacilitati: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				proprietarAuto: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaProprietarMasina: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				autovehiculNumarInmatriculare: { maxLength: 30, defaultValue: "" },
				autovehiculSerieSasiu: { maxLength: 50, defaultValue: "" },
				autovehiculMarca: { maxLength: 150, defaultValue: "" },
				idTipAutovehicul: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"asisoc.DocumentVinieta": {
			shortName: "DocumentVinieta",
			namespace: "asisoc",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentSolicitareFacilitatiVinieta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarVinieta: { maxLength: 50, defaultValue: "" },
				dataEmitere: { dataType: "DateTime" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				autovehiculNumarInmatriculare: { maxLength: 30, defaultValue: "" },
				autovehiculSerieSasiu: { maxLength: 50, defaultValue: "" },
				autovehiculMarca: { maxLength: 150, defaultValue: "" },
			}
		},
		"asisoc.Familii": {
			shortName: "Familii",
			namespace: "asisoc",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idrd: { dataType: "Int32", defaultValue: 0 },
				idPers: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipRelatieRudenie: { dataType: "Int32", defaultValue: 0 },
				idTipOcupatie: { dataType: "Int32", defaultValue: 0 },
				dataIntrare: { dataType: "DateTime" },
				dataIesire: { dataType: "DateTime" },
				idTipAptMunca: { dataType: "Int32", defaultValue: 0 },
				isNomPtMunca: { dataType: "Boolean" },
				profesia: { maxLength: 50, defaultValue: "" },
			}
		},
		"asisoc.HandicapGrad": {
			shortName: "HandicapGrad",
			namespace: "asisoc",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"asisoc.HandicapTip": {
			shortName: "HandicapTip",
			namespace: "asisoc",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"asisoc.TipRelatieRudenie": {
			shortName: "TipRelatieRudenie",
			namespace: "asisoc",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
			}
		},
		"asisoc.Versiune": {
			shortName: "Versiune",
			namespace: "asisoc",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				version: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stamp: { dataType: "DateTime", isNullable: false },
			}
		},
		"asisocistoric.DocumentCertificatHandicap": {
			shortName: "DocumentCertificatHandicap",
			namespace: "asisocistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentSolicitareFacilitatiCertificatHandicap: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idHandicapGrad: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idHandicapTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				data: { dataType: "DateTime" },
				dataExpirareCertificat: { dataType: "DateTime" },
				dataReevaluareStart: { dataType: "DateTime" },
				dataReevaluareStop: { dataType: "DateTime" },
				idDocumentCertificatHandicap: { dataType: "Int32" },
				dataStopValabilitate: { dataType: "DateTime" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataInceputCertificat: { dataType: "DateTime" },
			}
		},
		"asisocistoric.HandicapGrad": {
			shortName: "HandicapGrad",
			namespace: "asisocistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idHandicapGrad: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"asisocistoric.HandicapTip": {
			shortName: "HandicapTip",
			namespace: "asisocistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idHandicapTip: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"bilant.Formular": {
			shortName: "Formular",
			namespace: "bilant",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				iD: { dataType: "Int32", isPartOfKey: true },
				nrrnd: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nrrndf: { maxLength: 10, defaultValue: "" },
				denumire: { maxLength: 800, defaultValue: "" },
				cod: { maxLength: 20, defaultValue: "" },
				tip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anexa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				trimestru: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sursa: { maxLength: 4000, defaultValue: "" },
				titlu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				decalc: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sursa2: { maxLength: 4000, defaultValue: "" },
				sursa3: { maxLength: 4000, defaultValue: "" },
				sursa4: { maxLength: 4000, defaultValue: "" },
				sursa5: { maxLength: 4000, defaultValue: "" },
				sursa6: { maxLength: 4000, defaultValue: "" },
				sursa7: { maxLength: 4000, defaultValue: "" },
				sursa8: { maxLength: 4000, defaultValue: "" },
				sursa9: { maxLength: 4000, defaultValue: "" },
				sursa10: { maxLength: 4000, defaultValue: "" },
				sursa11: { maxLength: 4000, defaultValue: "" },
				sursa12: { maxLength: 4000, defaultValue: "" },
				sursa13: { maxLength: 4000, defaultValue: "" },
				sursa14: { maxLength: 4000, defaultValue: "" },
				sursa15: { maxLength: 4000, defaultValue: "" },
				nuLista: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.AsociereCapitolSectorSursa": {
			shortName: "AsociereCapitolSectorSursa",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codCapitol: { maxLength: 2, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.Buget": {
			shortName: "Buget",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 150, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				importDataJson: { defaultValue: "" },
				isInLei: { dataType: "Boolean", isNullable: false },
				isCrediteAngajament: { dataType: "Boolean", isNullable: false },
				versiuneFormularBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				versiuneFormularDetaliere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"buget.BugetClasificatieFunctionala": {
			shortName: "BugetClasificatieFunctionala",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBuget: { dataType: "Int32", defaultValue: 0 },
				idClasificatieFunctionala: { dataType: "Int32", defaultValue: 0 },
				capitol: { maxLength: 10, defaultValue: "" },
				sursa: { maxLength: 10, defaultValue: "" },
				subcapitol: { maxLength: 10, defaultValue: "" },
				paragraf: { maxLength: 50, defaultValue: "" },
				codSufix: { maxLength: 100, defaultValue: "" },
				codClasificatie: { maxLength: 70, defaultValue: "" },
				codClasificatiePersonalizare: { maxLength: 170, defaultValue: "" },
				codClasificatieSursa: { maxLength: 80, defaultValue: "" },
				codClasificatieSursaPersonalizare: { maxLength: 180, defaultValue: "" },
				nume: { maxLength: 400, defaultValue: "" },
				codIBAN: { defaultValue: "" },
				codIBANPersonalizare: { defaultValue: "" },
				cui: { dataType: "Int32", defaultValue: 0 },
				numeDeschidereCredite: { maxLength: 400, defaultValue: "" },
				isDeschidereCredite: { dataType: "Boolean", isNullable: false },
				isExportSICC: { dataType: "Boolean", isNullable: false },
				forexeBugApareInBugetIndividual: { dataType: "Boolean", isNullable: false },
				trezoreriaOperativa: { maxLength: 400, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.BugetClasificatieFunctionalaFormula": {
			shortName: "BugetClasificatieFunctionalaFormula",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBugetClasificatieFunctionalaParent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionalaChild: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"buget.BugetClasificatieFunctionalaFormulaPersonalizare": {
			shortName: "BugetClasificatieFunctionalaFormulaPersonalizare",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBugetClasificatieFunctionalaPersonalizareParent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionalaChild: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.BugetClasificatieFunctionalaPersonalizare": {
			shortName: "BugetClasificatieFunctionalaPersonalizare",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				capitol: { maxLength: 10, defaultValue: "" },
				sursa: { maxLength: 10, defaultValue: "" },
				subcapitol: { maxLength: 10, defaultValue: "" },
				paragraf: { maxLength: 50, defaultValue: "" },
				codSufix: { maxLength: 100, defaultValue: "" },
				codClasificatie: { maxLength: 70, defaultValue: "" },
				codClasificatiePersonalizare: { maxLength: 170, defaultValue: "" },
				codClasificatieSursa: { maxLength: 80, defaultValue: "" },
				codClasificatieSursaPersonalizare: { maxLength: 180, defaultValue: "" },
				nume: { maxLength: 400, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.BugetClasificatieFunctionalaSuma": {
			shortName: "BugetClasificatieFunctionalaSuma",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBugetRectificare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaInitial: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInitialCreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInfluente: { dataType: "Decimal", defaultValue: 0 },
				sumaInfluenteCreditAngajament: { dataType: "Decimal", defaultValue: 0 },
				sumaTotal: { dataType: "Decimal", defaultValue: 0 },
				sumaTotalCreditAngajament: { dataType: "Decimal", defaultValue: 0 },
				sumaTotalCorectie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTotalCorectieCreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInfluenteTrimestru1: { dataType: "Decimal", defaultValue: 0 },
				sumaInfluenteTrimestru1CreditAngajament: { dataType: "Decimal", defaultValue: 0 },
				sumaInitialTrimestru1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInitialTrimestru1CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru1CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInfluenteTrimestru2: { dataType: "Decimal", defaultValue: 0 },
				sumaInfluenteTrimestru2CreditAngajament: { dataType: "Decimal", defaultValue: 0 },
				sumaInitialTrimestru2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInitialTrimestru2CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru2CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInfluenteTrimestru3: { dataType: "Decimal", defaultValue: 0 },
				sumaInfluenteTrimestru3CreditAngajament: { dataType: "Decimal", defaultValue: 0 },
				sumaInitialTrimestru3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInitialTrimestru3CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru3CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInfluenteTrimestru4: { dataType: "Decimal", defaultValue: 0 },
				sumaInfluenteTrimestru4CreditAngajament: { dataType: "Decimal", defaultValue: 0 },
				sumaInitialTrimestru4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInitialTrimestru4CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru4CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaRestanta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn1CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn2CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn3CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				creditAngajamentSoldInitial: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				creditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				creditAngajamentTotal: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.BugetClasificatieFunctionalaSumaPersonalizare": {
			shortName: "BugetClasificatieFunctionalaSumaPersonalizare",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBugetRectificare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionalaPersonalizare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaInitial: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaRestanta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				creditAngajamentSoldInitial: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				creditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				creditAngajamentTotal: { dataType: "Decimal", defaultValue: 0 },
				sumaInitialTrimestru1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInitialTrimestru2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInitialTrimestru3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInitialTrimestru4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInfluenteTrimestru1: { dataType: "Decimal", defaultValue: 0 },
				sumaInfluenteTrimestru2: { dataType: "Decimal", defaultValue: 0 },
				sumaInfluenteTrimestru3: { dataType: "Decimal", defaultValue: 0 },
				sumaInfluenteTrimestru4: { dataType: "Decimal", defaultValue: 0 },
				sumaTotalCorectie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInfluente: { dataType: "Decimal", defaultValue: 0 },
				sumaTotal: { dataType: "Decimal", defaultValue: 0 },
				sumaInitialCreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTotalCorectieCreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInitialTrimestru1CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru1CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInitialTrimestru2CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru2CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInitialTrimestru3CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru3CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInitialTrimestru4CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru4CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn1CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn2CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn3CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInfluenteCreditAngajament: { dataType: "Decimal", defaultValue: 0 },
				sumaTotalCreditAngajament: { dataType: "Decimal", defaultValue: 0 },
				sumaInfluenteTrimestru1CreditAngajament: { dataType: "Decimal", defaultValue: 0 },
				sumaInfluenteTrimestru2CreditAngajament: { dataType: "Decimal", defaultValue: 0 },
				sumaInfluenteTrimestru3CreditAngajament: { dataType: "Decimal", defaultValue: 0 },
				sumaInfluenteTrimestru4CreditAngajament: { dataType: "Decimal", defaultValue: 0 },
			}
		},
		"buget.BugetClasificatieFunctionalaTree": {
			shortName: "BugetClasificatieFunctionalaTree",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBugetClasificatieFunctionala: { dataType: "Int32", defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				hierarchyIdParentId: { maxLength: 4000, defaultValue: "" },
				isLeafNode: { dataType: "Boolean", isNullable: false },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				importDataJson: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.BugetRectificare": {
			shortName: "BugetRectificare",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBuget: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 50, defaultValue: "" },
				trimestru: { dataType: "Int32", defaultValue: 0 },
				dataRectificare: { dataType: "DateTime", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				dataAnulare: { dataType: "DateTime" },
				isPropunereDeRectificare: { dataType: "Boolean", isNullable: false },
				isBugetProvizoriu: { dataType: "Boolean", isNullable: false },
				importDataJson: { defaultValue: "" },
				idBugetRectificareSursa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentInregistrareCrediteBugetare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentInregistrareCrediteBugetareVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				initial: { dataType: "Boolean", isNullable: false },
				dataAprobare: { dataType: "DateTime" },
			}
		},
		"buget.BugetSuma": {
			shortName: "BugetSuma",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBugetRectificare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaInitial: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInfluente: { dataType: "Decimal", defaultValue: 0 },
				sumaTotal: { dataType: "Decimal", defaultValue: 0 },
				sumaTotalCorectie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaRestanta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				creditAngajamentSoldInitial: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				creditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				creditAngajamentTotal: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.ClasificatieEconomica": {
			shortName: "ClasificatieEconomica",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				titlu: { maxLength: 2, defaultValue: "" },
				articol: { maxLength: 5, defaultValue: "" },
				alineat: { maxLength: 50, defaultValue: "" },
				codClasificatie: { maxLength: 57, defaultValue: "" },
				nume: { maxLength: 400, defaultValue: "" },
				isVenit: { dataType: "Boolean", isNullable: false },
				tipSectiune: { maxLength: 1, defaultValue: "" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.ClasificatieFunctionala": {
			shortName: "ClasificatieFunctionala",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				capitol: { maxLength: 10, defaultValue: "" },
				sursa: { maxLength: 10, defaultValue: "" },
				subcapitol: { maxLength: 10, defaultValue: "" },
				paragraf: { maxLength: 100, defaultValue: "" },
				codClasificatie: { maxLength: 120, defaultValue: "" },
				codClasificatieSursa: { maxLength: 130, defaultValue: "" },
				nume: { maxLength: 400, defaultValue: "" },
				isVenit: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.ClasificatieFunctionalaTree": {
			shortName: "ClasificatieFunctionalaTree",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idClasificatieFunctionala: { dataType: "Int32", defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.CodAngajament": {
			shortName: "CodAngajament",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codAngajament: { maxLength: 50, defaultValue: "" },
				codSectorSursa: { maxLength: 20, defaultValue: "" },
				codClasificatie: { maxLength: 50, defaultValue: "" },
			}
		},
		"buget.CodProgramBugetar": {
			shortName: "CodProgramBugetar",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 10, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cuiOpcOsc: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numeOpcOsc: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.DocumentAngajamentBugetar": {
			shortName: "DocumentAngajamentBugetar",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				scop: { maxLength: 100, defaultValue: "" },
				ibanPartener: { maxLength: 24, defaultValue: "" },
				isGlobal: { dataType: "Boolean", isNullable: false },
				explicatie: { maxLength: 250, defaultValue: "" },
				idCodProgramBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codProgramInvestitie: { maxLength: 10, defaultValue: "" },
				idObiectivInvestitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idObiectivInvestitieSubobiectiv: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isCreditAngajament: { dataType: "Boolean", isNullable: false },
				isIdenticPropunereCheltuiala: { dataType: "Boolean", isNullable: false },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isCuSold: { dataType: "Boolean", isNullable: false },
				soldNeplatit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.DocumentAngajamentBugetarSuplimentare": {
			shortName: "DocumentAngajamentBugetarSuplimentare",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				modSuplimentare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.DocumentAngajamentLegal": {
			shortName: "DocumentAngajamentLegal",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 250, defaultValue: "" },
				idLucrare: { dataType: "Int32", defaultValue: 0 },
				idAchizitie: { dataType: "Int32", defaultValue: 0 },
				codAngajament: { maxLength: 50, defaultValue: "" },
				codAngajamentInitial: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajamentInitial: { maxLength: 50, defaultValue: "" },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				soldNeplatit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isGlobal: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.DocumentAngajamentLegalSuplimentare": {
			shortName: "DocumentAngajamentLegalSuplimentare",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				modSuplimentare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.DocumentCerereCredite": {
			shortName: "DocumentCerereCredite",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataDreptUtilizare: { dataType: "DateTime" },
				tipSectiune: { maxLength: 1, defaultValue: "" },
				tipCerere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				explicatieNotaJustificativa: { defaultValue: "" },
			}
		},
		"buget.DocumentCerereCrediteSuma": {
			shortName: "DocumentCerereCrediteSuma",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaValuta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaLei: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.DocumentDeschidereCredite": {
			shortName: "DocumentDeschidereCredite",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				scop: { maxLength: 100, defaultValue: "" },
				explicatie: { maxLength: 200, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.DocumentDeschidereCrediteSuma": {
			shortName: "DocumentDeschidereCrediteSuma",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaValuta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaLei: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.DocumentLichidare": {
			shortName: "DocumentLichidare",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 250, defaultValue: "" },
				codModGenerare: { dataType: "Int32", defaultValue: 0 },
				isCuSold: { dataType: "Boolean", isNullable: false },
				soldNeplatit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isViramentDirect: { dataType: "Boolean", isNullable: false },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isTransferIntreConturi: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idPlataNumerarTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"buget.DocumentOrdonantare": {
			shortName: "DocumentOrdonantare",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 250, defaultValue: "" },
				idPersoanaBancaPlatitor: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				ibanPlatitor: { maxLength: 24, defaultValue: "" },
				isAvans: { dataType: "Boolean", isNullable: false },
				modPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentDeviz: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIbanBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isPenalitateContractRetinutaLaPlata: { dataType: "Boolean", isNullable: false },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isCuSold: { dataType: "Boolean", isNullable: false },
				soldNeplatit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.DocumentPropunereCheltuiala": {
			shortName: "DocumentPropunereCheltuiala",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				scop: { maxLength: 100, defaultValue: "" },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.FormularBuget": {
			shortName: "FormularBuget",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				capitol: { maxLength: 10, defaultValue: "" },
				sursa: { maxLength: 10, defaultValue: "" },
				subcapitol: { maxLength: 10, defaultValue: "" },
				paragraf: { maxLength: 50, defaultValue: "" },
				codClasificatie: { maxLength: 70, defaultValue: "" },
				codClasificatieSursa: { maxLength: 80, defaultValue: "" },
				codIBAN: { defaultValue: "" },
				tipSectiune: { maxLength: 1, defaultValue: "" },
				nume: { maxLength: 400, defaultValue: "" },
				idClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isVenit: { dataType: "Boolean", isNullable: false },
				isExtindereDinDetaliere: { dataType: "Boolean", isNullable: false },
				formulaExtindereDinDetaliere: { maxLength: 50, defaultValue: "" },
				formulaEstimari: { maxLength: 50, defaultValue: "" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.FormularBugetFormula": {
			shortName: "FormularBugetFormula",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idFormularBugetParent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularBugetChild: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sign: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.FormularBugetTree": {
			shortName: "FormularBugetTree",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idFormularBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				sign: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isLeafNode: { dataType: "Boolean", isNullable: false },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.FormularClasificatieEconomica": {
			shortName: "FormularClasificatieEconomica",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				titlu: { maxLength: 2, defaultValue: "" },
				sursa: { maxLength: 10, defaultValue: "" },
				articol: { maxLength: 5, defaultValue: "" },
				alineat: { maxLength: 50, defaultValue: "" },
				codSufix: { maxLength: 100, defaultValue: "" },
				nume: { maxLength: 400, defaultValue: "" },
				isVenit: { dataType: "Boolean", isNullable: false },
				tipSectiune: { maxLength: 1, defaultValue: "" },
				idClasificatieEconomica: { dataType: "Int32", defaultValue: 0 },
				idClasificatieFunctionala: { dataType: "Int32", defaultValue: 0 },
				codClasificatie: { maxLength: 57, defaultValue: "" },
				codClasificatieSursa: { maxLength: 67, defaultValue: "" },
				codIBAN: { defaultValue: "" },
				isExtindereInBuget: { dataType: "Boolean", isNullable: false },
				isDeschidereCredite: { dataType: "Boolean", isNullable: false },
				isListareAfisareBold: { dataType: "Boolean", isNullable: false },
				isListareFaraAfisare: { dataType: "Boolean", isNullable: false },
				formulaEstimari: { maxLength: 50, defaultValue: "" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				codClasificatieSursaPersonalizare: { maxLength: 167, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idFormularClasificatieEconomicaTitlu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"buget.FormularClasificatieEconomicaFormula": {
			shortName: "FormularClasificatieEconomicaFormula",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idFormularClasificatieEconomicaParent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomicaChild: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sign: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.FormularClasificatieEconomicaPersonalizare": {
			shortName: "FormularClasificatieEconomicaPersonalizare",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idFormularClasificatieEconomica: { dataType: "Int32", defaultValue: 0 },
				isExtindereInBuget: { dataType: "Boolean", isNullable: false },
				isDeschidereCredite: { dataType: "Boolean", isNullable: false },
				isListareAfisareBold: { dataType: "Boolean", isNullable: false },
				isListareFaraAfisare: { dataType: "Boolean", isNullable: false },
			}
		},
		"buget.FormularClasificatieEconomicaTree": {
			shortName: "FormularClasificatieEconomicaTree",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				sign: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isLeafNode: { dataType: "Boolean", isNullable: false },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.SectorBugetar": {
			shortName: "SectorBugetar",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 10, defaultValue: "" },
				codIBAN: { maxLength: 10, defaultValue: "" },
				nume: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.SumaExecutie": {
			shortName: "SumaExecutie",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaValuta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaLei: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"buget.SursaFinantare": {
			shortName: "SursaFinantare",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 10, defaultValue: "" },
				nume: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				formular: { maxLength: 50, defaultValue: "" },
			}
		},
		"buget.Versiune": {
			shortName: "Versiune",
			namespace: "buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				version: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stamp: { dataType: "DateTime", isNullable: false },
			}
		},
		"bugetistoric.AsociereCapitolSectorSursa": {
			shortName: "AsociereCapitolSectorSursa",
			namespace: "bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codCapitol: { maxLength: 2, defaultValue: "" },
				idAsociereCapitolSectorSursa: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"bugetistoric.Buget": {
			shortName: "Buget",
			namespace: "bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 150, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idBuget: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				importDataJson: { defaultValue: "" },
				isInLei: { dataType: "Boolean", isNullable: false },
				isCrediteAngajament: { dataType: "Boolean", isNullable: false },
				versiuneFormularBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				versiuneFormularDetaliere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"bugetistoric.BugetClasificatieFunctionala": {
			shortName: "BugetClasificatieFunctionala",
			namespace: "bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBuget: { dataType: "Int32", defaultValue: 0 },
				idClasificatieFunctionala: { dataType: "Int32", defaultValue: 0 },
				capitol: { maxLength: 10, defaultValue: "" },
				sursa: { maxLength: 10, defaultValue: "" },
				subcapitol: { maxLength: 10, defaultValue: "" },
				paragraf: { maxLength: 50, defaultValue: "" },
				codSufix: { maxLength: 100, defaultValue: "" },
				codClasificatie: { maxLength: 70, defaultValue: "" },
				codClasificatiePersonalizare: { maxLength: 170, defaultValue: "" },
				codClasificatieSursa: { maxLength: 80, defaultValue: "" },
				codClasificatieSursaPersonalizare: { maxLength: 180, defaultValue: "" },
				nume: { maxLength: 400, defaultValue: "" },
				codIBAN: { defaultValue: "" },
				codIBANPersonalizare: { defaultValue: "" },
				cui: { dataType: "Int32", defaultValue: 0 },
				numeDeschidereCredite: { maxLength: 400, defaultValue: "" },
				isDeschidereCredite: { dataType: "Boolean", isNullable: false },
				isExportSICC: { dataType: "Boolean", isNullable: false },
				forexeBugApareInBugetIndividual: { dataType: "Boolean", isNullable: false },
				idBugetClasificatieFunctionala: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				trezoreriaOperativa: { maxLength: 400, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"bugetistoric.BugetClasificatieFunctionalaFormula": {
			shortName: "BugetClasificatieFunctionalaFormula",
			namespace: "bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBugetClasificatieFunctionalaParent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionalaChild: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionalaFormula: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"bugetistoric.BugetClasificatieFunctionalaFormulaPersonalizare": {
			shortName: "BugetClasificatieFunctionalaFormulaPersonalizare",
			namespace: "bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBugetClasificatieFunctionalaPersonalizareParent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionalaChild: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionalaFormulaPersonalizare: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"bugetistoric.BugetClasificatieFunctionalaPersonalizare": {
			shortName: "BugetClasificatieFunctionalaPersonalizare",
			namespace: "bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				capitol: { maxLength: 10, defaultValue: "" },
				sursa: { maxLength: 10, defaultValue: "" },
				subcapitol: { maxLength: 10, defaultValue: "" },
				paragraf: { maxLength: 50, defaultValue: "" },
				codSufix: { maxLength: 100, defaultValue: "" },
				codClasificatie: { maxLength: 70, defaultValue: "" },
				codClasificatiePersonalizare: { maxLength: 170, defaultValue: "" },
				codClasificatieSursa: { maxLength: 80, defaultValue: "" },
				codClasificatieSursaPersonalizare: { maxLength: 180, defaultValue: "" },
				nume: { maxLength: 400, defaultValue: "" },
				idBugetClasificatieFunctionalaPersonalizare: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"bugetistoric.BugetClasificatieFunctionalaTree": {
			shortName: "BugetClasificatieFunctionalaTree",
			namespace: "bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBugetClasificatieFunctionala: { dataType: "Int32", defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				hierarchyIdParentId: { maxLength: 4000, defaultValue: "" },
				idBugetClasificatieFunctionalaTree: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				isLeafNode: { dataType: "Boolean", isNullable: false },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				importDataJson: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"bugetistoric.ClasificatieEconomica": {
			shortName: "ClasificatieEconomica",
			namespace: "bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				titlu: { maxLength: 2, defaultValue: "" },
				articol: { maxLength: 5, defaultValue: "" },
				alineat: { maxLength: 50, defaultValue: "" },
				codClasificatie: { maxLength: 57, defaultValue: "" },
				nume: { maxLength: 400, defaultValue: "" },
				isVenit: { dataType: "Boolean", isNullable: false },
				tipSectiune: { maxLength: 1, defaultValue: "" },
				idClasificatieEconomica: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"bugetistoric.ClasificatieFunctionala": {
			shortName: "ClasificatieFunctionala",
			namespace: "bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				capitol: { maxLength: 10, defaultValue: "" },
				sursa: { maxLength: 10, defaultValue: "" },
				subcapitol: { maxLength: 10, defaultValue: "" },
				paragraf: { maxLength: 100, defaultValue: "" },
				codClasificatie: { maxLength: 120, defaultValue: "" },
				codClasificatieSursa: { maxLength: 130, defaultValue: "" },
				nume: { maxLength: 400, defaultValue: "" },
				isVenit: { dataType: "Boolean", isNullable: false },
				idClasificatieFunctionala: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"bugetistoric.ClasificatieFunctionalaTree": {
			shortName: "ClasificatieFunctionalaTree",
			namespace: "bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idClasificatieFunctionala: { dataType: "Int32", defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				idClasificatieFunctionalaTree: { dataType: "Int32" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"bugetistoric.CodProgramBugetar": {
			shortName: "CodProgramBugetar",
			namespace: "bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 10, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cuiOpcOsc: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numeOpcOsc: { maxLength: 250, defaultValue: "" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idCodProgramBugetar: { dataType: "Int32" },
			}
		},
		"bugetistoric.FormularBuget": {
			shortName: "FormularBuget",
			namespace: "bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				capitol: { maxLength: 10, defaultValue: "" },
				sursa: { maxLength: 10, defaultValue: "" },
				subcapitol: { maxLength: 10, defaultValue: "" },
				paragraf: { maxLength: 50, defaultValue: "" },
				codClasificatie: { maxLength: 70, defaultValue: "" },
				codClasificatieSursa: { maxLength: 80, defaultValue: "" },
				codIBAN: { defaultValue: "" },
				tipSectiune: { maxLength: 1, defaultValue: "" },
				nume: { maxLength: 400, defaultValue: "" },
				idClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isVenit: { dataType: "Boolean", isNullable: false },
				isExtindereDinDetaliere: { dataType: "Boolean", isNullable: false },
				formulaExtindereDinDetaliere: { maxLength: 50, defaultValue: "" },
				formulaEstimari: { maxLength: 50, defaultValue: "" },
				idFormularBuget: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"bugetistoric.FormularBugetFormula": {
			shortName: "FormularBugetFormula",
			namespace: "bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idFormularBugetParent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularBugetChild: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sign: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularBugetFormula: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"bugetistoric.FormularBugetTree": {
			shortName: "FormularBugetTree",
			namespace: "bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idFormularBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				sign: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isLeafNode: { dataType: "Boolean", isNullable: false },
				idFormularBugetTree: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"bugetistoric.FormularClasificatieEconomica": {
			shortName: "FormularClasificatieEconomica",
			namespace: "bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				titlu: { maxLength: 2, defaultValue: "" },
				sursa: { maxLength: 10, defaultValue: "" },
				articol: { maxLength: 5, defaultValue: "" },
				alineat: { maxLength: 50, defaultValue: "" },
				codSufix: { maxLength: 100, defaultValue: "" },
				nume: { maxLength: 400, defaultValue: "" },
				isVenit: { dataType: "Boolean", isNullable: false },
				tipSectiune: { maxLength: 1, defaultValue: "" },
				idClasificatieEconomica: { dataType: "Int32", defaultValue: 0 },
				idClasificatieFunctionala: { dataType: "Int32", defaultValue: 0 },
				codClasificatie: { maxLength: 57, defaultValue: "" },
				codClasificatieSursa: { maxLength: 67, defaultValue: "" },
				codIBAN: { defaultValue: "" },
				isExtindereInBuget: { dataType: "Boolean", isNullable: false },
				isDeschidereCredite: { dataType: "Boolean", isNullable: false },
				isListareAfisareBold: { dataType: "Boolean", isNullable: false },
				isListareFaraAfisare: { dataType: "Boolean", isNullable: false },
				formulaEstimari: { maxLength: 50, defaultValue: "" },
				idFormularClasificatieEconomica: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				codClasificatieSursaPersonalizare: { maxLength: 167, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idFormularClasificatieEconomicaTitlu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"bugetistoric.FormularClasificatieEconomicaFormula": {
			shortName: "FormularClasificatieEconomicaFormula",
			namespace: "bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idFormularClasificatieEconomicaParent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomicaChild: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sign: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomicaFormula: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"bugetistoric.FormularClasificatieEconomicaPersonalizare": {
			shortName: "FormularClasificatieEconomicaPersonalizare",
			namespace: "bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idFormularClasificatieEconomica: { dataType: "Int32", defaultValue: 0 },
				isExtindereInBuget: { dataType: "Boolean", isNullable: false },
				isDeschidereCredite: { dataType: "Boolean", isNullable: false },
				isListareAfisareBold: { dataType: "Boolean", isNullable: false },
				isListareFaraAfisare: { dataType: "Boolean", isNullable: false },
				idFormularClasificatieEconomicaPersonalizare: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"bugetistoric.FormularClasificatieEconomicaTree": {
			shortName: "FormularClasificatieEconomicaTree",
			namespace: "bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				sign: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomicaTree: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				isLeafNode: { dataType: "Boolean", isNullable: false },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"bugetistoric.SectorBugetar": {
			shortName: "SectorBugetar",
			namespace: "bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 10, defaultValue: "" },
				codIBAN: { maxLength: 10, defaultValue: "" },
				nume: { maxLength: 50, defaultValue: "" },
				idSectorBugetar: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"bugetistoric.SursaFinantare": {
			shortName: "SursaFinantare",
			namespace: "bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 10, defaultValue: "" },
				nume: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idSursaFinantare: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				formular: { maxLength: 50, defaultValue: "" },
			}
		},
		"cantina.Alergen": {
			shortName: "Alergen",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				denumire: { maxLength: 250, defaultValue: "" },
				descriere: { maxLength: 1000, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idCategorieAlergen: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"cantina.Aliment": {
			shortName: "Aliment",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				idUnitateMasura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				scazamant: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"cantina.AlimentAlergen": {
			shortName: "AlimentAlergen",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idAliment: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idAlergen: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"cantina.AlimentContinutNutritional": {
			shortName: "AlimentContinutNutritional",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idAliment: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				alergeni: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				lipide: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				glucide: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				calorii: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				proteine: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				apa: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				colesterol: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				celuloza: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
			}
		},
		"cantina.Categorie": {
			shortName: "Categorie",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
			}
		},
		"cantina.CategorieBeneficiar": {
			shortName: "CategorieBeneficiar",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"cantina.CategorieBeneficiarOrganizareInstitutionala": {
			shortName: "CategorieBeneficiarOrganizareInstitutionala",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idCategorieBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isFaraCalculEconomiiDepasiri: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"cantina.ImaginiMeniuPlanificat": {
			shortName: "ImaginiMeniuPlanificat",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				image: { defaultValue: "" },
			}
		},
		"cantina.MasaTip": {
			shortName: "MasaTip",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"cantina.MeniuPlanificat": {
			shortName: "MeniuPlanificat",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				data: { dataType: "DateTime" },
				idMeniuZi: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCategorieBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				alergeni: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				lipide: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				glucide: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				calorii: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				proteine: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				numarMeniuriEstimat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarMeniuriServit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarMeniuriPreparat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"cantina.MeniuPlanificatDetaliu": {
			shortName: "MeniuPlanificatDetaliu",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idMeniuPlanificat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idMasaTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarMeniuriEstimat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarMeniuriServit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarMeniuriPreparat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"cantina.MeniuZi": {
			shortName: "MeniuZi",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"cantina.MeniuZiCategorieBeneficiar": {
			shortName: "MeniuZiCategorieBeneficiar",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idMeniuZi: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCategorieBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"cantina.MeniuZiPreparat": {
			shortName: "MeniuZiPreparat",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idMeniuZi: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPreparat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOraServire: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idMasaTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"cantina.OraServire": {
			shortName: "OraServire",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				ora: { dataType: "DateTime", isNullable: false },
				nume: { maxLength: 150, defaultValue: "" },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idMasaTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"cantina.OraServireCategorieBeneficiar": {
			shortName: "OraServireCategorieBeneficiar",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOraServire: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCategorieBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"cantina.Preparat": {
			shortName: "Preparat",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"cantina.PreparatAliment": {
			shortName: "PreparatAliment",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPreparat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idAliment: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"cantina.PreparatCategorie": {
			shortName: "PreparatCategorie",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPreparat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCategorie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"cantina.PreparatCategorieBeneficiar": {
			shortName: "PreparatCategorieBeneficiar",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPreparat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCategorieBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"cantina.SemnaturaListaZilnicaAlimente": {
			shortName: "SemnaturaListaZilnicaAlimente",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionalaCantina: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataSemnatura: { dataType: "DateTime" },
				idPersoanaSefCompartiment: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaIntocmit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBucatar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaCalculat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaGestionar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaConfirmat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"cantina.SoldInitial": {
			shortName: "SoldInitial",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCategorieBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valoareDrepturi: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareEliberata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"cantina.Versiune": {
			shortName: "Versiune",
			namespace: "cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				version: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stamp: { dataType: "DateTime", isNullable: false },
			}
		},
		"cantinaistoric.CategorieBeneficiarOrganizareInstitutionala": {
			shortName: "CategorieBeneficiarOrganizareInstitutionala",
			namespace: "cantinaistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idCategorieBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isFaraCalculEconomiiDepasiri: { dataType: "Boolean", isNullable: false },
				idCategorieBeneficiarOrganizareInstitutionala: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"cantinaistoric.OraServire": {
			shortName: "OraServire",
			namespace: "cantinaistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				ora: { dataType: "DateTime", isNullable: false },
				nume: { maxLength: 150, defaultValue: "" },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOraServire: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				idMasaTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"casierie.AmenziFiz": {
			shortName: "AmenziFiz",
			namespace: "casierie",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", defaultValue: 0 },
				idTipVenit: { dataType: "Int32", defaultValue: 0 },
				procesVerbal: { maxLength: 50, defaultValue: "" },
				procesVerbalNr: { maxLength: 20, defaultValue: "" },
				valoare: { dataType: "Decimal", defaultValue: 0 },
				dataScadenta: { dataType: "DateTime" },
				explicatie: { maxLength: 100, defaultValue: "" },
				dataAmenda: { dataType: "DateTime" },
				procentReducere: { dataType: "Int32", defaultValue: 0 },
				valoareReducere: { dataType: "Decimal", defaultValue: 0 },
				dataReducere: { dataType: "DateTime" },
				explicatieReducere: { maxLength: 50, defaultValue: "" },
				utiReducere: { dataType: "Int32", defaultValue: 0 },
				valoareAchitata: { dataType: "Decimal", defaultValue: 0 },
				dataAchitare: { dataType: "DateTime" },
				explicatieAchitare: { maxLength: 50, defaultValue: "" },
				utiAchitare: { maxLength: 50, defaultValue: "" },
				nrInreg: { dataType: "Int32", defaultValue: 0 },
				dataValidare: { dataType: "DateTime" },
				utiValidare: { maxLength: 50, defaultValue: "" },
				dataCreare: { dataType: "DateTime" },
				utiCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				explicatieAnulare: { maxLength: 100, defaultValue: "" },
				utiAnulare: { maxLength: 50, defaultValue: "" },
				dataAnulareUti: { dataType: "DateTime" },
				anDeclaratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				judetIntrare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				matricola: { dataType: "Int32", defaultValue: 0 },
				nrInregAmenda: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dataInregAmenda: { dataType: "DateTime", isNullable: false },
				localitateIntrare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				flagIncas: { dataType: "Int32", defaultValue: 0 },
				emitent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				somatie: { maxLength: 50, defaultValue: "" },
				nrcid: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				datacid: { dataType: "DateTime", isNullable: false },
				dataInstiintare: { dataType: "DateTime" },
			}
		},
		"casierie.DebitFiz": {
			shortName: "DebitFiz",
			namespace: "casierie",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnic: { maxLength: 50, defaultValue: "" },
				idPoz: { dataType: "Int32", defaultValue: 0 },
				explicatie: { maxLength: 150, defaultValue: "" },
				sumaTrim1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrim2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrim3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrim4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dataTrim1: { dataType: "DateTime" },
				dataTrim2: { dataType: "DateTime" },
				dataTrim3: { dataType: "DateTime" },
				dataTrim4: { dataType: "DateTime" },
				bonitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataAnulare: { dataType: "DateTime" },
				utiAnulare: { maxLength: 50, defaultValue: "" },
				explicatieAnulare: { maxLength: 100, defaultValue: "" },
				dataCreare: { dataType: "DateTime" },
				utiCreare: { maxLength: 50, defaultValue: "" },
				uti: { maxLength: 50, defaultValue: "" },
				an: { dataType: "Int32", defaultValue: 0 },
				l1: { dataType: "Decimal", defaultValue: 0 },
				l2: { dataType: "Decimal", defaultValue: 0 },
				l3: { dataType: "Decimal", defaultValue: 0 },
				l4: { dataType: "Decimal", defaultValue: 0 },
				l5: { dataType: "Decimal", defaultValue: 0 },
				l6: { dataType: "Decimal", defaultValue: 0 },
				l7: { dataType: "Decimal", defaultValue: 0 },
				l8: { dataType: "Decimal", defaultValue: 0 },
				l9: { dataType: "Decimal", defaultValue: 0 },
				l10: { dataType: "Decimal", defaultValue: 0 },
				l11: { dataType: "Decimal", defaultValue: 0 },
				l12: { dataType: "Decimal", defaultValue: 0 },
				initial: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valImpozabila: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				operatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codact: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"casierie.DebitJur": {
			shortName: "DebitJur",
			namespace: "casierie",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnic: { maxLength: 50, defaultValue: "" },
				idPoz: { dataType: "Int32", defaultValue: 0 },
				explicatie: { maxLength: 150, defaultValue: "" },
				sumaTrim1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrim2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrim3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrim4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dobanda: { dataType: "Decimal", defaultValue: 0 },
				penalitate: { dataType: "Decimal", defaultValue: 0 },
				dataTrim1: { dataType: "DateTime" },
				dataTrim2: { dataType: "DateTime" },
				dataTrim3: { dataType: "DateTime" },
				dataTrim4: { dataType: "DateTime" },
				bonitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataAnulare: { dataType: "DateTime" },
				utiAnulare: { maxLength: 50, defaultValue: "" },
				explicatieAnulare: { maxLength: 100, defaultValue: "" },
				dataCreare: { dataType: "DateTime" },
				l7: { dataType: "Decimal", defaultValue: 0 },
				utiCreare: { maxLength: 50, defaultValue: "" },
				uti: { maxLength: 50, defaultValue: "" },
				an: { dataType: "Int32", defaultValue: 0 },
				l1: { dataType: "Decimal", defaultValue: 0 },
				l2: { dataType: "Decimal", defaultValue: 0 },
				l3: { dataType: "Decimal", defaultValue: 0 },
				l4: { dataType: "Decimal", defaultValue: 0 },
				l5: { dataType: "Decimal", defaultValue: 0 },
				l6: { dataType: "Decimal", defaultValue: 0 },
				l8: { dataType: "Decimal", defaultValue: 0 },
				l9: { dataType: "Decimal", defaultValue: 0 },
				l10: { dataType: "Decimal", defaultValue: 0 },
				l11: { dataType: "Decimal", defaultValue: 0 },
				l12: { dataType: "Decimal", defaultValue: 0 },
				initial: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				operatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codact: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valImpozabila: { dataType: "Decimal", defaultValue: 0 },
			}
		},
		"casierie.GrupVenit": {
			shortName: "GrupVenit",
			namespace: "casierie",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				tipuri: { maxLength: 4000, defaultValue: "" },
				dataCreare: { dataType: "DateTime", isNullable: false },
				utiCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utiAnulare: { maxLength: 50, defaultValue: "" },
				tip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"casierie.IncasareFiz": {
			shortName: "IncasareFiz",
			namespace: "casierie",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", defaultValue: 0 },
				idTipVenit: { dataType: "Int32", defaultValue: 0 },
				idUnic: { maxLength: 50, defaultValue: "" },
				idIntrare: { dataType: "Int32", defaultValue: 0 },
				data: { dataType: "DateTime" },
				datai: { dataType: "DateTime" },
				chitanta: { maxLength: 25, defaultValue: "" },
				an: { dataType: "Int32", defaultValue: 0 },
				debit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				ramasita: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dobanda: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				penalitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 100, defaultValue: "" },
				bazad: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvad: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				bazar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumalu: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumalg: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				restlu: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				restlg: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rrn: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				banca: { dataType: "Int32", defaultValue: 0 },
				dataCreare: { dataType: "DateTime" },
				utiCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utiAnulare: { maxLength: 50, defaultValue: "" },
				dataValidare: { dataType: "DateTime" },
				utiValidare: { maxLength: 50, defaultValue: "" },
				bonitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				operatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sursa: { dataType: "Int32", defaultValue: 0 },
				idPoz: { dataType: "Int32", defaultValue: 0 },
				pereche: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenitSupr: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnicSupr: { maxLength: 50, defaultValue: "" },
				nrFactura: { dataType: "Int32", defaultValue: 0 },
				codact: { dataType: "Int32", defaultValue: 0 },
				explChitanta: { maxLength: 250, defaultValue: "" },
				idEpay: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idEIncasari: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				incfictiv: { dataType: "Int32", defaultValue: 0 },
				idIncasator: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idLocatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idInstanta: { maxLength: 50, defaultValue: "" },
			}
		},
		"casierie.IncasareJur": {
			shortName: "IncasareJur",
			namespace: "casierie",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", defaultValue: 0 },
				idTipVenit: { dataType: "Int32", defaultValue: 0 },
				idUnic: { maxLength: 50, defaultValue: "" },
				idIntrare: { dataType: "Int32", defaultValue: 0 },
				data: { dataType: "DateTime" },
				datai: { dataType: "DateTime" },
				chitanta: { maxLength: 25, defaultValue: "" },
				an: { dataType: "Int32", defaultValue: 0 },
				debit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				ramasita: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dobanda: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				penalitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 100, defaultValue: "" },
				bazad: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvad: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				bazar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumalu: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumalg: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				restlu: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				restlg: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rrn: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				banca: { dataType: "Int32", defaultValue: 0 },
				dataCreare: { dataType: "DateTime" },
				utiCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utiAnulare: { maxLength: 50, defaultValue: "" },
				dataValidare: { dataType: "DateTime" },
				utiValidare: { maxLength: 50, defaultValue: "" },
				bonitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				operatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sursa: { dataType: "Int32", defaultValue: 0 },
				idPoz: { dataType: "Int32", defaultValue: 0 },
				pereche: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnicVechi: { maxLength: 50, defaultValue: "" },
				idVenitSupr: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnicSupr: { maxLength: 50, defaultValue: "" },
				nrFactura: { dataType: "Int32", defaultValue: 0 },
				codact: { dataType: "Int32", defaultValue: 0 },
				explChitanta: { maxLength: 250, defaultValue: "" },
				idEpay: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idEIncasari: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				incfictiv: { dataType: "Int32", defaultValue: 0 },
				idLocatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idIncasator: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idInstanta: { maxLength: 50, defaultValue: "" },
			}
		},
		"casierie.RamasitaFiz": {
			shortName: "RamasitaFiz",
			namespace: "casierie",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", defaultValue: 0 },
				idTipVenit: { dataType: "Int32", defaultValue: 0 },
				idUnic: { maxLength: 50, defaultValue: "" },
				data: { dataType: "DateTime", isNullable: false },
				an: { dataType: "Int32", defaultValue: 0 },
				debit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dobanda: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				penalitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 100, defaultValue: "" },
				dataCreare: { dataType: "DateTime" },
				utiCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utiAnulare: { maxLength: 50, defaultValue: "" },
				explicatieAnulare: { maxLength: 100, defaultValue: "" },
				dataValidare: { dataType: "DateTime" },
				utiValidare: { maxLength: 50, defaultValue: "" },
				uti: { maxLength: 50, defaultValue: "" },
				datai: { dataType: "DateTime", isNullable: false },
				anRamasita: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				compensare: { dataType: "Int32", defaultValue: 0 },
				operatie: { dataType: "Int32", defaultValue: 0 },
				codact: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPoz: { dataType: "Int32", defaultValue: 0 },
				pereche: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursv: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				bazasct: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
			}
		},
		"casierie.RamasitaJur": {
			shortName: "RamasitaJur",
			namespace: "casierie",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", defaultValue: 0 },
				idTipVenit: { dataType: "Int32", defaultValue: 0 },
				idUnic: { maxLength: 50, defaultValue: "" },
				data: { dataType: "DateTime", isNullable: false },
				an: { dataType: "Int32", defaultValue: 0 },
				debit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dobanda: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				penalitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 100, defaultValue: "" },
				dataCreare: { dataType: "DateTime" },
				utiCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utiAnulare: { maxLength: 50, defaultValue: "" },
				explicatieAnulare: { maxLength: 100, defaultValue: "" },
				dataValidare: { dataType: "DateTime" },
				utiValidare: { maxLength: 50, defaultValue: "" },
				uti: { maxLength: 50, defaultValue: "" },
				datai: { dataType: "DateTime", isNullable: false },
				anRamasita: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				compensare: { dataType: "Int32", defaultValue: 0 },
				operatie: { dataType: "Int32", defaultValue: 0 },
				codact: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPoz: { dataType: "Int32", defaultValue: 0 },
				pereche: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursv: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				bazasct: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
			}
		},
		"companie.Repfisc": {
			shortName: "Repfisc",
			namespace: "companie",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cui: { maxLength: 50, defaultValue: "" },
				codCaen: { maxLength: 200, defaultValue: "" },
				nume: { maxLength: 251, defaultValue: "" },
				telefon: { maxLength: 50, defaultValue: "" },
				fax: { maxLength: 50, defaultValue: "" },
				email: { maxLength: 50, defaultValue: "" },
				numeReprezentant: { maxLength: 251, defaultValue: "" },
				functieReprezentant: { maxLength: 150, defaultValue: "" },
				adresaReprezentant: { maxLength: 150, defaultValue: "" },
				regiune: { maxLength: 50, defaultValue: "" },
				localitate: { maxLength: 50, defaultValue: "" },
				strada: { maxLength: 50, defaultValue: "" },
				numar: { maxLength: 30, defaultValue: "" },
				cuiReprezentant: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				telefonReprezentant: { maxLength: 50, defaultValue: "" },
				faxReprezentant: { maxLength: 50, defaultValue: "" },
				emailReprezentant: { maxLength: 50, defaultValue: "" },
				isRegimSpecialTva: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"comune.FormaDetinere": {
			shortName: "FormaDetinere",
			namespace: "comune",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
			}
		},
		"contab.AlgoritmInchidereConturi": {
			shortName: "AlgoritmInchidereConturi",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idContInchis: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContInchidere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isInchidereLuna: { dataType: "Boolean", isNullable: false },
				isInchidereAn: { dataType: "Boolean", isNullable: false },
				isInchidereTrimestru: { dataType: "Boolean", isNullable: false },
				isInchidereOrdonatorPrincipal: { dataType: "Boolean" },
				isInchidereOrdonatorSecundar: { dataType: "Boolean" },
				idTipInchidere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contab.AsociereActivitateEconomicaArticol": {
			shortName: "AsociereActivitateEconomicaArticol",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contab.AsociereClasificatieEconomicaCont": {
			shortName: "AsociereClasificatieEconomicaCont",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContFurnizor: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContStoc: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"contab.AsociereContCrediteBugetare": {
			shortName: "AsociereContCrediteBugetare",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idCont: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCrediteBugetareTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idJurnal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contab.AsociereNotaDetaliuDocument": {
			shortName: "AsociereNotaDetaliuDocument",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idNota: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentFurnizorClientDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"contab.AsociereOperatiuneDocFurnizorJurnal": {
			shortName: "AsociereOperatiuneDocFurnizorJurnal",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentOperatiuneTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idJurnal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"contab.Balanta": {
			shortName: "Balanta",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idCont: { dataType: "Int32", defaultValue: 0 },
				soldInitialDebit: { dataType: "Decimal", defaultValue: 0 },
				soldInitialCredit: { dataType: "Decimal", defaultValue: 0 },
				soldInitialDebitCalculat: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				soldInitialCreditCalculat: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				soldInitialDebitAjustare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				soldInitialCreditAjustare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit6: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit6: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit7: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit7: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit8: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit8: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit9: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit9: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit10: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit10: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit11: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit11: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit12: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit12: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit6: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit6: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit7: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit7: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit8: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit8: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit9: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit9: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit10: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit10: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit11: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit11: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit12: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit12: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajTotalDebit: { dataType: "Decimal", defaultValue: 0 },
				rulajTotalCredit: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contab.BalantaValuta": {
			shortName: "BalantaValuta",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idCont: { dataType: "Int32", defaultValue: 0 },
				soldInitialDebit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				soldInitialCredit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar6: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit6: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit6: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar7: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit7: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit7: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar8: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit8: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit8: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar9: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit9: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit9: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar10: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit10: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit10: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar11: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit11: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit11: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar12: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit12: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit12: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"contab.Cont": {
			shortName: "Cont",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				cod: { maxLength: 3, defaultValue: "" },
				sintetic2: { maxLength: 2, defaultValue: "" },
				sintetic3: { maxLength: 2, defaultValue: "" },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				eSA: { maxLength: 5, defaultValue: "" },
				analiticClient: { maxLength: 50, defaultValue: "" },
				codSufixBugetClasificatieFunctionala: { maxLength: 50, defaultValue: "" },
				codSufixFormularClasificatieEconomica: { maxLength: 50, defaultValue: "" },
				functie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isInafaraBilantului: { dataType: "Boolean", isNullable: false },
				isClasificatieCheltuieli: { dataType: "Boolean", isNullable: false },
				isClasificatieVenituri: { dataType: "Boolean", isNullable: false },
				isContIncasare: { dataType: "Boolean", isNullable: false },
				isContFinantare: { dataType: "Boolean", isNullable: false },
				isFaraClasificatie: { dataType: "Boolean", isNullable: false },
				isContTVANeexigibil: { dataType: "Boolean", isNullable: false },
				isContValuta: { dataType: "Boolean", isNullable: false },
				isContUrmaritInSoldInitial: { dataType: "Boolean" },
				isContExcedent: { dataType: "Boolean", isNullable: false },
				isContBanca: { dataType: "Boolean", isNullable: false },
				isContNeamortizabil: { dataType: "Boolean", isNullable: false },
				isContAmortizabil: { dataType: "Boolean", isNullable: false },
				isContProgrameInformatice: { dataType: "Boolean", isNullable: false },
				isContAlteActive: { dataType: "Boolean", isNullable: false },
				isClasificatieCheltuieliCapitolSiTitlu: { dataType: "Boolean", isNullable: false },
				domeniulApartinator: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tipSectiune: { maxLength: 1, defaultValue: "" },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isGeneratAutomat: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contab.ContGrupTemplate": {
			shortName: "ContGrupTemplate",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 100, defaultValue: "" },
				isFurnizor: { dataType: "Boolean", isNullable: false },
			}
		},
		"contab.ContGrupTemplateCod": {
			shortName: "ContGrupTemplateCod",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idContGrupTemplateCod: { dataType: "Int32", isNullable: false },
				codCont: { maxLength: 100, defaultValue: "" },
			}
		},
		"contab.ContInchidere": {
			shortName: "ContInchidere",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idCont: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContAsociat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isInchidereTrimestru: { dataType: "Boolean" },
				isInchidereLuna: { dataType: "Boolean" },
				isInchidereAn: { dataType: "Boolean" },
				idTipInchidere: { dataType: "Int32", defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", defaultValue: 0 },
				isSetatPrinAlgoritm: { dataType: "Boolean", isNullable: false },
			}
		},
		"contab.DocumentFurnizorClientDetaliuTipCont": {
			shortName: "DocumentFurnizorClientDetaliuTipCont",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentFurnizorClientDetaliuTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCont: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"contab.DocumentFurnizorClientSoldCont": {
			shortName: "DocumentFurnizorClientSoldCont",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentOperatiuneTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContAnaliticDebit: { dataType: "Int32", defaultValue: 0 },
				idContAnaliticCredit: { dataType: "Int32", defaultValue: 0 },
				idJurnal: { dataType: "Int32", defaultValue: 0 },
				sumaObligatie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaObligatieCuTVA: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaObligatieTVA: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaObligatieFaraTVA: { dataType: "Decimal", defaultValue: 0 },
				sumaPlata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaPlataCuTVA: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaPlataTVA: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaPlataFaraTVA: { dataType: "Decimal", defaultValue: 0 },
				sumaTotalPlata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTotalPlataCuTVA: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTotalPlataTVA: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTotalPlataFaraTVA: { dataType: "Decimal", defaultValue: 0 },
				sumaSold: { dataType: "Decimal", defaultValue: 0 },
				sumaSoldCuTVA: { dataType: "Decimal", defaultValue: 0 },
				sumaSoldTVA: { dataType: "Decimal", defaultValue: 0 },
				sumaSoldFaraTVA: { dataType: "Decimal", defaultValue: 0 },
				idDocumentFurnizorClientDetaliuTip: { dataType: "Int32", defaultValue: 0 },
				isDocumentCuSold: { dataType: "Boolean", isNullable: false },
				explicatie: { defaultValue: "" },
				isGenerat: { dataType: "Boolean", isNullable: false },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isNumerar: { dataType: "Boolean", isNullable: false },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				isCuGarantie: { dataType: "Boolean", isNullable: false },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCodProgramBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codTipInregistrare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusRaportareSectorAgricol: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusRaportareTaxareInversa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareContabOperatiuneProdusValoareTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contab.ForexeBugAnexa35_a": {
			shortName: "ForexeBugAnexa35_a",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				explicatie: { defaultValue: "" },
				terenuri: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				amenajari_terenuri: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				constructii_total: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				constructii_drumuri_publice: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				constructii_drumuri_ind: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				constructii_cai_ferate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				constructii_poduri: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				constructii_tunele: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				constructii_aeroporturi: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				constructii_canale: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				constructii_alte: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				echipamente_tehnologice: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				aparate_instalatii_masurare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				mijloace_transport: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				animale_plantatii: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				mobilier: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				alte_active: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				active_fixe_corporale: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				total: { dataType: "Decimal", defaultValue: 0 },
				id_row: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"contab.ForexeBugAnexa35_c": {
			shortName: "ForexeBugAnexa35_c",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				explicatie: { defaultValue: "" },
				cheltuieli_dezvoltare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				concesiuni_brevete: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				inregistrari_evenimente: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				programe_informatice: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				alte_active: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				active_fixe_necorporale: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				total: { dataType: "Decimal", defaultValue: 0 },
				id_row: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"contab.ForexeBugAnexa37": {
			shortName: "ForexeBugAnexa37",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				explicatie: { defaultValue: "" },
				materii_prime_materiale: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				materiale_rezerva: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				produse_finite: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				bunuri_confiscate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				obiecte_inventar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				animale_pasari: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				total: { dataType: "Decimal", defaultValue: 0 },
				id_row: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"contab.ForexeBugAnexa40": {
			shortName: "ForexeBugAnexa40",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod_SEC: { maxLength: 50, defaultValue: "" },
				explicatie: { defaultValue: "" },
				conturi: { defaultValue: "" },
				sold_inceput: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sold_sfarsit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				id_row: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				trimestru: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nivel: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				rowLeafList: { defaultValue: "" },
			}
		},
		"contab.ForexeBugFormular34": {
			shortName: "ForexeBugFormular34",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				explicatie: { defaultValue: "" },
				rezerve: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				fonduri: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				surplus_cumulat: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				surplus_an_curent: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				total: { dataType: "Decimal", defaultValue: 0 },
				id_row: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"contab.GenerareNotaAlgoritm": {
			shortName: "GenerareNotaAlgoritm",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaOperatiuneContTemplate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaOperatiuneContTemplateDefault: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idJurnal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusGrupTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaGestiune: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contab.GenerareNotaContTemplate": {
			shortName: "GenerareNotaContTemplate",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idGenerareNotaContTemplateDefault: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				idContSintetic: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sinteticModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sectorBugetarModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sursaFinantareModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				clasificatieFunctionalaModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				clasificatieEconomicaModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticCustom: { maxLength: 100, defaultValue: "" },
				analiticPozitieGestiune: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieClasificareProduse: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieTVA: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieTipProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieTipVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieObiectivInvestitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isVenit: { dataType: "Boolean", isNullable: false },
				isManual: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contab.GenerareNotaContTemplateDefault": {
			shortName: "GenerareNotaContTemplateDefault",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				idContSintetic: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sinteticModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sectorBugetarModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sursaFinantareModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				clasificatieFunctionalaModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				clasificatieEconomicaModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticCustom: { maxLength: 100, defaultValue: "" },
				analiticPozitieGestiune: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieClasificareProduse: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieTVA: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieTipProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieTipVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isVenit: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contab.GenerareNotaOperatiuneContTemplate": {
			shortName: "GenerareNotaOperatiuneContTemplate",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idGenerareNotaOperatiuneContTemplateDefault: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareContabOperatiuneProdusValoareTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaContTemplateDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaContTemplateCredit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isManual: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contab.GenerareNotaOperatiuneContTemplateDefault": {
			shortName: "GenerareNotaOperatiuneContTemplateDefault",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idGenerareContabOperatiuneProdusValoareTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaContTemplateDefaultDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaContTemplateDefaultCredit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contab.Jurnal": {
			shortName: "Jurnal",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				idContUrmarit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				importDataJson: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contab.JurnalGeneral": {
			shortName: "JurnalGeneral",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nrCrt: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContCredit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { defaultValue: "" },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				anLuna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				user: { maxLength: 50, defaultValue: "" },
				stamp: { dataType: "DateTime", isNullable: false },
			}
		},
		"contab.LunaInchisa": {
			shortName: "LunaInchisa",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anLuna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"contab.Nota": {
			shortName: "Nota",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idJurnal: { dataType: "Int32", defaultValue: 0 },
				idContDebit: { dataType: "Int32", defaultValue: 0 },
				idContCredit: { dataType: "Int32", defaultValue: 0 },
				suma: { dataType: "Decimal", defaultValue: 0 },
				anLuna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocument: { dataType: "Int32", defaultValue: 0 },
				idDocumentSursa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentFurnizorClientDetaliuTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cotaTVA: { dataType: "Int32", defaultValue: 0 },
				idNotaParinte: { dataType: "Int32", defaultValue: 0 },
				numarNota: { maxLength: 50, defaultValue: "" },
				dataNota: { dataType: "DateTime" },
				explicatie: { defaultValue: "" },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaValuta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isGeneratAutomat: { dataType: "Boolean", isNullable: false },
				idNaturaPlata: { dataType: "Int32", defaultValue: 0 },
				importDataJson: { defaultValue: "" },
				importDataTip: { maxLength: 100, defaultValue: "" },
				importDataId: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				codTipInregistrare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusRaportareSectorAgricol: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusRaportareTaxareInversa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareContabOperatiuneProdusValoareTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaAlgoritm: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isPersonalizare: { dataType: "Boolean", isNullable: false },
			}
		},
		"contab.NotaPropusa": {
			shortName: "NotaPropusa",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idJurnal: { dataType: "Int32", defaultValue: 0 },
				idContDebit: { dataType: "Int32", defaultValue: 0 },
				idContCredit: { dataType: "Int32", defaultValue: 0 },
				suma: { dataType: "Decimal", defaultValue: 0 },
				anLuna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocument: { dataType: "Int32", defaultValue: 0 },
				idDocumentSursa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentFurnizorClientDetaliuTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cotaTVA: { dataType: "Int32", defaultValue: 0 },
				idNotaParinte: { dataType: "Int32", defaultValue: 0 },
				numarNota: { maxLength: 50, defaultValue: "" },
				dataNota: { dataType: "DateTime" },
				explicatie: { defaultValue: "" },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaValuta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idNaturaPlata: { dataType: "Int32", defaultValue: 0 },
				codTipInregistrare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusRaportareSectorAgricol: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusRaportareTaxareInversa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareContabOperatiuneProdusValoareTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaAlgoritm: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isPersonalizare: { dataType: "Boolean", isNullable: false },
			}
		},
		"contab.PosturiAnexa30": {
			shortName: "PosturiAnexa30",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 2, defaultValue: "" },
				denumireIndicartor: { maxLength: 200, defaultValue: "" },
				nrPosturi: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				luna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"contab.SoldInitialPartener": {
			shortName: "SoldInitialPartener",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaPartener: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContAnalitic: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaObligatie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaPlata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaSold: { dataType: "Decimal", defaultValue: 0 },
			}
		},
		"contab.SoldParteneriAn": {
			shortName: "SoldParteneriAn",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				iD: { dataType: "Int32", isPartOfKey: true },
				idCont: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaObligatie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaPlata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaSold: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"contab.TipInchidere": {
			shortName: "TipInchidere",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 200, defaultValue: "" },
				ordine: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contab.Versiune": {
			shortName: "Versiune",
			namespace: "contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				version: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stamp: { dataType: "DateTime", isNullable: false },
			}
		},
		"contabistoric.AlgoritmInchidereConturi": {
			shortName: "AlgoritmInchidereConturi",
			namespace: "contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idContInchis: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContInchidere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isInchidereLuna: { dataType: "Boolean", isNullable: false },
				isInchidereAn: { dataType: "Boolean", isNullable: false },
				isInchidereTrimestru: { dataType: "Boolean", isNullable: false },
				isInchidereOrdonatorPrincipal: { dataType: "Boolean" },
				isInchidereOrdonatorSecundar: { dataType: "Boolean" },
				idTipInchidere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idAlgoritmInchidereConturi: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contabistoric.AsociereActivitateEconomicaArticol": {
			shortName: "AsociereActivitateEconomicaArticol",
			namespace: "contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idAsociereActivitateEconomicaArticol: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contabistoric.AsociereClasificatieEconomicaCont": {
			shortName: "AsociereClasificatieEconomicaCont",
			namespace: "contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContFurnizor: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContStoc: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idAsociereClasificatieEconomicaCont: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"contabistoric.AsociereContCrediteBugetare": {
			shortName: "AsociereContCrediteBugetare",
			namespace: "contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idCont: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCrediteBugetareTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idJurnal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idAsociereContCrediteBugetare: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contabistoric.AsociereOperatiuneDocFurnizorJurnal": {
			shortName: "AsociereOperatiuneDocFurnizorJurnal",
			namespace: "contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentOperatiuneTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idJurnal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idAsociereOperatiuneDocFurnizorJurnal: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"contabistoric.Cont": {
			shortName: "Cont",
			namespace: "contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				cod: { maxLength: 3, defaultValue: "" },
				sintetic2: { maxLength: 2, defaultValue: "" },
				sintetic3: { maxLength: 2, defaultValue: "" },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				eSA: { maxLength: 5, defaultValue: "" },
				analiticClient: { maxLength: 50, defaultValue: "" },
				codSufixBugetClasificatieFunctionala: { maxLength: 50, defaultValue: "" },
				codSufixFormularClasificatieEconomica: { maxLength: 50, defaultValue: "" },
				functie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isInafaraBilantului: { dataType: "Boolean", isNullable: false },
				isClasificatieCheltuieli: { dataType: "Boolean", isNullable: false },
				isClasificatieVenituri: { dataType: "Boolean", isNullable: false },
				isContIncasare: { dataType: "Boolean", isNullable: false },
				isContFinantare: { dataType: "Boolean", isNullable: false },
				isFaraClasificatie: { dataType: "Boolean", isNullable: false },
				isContTVANeexigibil: { dataType: "Boolean", isNullable: false },
				isContValuta: { dataType: "Boolean", isNullable: false },
				isContUrmaritInSoldInitial: { dataType: "Boolean" },
				isContExcedent: { dataType: "Boolean", isNullable: false },
				isContBanca: { dataType: "Boolean", isNullable: false },
				isContNeamortizabil: { dataType: "Boolean", isNullable: false },
				isContAmortizabil: { dataType: "Boolean", isNullable: false },
				isContProgrameInformatice: { dataType: "Boolean", isNullable: false },
				isContAlteActive: { dataType: "Boolean", isNullable: false },
				isClasificatieCheltuieliCapitolSiTitlu: { dataType: "Boolean", isNullable: false },
				domeniulApartinator: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tipSectiune: { maxLength: 1, defaultValue: "" },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isGeneratAutomat: { dataType: "Boolean", isNullable: false },
				idCont: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contabistoric.ContInchidere": {
			shortName: "ContInchidere",
			namespace: "contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idCont: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContAsociat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isInchidereTrimestru: { dataType: "Boolean" },
				isInchidereLuna: { dataType: "Boolean" },
				isInchidereAn: { dataType: "Boolean" },
				idTipInchidere: { dataType: "Int32", defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", defaultValue: 0 },
				isSetatPrinAlgoritm: { dataType: "Boolean", isNullable: false },
				idContInchidere: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"contabistoric.GenerareNotaAlgoritm": {
			shortName: "GenerareNotaAlgoritm",
			namespace: "contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaOperatiuneContTemplate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaOperatiuneContTemplateDefault: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idJurnal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusGrupTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaGestiune: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaAlgoritm: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contabistoric.GenerareNotaContTemplate": {
			shortName: "GenerareNotaContTemplate",
			namespace: "contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idGenerareNotaContTemplateDefault: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				idContSintetic: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sinteticModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sectorBugetarModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sursaFinantareModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				clasificatieFunctionalaModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				clasificatieEconomicaModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticCustom: { maxLength: 100, defaultValue: "" },
				analiticPozitieGestiune: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieClasificareProduse: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieTVA: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieTipProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieTipVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieObiectivInvestitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isVenit: { dataType: "Boolean", isNullable: false },
				isManual: { dataType: "Boolean", isNullable: false },
				idGenerareNotaContTemplate: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contabistoric.GenerareNotaContTemplateDefault": {
			shortName: "GenerareNotaContTemplateDefault",
			namespace: "contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				idContSintetic: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sinteticModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sectorBugetarModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sursaFinantareModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				clasificatieFunctionalaModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				clasificatieEconomicaModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticCustom: { maxLength: 100, defaultValue: "" },
				analiticPozitieGestiune: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieClasificareProduse: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieTVA: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieTipProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieTipVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isVenit: { dataType: "Boolean", isNullable: false },
				idGenerareNotaContTemplateDefault: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contabistoric.GenerareNotaOperatiuneContTemplate": {
			shortName: "GenerareNotaOperatiuneContTemplate",
			namespace: "contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idGenerareNotaOperatiuneContTemplateDefault: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareContabOperatiuneProdusValoareTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaContTemplateDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaContTemplateCredit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaOperatiuneContTemplate: { dataType: "Int32" },
				isManual: { dataType: "Boolean", isNullable: false },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contabistoric.GenerareNotaOperatiuneContTemplateDefault": {
			shortName: "GenerareNotaOperatiuneContTemplateDefault",
			namespace: "contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idGenerareContabOperatiuneProdusValoareTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaContTemplateDefaultDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaContTemplateDefaultCredit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaOperatiuneContTemplateDefault: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contabistoric.Jurnal": {
			shortName: "Jurnal",
			namespace: "contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				idContUrmarit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idJurnal: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				importDataJson: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"contracte.GrupDebitareTest": {
			shortName: "GrupDebitareTest",
			namespace: "contracte",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
			}
		},
		"dareseama.AIndex": {
			shortName: "AIndex",
			namespace: "dareseama",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				iD: { dataType: "Int32", isPartOfKey: true },
				denumire: { maxLength: 800, defaultValue: "" },
				cod: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tipf: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cod_f: { maxLength: 10, defaultValue: "" },
				cod_c: { maxLength: 10, defaultValue: "" },
				raport: { maxLength: 50, defaultValue: "" },
				col1: { maxLength: 50, defaultValue: "" },
				col2: { maxLength: 50, defaultValue: "" },
				col3: { maxLength: 50, defaultValue: "" },
				col4: { maxLength: 50, defaultValue: "" },
				col5: { maxLength: 50, defaultValue: "" },
				col6: { maxLength: 50, defaultValue: "" },
				col7: { maxLength: 50, defaultValue: "" },
				col8: { maxLength: 50, defaultValue: "" },
				col9: { maxLength: 50, defaultValue: "" },
				col10: { maxLength: 50, defaultValue: "" },
				col11: { maxLength: 50, defaultValue: "" },
				col12: { maxLength: 50, defaultValue: "" },
				col13: { maxLength: 50, defaultValue: "" },
				col14: { maxLength: 50, defaultValue: "" },
				col15: { maxLength: 50, defaultValue: "" },
				transfer: { maxLength: 250, defaultValue: "" },
				vonline: { maxLength: 250, defaultValue: "" },
				tipb: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				spdate: { maxLength: 100, defaultValue: "" },
				transferAn: { maxLength: 250, defaultValue: "" },
				b1: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b2: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b3: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b4: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b5: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b6: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b7: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b8: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b9: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b10: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b11: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b12: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b13: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b14: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b15: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				surseM: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sume: { maxLength: 800, defaultValue: "" },
				anexa_calcul: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumeAX: { maxLength: 800, defaultValue: "" },
				trim4: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tipRaportare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stampFormulare: { dataType: "DateTime", isNullable: false },
				stampRapoarte: { dataType: "DateTime", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"dareseama.Formular": {
			shortName: "Formular",
			namespace: "dareseama",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				iD: { dataType: "Int32", isPartOfKey: true },
				nrrnd: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nrrndf: { maxLength: 10, defaultValue: "" },
				denumire: { maxLength: 800, defaultValue: "" },
				cod: { maxLength: 20, defaultValue: "" },
				tip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anexa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				trimestru: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sursa: { maxLength: 4000, defaultValue: "" },
				titlu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				decalc: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sursa2: { maxLength: 4000, defaultValue: "" },
				sursa3: { maxLength: 4000, defaultValue: "" },
				sursa4: { maxLength: 4000, defaultValue: "" },
				sursa5: { maxLength: 4000, defaultValue: "" },
				sursa6: { maxLength: 4000, defaultValue: "" },
				sursa7: { maxLength: 4000, defaultValue: "" },
				sursa8: { maxLength: 4000, defaultValue: "" },
				sursa9: { maxLength: 4000, defaultValue: "" },
				sursa10: { maxLength: 4000, defaultValue: "" },
				sursa11: { maxLength: 4000, defaultValue: "" },
				sursa12: { maxLength: 4000, defaultValue: "" },
				sursa13: { maxLength: 4000, defaultValue: "" },
				sursa14: { maxLength: 4000, defaultValue: "" },
				sursa15: { maxLength: 4000, defaultValue: "" },
				nuLista: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"dataservice.DataImportState": {
			shortName: "DataImportState",
			namespace: "dataservice",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				databaseName: { maxLength: 100, defaultValue: "" },
				schemaName: { maxLength: 100, defaultValue: "" },
				tableName: { maxLength: 100, defaultValue: "" },
				dataActualizareServer: { dataType: "DateTime", isNullable: false },
				numarPachet: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Contine versiunea (de date) a " } },
				totalPachete: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				continutPachet: { defaultValue: "" },
				versiuneSistem: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Versiunea sistemului la care s" } },
				isProcesat: { dataType: "Boolean", isNullable: false },
			}
		},
		"dataservice.DataUpdateLog": {
			shortName: "DataUpdateLog",
			namespace: "dataservice",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				databaseName: { maxLength: 100, defaultValue: "" },
				schemaName: { maxLength: 100, defaultValue: "" },
				tableName: { maxLength: 100, defaultValue: "" },
				descriere: { maxLength: 500, defaultValue: "" },
				explicatie: { defaultValue: "" },
				data: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"dataservice.RegistruImportDate": {
			shortName: "RegistruImportDate",
			namespace: "dataservice",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				databaseName: { maxLength: 100, defaultValue: "" },
				schemaName: { maxLength: 100, defaultValue: "" },
				tableName: { maxLength: 100, defaultValue: "" },
				descriere: { maxLength: 500, defaultValue: "" },
				clientProc: { maxLength: 150, defaultValue: "" },
				serverProc: { maxLength: 150, defaultValue: "" },
				isAutomat: { dataType: "Boolean", isNullable: false },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "Data la care s-a realizat cu s" } },
				conditieWhere: { defaultValue: "" },
				seed: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isOnlyWhenClientTableIsEmpty: { dataType: "Boolean", isNullable: false },
			}
		},
		"dbo.PersoanaStareCivila": {
			shortName: "PersoanaStareCivila",
			namespace: "dbo",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 200, defaultValue: "" },
			}
		},
		"dbo.ResursaBkp": {
			shortName: "ResursaBkp",
			namespace: "dbo",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				parentId: { maxLength: 892, defaultValue: "" },
				level: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 200, defaultValue: "" },
				nivelAcces: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				customAcces: { dataType: "Boolean", isNullable: false },
				cod: { maxLength: 50, defaultValue: "" },
				type: { maxLength: 3, defaultValue: "" },
				icon: { maxLength: 50, defaultValue: "" },
				url: { maxLength: 200, defaultValue: "" },
				html: { maxLength: 200, defaultValue: "" },
				options: { defaultValue: "" },
				tooltip: { maxLength: 200, defaultValue: "" },
				searchQuery: { defaultValue: "" },
			}
		},
		"dbo.SpectacolValoare": {
			shortName: "SpectacolValoare",
			namespace: "dbo",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				valoare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				incinta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				uti: { maxLength: 50, defaultValue: "" },
			}
		},
		"dbo.TerenValoareCorectieRang": {
			shortName: "TerenValoareCorectieRang",
			namespace: "dbo",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				rang: { maxLength: 1, defaultValue: "" },
				valoare: { dataType: "Decimal", defaultValue: 0 },
				an: { dataType: "Int32", defaultValue: 0 },
				uti: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"deploy.Checkin": {
			shortName: "Checkin",
			namespace: "deploy",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				databaseName: { maxLength: 50, defaultValue: "" },
				version: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				script: { defaultValue: "" },
				userName: { maxLength: 100, defaultValue: "" },
				commitDate: { dataType: "DateTime", isNullable: false },
				commitStation: { maxLength: 50, defaultValue: "" },
				validateDate: { dataType: "DateTime", isNullable: false },
				validateStation: { maxLength: 50, defaultValue: "" },
				scriptOrder: { maxLength: 20, defaultValue: "" },
				minor: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "campul Minor din tabela deploy" } },
				isScriptConversie: { dataType: "Boolean", isNullable: false },
			}
		},
		"deploy.CheckinConversie": {
			shortName: "CheckinConversie",
			namespace: "deploy",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				databaseName: { maxLength: 50, defaultValue: "" },
				version: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				script: { defaultValue: "" },
				userName: { maxLength: 100, defaultValue: "" },
				commitDate: { dataType: "DateTime", isNullable: false },
				commitStation: { maxLength: 50, defaultValue: "" },
				validateDate: { dataType: "DateTime", isNullable: false },
				validateStation: { maxLength: 50, defaultValue: "" },
				scriptOrder: { maxLength: 20, defaultValue: "" },
				minor: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "campul Minor din tabela deploy" } },
			}
		},
		"deploy.JurnalActualizare": {
			shortName: "JurnalActualizare",
			namespace: "deploy",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				versiune: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				minor: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tipVersiune: { maxLength: 100, defaultValue: "" },
				dataArhiva: { dataType: "DateTime" },
				dataStartActualizare: { dataType: "DateTime" },
				dataStopActualizare: { dataType: "DateTime" },
				logActualizare: { defaultValue: "" },
			}
		},
		"deploy.Release": {
			shortName: "Release",
			namespace: "deploy",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				numeFisier: { maxLength: 250, defaultValue: "" },
				caleFisier: { maxLength: 250, defaultValue: "" },
				modul: { maxLength: 50, defaultValue: "" },
				versiune: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				minor: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stamp: { dataType: "DateTime", isNullable: false },
				userName: { maxLength: 250, defaultValue: "" },
				valid: { dataType: "Boolean", isNullable: false },
				completed: { dataType: "Boolean", isNullable: false },
				available: { dataType: "Boolean", isNullable: false },
				tip: { maxLength: 50, defaultValue: "" },
				fisiereExtra: { dataType: "Boolean", isNullable: false },
				caleFisierExtra: { maxLength: 250, defaultValue: "" },
				descriere: { defaultValue: "" },
			}
		},
		"deploy.Status": {
			shortName: "Status",
			namespace: "deploy",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				version: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Versiunea pentru care se fac c" } },
				isTestMode: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Sistemul este in testare (nu s" } },
				allowCommitUsers: { maxLength: 3000, defaultValue: "", custom: { MS_Description: "Lista de useri SQL (repet, SQL" } },
			}
		},
		"deploy.TabeleNomenclator": {
			shortName: "TabeleNomenclator",
			namespace: "deploy",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				databaseName: { maxLength: 100, defaultValue: "" },
				schemaName: { maxLength: 100, defaultValue: "" },
				tableName: { maxLength: 100, defaultValue: "" },
			}
		},
		"deploy.Validare": {
			shortName: "Validare",
			namespace: "deploy",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idVersiune: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUser: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idResurse: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idStatus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stamp: { dataType: "DateTime" },
				testedBy: { maxLength: 50, defaultValue: "" },
			}
		},
		"deploy.ValoareMemorata": {
			shortName: "ValoareMemorata",
			namespace: "deploy",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				valoare: { defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"documente.Document": {
			shortName: "Document",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				seria: { maxLength: 10, defaultValue: "" },
				numarDocument: { maxLength: 18, defaultValue: "", custom: { MS_Description: "Numarul documentului (Contract" } },
				numarDocumentNumeric: { dataType: "Int32", defaultValue: 0 },
				seriaSufix: { maxLength: 10, defaultValue: "" },
				dataDocument: { dataType: "DateTime", custom: { MS_Description: "Data documentului(ex. Data emi" } },
				numarInregistrare: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Numarul de inregistrare al doc" } },
				dataInregistrare: { dataType: "DateTime", custom: { MS_Description: "Data de inregistrare al docume" } },
				nume: { maxLength: 250, defaultValue: "", custom: { MS_Description: "Denumirea sau descrierea docum" } },
				numarInregistrareExtern: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Numerul de inregistrare al doc" } },
				dataInregistrareExtern: { dataType: "DateTime", custom: { MS_Description: "Data de inregistrare a documen" } },
				idDocumentTip: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Identificatorul pentru tipul d" } },
				idDocumentSubTip: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Identificatorul pentru SubTipu" } },
				categoria: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Intrare / Miscare / Iesire [1/" } },
				idOrganizareInstitutionala: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Id-ul Departamentului/Comparti" } },
				idPersoanaPartener: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Id-ul partenerului de la care " } },
				idPersoanaResponsabil: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Id-ul persoanei care primeste " } },
				importDataJson: { defaultValue: "" },
				importDataTip: { maxLength: 100, defaultValue: "" },
				importDataId: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				importDataTipParent: { maxLength: 100, defaultValue: "" },
				importDataIdParent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentAvansSpreDecontare": {
			shortName: "DocumentAvansSpreDecontare",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Documentul (Avans spre deconta" } },
				isDecontDirectFaraAvansDecontare: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Marcaj pentru Decont direct - " } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentAvansSpreDecontareSold": {
			shortName: "DocumentAvansSpreDecontareSold",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id document avans spre deconta" } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Bugetul asociat {Sigma-Buget.b" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia functionala {Sigm" } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia economica {Sigma-" } },
				sumaPlatita: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaCheltuita: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaIncasata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sold: { dataType: "Decimal", defaultValue: 0 },
			}
		},
		"documente.DocumentAvansSpreDecontareVirament": {
			shortName: "DocumentAvansSpreDecontareVirament",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Documentul (avans spre deconta" } },
				dataDocumentVirament: { dataType: "DateTime", custom: { MS_Description: "Data dcumentului care va fi ge" } },
				numarDocumentVirament: { maxLength: 18, defaultValue: "", custom: { MS_Description: "Numarului documentului care va" } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Bugetul asociat {Sigma-Buget.b" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia functionala {Sigm" } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia economica {Sigma-" } },
				idPersoanaBancaIban: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul pentru combinatia: Banca" } },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIbanBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul pentru combinatia: Banca" } },
				explicatie: { maxLength: 100, defaultValue: "" },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				idSumaExecutie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu Ordonantarile {Sig" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentBorderouIncasareDetaliu": {
			shortName: "DocumentBorderouIncasareDetaliu",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Id-ul documentului curent (Bor" } },
				idDocumentIncasare: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Id-ul documentului de incasare" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentCecRidicareNumerar": {
			shortName: "DocumentCecRidicareNumerar",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul documentului pe care il " } },
				idOrganizareInstitutionalaCasierie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Punctul de incasare din Organi" } },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu tipul de venit {Si" } },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Suma ridicata" } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Buget: Id comun {Sigma-Buget.b" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Capitol: Id comun {Sigma-Buget" } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Buget: Id comun {Sigma-Buget.b" } },
				idPersoanaBancaIban: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul pentru combinatia: Banca" } },
				idPersoanaBancaIbanContMandat: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul Iban-ului de mandat, pen" } },
				idCodProgramBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Codul de program bugetar, id c" } },
				codProgram: { maxLength: 50, defaultValue: "" },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				explicatie: { maxLength: 100, defaultValue: "" },
				listat: { dataType: "Boolean", isNullable: false },
				validatTrezorerie: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "cec-ul (documentul) a fost val" } },
				idDocumentStatPlataNumerar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				idDocumentAngajamentLegalGlobal: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Marcaj pentru detaliile de CEC" } },
				isFaraGenerareExecutieBugetara: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "luat in considerare doar pt de" } },
			}
		},
		"documente.DocumentCentralizatorIncasareNumerarContabilitate": {
			shortName: "DocumentCentralizatorIncasareNumerarContabilitate",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu documentul pe care" } },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu tipul de venit {Si" } },
			}
		},
		"documente.DocumentCentralizatorOPIncasareDetaliu": {
			shortName: "DocumentCentralizatorOPIncasareDetaliu",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul documentului curent (Cen" } },
				idDocumentIncasare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul documentului de incasare" } },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu tipul de venit {Si" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentCentralizatorVenitDebitareContabilitate": {
			shortName: "DocumentCentralizatorVenitDebitareContabilitate",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu documentul pe care" } },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu tipul de venit {Si" } },
			}
		},
		"documente.DocumentComanda": {
			shortName: "DocumentComanda",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Legatura la registrul general " } },
				valoareValuta: { dataType: "Decimal", defaultValue: 0, custom: { MS_Description: "Valoarea Comenzii in valuta (=" } },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Valuta in care s-a realizat Co" } },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Cursul valutar la data intocmi" } },
				valoareLei: { dataType: "Decimal", defaultValue: 0, custom: { MS_Description: " Valoarea Comenzii in lei(Valo" } },
				dataExpirare: { dataType: "DateTime", custom: { MS_Description: "Data la care Comanda expira" } },
				idAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Achizitia prin care s-a materi" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentContract": {
			shortName: "DocumentContract",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Legatura la registrul general " } },
				valoareValuta: { dataType: "Decimal", defaultValue: 0, custom: { MS_Description: "Valoarea contractului in valut" } },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Valuta in care s-a realizat co" } },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Cursul valutar la data intocmi" } },
				isFaraValoare: { dataType: "Boolean", isNullable: false },
				valoareLei: { dataType: "Decimal", defaultValue: 0, custom: { MS_Description: " Valoarea contractului in lei(" } },
				dataExpirare: { dataType: "DateTime", custom: { MS_Description: "Data la care contractul expira" } },
				idObiectivInvestitie: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Legatura cu obiectivul de inve" } },
				idAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Achizitia prin care s-a materi" } },
				idOperatiuneCFP: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Operatiunea CFP pentru vizare " } },
				isCrediteAngajament: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Flag contract din credite de a" } },
				perioadaAniCrediteAngajament: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Periaoda in ani pentru executi" } },
				dataIncepereExecutie: { dataType: "DateTime" },
				dataFinalizareExecutie: { dataType: "DateTime" },
				codAngajament: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				idPersoanaBancaIbanGarantieDeBunaExecutie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul pentru garantie {Sigma.p" } },
				procentGarantieBunaExecutie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareValutaGarantieBunaExecutie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareLeiGarantieBunaExecutie: { dataType: "Decimal", defaultValue: 0 },
				valoareValutaExecutata: { dataType: "Decimal", defaultValue: 0, custom: { MS_Description: "Valoare executata pana la oper" } },
				valoareLeiExecutata: { dataType: "Decimal", defaultValue: 0, custom: { MS_Description: "Valoare executata in lei (Valo" } },
				valoareValutaRamasaDeExecutat: { dataType: "Decimal", defaultValue: 0, custom: { MS_Description: "Valoare ramasa de executat in " } },
				valoareLeiRamasDeExecutat: { dataType: "Decimal", defaultValue: 0 },
				valoareValutaContractFaraTVA: { dataType: "Decimal", defaultValue: 0, custom: { MS_Description: "Valoarea contractului in valut" } },
				valoareLeiContractFaraTVA: { dataType: "Decimal", defaultValue: 0, custom: { MS_Description: "Valoarea contractului in lei  " } },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
			}
		},
		"documente.DocumentContractClasificatieBugetaraAnSume": {
			shortName: "DocumentContractClasificatieBugetaraAnSume",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura la registrul general " } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Bugetul asociat {Sigma-Buget.b" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia functionala {Sigm" } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia economica {Sigma-" } },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				valoareValuta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Valuta la care se calculeaza s" } },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareLei: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentContractMunca": {
			shortName: "DocumentContractMunca",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", defaultValue: 0 },
				idPost: { dataType: "Int32", defaultValue: 0 },
				isFunctieBaza: { dataType: "Boolean" },
				isConducatorDepartament: { dataType: "Boolean", isNullable: false },
				idDocument: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"documente.DocumentDebitNota": {
			shortName: "DocumentDebitNota",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isCreareDebit: { dataType: "Boolean", isNullable: false },
				isAnulareDebit: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"documente.DocumentDecontCheltuiala": {
			shortName: "DocumentDecontCheltuiala",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Documentul pe care il extinde " } },
				delegatPentru: { defaultValue: "" },
				delegatLa: { defaultValue: "" },
				durataDeplasareDataStart: { dataType: "DateTime" },
				durataDeplasareDataStop: { dataType: "DateTime" },
				legitimatCu: { defaultValue: "" },
				ziOraPlecare: { dataType: "DateTime" },
				ziOraSosire: { dataType: "DateTime" },
				dataDepunereDecont: { dataType: "DateTime" },
				sumaPenalizare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				diurnaTarifZi: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tariful zilnic pentru diurna s" } },
				diurnaNumarZile: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				diurnaSuma: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentDispozitieIncasare": {
			shortName: "DocumentDispozitieIncasare",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Documentul pe care il extinde " } },
				idPlataNumerarTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Se va specifica tipul platii i" } },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Suma de incasat" } },
				explicatie: { defaultValue: "" },
				dataIncasare: { dataType: "DateTime", custom: { MS_Description: "Data la care s-a facut incasar" } },
				userNameIncasare: { maxLength: 250, defaultValue: "", custom: { MS_Description: "Utilizatorul care a efectuat i" } },
				idOrganizareInstitutionalaCasierie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Punctul de Incasare (Casieria)" } },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu tipul de venit {Si" } },
				idUnic: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Cod pentru unificare/grupare i" } },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "0 - de mana, 1 - generat din d" } },
				isAfterDecont: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "De incasat in urma decontului" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentDispozitieIncasareDistribuireSume": {
			shortName: "DocumentDispozitieIncasareDistribuireSume",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Documentul (dispozitia de Inca" } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Bugetul asociat {Sigma-Buget.b" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia functionala {Sigm" } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia economica {Sigma-" } },
				idPersoanaBancaIban: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul pentru combinatia: Banca" } },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu tipul de venit {Si" } },
				idUnic: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Legatura cu tipul de venit {Si" } },
				idIncasare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "{Sigma-Impozite.impotax.Incasa" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentDispozitiePlata": {
			shortName: "DocumentDispozitiePlata",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Documentul pe care il extinde " } },
				idPlataNumerarTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Se va specifica tipul platii i" } },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Suma de plata" } },
				explicatie: { defaultValue: "" },
				dataPlata: { dataType: "DateTime", custom: { MS_Description: "Data la care sa efectuat plata" } },
				userNamePlata: { maxLength: 250, defaultValue: "", custom: { MS_Description: "Utilizatorul care a efectuat p" } },
				idOrganizareInstitutionalaCasierie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Punctul de Incasare(Casieria) " } },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu tipul de venit {Si" } },
				idUnic: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Cod pentru unificare/grupare i" } },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "0 - de mana, 1 - generat din d" } },
				isAfterDecont: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "De platit in urma decontului" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentDispozitiePlataDistribuireSume": {
			shortName: "DocumentDispozitiePlataDistribuireSume",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Documentul (dispozitia de plat" } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Bugetul asociat {Sigma-Buget.b" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia functionala {Sigm" } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia economica {Sigma-" } },
				idIncasare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu incasarea {Sigma-I" } },
				idPersoanaBancaIban: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul pentru combinatia: Banca" } },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu tipul de venit {Si" } },
				idUnic: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Cod pentru unificare/grupare i" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				idDocumentCecRidicareNumerar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul detaliului din CEC-ul de" } },
				isGenerareExecutieBugetara: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "luat in considerare doar pt de" } },
				idDocumentAngajamentLegalGlobal: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Marcaj pentru detaliile DP car" } },
			}
		},
		"documente.DocumentFlow": {
			shortName: "DocumentFlow",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul documentului din registr" } },
				idPersoanaExpeditor: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Id-ul persoanei de la care pro" } },
				idPersoanaDestinatar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id persoana la care este docum" } },
				idOrganizareInstitutionalaExpeditor: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Id departament din care face p" } },
				idOrganizareInstitutionalaDestinatar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id departament din care face p" } },
				dataIntrareDestinatar: { dataType: "DateTime", custom: { MS_Description: "Data la care intra documentul " } },
				dataIesireExpeditor: { dataType: "DateTime", custom: { MS_Description: "Data expediere document spre u" } },
				observatie: { maxLength: 250, defaultValue: "", custom: { MS_Description: "Motivul/observatiile legate de" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentFoaieVarsamant": {
			shortName: "DocumentFoaieVarsamant",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu documentul pe care" } },
				idOrganizareInstitutionalaCasierie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Punctul de incasare din Organi" } },
				idPersoanaBancaIban: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul pentru combinatia: Banca" } },
				idPersoanaBancaIbanContMandat: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul Iban-ului de mandat, pen" } },
				idPersoanaPlatitor: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Persoana care plateste{Sigma.p" } },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu tipul de venit {Si" } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Buget: Id comun {Sigma-Buget.b" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia functionala {Sigm" } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Buget: Id comun {Sigma-Buget.b" } },
				beneficiar: { maxLength: 100, defaultValue: "" },
				idCodProgramBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Codul de program bugetar, id c" } },
				codProgram: { maxLength: 50, defaultValue: "" },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				numarEvidentaPlata: { maxLength: 50, defaultValue: "" },
				explicatie: { maxLength: 100, defaultValue: "" },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isInclusivDataCurenta: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "1 - contine si platile/incasar" } },
				validatTrezorerie: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "cec-ul (documentul) a fost val" } },
				idDocumentStatPlataNumerar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				isCuiUnitatePlatitor: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Daca se seteaza: se utilizeaza" } },
				numePlatitorPersonalizat: { maxLength: 30, defaultValue: "", custom: { MS_Description: "Numele platitorului: persoana " } },
				idDocumentAngajamentLegalGlobal: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul documentului angajament " } },
			}
		},
		"documente.DocumentFoaieVarsamantIncasareCheltuiala": {
			shortName: "DocumentFoaieVarsamantIncasareCheltuiala",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentFoaieVarsamant: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul foii de varsamant {Sigma" } },
				idIncasare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul incasarii {Sigma-Impozit" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentFurnizorClient": {
			shortName: "DocumentFurnizorClient",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Id-ul documentului pe care il " } },
				idEFactura: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu eFactura {Sigma.eF" } },
				codStatusEFactura: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "0 - nu este eFactura / 1- nepr" } },
				idDocumentOperatiuneTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de operatiune / 68 = doc" } },
				idDocumentOperatiuneTipSoldInitial: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de operatiune pentru doc" } },
				idOperatiuneActivitateEconomicaClasificatieBugetara: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu operatiunea pentru" } },
				seria: { maxLength: 10, defaultValue: "", custom: { MS_Description: "Seria documentului" } },
				idPersoanaDelegat: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Id-ul persoanei care apare la " } },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id valuta ( se va inregistra p" } },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Cursul valutar aferent documen" } },
				valoareFaraTva: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Valoarea totala fara tva a doc" } },
				valoareFaraTvaLei: { dataType: "Decimal", defaultValue: 0 },
				valoareTva: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Valoare totala tva" } },
				valoareTvaLei: { dataType: "Decimal", defaultValue: 0 },
				valoareTotal: { dataType: "Decimal", defaultValue: 0 },
				valoareTotalLei: { dataType: "Decimal", defaultValue: 0 },
				dataScadenta: { dataType: "DateTime", custom: { MS_Description: "Data la care documentul devine" } },
				idPersoanaBancaIban: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Id-ul pentru combinatia: Banca" } },
				mijlocTransport: { maxLength: 100, defaultValue: "", custom: { MS_Description: "Mijlocul de transport trecut p" } },
				idAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Achizitia in baza careia s-a e" } },
				isGenerareLichidare: { dataType: "Boolean", isNullable: false },
				isGenerareOrdonantare: { dataType: "Boolean", isNullable: false },
				generareOrdonantarePlata: { dataType: "DateTime", custom: { MS_Description: "Data la care se va genera Ordo" } },
				isGenerarePlata: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Marcaj pentru plata automata f" } },
				generarePlataData: { dataType: "DateTime", custom: { MS_Description: "Data la care se va genera plat" } },
				generarePlataExplicatie: { maxLength: 100, defaultValue: "", custom: { MS_Description: "Explicatia care se pa trece pe" } },
				idDocumentPlataOPME: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Id-ul ordinului de plata multi" } },
				isGenerarePlataNumerarAvans: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Marcaj pentru plata cu numerar" } },
				valoareNumerarAvans: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareNumerarAvansLei: { dataType: "Decimal", defaultValue: 0 },
				valoareTvaNumerarAvans: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareTvaNumerarAvansLei: { dataType: "Decimal", defaultValue: 0 },
				idDocumentTipNumerarAvans: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarDocumentNumerarAvans: { maxLength: 20, defaultValue: "" },
				dataDocumentNumerarAvans: { dataType: "DateTime" },
				idPersoanaNumerarAvans: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id persoana numerar avans:  {S" } },
				idDocumentDispozitiePlataNumerarAvans: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Neutilizat pentru ca avem list" } },
				idDocumentPlataNumerarAvansSpreDecontareLista: { maxLength: 1000, defaultValue: "", custom: { MS_Description: "Lista de id documente de tip d" } },
				isGenerareAvansSpreDecontare: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Generare automata documente nu" } },
				isDecontDirectFaraAvansDecontare: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Generarea plata numerar prin D" } },
				jsonParamAvansSpreDecontare: { defaultValue: "" },
				isInLucru: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Documentul este in lucru si nu" } },
				explicatie: { maxLength: 100, defaultValue: "" },
				idOrganizareInstitutionalaCompartiment: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Compartimentul pentru care se " } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				isCuGarantie: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Specifica daca documentul are " } },
				idPersoanaBancaIbanGarantie: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Id-ul pentru garantie {Sigma.p" } },
				valoareGarantie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareGarantieLei: { dataType: "Decimal", defaultValue: 0 },
				procentGarantie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isInseratModificatDupaTransmitereSauExclus: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "eFactura: 0 - nemodificat dupa" } },
				dataInseratModificatDupaTransmitereSauExclus: { dataType: "DateTime", custom: { MS_Description: "eFactura - data pantru inserar" } },
			}
		},
		"documente.DocumentFurnizorClientDetaliu": {
			shortName: "DocumentFurnizorClientDetaliu",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentFurnizorClientDetaliuTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul detaliului (ex.Achizitii" } },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura la registrul unic al " } },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Activitatea economica PK {Sigm" } },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Centru de cheltuiala 0 : - far" } },
				idDocumentFurnizorClientDetaliuGrup: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Grupare logica a mai multor in" } },
				numarCurent: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Numarul curent al detaliului i" } },
				nume: { maxLength: 300, defaultValue: "", custom: { MS_Description: "Descrierea pozitiei/detaliu ca" } },
				numeEFactura: { maxLength: 300, defaultValue: "", custom: { MS_Description: "Numele detaliului din eFactura" } },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Valuta de referinta {Sigma.nom" } },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarValuta: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Pretul unitar al produsului de" } },
				pretUnitarValutaEFactura: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarLei: { dataType: "Decimal", defaultValue: 0, custom: { MS_Description: "Pret unitar la nivel de poziti" } },
				discountProcent: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				discountSuma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				discountSumaLei: { dataType: "Decimal", defaultValue: 0 },
				pretUnitarValutaDiscount: { dataType: "Decimal", defaultValue: 0 },
				pretUnitarLeiDiscount: { dataType: "Decimal", defaultValue: 0, custom: { MS_Description: "Pretul unitar dupa ca s-a scos" } },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Cantitatea la nivel de pozitie" } },
				cantitateEFactura: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Cantitatea din eFactura" } },
				valoareBazaLeiRotunjit: { dataType: "Decimal", defaultValue: 0 },
				valoareTva: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "tva-ul aplicat la pretUnitar*C" } },
				valoareTvaEFactura: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareTvaLei: { dataType: "Decimal", defaultValue: 0 },
				valoareTotal: { dataType: "Decimal", defaultValue: 0 },
				valoareTotalLei: { dataType: "Decimal", defaultValue: 0 },
				idUnitateMasura: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul unitatii de masura {Sigm" } },
				idUnitateMasuraEFactura: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Unitatea de masura din EFactur" } },
				isServiciu: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Detaliu de tip serviciu (servi" } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul comun din buget {Sigma-B" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul comun din clasificatia f" } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul comun din clasificaţia e" } },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de venit pentru document" } },
				idUtilitatiTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Daca este utilitate: se va spe" } },
				idServiciuTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Daca este serviciu: se va spec" } },
				isModernizare: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Flag pentru existenta moderniz" } },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Natura platii {Sigma.nomenclat" } },
				idObiectivInvestitie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id obiectiv investitie, id com" } },
				idObiectivInvestitieSubobiectiv: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id SubObiectiv investitie, id " } },
				idUnicDebit: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Campul de legatura cu debite/r" } },
				idCodProgramBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Codul de program bugetar, id c" } },
				codAngajament: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Codul de angajament dat de CAB" } },
				indicatorAngajament: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Indicatorul din cadrul angajam" } },
				idOrganizareInstitutionalaLocFolosintaModernizare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Loc de folosinta pentru filtra" } },
				idProdusRaportareSectorAgricol: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de produs pentru raporta" } },
				idProdusRaportareTaxareInversa: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de produs pentru raporta" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				isDeleted: { dataType: "Boolean", isNullable: false },
				idPersoanaReturFiltru: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Factura retur - filtru pe add/" } },
				isReturIndiferentFurnizorFiltru: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Se seteaza daca NU se doreste " } },
				idDocumentFurnizorClientSoldCont: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Se foloseste pentru documentel" } },
				idCPV: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "ID-ul comun pentru codul CPV: " } },
			}
		},
		"documente.DocumentFurnizorClientDetaliuClasificatieBugetara": {
			shortName: "DocumentFurnizorClientDetaliuClasificatieBugetara",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentFurnizorClientDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Detaliul de factura {Sigma.doc" } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul comun din buget {Sigma-B" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul comun din clasificatia f" } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul comun din clasificaţia e" } },
			}
		},
		"documente.DocumentFurnizorClientDetaliuSursa": {
			shortName: "DocumentFurnizorClientDetaliuSursa",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentFurnizorClientDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentFurnizorClientDetaliuSursa: { dataType: "Int32", isNullable: false, custom: { MS_Description: "Avizul este sursa pentru Factu" } },
			}
		},
		"documente.DocumentFurnizorClientDetaliuSursaDocument": {
			shortName: "DocumentFurnizorClientDetaliuSursaDocument",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentFurnizorClientDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentFurnizorClientDetaliuSursa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentSursa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"documente.DocumentFurnizorClientGenerareExecutieBugetara": {
			shortName: "DocumentFurnizorClientGenerareExecutieBugetara",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura la registrul unic al " } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul comun din buget {Sigma-B" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul comun din clasificatia f" } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul comun din clasificaţia e" } },
				idCodProgramBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Codul de program bugetar, id c" } },
				codAngajament: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Codul de angajament dat de CAB" } },
				indicatorAngajament: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Indicatorul din cadrul angajam" } },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Suma cumulata din detaliile fa" } },
				isAngajamentGlobal: { dataType: "Boolean", isNullable: false },
				idDocumentAngajamentBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Angajamentul Bugetar Global {S" } },
				sumaDisponibilAngajamentBugetar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idDocumentAngajamentLegal: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Angajamentul Legal Global {Sig" } },
				sumaDisponibilAngajamentLegal: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Activitatea economica PK {Sigm" } },
				isPenalitateContractRetinutaLaPlata: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "flag pentru penalitate stabili" } },
				idPersoanaBancaIbanPenalitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul pentru combinatia: Banca" } },
				sumaPenalitate: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Parte din suma, ce reprezinta " } },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu tipul de venit {Si" } },
			}
		},
		"documente.DocumentFurnizorRefacturareUtilitati": {
			shortName: "DocumentFurnizorRefacturareUtilitati",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentFurnizorClientDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu furnizorul prin de" } },
				idDocumentFurnizorClientDetaliuGenerat: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu detaliul de factur" } },
				anLunaRefacturare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaDebitor: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul persoanei la care se va " } },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id valuta ( se va inregistra p" } },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarValuta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarLei: { dataType: "Decimal", defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareFaraTvaValuta: { dataType: "Decimal", defaultValue: 0 },
				valoareFaraTvaLei: { dataType: "Decimal", defaultValue: 0 },
				valoareTva: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareTotal: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentGarantieBunaExecutie": {
			shortName: "DocumentGarantieBunaExecutie",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Documentul de tip garantie de " } },
				idDocumentPlata: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Constituire prin plata factura" } },
				idIncasare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu incasarea prin car" } },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Centru de cheltuiala: 0 - fara" } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul comun din buget {Sigma-B" } },
				sumaDebit: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Suma debit" } },
				sumaCredit: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Suma credit" } },
				explicatie: { defaultValue: "" },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "{Sigma.nomenclatoare.NaturaPla" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				idObiectivInvestitie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id obiectiv investitie, id com" } },
				idObiectivInvestitieSubobiectiv: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id SubObiectiv investitie, id " } },
				dataExpirare: { dataType: "DateTime" },
			}
		},
		"documente.DocumentGarantieParticipareLicitatie": {
			shortName: "DocumentGarantieParticipareLicitatie",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Documentul de tip garantie de " } },
				idDocumentPlata: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Restituire.  Documentul de pla" } },
				idAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Achizitia {Sigma-Achizitii.ach" } },
				idIncasare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu incasarea prin car" } },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Centru de cheltuiala: 0 - fara" } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul comun din buget {Sigma-B" } },
				sumaDebit: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Suma debit" } },
				sumaCredit: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Suma credit" } },
				explicatie: { defaultValue: "" },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "{Sigma.nomenclatoare.NaturaPla" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentJustificativ": {
			shortName: "DocumentJustificativ",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Id-ul documentului curent" } },
				idDocumentJustificativ: { dataType: "Int32", custom: { MS_Description: "Id-ul documentului justificati" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentListaZilnicaAlimente": {
			shortName: "DocumentListaZilnicaAlimente",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Documentul din registrul de do" } },
				idCategorieBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Categoria de beneficiar - {Sig" } },
				efectiv: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Efectivul la masa pe categorie" } },
				isFaraCalculEconomiiDepasiri: { dataType: "Boolean", isNullable: false },
				valoareDrepturiBeneficiar: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Valoarea drepturilor pe benefi" } },
				valoareEliberataBeneficiar: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Valoarea eliberata(alocata) pe" } },
				valoareDrepturi: { dataType: "Decimal", defaultValue: 0 },
				valoareEliberata: { dataType: "Decimal", defaultValue: 0 },
				valoareEconomii: { dataType: "Decimal", defaultValue: 0 },
				valoareDepasiri: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentMiscareInventar": {
			shortName: "DocumentMiscareInventar",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura la registrul general " } },
				idDocumentOperatiuneTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Operatiunea pentru generare NC" } },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul activitatii economica - " } },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul centrului de cheltuiala " } },
				idOrganizareInstitutionalaPredare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul gestiunii PK din care ie" } },
				idOrganizareInstitutionalaPrimire: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul gestiunii PK in care int" } },
				idPersoanaPredator: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul gestionarului care preda" } },
				idPersoanaPrimitor: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul gestionarului PK care pr" } },
				idProdusGrupTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Marcheaza grupul de produse ca" } },
				idOrganizareInstitutionalaCompartiment: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul compartimentului/departa" } },
				idPersoanaDelegat: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul delegatului {Sigma.perso" } },
				mijlocTransport: { maxLength: 100, defaultValue: "" },
				idComisiaReceptie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Comisia de receptie {Sigma.per" } },
				idComisiaCasare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "{Sigma.persoane.Comisia.Id}" } },
				idComisiaDistrugere: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "{Sigma.persoane.Comisia.Id}" } },
				idComisiaInventariere: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul comisiei de inventariere" } },
				isGeneratDinCantina: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "NU SE UTILIZEAZA !!!" } },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "0 - de mana, 1 - generat din o" } },
				documentJustificativ: { maxLength: 250, defaultValue: "" },
				explicatie: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentMiscareInventarOrganizareInstitutionalaPredare": {
			shortName: "DocumentMiscareInventarOrganizareInstitutionalaPredare",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentOperatiuneTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaPredare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaPrimire: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaPredator: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaPrimitor: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusGrupTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCompartiment: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaDelegat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				mijlocTransport: { maxLength: 100, defaultValue: "" },
				idComisiaReceptie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idComisiaCasare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idComisiaDistrugere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isGeneratDinCantina: { dataType: "Boolean", isNullable: false },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaComun: { dataType: "Int32", defaultValue: 0 },
				dataStartValabilitateOrganizareInstitutionalaDocument: { dataType: "DateTime" },
				dataStopValabilitateOrganizareInstitutionalaDocument: { dataType: "DateTime" },
				hierarchyIdOrganizareInstitutionalaDocument: { maxLength: 892, defaultValue: "" },
				hierarchyIdPathOrganizareInstitutionalaDocument: { maxLength: 4000, defaultValue: "" },
				idPersoanaUnitateDocument: { dataType: "Int32", defaultValue: 0 },
				numeOrganizareInstitutionalaDocument: { maxLength: 150, defaultValue: "" },
				idOrganizareInstitutionalaPKCurent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStartValabilitateOrganizareInstitutionalaCurent: { dataType: "DateTime" },
				dataStopValabilitateOrganizareInstitutionalaCurent: { dataType: "DateTime" },
				hierarchyIdOrganizareInstitutionalaCurent: { maxLength: 892, defaultValue: "" },
				hierarchyIdPathOrganizareInstitutionalaCurent: { maxLength: 4000, defaultValue: "" },
				numeOrganizareInstitutionalaCurent: { maxLength: 150, defaultValue: "" },
			}
		},
		"documente.DocumentMiscareInventarOrganizareInstitutionalaPrimire": {
			shortName: "DocumentMiscareInventarOrganizareInstitutionalaPrimire",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentOperatiuneTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaPredare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaPrimire: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaPredator: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaPrimitor: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusGrupTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCompartiment: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaDelegat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				mijlocTransport: { maxLength: 100, defaultValue: "" },
				idComisiaReceptie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idComisiaCasare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idComisiaDistrugere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isGeneratDinCantina: { dataType: "Boolean", isNullable: false },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaComun: { dataType: "Int32", defaultValue: 0 },
				dataStartValabilitateOrganizareInstitutionalaDocument: { dataType: "DateTime" },
				dataStopValabilitateOrganizareInstitutionalaDocument: { dataType: "DateTime" },
				hierarchyIdOrganizareInstitutionalaDocument: { maxLength: 892, defaultValue: "" },
				hierarchyIdPathOrganizareInstitutionalaDocument: { maxLength: 4000, defaultValue: "" },
				idPersoanaUnitateDocument: { dataType: "Int32", defaultValue: 0 },
				numeOrganizareInstitutionalaDocument: { maxLength: 150, defaultValue: "" },
				idOrganizareInstitutionalaPKCurent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStartValabilitateOrganizareInstitutionalaCurent: { dataType: "DateTime" },
				dataStopValabilitateOrganizareInstitutionalaCurent: { dataType: "DateTime" },
				hierarchyIdOrganizareInstitutionalaCurent: { maxLength: 892, defaultValue: "" },
				hierarchyIdPathOrganizareInstitutionalaCurent: { maxLength: 4000, defaultValue: "" },
				numeOrganizareInstitutionalaCurent: { maxLength: 150, defaultValue: "" },
				isMagazieCurent: { dataType: "Boolean", isNullable: false },
			}
		},
		"documente.DocumentNotaContabilaCorectieCAB": {
			shortName: "DocumentNotaContabilaCorectieCAB",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				validatTrezorerie: { dataType: "Boolean", isNullable: false },
				listat: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentNotaContabilaCorectieCABDetaliu": {
			shortName: "DocumentNotaContabilaCorectieCABDetaliu",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu documentul pe care" } },
				idPersoanaBancaIban: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul pentru combinatia: Banca" } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Buget: Id comun {Sigma-Buget.b" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia functionala {Sigm" } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Buget: Id comun {Sigma-Buget.b" } },
				codProgram: { maxLength: 50, defaultValue: "" },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				numarOperatiuneInitiala: { maxLength: 50, defaultValue: "" },
				dataOperatiuneInitiala: { dataType: "DateTime" },
				explicatie: { maxLength: 100, defaultValue: "" },
				sumaDebit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaCredit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idExtrasContTrezoDetaliiMisc: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Suma din extrasul de cont {Sig" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentNotaContabilaCorectieCABPdf417": {
			shortName: "DocumentNotaContabilaCorectieCABPdf417",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", defaultValue: 0 },
				codBare: { defaultValue: "" },
			}
		},
		"documente.DocumentNumarSters": {
			shortName: "DocumentNumarSters",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "{Sigma.documente.Document.Id} " } },
				idDocumentGenerator: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "{Sigma.documente.Document.Id} " } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul unic din clasificatia fu" } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul unic din clasificaţia ec" } },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "{Sigma.nomenclatoare.NaturaPla" } },
				numarDocument: { maxLength: 20, defaultValue: "", custom: { MS_Description: "numarul documentului" } },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "{Sigma.unitatiIstoric.Organiza" } },
				idDocumentTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Identificatorul pentru tipul d" } },
				idDocumentTipVizat: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Identificatorul pentru tipul d" } },
				idPersoanaPartener: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul partenerului de la care " } },
				idPersoanaBancaIbanBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul pentru combinatia: Banca" } },
				idRetinereSalariiTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de retinere {Sigma.nomen" } },
				explicatie: { maxLength: 300, defaultValue: "" },
				dataDocument: { dataType: "DateTime" },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"documente.DocumentObligatieSalarii": {
			shortName: "DocumentObligatieSalarii",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataPlata: { dataType: "DateTime", custom: { MS_Description: "Data la care se va genera plat" } },
				idOrganizareInstitutionalaCompartiment: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul (PK) Compartimentului in" } },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "0 - de mana, 1 - generat din R" } },
				idPlataNumerarTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id comun {sigma.nomenclatoare." } },
				isFaraNotaContabilaObligatie: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Daca NU se doreste generarea n" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				isPlataExtrabugetar: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Daca plata se face dintr-un co" } },
				idPersoanaBancaIbanExtrabugetar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul pentru combinatia: Banca" } },
				stampLichidare: { dataType: "DateTime" },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Centrul de cheltuiala - IBAN e" } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id buget COMUN {Sigma-Buget.bu" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id capitol COMUN {Sigma-Buget." } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id articol COMUN {Sigma-Buget." } },
				codSufixBugetClasificatieFunctionala: { maxLength: 100, defaultValue: "", custom: { MS_Description: "CodSufix folosit la import" } },
				isFaraExecutieBugetara: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Daca NU se doreste generarea e" } },
				idDocumentSubTipAvans: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Subtipul de document pentru ca" } },
				isPlatiEduSalLaAltaData: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "1 - daca se doreste generarea " } },
				isExecutieSimplificataAngajamenteGlobale: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "0 - Daca se doreste executie b" } },
			}
		},
		"documente.DocumentObligatieSalariiDetaliu": {
			shortName: "DocumentObligatieSalariiDetaliu",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id buget COMUN {Sigma-Buget.bu" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id capitol COMUN {Sigma-Buget." } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id articol COMUN {Sigma-Buget." } },
				idRetinereSalariiTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de retinere, rest d epla" } },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idPersoanaBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul beneficiarului platii. I" } },
				idPersoanaBancaIbanBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul pentru combinatia: Banca" } },
				idPersoanaBeneficiarReal: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul beneficiarului real al p" } },
				modPlata: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Modul de plata: 1 - virament; " } },
				eduSalNrOp: { maxLength: 20, defaultValue: "" },
				eduSalDataPlata: { dataType: "DateTime" },
				idSumaExecutie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu Ordonantarile {Sig" } },
				explicatie: { maxLength: 250, defaultValue: "" },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				codFiscalBeneficiar: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Se completeaza doar pentru caz" } },
				idCodProgramBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Codul de program bugetar, id c" } },
			}
		},
		"documente.DocumentObligatieSalariiDetaliuPlata": {
			shortName: "DocumentObligatieSalariiDetaliuPlata",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentObligatieSalariiDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul retinerii din statul de " } },
				idDocumentPlata: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul ordinului de plata {Sigm" } },
				idSumaExecutie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu Ordonantarile {Sig" } },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Suma platita" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentOperatiuneProprie": {
			shortName: "DocumentOperatiuneProprie",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Documentul corespunzator  {Sig" } },
				idOperatiuneProprieTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Operatiunea: id comun {Sigma.r" } },
				idPersoanaBancaIban: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul pentru combinatia: Banca" } },
				idOrganizareInstitutionalaCompartiment: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Compartimetul - ID PK {Sigma.u" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentOperatiuneProprieDetaliu": {
			shortName: "DocumentOperatiuneProprieDetaliu",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Documentul {Sigma.documente.Do" } },
				idOperatiuneProprieTipDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Detaliul de operatiune {Sigma." } },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Valoare fara TVA in valuta" } },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Valuta de referinta {Sigma.nom" } },
				cursValutar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaLei: { dataType: "Decimal", defaultValue: 0 },
				sumaTVA: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Valoarea TVA in valuta" } },
				sumaTVALei: { dataType: "Decimal", defaultValue: 0 },
				sumaTotal: { dataType: "Decimal", defaultValue: 0 },
				sumaTotalLei: { dataType: "Decimal", defaultValue: 0 },
				idDocumentFurnizorClientDetaliuTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul detaliului (ex.Achizitii" } },
				idProdusRaportareSectorAgricol: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de produs pentru raporta" } },
				idProdusRaportareTaxareInversa: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de produs pentru raporta" } },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Natura platii {Sigma.nomenclat" } },
				idCodProgramBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Codul de program bugetar, id c" } },
				codAngajament: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Codul de angajament dat de CAB" } },
				indicatorAngajament: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Indicatorul din cadrul angajam" } },
				idSumaExecutie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu Ordonantarile {Sig" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				idDocumentPlataOPME: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Id-ul ordinului de plata multi" } },
				idDocumentAngajamentLegalGlobal: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Marcaj pentru detaliile care s" } },
			}
		},
		"documente.DocumentOrdinDeplasare": {
			shortName: "DocumentOrdinDeplasare",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Documentul pe care il extinde " } },
				delegatPentru: { defaultValue: "" },
				delegatLa: { defaultValue: "" },
				durataDeplasareDataStart: { dataType: "DateTime" },
				durataDeplasareDataStop: { dataType: "DateTime" },
				legitimatCu: { defaultValue: "" },
				ziOraPlecare: { dataType: "DateTime" },
				ziOraSosire: { dataType: "DateTime" },
				dataDepunereDecont: { dataType: "DateTime" },
				sumaPenalizare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				diurnaTarifZi: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tariful zilnic pentru diurna s" } },
				diurnaNumarZile: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				diurnaSuma: { dataType: "Decimal", defaultValue: 0 },
				idBugetCazare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Bugetul asociat {Sigma-Buget.b" } },
				idBugetClasificatieFunctionalaCazare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia functionala {Sigm" } },
				idFormularClasificatieEconomicaCazare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia economica {Sigma-" } },
				sumaCazare: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Suma cazare fara documente jus" } },
				idBugetTransportAuto: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Bugetul asociat {Sigma-Buget.b" } },
				idBugetClasificatieFunctionalaTransportAuto: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia functionala {Sigm" } },
				idFormularClasificatieEconomicaTransportAuto: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia economica {Sigma-" } },
				sumaTransportAuto: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Suma decontata transport auto " } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentOrdinDeplasareSold": {
			shortName: "DocumentOrdinDeplasareSold",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id document avans spre deconta" } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Bugetul asociat {Sigma-Buget.b" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia functionala {Sigm" } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia economica {Sigma-" } },
				sumaPlatita: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaCheltuita: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaDiurna: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaIncasata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sold: { dataType: "Decimal", defaultValue: 0 },
			}
		},
		"documente.DocumentOrdinDeplasareVirament": {
			shortName: "DocumentOrdinDeplasareVirament",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Documentul (avans spre deconta" } },
				dataDocumentVirament: { dataType: "DateTime", custom: { MS_Description: "Data dcumentului care va fi ge" } },
				numarDocumentVirament: { maxLength: 18, defaultValue: "", custom: { MS_Description: "Numarului documentului care va" } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Bugetul asociat {Sigma-Buget.b" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia functionala {Sigm" } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia economica {Sigma-" } },
				idPersoanaBancaIban: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul pentru combinatia: Banca" } },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIbanBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul pentru combinatia: Banca" } },
				explicatie: { maxLength: 100, defaultValue: "" },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				idSumaExecutie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu Ordonantarile {Sig" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentOrganizareInstitutionala": {
			shortName: "DocumentOrganizareInstitutionala",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				seria: { maxLength: 10, defaultValue: "" },
				numarDocument: { maxLength: 18, defaultValue: "" },
				numarDocumentNumeric: { dataType: "Int32", defaultValue: 0 },
				seriaSufix: { maxLength: 10, defaultValue: "" },
				dataDocument: { dataType: "DateTime" },
				numarInregistrare: { dataType: "Int32", defaultValue: 0 },
				dataInregistrare: { dataType: "DateTime" },
				nume: { maxLength: 250, defaultValue: "" },
				numarInregistrareExtern: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataInregistrareExtern: { dataType: "DateTime" },
				idDocumentTip: { dataType: "Int32", defaultValue: 0 },
				idDocumentSubTip: { dataType: "Int32", defaultValue: 0 },
				categoria: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", defaultValue: 0 },
				idPersoanaPartener: { dataType: "Int32", defaultValue: 0 },
				idPersoanaResponsabil: { dataType: "Int32", defaultValue: 0 },
				importDataJson: { defaultValue: "" },
				importDataTip: { maxLength: 100, defaultValue: "" },
				importDataId: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				importDataTipParent: { maxLength: 100, defaultValue: "" },
				importDataIdParent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idOrganizareInstitutionalaComun: { dataType: "Int32", defaultValue: 0 },
				dataStartValabilitateOrganizareInstitutionalaDocument: { dataType: "DateTime" },
				dataStopValabilitateOrganizareInstitutionalaDocument: { dataType: "DateTime" },
				idPersoanaUnitateDocument: { dataType: "Int32", defaultValue: 0 },
				hierarchyIdOrganizareInstitutionalaDocument: { maxLength: 892, defaultValue: "" },
				hierarchyIdPathOrganizareInstitutionalaDocument: { maxLength: 4000, defaultValue: "" },
				idOrganizareInstitutionalaPKCurent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStartValabilitateOrganizareInstitutionalaCurent: { dataType: "DateTime" },
				dataStopValabilitateOrganizareInstitutionalaCurent: { dataType: "DateTime" },
				hierarchyIdOrganizareInstitutionalaCurent: { maxLength: 892, defaultValue: "" },
				hierarchyIdPathOrganizareInstitutionalaCurent: { maxLength: 4000, defaultValue: "" },
			}
		},
		"documente.DocumentPlata": {
			shortName: "DocumentPlata",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul documentului pe care il " } },
				seria: { maxLength: 10, defaultValue: "" },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Suma platita" } },
				idPersoanaBancaIbanPlatitor: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul pentru combinatia: Banca" } },
				idPersoanaBancaIbanBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul pentru combinatia: Banca" } },
				explicatie: { maxLength: 100, defaultValue: "" },
				listat: { dataType: "Boolean", isNullable: false },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "{Sigma.nomeclatoare.NaturaPlat" } },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				idDocumentPlataOPME: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul ordinului de plata multi" } },
				isAfterDecont: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "De platit in urma decontului" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Pentru plati in afara bugetulu" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Pentru plati din mandat in che" } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia economica {Sigma-" } },
				codFiscalBeneficiar: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Se completeaza doar pentru caz" } },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu tipul de venit {Si" } },
				idCodProgramBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Codul de program bugetar, id c" } },
				isIncasareBanca: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Incasarile in banca (instituti" } },
			}
		},
		"documente.DocumentPlataIncasareCheltuiala": {
			shortName: "DocumentPlataIncasareCheltuiala",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentPlata: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul platii {Sigma.documente." } },
				idIncasare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul incasarii {Sigma-Impozit" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentPlataOPME": {
			shortName: "DocumentPlataOPME",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul documentului pe care il " } },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				numarInregistrari: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"documente.DocumentPlataOrdonantareSuma": {
			shortName: "DocumentPlataOrdonantareSuma",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul documentului de plata: {" } },
				idDocumentPlata: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul documentului de plata de" } },
				idSumaExecutie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul sumei din ordonantare pe" } },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Suma platita din document" } },
				idDocumentDispozitiePlataDistribuireSume: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul detaliului pentru Dispoz" } },
				idDocumentDispozitieIncasareDistribuireSume: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul detaliului pentru Dispoz" } },
				idDocumentCecRidicareNumerar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "{Sigma.documente.DocumentCecRi" } },
				idDocumentFoaieVarsamant: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "{Sigma.documente.DocumentFoaie" } },
			}
		},
		"documente.DocumentPlataPdf417": {
			shortName: "DocumentPlataPdf417",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", defaultValue: 0 },
				codBare: { defaultValue: "" },
			}
		},
		"documente.DocumentProcesVerbalObiectivInCurs": {
			shortName: "DocumentProcesVerbalObiectivInCurs",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura la registrul general " } },
				idDocumentOperatiuneTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Operatiunea pentru generare NC" } },
				idObiectiv: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu obiectivul in curs" } },
				idSubObiectiv: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu subObiectivul in c" } },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul activitatii economica - " } },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul centrului de cheltuiala " } },
				idOrganizareInstitutionalaPrimire: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul gestiunii PK in care int" } },
				idPersoanaPrimitor: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul gestionarului PK care pr" } },
				idComisiaReceptie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Comisia de receptie {Sigma.per" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentReevaluare": {
			shortName: "DocumentReevaluare",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul documentului pe care il " } },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul unitatii, comun {Sigma.p" } },
				dataReevaluarePrecedenta: { dataType: "DateTime", custom: { MS_Description: "Data reevaluarii precedente" } },
				isConformIPC: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Reevaluaare pe baza de Indici " } },
				isCuEvaluator: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Reevaluare cu evaluator autori" } },
				isMFInclusivExpirate: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Se reevalueaza inclusiv MF exp" } },
				isMFInclusivIntrariAn: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Se reevalueaza inclusiv MF int" } },
				isMFInclusivInCursExecutie: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Se reevalueaza inclusiv MF in " } },
				isMFInclusivNuSeReevalueaza: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Se reevalueaza inclusiv MF car" } },
				isMFInclusivNecorporale: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Se reevalueaza inclusiv active" } },
				isInfluenteInclusivIntrariAn: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Se reevalueaza inclusiv influe" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentReglareNotaContabila": {
			shortName: "DocumentReglareNotaContabila",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura la registrul unic al " } },
				idObiectivInvestitie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id obiectiv investitie, id com" } },
				idObiectivInvestitieSubobiectiv: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id SubObiectiv investitie, id " } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentSoldCasierie": {
			shortName: "DocumentSoldCasierie",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul comun al persoanei unita" } },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul activitatii economice in" } },
				iBAN: { maxLength: 50, defaultValue: "" },
				idPersoanaIBAN: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul comun al titularului IBA" } },
				idDocumentTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Identificatorul pentru tipul d" } },
				numarDocumentIncasare: { maxLength: 18, defaultValue: "", custom: { MS_Description: "Numarul documentului de intrar" } },
				dataInregistrareIncasare: { dataType: "DateTime", custom: { MS_Description: "Data de inregistrare a documen" } },
				numarDocumentPlata: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Numarul documentului de iesire" } },
				dataInregistrarePlata: { dataType: "DateTime", custom: { MS_Description: "Data de inregistrare a documen" } },
				sumaIncasata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaPlatita: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				soldDocumentIncasare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idDocumentCecRidicareNumerar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul cecului: {Sigma.document" } },
				idIncasare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul incasarii: {Sigma-Impota" } },
				idDocumentFoaieVarsamant: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul foii de varsamant: {Sigm" } },
				idDocumentDispozitiePlataDistribuireSume: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul dispozitiei de plata: {S" } },
				idDocumentStatPlataNumerarDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul platii efective: {Sigma." } },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentStare": {
			shortName: "DocumentStare",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentStareTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Starea documentului (in operar" } },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Documentul pentru care stabili" } },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Persoana care stabileste stare" } },
				dataStare: { dataType: "DateTime", custom: { MS_Description: "Momentul la care se seteaza st" } },
				observatie: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentStatPlataNumerar": {
			shortName: "DocumentStatPlataNumerar",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCompartiment: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul (PK) Compartimentului in" } },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Centrul de cheltuiala - Id PK " } },
				idPlataNumerarTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id comun {sigma.nomenclatoare." } },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id buget COMUN {Sigma-Buget.bu" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id capitol COMUN {Sigma-Buget." } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id articol COMUN {Sigma-Buget." } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentStatPlataNumerarDetaliu": {
			shortName: "DocumentStatPlataNumerarDetaliu",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Documentul pe care il extinde " } },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Persoana care  primeste banii " } },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dataPlata: { dataType: "DateTime", custom: { MS_Description: "Data efectiva a platii, Tine l" } },
				userNamePlata: { maxLength: 250, defaultValue: "" },
				idOrganizareInstitutionalaCasierie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Punctul de Incasare(Casieria) " } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				idDocumentCecRidicareNumerar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul detaliului din CEC-ul de" } },
				isGenerareExecutieBugetara: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "1 - se va genera executie buge" } },
				idDocumentAngajamentLegalGlobal: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Marcaj pentru legatura de un a" } },
			}
		},
		"documente.DocumentSursa": {
			shortName: "DocumentSursa",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Id-ul documentului curent {Sig" } },
				idDocumentSursa: { dataType: "Int32", custom: { MS_Description: "Id-ul documentului sursa (ex. " } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.DocumentSursaTip": {
			shortName: "DocumentSursaTip",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", defaultValue: 0 },
				idDocumentSursa: { dataType: "Int32", defaultValue: 0 },
				idDocumentSursaTip: { dataType: "Int32" },
				idDocumentTip: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"documente.DocumentSursaTipData": {
			shortName: "DocumentSursaTipData",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", defaultValue: 0 },
				idDocumentSursa: { dataType: "Int32", defaultValue: 0 },
				idDocumentTipSursa: { dataType: "Int32", defaultValue: 0 },
				idDocumentTip: { dataType: "Int32", defaultValue: 0 },
				dataDocumentSursa: { dataType: "DateTime" },
				dataDocument: { dataType: "DateTime" },
				numarDocument: { maxLength: 18, defaultValue: "" },
				numarDocumentSursa: { maxLength: 18, defaultValue: "" },
				numarDocumentNumeric: { dataType: "Int32", defaultValue: 0 },
				numarDocumentNumericSursa: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"documente.OperatiuneCFP": {
			shortName: "OperatiuneCFP",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 20, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"documente.OperatiuneCFPClasificatieEconomica": {
			shortName: "OperatiuneCFPClasificatieEconomica",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOperatiuneCFP: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isExclusiv: { dataType: "Boolean", isNullable: false },
			}
		},
		"documente.OperatiuneCFPDocumentSubTip": {
			shortName: "OperatiuneCFPDocumentSubTip",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentSubTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOperatiuneCFP: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"documente.RegistruVizaCFP": {
			shortName: "RegistruVizaCFP",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Documentul pe care se aplica v" } },
				idControlorCFP: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Controlorul CFP care aplica vi" } },
				idOperatiuneCFP: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul unic pentru operatiunea " } },
				numarRegistruVizaCFP: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataRegistruVizaCFP: { dataType: "DateTime" },
				isRefuzVizaCFP: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Marcheaza un refuz de viza cfp" } },
				nume: { maxLength: 250, defaultValue: "", custom: { MS_Description: "O descriere generica a documen" } },
				explicatie: { maxLength: 250, defaultValue: "" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Unitatea Id comun {Sigma.perso" } },
				idOrganizareInstitutionalaEmitent: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Compartimentul Emitent: {Sigma" } },
				valoare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				motivRefuzVizaCFP: { maxLength: 250, defaultValue: "", custom: { MS_Description: "Motivul refuzului de viza CFP" } },
				idDocumentTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "se va salva pentru inregistrar" } },
				numarDocumentVizat: { maxLength: 20, defaultValue: "", custom: { MS_Description: "se va salva pentru inregistrar" } },
				dataDocumentVizat: { dataType: "DateTime", custom: { MS_Description: "se va salva pentru inregistrar" } },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul comun al sectorului buge" } },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul comun al sursei de finan" } },
				isPermisaEditareSectorSursa: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Este permisa editarea campuril" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.SetareNumarUrmator": {
			shortName: "SetareNumarUrmator",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de document pentru care " } },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Unitatea de lucru {Sigma.perso" } },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Casieria / Departamantul pentr" } },
				seria: { maxLength: 10, defaultValue: "" },
				numarUrmator: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				numarMaximAlocat: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Daca este completat ==> modul " } },
				modPrefixareNumar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "0 - fara prefixare / 1 - prefi" } },
				prefixareNumarValoare: { maxLength: 10, defaultValue: "" },
				modResetareNumar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "0 - fara resetare / 1 - anual " } },
				numarCaractere: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Pe cate caractere va fi afisat" } },
				seriaSufix: { maxLength: 10, defaultValue: "", custom: { MS_Description: "Seria pentru sufixare numar ge" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documente.SetareNumarUrmatorAn": {
			shortName: "SetareNumarUrmatorAn",
			namespace: "documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de document pentru care " } },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Unitatea de lucru {Sigma.perso" } },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Casieria / Departamantul pentr" } },
				seria: { maxLength: 10, defaultValue: "" },
				numarUrmator: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				numarMaximAlocat: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Daca este completat ==> modul " } },
				modPrefixareNumar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "0 - fara prefixare / 1 - prefi" } },
				prefixareNumarValoare: { maxLength: 10, defaultValue: "" },
				modResetareNumar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "0 - fara resetare / 1 - anual " } },
				numarCaractere: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Pe cate caractere va fi afisat" } },
				seriaSufix: { maxLength: 10, defaultValue: "", custom: { MS_Description: "Seria pentru sufixare numar ge" } },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documenteistoric.DocumentContractMunca": {
			shortName: "DocumentContractMunca",
			namespace: "documenteistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Persoana din institutie (Perso" } },
				idOrganizareInstitutionala: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Postul din institutie ocupat d" } },
				idPost: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "DEPRICATED: Postul din institu" } },
				isFunctieBaza: { dataType: "Boolean" },
				isConducatorDepartament: { dataType: "Boolean", isNullable: false },
				idDocument: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Legatura cu Documentul din Reg" } },
				idDocumentContractMunca: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documenteistoric.OperatiuneCFP": {
			shortName: "OperatiuneCFP",
			namespace: "documenteistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 20, defaultValue: "", custom: { MS_Description: "Codul asociat operatiunii CFP" } },
				nume: { maxLength: 250, defaultValue: "", custom: { MS_Description: "Denumire operatiune supusa viz" } },
				idOperatiuneCFP: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documenteistoric.OperatiuneCFPClasificatieEconomica": {
			shortName: "OperatiuneCFPClasificatieEconomica",
			namespace: "documenteistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOperatiuneCFP: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id comun {Sigma.documenteIstor" } },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id comun {Sigma-Buget.bugetIst" } },
				isExclusiv: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "0 - pentru Clasificatia econom" } },
				idOperatiuneCFPClasificatieEconomica: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"documenteistoric.OperatiuneCFPDocumentSubTip": {
			shortName: "OperatiuneCFPDocumentSubTip",
			namespace: "documenteistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Unitatea, Id comun {Sigma.pers" } },
				idDocumentSubTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de document {Sigma.nomen" } },
				idOperatiuneCFP: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Operatiunea {Sigma.documenteIs" } },
				idOperatiuneCFPDocumentSubTip: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"efactura.DiscountMotivCodBT140": {
			shortName: "DiscountMotivCodBT140",
			namespace: "efactura",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 10, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				descriere: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"efactura.eFactura": {
			shortName: "eFactura",
			namespace: "efactura",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "ID-ul persoanei - unitatii de " } },
				cif: { maxLength: 50, defaultValue: "" },
				tip: { maxLength: 250, defaultValue: "" },
				codTipFactura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataCreare: { dataType: "DateTime" },
				dataEmitere: { dataType: "DateTime" },
				idSolicitare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idEfactura: { dataType: "Int32", isNullable: false, custom: { MS_Description: "se va salva campul 'id' primit" } },
				detaliu: { defaultValue: "" },
				xmlData: { defaultValue: "" },
				cumparator: { maxLength: 250, defaultValue: "" },
				furnizor: { maxLength: 250, defaultValue: "" },
				valoare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareMoneda: { maxLength: 3, defaultValue: "" },
				isListat: { dataType: "Boolean", isNullable: false },
				isMultiplicat: { dataType: "Boolean", isNullable: false },
				notaFactura: { defaultValue: "" },
				nrFactura: { maxLength: 256, defaultValue: "" },
				userNameDescarcare: { maxLength: 256, defaultValue: "" },
				dataDescarcare: { dataType: "DateTime" },
				status: { defaultValue: "" },
				anexaContinut: { defaultValue: "" },
				anexaDenumire: { maxLength: 100, defaultValue: "" },
				anexaTip: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				cIDId: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cIDData: { dataType: "DateTime" },
			}
		},
		"efactura.FacturaTipBt3": {
			shortName: "FacturaTipBt3",
			namespace: "efactura",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 10, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"efactura.Fisier": {
			shortName: "Fisier",
			namespace: "efactura",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "ID-ul persoanei - unitatii de " } },
				nume: { maxLength: 255, defaultValue: "" },
				cale: { maxLength: 255, defaultValue: "" },
				continutArhiva: { defaultValue: "", custom: { MS_Description: "Continutul arhivei de la anaf" } },
				idEFactura: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "id-ul cheie primara din tabelu" } },
				userNameDescarcare: { maxLength: 256, defaultValue: "" },
				dataDescarcare: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"efactura.InstructiunePlataBg16": {
			shortName: "InstructiunePlataBg16",
			namespace: "efactura",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 10, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"efactura.MonedaBt5": {
			shortName: "MonedaBt5",
			namespace: "efactura",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 10, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"efactura.TaraBt40": {
			shortName: "TaraBt40",
			namespace: "efactura",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 10, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"efactura.TvaCategorieBt151": {
			shortName: "TvaCategorieBt151",
			namespace: "efactura",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 10, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"efactura.TvaMotivScutireBt121": {
			shortName: "TvaMotivScutireBt121",
			namespace: "efactura",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 50, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"efactura.UnitateMasuraBt130": {
			shortName: "UnitateMasuraBt130",
			namespace: "efactura",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 10, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"epay.DovadaPlataOnline": {
			shortName: "DovadaPlataOnline",
			namespace: "epay",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				stream_idDovadaPlataOnlineContinut: { defaultValue: null },
				idEpay: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codUnic: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				detaliuPlatitor: { maxLength: 250, defaultValue: "" },
				adresaPlatitor: { maxLength: 250, defaultValue: "" },
				numeDovada: { maxLength: 250, defaultValue: "" },
				dataDovada: { dataType: "DateTime" },
				dataCreare: { dataType: "DateTime", isNullable: false },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utilizatorAnulare: { maxLength: 50, defaultValue: "" },
				sistem: { maxLength: 50, defaultValue: "" },
			}
		},
		"epay.PersoanaOnline": {
			shortName: "PersoanaOnline",
			namespace: "epay",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idEpay: { dataType: "Int32", defaultValue: 0 },
				cui: { maxLength: 19, defaultValue: "" },
				nume: { maxLength: 100, defaultValue: "" },
				prenume: { maxLength: 100, defaultValue: "" },
				numarRegistruComert: { maxLength: 50, defaultValue: "" },
				atributFiscal: { maxLength: 10, defaultValue: "" },
				tara: { maxLength: 50, defaultValue: "" },
				judet: { maxLength: 50, defaultValue: "" },
				localitate: { maxLength: 50, defaultValue: "" },
				idStrada: { dataType: "Int32", defaultValue: 0 },
				strada: { maxLength: 50, defaultValue: "" },
				numarPostal: { maxLength: 10, defaultValue: "" },
				bloc: { maxLength: 10, defaultValue: "" },
				scara: { maxLength: 10, defaultValue: "" },
				apartament: { maxLength: 10, defaultValue: "" },
				dataCreare: { dataType: "DateTime", isNullable: false },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
			}
		},
		"ghiseul.BorderouIncasare": {
			shortName: "BorderouIncasare",
			namespace: "ghiseul",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				continutCSV: { defaultValue: "" },
				statusCod: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				statusMsg: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"ghiseul.BorderouIncasareDetalii": {
			shortName: "BorderouIncasareDetalii",
			namespace: "ghiseul",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentBorderouIncasare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataPlata: { dataType: "DateTime", isNullable: false },
				cUIInstitutie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cUIPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cUIPersoanaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaPlata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				codTaxaNomenclatorUnic: { maxLength: 100, defaultValue: "" },
				codTaxa: { maxLength: 100, defaultValue: "" },
				descrierePlata: { maxLength: 500, defaultValue: "" },
				numePersoanaPlata: { maxLength: 250, defaultValue: "" },
				adresaPersoanaPlata: { maxLength: 500, defaultValue: "" },
				idPlataGhiseul: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isDeleted: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"importdata.ConversiePasStatus": {
			shortName: "ConversiePasStatus",
			namespace: "importdata",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idConversieTemplate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul templeate-ului : {Sigma." } },
				codPas: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Codul pasului care se executa" } },
				pasStatusJson: { defaultValue: "", custom: { MS_Description: "JSON de informatii" } },
				pasRezultatJSON: { defaultValue: "" },
				pasCompleted: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "flag: pas finalizat" } },
				proceduraCompleted: { dataType: "Boolean", isNullable: false },
			}
		},
		"importdata.ConversieTemplate": {
			shortName: "ConversieTemplate",
			namespace: "importdata",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				descriere: { defaultValue: "" },
				listaCodPas: { defaultValue: "" },
			}
		},
		"importdata.DateAfectateStergereUnitate": {
			shortName: "DateAfectateStergereUnitate",
			namespace: "importdata",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				dataBaseName: { maxLength: 128, defaultValue: "" },
				schemaName: { maxLength: 128, defaultValue: "" },
				tableName: { maxLength: 128, defaultValue: "" },
				idValidare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"importdata.DateNeafectateStergereUnitate": {
			shortName: "DateNeafectateStergereUnitate",
			namespace: "importdata",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				dataBaseName: { maxLength: 128, defaultValue: "" },
				schemaName: { maxLength: 128, defaultValue: "" },
				tableName: { maxLength: 128, defaultValue: "" },
				idValidare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"importdata.LegaturaApartament": {
			shortName: "LegaturaApartament",
			namespace: "importdata",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idApartamentSigma: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idApartamentImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				informatiaImpotaxJson: { defaultValue: "" },
			}
		},
		"importdata.LegaturaBloc": {
			shortName: "LegaturaBloc",
			namespace: "importdata",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBlocSigma: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBlocImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				informatiaImpotaxJson: { defaultValue: "" },
			}
		},
		"importdata.LegaturaNumarPostal": {
			shortName: "LegaturaNumarPostal",
			namespace: "importdata",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idNumarSigma: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idNumarImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				informatiaImpotaxJson: { defaultValue: "" },
				extravilan: { dataType: "Boolean", isNullable: false },
			}
		},
		"importdata.LegaturaPersoana": {
			shortName: "LegaturaPersoana",
			namespace: "importdata",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaSigma: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu persoana din Sigma" } },
				idPersoanaEcub: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaEcubBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaEcubGestStoc: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaEcubMiFix: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaEcubResum: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaAgroregis: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				informatiaEcubJson: { defaultValue: "" },
				informatiaImpotaxJson: { defaultValue: "" },
				informatiaAgroregisJson: { defaultValue: "" },
			}
		},
		"importdata.LegaturaScara": {
			shortName: "LegaturaScara",
			namespace: "importdata",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idScaraSigma: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idScaraImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				informatiaImpotaxJson: { defaultValue: "" },
			}
		},
		"importdata.LegaturaStrada": {
			shortName: "LegaturaStrada",
			namespace: "importdata",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idStradaSigma: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idStradaImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				informatiaImpotaxJson: { defaultValue: "" },
				extravilan: { dataType: "Boolean", isNullable: false },
			}
		},
		"importdata.LegaturaTipVenit": {
			shortName: "LegaturaTipVenit",
			namespace: "importdata",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idVenitSigma: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenitImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				informatiaImpotaxJson: { defaultValue: "" },
				numeImpotax: { maxLength: 150, defaultValue: "" },
			}
		},
		"importdata.StandardMiFix": {
			shortName: "StandardMiFix",
			namespace: "importdata",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				numeLocFolosinta: { maxLength: 100, defaultValue: "" },
				locFolosintaExista: { dataType: "Boolean" },
				numePersoana: { maxLength: 100, defaultValue: "" },
				prenumePersoana: { maxLength: 100, defaultValue: "" },
				numarInventar: { maxLength: 50, defaultValue: "" },
				numeProdus: { maxLength: 250, defaultValue: "" },
				clasificare: { maxLength: 20, defaultValue: "" },
				dataEvidenta: { dataType: "DateTime" },
				dataPIF: { dataType: "DateTime" },
				durata: { dataType: "Int32", defaultValue: 0 },
				valoare: { dataType: "Decimal", defaultValue: 0 },
				valoareRamasaAmortizat: { dataType: "Decimal", defaultValue: 0 },
				soldInitialData: { dataType: "DateTime" },
				domeniu: { maxLength: 20, defaultValue: "" },
				necorporal: { dataType: "Boolean" },
				documentTip: { maxLength: 50, defaultValue: "" },
				documentNumar: { maxLength: 20, defaultValue: "" },
				documentData: { dataType: "DateTime" },
				receptieNumar: { dataType: "Decimal", defaultValue: 0 },
				receptieData: { dataType: "DateTime" },
				caracteristici: { maxLength: 500, defaultValue: "" },
				nr_topo: { maxLength: 50, defaultValue: "" },
				nr_carte_f: { maxLength: 50, defaultValue: "" },
				nr_cadastr: { maxLength: 50, defaultValue: "" },
				supraf_tot: { maxLength: 50, defaultValue: "" },
				hcl: { maxLength: 50, defaultValue: "" },
				hg: { maxLength: 50, defaultValue: "" },
				iesireDocumentData: { dataType: "DateTime" },
				iesireDocumentNumar: { dataType: "Decimal", defaultValue: 0 },
				iesireExplicatie: { maxLength: 100, defaultValue: "" },
				idProdusSigma: { dataType: "Int32", defaultValue: 0 },
				clasificatieFunctionala: { maxLength: 50, defaultValue: "" },
				clasificatieEconomica: { maxLength: 20, defaultValue: "" },
				inAfaraBilantului: { dataType: "Boolean" },
			}
		},
		"importdata.StandardStoc": {
			shortName: "StandardStoc",
			namespace: "importdata",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				numeGestiune: { maxLength: 100, defaultValue: "" },
				numeGestiuneParinte: { maxLength: 100, defaultValue: "" },
				cuiPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numePersoana: { maxLength: 100, defaultValue: "" },
				prenumePersoana: { maxLength: 100, defaultValue: "" },
				cod: { maxLength: 50, defaultValue: "" },
				numeProdus: { maxLength: 250, defaultValue: "" },
				numeUnitateMasura: { maxLength: 100, defaultValue: "" },
				cantitate: { dataType: "Decimal", defaultValue: 0 },
				pret: { dataType: "Decimal", defaultValue: 0 },
				clasaMagazie: { maxLength: 20, defaultValue: "" },
				clasaFolosinta: { maxLength: 20, defaultValue: "" },
				sectorBugetar: { maxLength: 2, defaultValue: "", custom: { MS_Description: "Valori: '01'; '01'; '03'; ...." } },
				sursaFinantare: { maxLength: 1, defaultValue: "", custom: { MS_Description: "Valori: A; B; C; .... echivale" } },
				clasificatieFunctionala: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Cod clasificatie functionala f" } },
				clasificatieEconomica: { maxLength: 20, defaultValue: "" },
				detalii: { maxLength: 254, defaultValue: "" },
				dataIntrare: { dataType: "DateTime" },
				inAfaraBilantului: { dataType: "Boolean" },
				codCPV: { maxLength: 150, defaultValue: "" },
			}
		},
		"impotax.AlocareNrChitanta": {
			shortName: "AlocareNrChitanta",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionalaCasierie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				serieStart: { maxLength: 50, defaultValue: "" },
				numarStart: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarStop: { dataType: "Int32", defaultValue: 0 },
				serieStop: { maxLength: 50, defaultValue: "" },
				faraPlaja: { dataType: "Boolean" },
				numarCaractere: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"impotax.AlteDebite": {
			shortName: "AlteDebite",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 100, defaultValue: "" },
				idStrada: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idNumarPostal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBloc: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idApartament: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStart: { dataType: "DateTime" },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utilizatorAnulare: { maxLength: 50, defaultValue: "" },
				numarInregistrare: { dataType: "Int32", defaultValue: 0 },
				explicatieAnulare: { maxLength: 100, defaultValue: "" },
				dataOprire: { dataType: "DateTime" },
				explicatieOprire: { maxLength: 100, defaultValue: "" },
				utilizatorOprire: { maxLength: 50, defaultValue: "" },
				dataOprireUtilizator: { dataType: "DateTime" },
				anDeclaratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				matricolaVeche: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idModificare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarMatricolDeclaratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				procentProprietate: { dataType: "Decimal", defaultValue: 0 },
				idActStop: { dataType: "Int32", defaultValue: 0 },
				numarActStop: { maxLength: 50, defaultValue: "" },
				idActEmis: { dataType: "Int32", defaultValue: 0 },
				emitentAct: { maxLength: 100, defaultValue: "" },
				numarActEmis: { maxLength: 50, defaultValue: "" },
				idTipScutire: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataRevizieScutire: { dataType: "DateTime" },
				explicatieScutire: { maxLength: 100, defaultValue: "" },
				dataStopScutire: { dataType: "DateTime" },
				dataScutire: { dataType: "DateTime" },
				dataCerereScutire: { dataType: "DateTime" },
				numarCerereScutire: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				procentScutire: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dataDobandireInitiala: { dataType: "DateTime" },
				idLocalitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idScara: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				operatie: { dataType: "Int32", defaultValue: 0 },
				dataDobandire: { dataType: "DateTime" },
				caracter: { maxLength: 1, defaultValue: "" },
				dataCalendar: { dataType: "DateTime" },
				persoane: { dataType: "Int32", defaultValue: 0 },
				sumaBani: { dataType: "Decimal", defaultValue: 0 },
				suprafata: { dataType: "Decimal", defaultValue: 0 },
				procent: { dataType: "Decimal", defaultValue: 0 },
				zile: { dataType: "Int32", defaultValue: 0 },
				cantitate: { dataType: "Decimal", defaultValue: 0 },
			}
		},
		"impotax.Amenda": {
			shortName: "Amenda",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idPersoana: { dataType: "Int32", defaultValue: 0 },
				idVenit: { dataType: "Int32", defaultValue: 0 },
				procesVerbalSerie: { maxLength: 50, defaultValue: "" },
				procesVerbalNumar: { maxLength: 20, defaultValue: "" },
				valoare: { dataType: "Decimal", defaultValue: 0 },
				dataScadenta: { dataType: "DateTime" },
				explicatie: { maxLength: 100, defaultValue: "" },
				dataAmenda: { dataType: "DateTime" },
				procentReducere: { dataType: "Int32", defaultValue: 0 },
				valoareReducere: { dataType: "Decimal", defaultValue: 0 },
				dataReducere: { dataType: "DateTime" },
				explicatieReducere: { maxLength: 50, defaultValue: "" },
				utilizatorReducere: { dataType: "Int32", defaultValue: 0 },
				numarInregistrare: { dataType: "Int32", defaultValue: 0 },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				explicatieAnulare: { maxLength: 100, defaultValue: "" },
				utilizatorAnulare: { maxLength: 50, defaultValue: "" },
				anDeclaratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idJudet: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				matricola: { dataType: "Int32", defaultValue: 0 },
				numarInregistrareAmenda: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dataInregistrareAmenda: { dataType: "DateTime", isNullable: false },
				idLocalitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				flagIncasat48Ore: { dataType: "Int32", defaultValue: 0 },
				idTipEmitent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				somatie: { maxLength: 50, defaultValue: "" },
				numarCID: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataCID: { dataType: "DateTime", isNullable: false },
				dataInstiintare: { dataType: "DateTime" },
				numarMatricolDeclaratie: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"impotax.Auto": {
			shortName: "Auto",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idTipAutovehicul: { dataType: "Int32", defaultValue: 0 },
				idVenit: { dataType: "Int32", defaultValue: 0 },
				masa: { dataType: "Decimal", defaultValue: 0 },
				serieSasiu: { maxLength: 50, defaultValue: "" },
				numarInmatriculare: { maxLength: 20, defaultValue: "" },
				axaSuspensie: { dataType: "Int32", defaultValue: 0 },
				capacitateCilindrica: { dataType: "Int32", defaultValue: 0 },
				capacitateRemorca: { dataType: "Decimal", defaultValue: 0 },
				serieMotor: { maxLength: 15, defaultValue: "" },
				procentProprietate: { dataType: "Decimal", defaultValue: 0 },
				numarInregistrare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataDobandire: { dataType: "DateTime" },
				dataStart: { dataType: "DateTime" },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utilizatorAnulare: { maxLength: 50, defaultValue: "" },
				idMarcaAuto: { dataType: "Int32", defaultValue: 0 },
				explicatieAnulare: { maxLength: 100, defaultValue: "" },
				explicatieOprire: { maxLength: 100, defaultValue: "" },
				conform: { maxLength: 100, defaultValue: "" },
				dataOprireUtilizator: { dataType: "DateTime" },
				procentScutire: { dataType: "Decimal", defaultValue: 0 },
				numarCerereScutire: { dataType: "Int32", defaultValue: 0 },
				dataCerereScutire: { dataType: "DateTime" },
				dataScutire: { dataType: "DateTime" },
				dataStopScutire: { dataType: "DateTime" },
				explicatieScutire: { maxLength: 100, defaultValue: "" },
				dataRevizieScutire: { dataType: "DateTime" },
				idTipScutire: { dataType: "Int32", defaultValue: 0 },
				anDeclaratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 4000, defaultValue: "" },
				idModificare: { dataType: "Int32", defaultValue: 0 },
				matricolaVeche: { maxLength: 10, defaultValue: "" },
				operatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				leasing: { dataType: "Boolean", isNullable: false },
				chirie: { dataType: "Boolean", isNullable: false },
				anOperatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				international: { dataType: "Boolean" },
				numarMatricolDeclaratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				mentiuni: { maxLength: 250, defaultValue: "" },
				idActStop: { dataType: "Int32", defaultValue: 0 },
				numarActStop: { maxLength: 50, defaultValue: "" },
				idActEmis: { dataType: "Int32", defaultValue: 0 },
				emitentAct: { maxLength: 100, defaultValue: "" },
				numarActEmis: { maxLength: 50, defaultValue: "" },
				dataDobandireInitiala: { dataType: "DateTime" },
				electric: { dataType: "Boolean", isNullable: false },
				anulFabricatiei: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataInmatriculare: { dataType: "DateTime" },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idLocalitate: { dataType: "Int32", defaultValue: 0 },
				idScara: { dataType: "Int32", defaultValue: 0 },
				idDeclaratieOnline: { dataType: "Int32", defaultValue: 0 },
				idImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"impotax.AutoLente": {
			shortName: "AutoLente",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idTipAutoLente: { dataType: "Int32", defaultValue: 0 },
				numarInregistrare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStart: { dataType: "DateTime" },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utilizatorAnulare: { maxLength: 50, defaultValue: "" },
				procentProprietate: { dataType: "Decimal", defaultValue: 0 },
				dataDobandire: { dataType: "DateTime" },
				explicatieAnulare: { maxLength: 100, defaultValue: "" },
				explicatieOprire: { maxLength: 100, defaultValue: "" },
				explicatie: { maxLength: 100, defaultValue: "" },
				dataOprireUtilizator: { dataType: "DateTime" },
				anDeclaratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				matricolaVeche: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idMarca: { dataType: "Int32", defaultValue: 0 },
				serieMotor: { maxLength: 15, defaultValue: "" },
				serieSasiu: { maxLength: 50, defaultValue: "" },
				numarInmatriculare: { maxLength: 20, defaultValue: "" },
				leasing: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				procentScutire: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				numarCerereScutire: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataCerereScutire: { dataType: "DateTime" },
				dataScutire: { dataType: "DateTime" },
				dataStopScutire: { dataType: "DateTime" },
				explicatieScutire: { maxLength: 100, defaultValue: "" },
				dataRevizieScutire: { dataType: "DateTime" },
				idTipScutire: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarMatricolDeclaratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				mentiuni: { maxLength: 250, defaultValue: "" },
				idActStop: { dataType: "Int32", defaultValue: 0 },
				numarActStop: { maxLength: 50, defaultValue: "" },
				dataDobandireInitiala: { dataType: "DateTime" },
				idActEmis: { dataType: "Int32", defaultValue: 0 },
				emitentAct: { maxLength: 100, defaultValue: "" },
				numarActEmis: { maxLength: 50, defaultValue: "" },
				idPersoana: { dataType: "Int32", defaultValue: 0 },
				operatie: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"impotax.AutoLenteValoareImpozitare": {
			shortName: "AutoLenteValoareImpozitare",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idTipAutoLente: { dataType: "Int32", defaultValue: 0 },
				valoare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				an: { dataType: "Decimal", defaultValue: 0 },
			}
		},
		"impotax.AutoRegistru": {
			shortName: "AutoRegistru",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				iD: { dataType: "Int32", isPartOfKey: true },
				nrCrt: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDeclaratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataInregistrare: { dataType: "DateTime" },
				tipVanzator: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				vanzator: { maxLength: 200, defaultValue: "" },
				codRolVanzator: { dataType: "Int32", defaultValue: 0 },
				cuiVanzator: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				domiciliuVanzator: { maxLength: 250, defaultValue: "" },
				tipCumparator: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cumparator: { maxLength: 200, defaultValue: "" },
				codRolCumparator: { dataType: "Int32", defaultValue: 0 },
				cuiCumparator: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				domiciliuCumparator: { maxLength: 250, defaultValue: "" },
				identificare: { maxLength: 100, defaultValue: "" },
				dataDobandire: { dataType: "DateTime" },
				certificat: { maxLength: 50, defaultValue: "" },
				numarExemplar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarInregistrare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anInregistrare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataAnulare: { dataType: "DateTime" },
				utiAnulare: { maxLength: 50, defaultValue: "" },
				mentiune: { maxLength: 4000, defaultValue: "" },
			}
		},
		"impotax.AutoRetro": {
			shortName: "AutoRetro",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipAutovehicul: { dataType: "Int32", defaultValue: 0 },
				masa: { dataType: "Decimal", defaultValue: 0 },
				nrIdentificare: { maxLength: 50, defaultValue: "" },
				nrInmatriculare: { maxLength: 20, defaultValue: "" },
				capCil: { dataType: "Int32", defaultValue: 0 },
				capRemorca: { dataType: "Decimal", defaultValue: 0 },
				serieMotor: { maxLength: 15, defaultValue: "" },
				procentProprietate: { dataType: "Decimal", defaultValue: 0 },
				dataStart: { dataType: "DateTime" },
				dataStop: { dataType: "DateTime" },
				dataCreare: { dataType: "DateTime" },
				utiCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utiAnulare: { maxLength: 50, defaultValue: "" },
				marca: { dataType: "Int32", defaultValue: 0 },
				conform: { maxLength: 100, defaultValue: "" },
				idPozOrig: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"impotax.AutoValoareImpozitare": {
			shortName: "AutoValoareImpozitare",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idTipAutovehicul: { dataType: "Int32", defaultValue: 0 },
				masaInferioara: { dataType: "Int32", defaultValue: 0 },
				masaSuperioara: { dataType: "Int32", defaultValue: 0 },
				axaSuspensie: { dataType: "Int32", defaultValue: 0 },
				valoare: { dataType: "Decimal", defaultValue: 0 },
				valoareExterna: { dataType: "Decimal", defaultValue: 0 },
				an: { dataType: "Int32", defaultValue: 0 },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				valoare2010: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
			}
		},
		"impotax.AutoVanzare": {
			shortName: "AutoVanzare",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idUnic: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tipCumparator: { maxLength: 20, defaultValue: "" },
				cnp: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numePersoana: { maxLength: 150, defaultValue: "" },
				prenumePersoana: { maxLength: 150, defaultValue: "" },
				cui: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numeCompanie: { maxLength: 150, defaultValue: "" },
				numeAmbasada: { maxLength: 150, defaultValue: "" },
				numeStrain: { maxLength: 100, defaultValue: "" },
				prenumeStrain: { maxLength: 100, defaultValue: "" },
				taraStrain: { maxLength: 50, defaultValue: "" },
				judet: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				adresa: { maxLength: 240, defaultValue: "" },
				dataCreare: { dataType: "DateTime", isNullable: false },
				utiCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utiAnulare: { maxLength: 50, defaultValue: "" },
				numeCompanieStraina: { maxLength: 150, defaultValue: "" },
				taraCompanieStraina: { maxLength: 50, defaultValue: "" },
			}
		},
		"impotax.Banca": {
			shortName: "Banca",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				adresa: { maxLength: 250, defaultValue: "" },
				telefon: { maxLength: 50, defaultValue: "" },
				localitate: { maxLength: 100, defaultValue: "" },
				tert: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				institutie: { dataType: "Boolean", isNullable: false },
				cui: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"impotax.Cladire": {
			shortName: "Cladire",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarInregistrare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idStrada: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idNumarPostal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBloc: { dataType: "Int32", defaultValue: 0 },
				idScara: { dataType: "Int32", defaultValue: 0 },
				idApartament: { dataType: "Int32", defaultValue: 0 },
				numarActEmis: { maxLength: 50, defaultValue: "" },
				emitentAct: { maxLength: 100, defaultValue: "" },
				cladiriBetonU: { dataType: "Decimal", defaultValue: 0 },
				cladiriBetonD: { dataType: "Decimal", defaultValue: 0 },
				cladiriBetonCI: { dataType: "Int32", defaultValue: 0 },
				cladiriBetonAn: { dataType: "Int32", defaultValue: 0 },
				cladiriLemnU: { dataType: "Decimal", defaultValue: 0 },
				cladiriLemnD: { dataType: "Decimal", defaultValue: 0 },
				cladiriLemnCI: { dataType: "Int32", defaultValue: 0 },
				cladiriLemnAn: { dataType: "Int32", defaultValue: 0 },
				anexeBetonU: { dataType: "Decimal", defaultValue: 0 },
				anexeBetonD: { dataType: "Decimal", defaultValue: 0 },
				anexeBetonCI: { dataType: "Int32", defaultValue: 0 },
				anexeBetonAn: { dataType: "Int32", defaultValue: 0 },
				anexeLemnU: { dataType: "Decimal", defaultValue: 0 },
				anexeLemnD: { dataType: "Decimal", defaultValue: 0 },
				anexeLemnCI: { dataType: "Int32", defaultValue: 0 },
				anexeLemnAn: { dataType: "Int32", defaultValue: 0 },
				locuintaSubsolU: { dataType: "Decimal", defaultValue: 0 },
				locuintaSubsolD: { dataType: "Decimal", defaultValue: 0 },
				locuintaSubsolCI: { dataType: "Int32", defaultValue: 0 },
				locuintaSubsolAn: { dataType: "Int32", defaultValue: 0 },
				locuintaSubsolPozitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				spatiiSubsolU: { dataType: "Decimal", defaultValue: 0 },
				spatiiSubsolD: { dataType: "Decimal", defaultValue: 0 },
				spatiiSubsolCI: { dataType: "Int32", defaultValue: 0 },
				spatiiSubsolAn: { dataType: "Int32", defaultValue: 0 },
				spatiiSubsolPozitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				suprafataConstruitaSol: { dataType: "Decimal", defaultValue: 0 },
				registruAgricolAn1: { dataType: "Int32", defaultValue: 0 },
				registruAgricolAn2: { dataType: "Int32", defaultValue: 0 },
				registruAgricolTip: { maxLength: 10, defaultValue: "" },
				registruAgricolVolum: { maxLength: 10, defaultValue: "" },
				registruAgricolPozitie: { maxLength: 10, defaultValue: "" },
				impozitMajorat: { dataType: "Int32", defaultValue: 0 },
				procentProprietate: { dataType: "Decimal", defaultValue: 0 },
				procentScutire: { dataType: "Decimal", defaultValue: 0 },
				dataScutire: { dataType: "DateTime" },
				dataStopScutire: { dataType: "DateTime" },
				numarCerereScutire: { dataType: "Int32", defaultValue: 0 },
				dataRevizieScutire: { dataType: "DateTime" },
				dataCerereScutire: { dataType: "DateTime" },
				explicatieScutire: { maxLength: 100, defaultValue: "" },
				idTipScutire: { dataType: "Int32", defaultValue: 0 },
				dataDobandire: { dataType: "DateTime" },
				dataStart: { dataType: "DateTime" },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				explicatieAnulare: { maxLength: 100, defaultValue: "" },
				utilizatorAnulare: { maxLength: 50, defaultValue: "" },
				explicatieOprire: { maxLength: 100, defaultValue: "" },
				dataOprireUtilizator: { dataType: "DateTime" },
				anDeclaratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 4000, defaultValue: "" },
				numarCarteFunciara: { maxLength: 20, defaultValue: "" },
				numarTopo: { maxLength: 40, defaultValue: "" },
				idModificare: { dataType: "Int32", defaultValue: 0 },
				matricolaVeche: { maxLength: 10, defaultValue: "" },
				operatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				leasing: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				chirie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				destinatieLocuinta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anOperatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				mostenire: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarMatricolDeclaratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idActStop: { dataType: "Int32", defaultValue: 0 },
				numarActStop: { maxLength: 50, defaultValue: "" },
				dataDobandireInitiala: { dataType: "DateTime" },
				idActEmis: { dataType: "Int32", defaultValue: 0 },
				inDevalmasie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cladireagricol: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valoare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				datareevaluare: { dataType: "DateTime", isNullable: false },
				codunic: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				procentNeingrijit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				specificare: { maxLength: 100, defaultValue: "" },
				valoareContabila: { dataType: "Decimal", defaultValue: 0 },
				chirieIdPersoana: { dataType: "Int32", defaultValue: 0 },
				cotaExplicita: { dataType: "Decimal", defaultValue: 0 },
				suprafataTotala: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idTipCladire: { dataType: "Int32", defaultValue: 0 },
				amortizat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idLocalitate: { dataType: "Int32", defaultValue: 0 },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				adresa: { maxLength: 4000, defaultValue: "" },
			}
		},
		"impotax.CladirePFValoareImpozitare": {
			shortName: "CladirePFValoareImpozitare",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cuInstalatii: { dataType: "Int32", defaultValue: 0 },
				cladiriBeton: { dataType: "Decimal", defaultValue: 0 },
				cladiriLemn: { dataType: "Decimal", defaultValue: 0 },
				anexeBeton: { dataType: "Decimal", defaultValue: 0 },
				anexeLemn: { dataType: "Decimal", defaultValue: 0 },
				an: { dataType: "Int32", defaultValue: 0 },
				uti: { maxLength: 50, defaultValue: "" },
			}
		},
		"impotax.CladirePJValoareImpozitare": {
			shortName: "CladirePJValoareImpozitare",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cotaNereevaluata: { dataType: "Decimal", defaultValue: 0 },
				cotaReevaluata: { dataType: "Decimal", defaultValue: 0 },
				an: { dataType: "Int32", defaultValue: 0 },
				uti: { maxLength: 50, defaultValue: "" },
				cotaNereevaluataPeste5Ani: { dataType: "Decimal", defaultValue: 0 },
				destinatie: { maxLength: 30, defaultValue: "" },
			}
		},
		"impotax.CladireValoareImpozitMajorat": {
			shortName: "CladireValoareImpozitMajorat",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				pozitie: { dataType: "Int32", defaultValue: 0 },
				procent: { dataType: "Int32", defaultValue: 0 },
				an: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"impotax.CladireValoareProcentMansarda": {
			shortName: "CladireValoareProcentMansarda",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				procentLocuintaMansardaSubsol: { dataType: "Int32", defaultValue: 0 },
				procentSpatiuMansardaSubsol: { dataType: "Int32", defaultValue: 0 },
				an: { dataType: "Int32", defaultValue: 0 },
				uti: { maxLength: 10, defaultValue: "" },
			}
		},
		"impotax.CladireValoareRangZona": {
			shortName: "CladireValoareRangZona",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				rang: { maxLength: 1, defaultValue: "" },
				zona: { maxLength: 3, defaultValue: "" },
				valoare: { dataType: "Decimal", defaultValue: 0 },
				an: { dataType: "Int32", defaultValue: 0 },
				uti: { maxLength: 10, defaultValue: "" },
			}
		},
		"impotax.CladireValoareReducereAnConstructie": {
			shortName: "CladireValoareReducereAnConstructie",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				procent: { dataType: "Decimal", defaultValue: 0 },
				anReferintaMin: { dataType: "Int32", defaultValue: 0 },
				anReferintaMax: { dataType: "Int32", defaultValue: 0 },
				an: { dataType: "Int32", defaultValue: 0 },
				uti: { maxLength: 10, defaultValue: "" },
			}
		},
		"impotax.CladireValoareTipCladireJuridica": {
			shortName: "CladireValoareTipCladireJuridica",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cotaNereevaluata: { dataType: "Decimal", defaultValue: 0 },
				an: { dataType: "Int32", defaultValue: 0 },
				uti: { maxLength: 50, defaultValue: "" },
				idTipCladire: { dataType: "Int32", defaultValue: 0 },
				cotaReevaluata: { dataType: "Decimal", defaultValue: 0 },
				cotaNereevaluataPeste5Ani: { dataType: "Decimal", defaultValue: 0 },
				destinatie: { maxLength: 30, defaultValue: "" },
			}
		},
		"impotax.CursValuta": {
			shortName: "CursValuta",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idTipValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				data: { dataType: "DateTime" },
				valoare: { dataType: "Decimal", defaultValue: 0 },
				an: { dataType: "Int32", defaultValue: 0 },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utilizatorAnulare: { maxLength: 50, defaultValue: "" },
			}
		},
		"impotax.Debit": {
			shortName: "Debit",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnic: { maxLength: 50, defaultValue: "" },
				idDeclaratie: { dataType: "Int32", defaultValue: 0 },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 500, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utilizatorAnulare: { maxLength: 50, defaultValue: "" },
				explicatieAnulare: { maxLength: 100, defaultValue: "" },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				an: { dataType: "Int32", defaultValue: 0 },
				valoareImpozabila: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				operatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codAct: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"impotax.DebitDetaliu": {
			shortName: "DebitDetaliu",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				l1: { dataType: "Decimal", defaultValue: 0 },
				l2: { dataType: "Decimal", defaultValue: 0 },
				l3: { dataType: "Decimal", defaultValue: 0 },
				l4: { dataType: "Decimal", defaultValue: 0 },
				l5: { dataType: "Decimal", defaultValue: 0 },
				l6: { dataType: "Decimal", defaultValue: 0 },
				l7: { dataType: "Decimal", defaultValue: 0 },
				l8: { dataType: "Decimal", defaultValue: 0 },
				l9: { dataType: "Decimal", defaultValue: 0 },
				l10: { dataType: "Decimal", defaultValue: 0 },
				l11: { dataType: "Decimal", defaultValue: 0 },
				l12: { dataType: "Decimal", defaultValue: 0 },
				detaliiSuplimentare: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"impotax.DebitScadenta": {
			shortName: "DebitScadenta",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dataScadenta: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"impotax.DeclaratiePeOre": {
			shortName: "DeclaratiePeOre",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProprietate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarOre: { dataType: "Int32", defaultValue: 0 },
				ziua: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tipProprietate: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"impotax.DeclaratieTipOperatie": {
			shortName: "DeclaratieTipOperatie",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
			}
		},
		"impotax.Dobanda": {
			shortName: "Dobanda",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				persoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", defaultValue: 0 },
				idDeclaratie: { maxLength: 50, defaultValue: "" },
				dataInceput: { dataType: "DateTime", isNullable: false },
				dataSfarsit: { dataType: "DateTime" },
				valoare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				zile: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				proprietate: { maxLength: 50, defaultValue: "" },
				explicatieCreare: { maxLength: 100, defaultValue: "" },
				explicatieAnulare: { maxLength: 100, defaultValue: "" },
				lunara: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dobandaLegala: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"impotax.DocumentListat": {
			shortName: "DocumentListat",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cui: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDoc: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataCreare: { dataType: "DateTime" },
				utiCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utiAnulare: { maxLength: 50, defaultValue: "" },
				explicatieAnulare: { maxLength: 200, defaultValue: "" },
				borderouPosta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				starePosta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatiePosta: { maxLength: 200, defaultValue: "" },
				nrDosar: { dataType: "Int32", defaultValue: 0 },
				nrEvidenta: { dataType: "Int32", defaultValue: 0 },
				nrCid: { dataType: "Int32", defaultValue: 0 },
				idVederea: { dataType: "Int32", defaultValue: 0 },
				tipdoc: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tipsom: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tiptitlu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"impotax.DocumentListatDetaliu": {
			shortName: "DocumentListatDetaliu",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentListat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				continut: { maxLength: 200, defaultValue: "" },
				totalRestant: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", defaultValue: 0 },
				debitRestant: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				ramasita: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dobanda: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				penalitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				plati: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idDeclaratie: { dataType: "Int32", defaultValue: 0 },
				idUnic: { maxLength: 50, defaultValue: "" },
			}
		},
		"impotax.Incasare": {
			shortName: "Incasare",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idPersoana: { dataType: "Int32", defaultValue: 0 },
				idVenit: { dataType: "Int32", defaultValue: 0 },
				idUnic: { maxLength: 50, defaultValue: "" },
				idIntrare: { dataType: "Int32", defaultValue: 0 },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataPlata: { dataType: "DateTime" },
				dataExtras: { dataType: "DateTime" },
				numarExtras: { maxLength: 25, defaultValue: "" },
				document: { maxLength: 25, defaultValue: "" },
				an: { dataType: "Int32", defaultValue: 0 },
				debit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				ramasita: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dobanda: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				penalitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 100, defaultValue: "" },
				pOSRrn: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idBanca: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBanca: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIban: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utilizatorAnulare: { maxLength: 50, defaultValue: "" },
				dataSomatie: { dataType: "DateTime" },
				numarSomatie: { maxLength: 50, defaultValue: "" },
				bonitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				operatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursa: { dataType: "Int32", defaultValue: 0 },
				idDeclaratie: { dataType: "Int32", defaultValue: 0 },
				pOSFactura: { dataType: "Int32", defaultValue: 0 },
				codAct: { dataType: "Int32", defaultValue: 0 },
				explicatieSuplimentara: { maxLength: 250, defaultValue: "" },
				idEpay: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				incasareFaraPersoana: { dataType: "Int32", defaultValue: 0 },
				idIncasator: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idLocatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUser: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idDocumentAngajamentLegalGlobal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"impotax.IncasareInsolvabil": {
			shortName: "IncasareInsolvabil",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idPersoana: { dataType: "Int32", defaultValue: 0 },
				idVenit: { dataType: "Int32", defaultValue: 0 },
				idUnic: { maxLength: 50, defaultValue: "" },
				idIntrare: { dataType: "Int32", defaultValue: 0 },
				dataPlata: { dataType: "DateTime" },
				dataExtras: { dataType: "DateTime" },
				document: { maxLength: 25, defaultValue: "" },
				an: { dataType: "Int32", defaultValue: 0 },
				debit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				ramasita: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dobanda: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				penalitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 100, defaultValue: "" },
				pOSRrn: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idBanca: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utilizatorAnulare: { maxLength: 50, defaultValue: "" },
				dataSomatie: { dataType: "DateTime" },
				numarSomatie: { maxLength: 50, defaultValue: "" },
				bonitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idOperatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursa: { dataType: "Int32", defaultValue: 0 },
				idDeclaratie: { dataType: "Int32", defaultValue: 0 },
				pOSFactura: { dataType: "Int32", defaultValue: 0 },
				incasareFaraPersoana: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"impotax.IncasareTipIntrare": {
			shortName: "IncasareTipIntrare",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
			}
		},
		"impotax.LegaturaPersoanaBanca": {
			shortName: "LegaturaPersoanaBanca",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBanca: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tipPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utilizatorAnulare: { maxLength: 50, defaultValue: "" },
			}
		},
		"impotax.MarcaAuto": {
			shortName: "MarcaAuto",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"impotax.NotaContabilaSablon": {
			shortName: "NotaContabilaSablon",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idJurnal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContCredit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				ziGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				fizJur: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 250, defaultValue: "" },
				idTipOperatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipOperatieGrup: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipOperatieLegatura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				formula: { defaultValue: "" },
			}
		},
		"impotax.Penalitate": {
			shortName: "Penalitate",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				persoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", defaultValue: 0 },
				idDeclaratie: { maxLength: 50, defaultValue: "" },
				dataInceput: { dataType: "DateTime", isNullable: false },
				dataSfarsit: { dataType: "DateTime" },
				valoare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				zile: { dataType: "Int32", defaultValue: 0 },
				proprietate: { maxLength: 50, defaultValue: "" },
				explicatieCreare: { maxLength: 100, defaultValue: "" },
				explicatieAnulare: { maxLength: 100, defaultValue: "" },
				lunara: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"impotax.Procentmajorari2013": {
			shortName: "Procentmajorari2013",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valoare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
			}
		},
		"impotax.Ramasita": {
			shortName: "Ramasita",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idPersoana: { dataType: "Int32", defaultValue: 0 },
				idVenit: { dataType: "Int32", defaultValue: 0 },
				idUnic: { maxLength: 50, defaultValue: "" },
				data: { dataType: "DateTime", isNullable: false },
				an: { dataType: "Int32", defaultValue: 0 },
				debit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dobanda: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				penalitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 150, defaultValue: "" },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utilizatorAnulare: { maxLength: 50, defaultValue: "" },
				explicatieAnulare: { maxLength: 100, defaultValue: "" },
				dataSomatie: { dataType: "DateTime" },
				numarSomatie: { maxLength: 50, defaultValue: "" },
				dataScadenta: { dataType: "DateTime", isNullable: false },
				anRamasita: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				compensare: { dataType: "Int32", defaultValue: 0 },
				operatie: { dataType: "Int32", defaultValue: 0 },
				codAct: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDeclaratie: { dataType: "Int32", defaultValue: 0 },
				pereche: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				bazaSct: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				stingereAlteCai: { dataType: "Boolean", isNullable: false },
				idImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codPozImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"impotax.RamasitaInsolvabil": {
			shortName: "RamasitaInsolvabil",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idPersoana: { dataType: "Int32", defaultValue: 0 },
				idVenit: { dataType: "Int32", defaultValue: 0 },
				idUnic: { maxLength: 50, defaultValue: "" },
				data: { dataType: "DateTime", isNullable: false },
				an: { dataType: "Int32", defaultValue: 0 },
				debit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dobanda: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				penalitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 150, defaultValue: "" },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utilizatorAnulare: { maxLength: 50, defaultValue: "" },
				explicatieAnulare: { maxLength: 100, defaultValue: "" },
				dataSomatie: { dataType: "DateTime" },
				numarSomatie: { maxLength: 50, defaultValue: "" },
				dataScadenta: { dataType: "DateTime", isNullable: false },
				anRamasita: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				compensare: { dataType: "Int32", defaultValue: 0 },
				operatie: { dataType: "Int32", defaultValue: 0 },
				codAct: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDeclaratie: { dataType: "Int32", defaultValue: 0 },
				pereche: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				bazaSct: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
			}
		},
		"impotax.RamasitaSimulat": {
			shortName: "RamasitaSimulat",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idPersoana: { dataType: "Int32", defaultValue: 0 },
				idVenit: { dataType: "Int32", defaultValue: 0 },
				idUnic: { maxLength: 50, defaultValue: "" },
				data: { dataType: "DateTime", isNullable: false },
				an: { dataType: "Int32", defaultValue: 0 },
				debit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dobanda: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				penalitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 150, defaultValue: "" },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utilizatorAnulare: { maxLength: 50, defaultValue: "" },
				explicatieAnulare: { maxLength: 100, defaultValue: "" },
				dataSomatie: { dataType: "DateTime" },
				numarSomatie: { maxLength: 50, defaultValue: "" },
				dataScadenta: { dataType: "DateTime", isNullable: false },
				anRamasita: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				compensare: { dataType: "Int32", defaultValue: 0 },
				operatie: { dataType: "Int32", defaultValue: 0 },
				codAct: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDeclaratie: { dataType: "Int32", defaultValue: 0 },
				pereche: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				bazaSct: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
			}
		},
		"impotax.SarbatoareLegala": {
			shortName: "SarbatoareLegala",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				data: { dataType: "DateTime" },
			}
		},
		"impotax.SpectacolValoare": {
			shortName: "SpectacolValoare",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				valoare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				incinta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				uti: { maxLength: 50, defaultValue: "" },
			}
		},
		"impotax.TaxaFirmaAfisaj": {
			shortName: "TaxaFirmaAfisaj",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipAutovehicul: { dataType: "Int32", defaultValue: 0 },
				idVenit: { dataType: "Int32", defaultValue: 0 },
				numarMatricolDeclaratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataDobandire: { dataType: "DateTime" },
				dataDobandireInitiala: { dataType: "DateTime" },
				dataStart: { dataType: "DateTime" },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utilizatorAnulare: { maxLength: 50, defaultValue: "" },
				explicatieAnulare: { maxLength: 100, defaultValue: "" },
				explicatieOprire: { maxLength: 100, defaultValue: "" },
				suprafata: { dataType: "Decimal", defaultValue: 0 },
				luminoasa: { dataType: "Boolean" },
				matricola: { dataType: "Int32", defaultValue: 0 },
				explicatie: { maxLength: 100, defaultValue: "" },
				idActEmis: { dataType: "Int32", defaultValue: 0 },
				numarActEmis: { dataType: "Int32", defaultValue: 0 },
				emitentAct: { maxLength: 100, defaultValue: "" },
				idActStop: { dataType: "Int32", defaultValue: 0 },
				numarActStop: { dataType: "Int32", defaultValue: 0 },
				idLocalitate: { dataType: "Int32", defaultValue: 0 },
				idStrada: { dataType: "Int32", defaultValue: 0 },
				idNumarPostal: { dataType: "Int32", defaultValue: 0 },
				idBloc: { dataType: "Int32", defaultValue: 0 },
				idApartament: { dataType: "Int32", defaultValue: 0 },
				idScara: { dataType: "Int32", defaultValue: 0 },
				procentScutire: { dataType: "Decimal", defaultValue: 0 },
				numarCerereScutire: { dataType: "Int32", defaultValue: 0 },
				dataCerereScutire: { dataType: "DateTime" },
				dataScutire: { dataType: "DateTime" },
				dataStopScutire: { dataType: "DateTime" },
				explicatieScutire: { maxLength: 100, defaultValue: "" },
				dataRevizieScutire: { dataType: "DateTime" },
				idTipScutire: { dataType: "Int32", defaultValue: 0 },
				anDeclaratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				operatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				adresa: { maxLength: 4000, defaultValue: "" },
				dataOprireUtilizator: { dataType: "DateTime" },
			}
		},
		"impotax.TaxaFirmaAfisajValoare": {
			shortName: "TaxaFirmaAfisajValoare",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idVenit: { dataType: "Int32", defaultValue: 0 },
				valoare: { dataType: "Decimal", defaultValue: 0 },
				luminoasa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"impotax.Teren": {
			shortName: "Teren",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idVenit: { dataType: "Int32", defaultValue: 0 },
				numarInregistrare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idStrada: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idNumarPostal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBloc: { dataType: "Int32", defaultValue: 0 },
				idApartament: { dataType: "Int32", defaultValue: 0 },
				localizare: { maxLength: 1, defaultValue: "" },
				curteSuprafata: { dataType: "Decimal", defaultValue: 0 },
				curteOcupat: { dataType: "Decimal", defaultValue: 0 },
				curteSubCategorie: { maxLength: 4, defaultValue: "" },
				arabilSuprafata: { dataType: "Decimal", defaultValue: 0 },
				arabilSubCategorie: { maxLength: 4, defaultValue: "" },
				pasuneSuprafata: { dataType: "Decimal", defaultValue: 0 },
				pasuneSubCategorie: { maxLength: 4, defaultValue: "" },
				faneataSuprafata: { dataType: "Decimal", defaultValue: 0 },
				faneataSubCategorie: { maxLength: 4, defaultValue: "" },
				vieSuprafata: { dataType: "Decimal", defaultValue: 0 },
				vieSubCategorie: { maxLength: 4, defaultValue: "" },
				vieTanaraSuprafata: { dataType: "Decimal", defaultValue: 0 },
				vieTanaraSubCategorie: { maxLength: 4, defaultValue: "" },
				livadaSuprafata: { dataType: "Decimal", defaultValue: 0 },
				livadaSubCategorie: { maxLength: 4, defaultValue: "" },
				livadaTanaraSuprafata: { dataType: "Decimal", defaultValue: 0 },
				livadaTanaraSubCategorie: { maxLength: 4, defaultValue: "" },
				padureSuprafata: { dataType: "Decimal", defaultValue: 0 },
				padureSubCategorie: { maxLength: 4, defaultValue: "" },
				padureTanaraSuprafata: { dataType: "Decimal", defaultValue: 0 },
				padureTanaraSubCategorie: { maxLength: 4, defaultValue: "" },
				terenApaSuprafata: { dataType: "Decimal", defaultValue: 0 },
				terenApaSubCategorie: { maxLength: 4, defaultValue: "" },
				terenPiscicolSuprafata: { dataType: "Decimal", defaultValue: 0 },
				terenPiscicolSubCategorie: { maxLength: 4, defaultValue: "" },
				drumSuprafata: { dataType: "Decimal", defaultValue: 0 },
				drumSubCategorie: { maxLength: 4, defaultValue: "" },
				neproductivSuprafata: { dataType: "Decimal", defaultValue: 0 },
				neproductivSubCategorie: { maxLength: 4, defaultValue: "" },
				totalSuprafata: { dataType: "Decimal", defaultValue: 0 },
				registruAgricolAn1: { dataType: "Int32", defaultValue: 0 },
				registruAgricolAn2: { dataType: "Int32", defaultValue: 0 },
				registruAgricolTip: { maxLength: 10, defaultValue: "" },
				registruAgricolVolum: { maxLength: 10, defaultValue: "" },
				registruAgricolPozitie: { maxLength: 10, defaultValue: "" },
				procentProprietate: { dataType: "Decimal", defaultValue: 0 },
				procentScutire: { dataType: "Decimal", defaultValue: 0 },
				dataScutire: { dataType: "DateTime" },
				numarCerereScutire: { dataType: "Int32", defaultValue: 0 },
				dataCerereScutire: { dataType: "DateTime" },
				dataRevizieScutire: { dataType: "DateTime" },
				dataStopScutire: { dataType: "DateTime" },
				explicatieScutire: { maxLength: 100, defaultValue: "" },
				idTipScutire: { dataType: "Int32", defaultValue: 0 },
				dataDobandire: { dataType: "DateTime" },
				dataStart: { dataType: "DateTime" },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				explicatieAnulare: { maxLength: 100, defaultValue: "" },
				utilizatorAnulare: { maxLength: 50, defaultValue: "" },
				explicatieOprire: { maxLength: 100, defaultValue: "" },
				dataOprireUtilizator: { dataType: "DateTime" },
				anDeclaratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTarla: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idParcela: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 4000, defaultValue: "" },
				numarCarteFunciara: { maxLength: 30, defaultValue: "" },
				numarTopo: { maxLength: 50, defaultValue: "" },
				idModificare: { dataType: "Int32", defaultValue: 0 },
				matricolaVeche: { maxLength: 10, defaultValue: "" },
				operatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				leasing: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				chirie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anOperatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				calculTerenCurte: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarMatricolDeclaratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idActstop: { dataType: "Int32", defaultValue: 0 },
				numarActStop: { maxLength: 50, defaultValue: "" },
				dataDobandireInitiala: { dataType: "DateTime" },
				idActemis: { dataType: "Int32", defaultValue: 0 },
				emitentAct: { maxLength: 100, defaultValue: "" },
				numarActEmis: { maxLength: 50, defaultValue: "" },
				numarCadastru: { maxLength: 100, defaultValue: "" },
				inDevalmasie: { dataType: "Boolean", isNullable: false },
				procentNeingrijit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idScara: { dataType: "Int32", defaultValue: 0 },
				idLocalitate: { dataType: "Int32", defaultValue: 0 },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				adresa: { maxLength: 4000, defaultValue: "" },
			}
		},
		"impotax.TerenCategorie": {
			shortName: "TerenCategorie",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
			}
		},
		"impotax.TerenExtravilanValoare": {
			shortName: "TerenExtravilanValoare",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				categorie: { dataType: "Int32", defaultValue: 0 },
				zona: { maxLength: 3, defaultValue: "" },
				valoare: { dataType: "Decimal", defaultValue: 0 },
				an: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"impotax.TerenIntravilanAgricolValoare": {
			shortName: "TerenIntravilanAgricolValoare",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				categorie: { dataType: "Int32", defaultValue: 0 },
				zona: { maxLength: 3, defaultValue: "" },
				valoare: { dataType: "Decimal", defaultValue: 0 },
				an: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"impotax.TerenIntravilanCurteValoare": {
			shortName: "TerenIntravilanCurteValoare",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				zona: { maxLength: 3, defaultValue: "" },
				rang: { maxLength: 1, defaultValue: "" },
				valoare: { dataType: "Decimal", defaultValue: 0 },
				an: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"impotax.TerenSubCategorie": {
			shortName: "TerenSubCategorie",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idTerenCategorie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 35, defaultValue: "" },
				simbol: { maxLength: 4, defaultValue: "" },
			}
		},
		"impotax.TerenValoareCorectieRang": {
			shortName: "TerenValoareCorectieRang",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				rang: { maxLength: 1, defaultValue: "" },
				valoare: { dataType: "Decimal", defaultValue: 0 },
				an: { dataType: "Int32", defaultValue: 0 },
				uti: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"impotax.TipAlteDebite": {
			shortName: "TipAlteDebite",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idVenit: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 100, defaultValue: "" },
			}
		},
		"impotax.TipAutoLente": {
			shortName: "TipAutoLente",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 75, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"impotax.TipAutovehicul": {
			shortName: "TipAutovehicul",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 75, defaultValue: "" },
				dataCreare: { dataType: "DateTime", isNullable: false },
				utiCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utiAnulare: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"impotax.TipAutovehiculRetro": {
			shortName: "TipAutovehiculRetro",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 75, defaultValue: "" },
				dataCreare: { dataType: "DateTime", isNullable: false },
				utiCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utiAnulare: { maxLength: 50, defaultValue: "" },
			}
		},
		"impotax.TipCertificatFiscal": {
			shortName: "TipCertificatFiscal",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				pentruVanzare: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"impotax.TipCladire": {
			shortName: "TipCladire",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"impotax.TipDocumentDeclaratie": {
			shortName: "TipDocumentDeclaratie",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"impotax.TipEmitent": {
			shortName: "TipEmitent",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"impotax.TipOperatie": {
			shortName: "TipOperatie",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				dataCreare: { dataType: "DateTime" },
				utiCreare: { maxLength: 50, defaultValue: "" },
				idOperatie: { dataType: "Int32", defaultValue: 0 },
				explicatie: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utiAnulare: { maxLength: 50, defaultValue: "" },
				impotaxPosta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"impotax.TipOperatieGrup": {
			shortName: "TipOperatieGrup",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"impotax.TipOperatieLegatura": {
			shortName: "TipOperatieLegatura",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idTipOperatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipOperatieGrup: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"impotax.TipScutire": {
			shortName: "TipScutire",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				valabil: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"impotax.TipStarePosta": {
			shortName: "TipStarePosta",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"impotax.TipSursa": {
			shortName: "TipSursa",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				idOp: { dataType: "Int32", defaultValue: 0 },
				dataCreare: { dataType: "DateTime" },
				utiCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utiAnulare: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"impotax.TipValuta": {
			shortName: "TipValuta",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
			}
		},
		"impotax.ValoareCoeficientMajorareConsiliuLocal": {
			shortName: "ValoareCoeficientMajorareConsiliuLocal",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cladirePF: { dataType: "Decimal", defaultValue: 0 },
				autoPF: { dataType: "Decimal", defaultValue: 0 },
				terenIntravilanPF: { dataType: "Decimal", defaultValue: 0 },
				terenExtravilanPF: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cladirePJ: { dataType: "Decimal", defaultValue: 0 },
				autoPJ: { dataType: "Decimal", defaultValue: 0 },
				terenIntravilanPJ: { dataType: "Decimal", defaultValue: 0 },
				terenExtravilanPJ: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", defaultValue: 0 },
				autoPJ12t: { dataType: "Decimal", defaultValue: 0 },
				autoPF12t: { dataType: "Decimal", defaultValue: 0 },
			}
		},
		"impotax.ValoareDataScadenta": {
			shortName: "ValoareDataScadenta",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				ordine: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataScadenta: { dataType: "DateTime", isNullable: false },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"impotax.ValoareDebitCheltuialaExecutareSilita": {
			shortName: "ValoareDebitCheltuialaExecutareSilita",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				valoare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"impotax.ValoareDebitPoprire": {
			shortName: "ValoareDebitPoprire",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				valoare: { dataType: "Decimal", defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"impotax.ValoareTaxaLunara": {
			shortName: "ValoareTaxaLunara",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				valoare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				luna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				zona: { maxLength: 5, defaultValue: "" },
			}
		},
		"impotax.Venit": {
			shortName: "Venit",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				cuDobanda: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cuDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cuFormula: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				procentBonificatieFizice: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				procentBonificatieJuridice: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cumulatFizice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cumulatJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cumulvenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				contTrezorerieFizice: { maxLength: 50, defaultValue: "" },
				contTrezorerieJuridice: { maxLength: 50, defaultValue: "" },
				contDFiz: { maxLength: 50, defaultValue: "" },
				contDJur: { maxLength: 50, defaultValue: "" },
				contCFiz: { maxLength: 50, defaultValue: "" },
				contCJur: { maxLength: 50, defaultValue: "" },
				grupFiz: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				grupJur: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				d1: { maxLength: 50, defaultValue: "" },
				v1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				d2: { maxLength: 50, defaultValue: "" },
				v2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				d3: { maxLength: 50, defaultValue: "" },
				v3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				d4: { maxLength: 50, defaultValue: "" },
				v4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				d5: { maxLength: 50, defaultValue: "" },
				v5: { maxLength: 3, defaultValue: "" },
				d6: { maxLength: 50, defaultValue: "" },
				v6: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				d7: { maxLength: 50, defaultValue: "" },
				v7: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				d8: { maxLength: 50, defaultValue: "" },
				v8: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				d9: { maxLength: 50, defaultValue: "" },
				v9: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				uM: { maxLength: 5, defaultValue: "" },
				formula1: { maxLength: 254, defaultValue: "" },
				formula2: { maxLength: 255, defaultValue: "" },
				modDebitare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				grupDebitare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cuTVA: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tipScadenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				pornireOprireCalcul: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				variabilaModificataOperatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				variabilaModificata: { maxLength: 15, defaultValue: "" },
				formulaAnAnteriorDiferentaIndexare: { maxLength: 100, defaultValue: "" },
				formulaAnCurentDiferentaIndexare: { maxLength: 100, defaultValue: "" },
				cuDiferentaIndexare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataSc1: { dataType: "DateTime" },
				taxareInversa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataSc2: { dataType: "DateTime" },
				dataSc3: { dataType: "DateTime" },
				dataSc4: { dataType: "DateTime" },
				dataSc5: { dataType: "DateTime" },
				dataSc6: { dataType: "DateTime" },
				dataSc7: { dataType: "DateTime" },
				dataSc8: { dataType: "DateTime" },
				dataSc9: { dataType: "DateTime" },
				dataSc10: { dataType: "DateTime" },
				dataSc11: { dataType: "DateTime" },
				dataSc12: { dataType: "DateTime" },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idTipValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenitBazaDetaliu: { dataType: "Int32", defaultValue: 0 },
				idDetaliuVenit: { dataType: "Int32", defaultValue: 0 },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utiAnulare: { maxLength: 50, defaultValue: "" },
				valabil: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cuFactura: { dataType: "Int32", defaultValue: 0 },
				tipDebitareAlteDebite: { dataType: "Int32", defaultValue: 0 },
				contIBANFizice: { maxLength: 50, defaultValue: "" },
				contIBANJuridice: { maxLength: 50, defaultValue: "" },
				bancaFiz: { maxLength: 100, defaultValue: "" },
				bancaJur: { maxLength: 100, defaultValue: "" },
				debitareAn: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valabilPosta: { dataType: "Int32", defaultValue: 0 },
				idUnicComun: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tVAProcent: { dataType: "Decimal", defaultValue: 0 },
				expValid: { maxLength: 15, defaultValue: "" },
				expExpir: { maxLength: 15, defaultValue: "" },
				faraDenumireVenitInFacturi: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursDinValoriCalcul: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				denumireVirament1: { maxLength: 25, defaultValue: "" },
				denumireVirament2: { maxLength: 25, defaultValue: "" },
				procentVirament1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				procentVirament2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				bMaj1: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				bMaj2: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tipZona: { dataType: "Int32", defaultValue: 0 },
				categorieVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				debitareExpirate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				facturareSeparata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				perioadaScadenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				perioadaScadentaLuna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				perioadaScadentaZi: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				bifaAnulare: { dataType: "Int32", defaultValue: 0 },
				idCassaFizice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCassaMajorariFizice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCassaJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCassaMajorariJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cif2SiDen2: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cuLimitareDobanda: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				inactiv: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				minimBonificatieFizice: { dataType: "Decimal", defaultValue: 0 },
				minimBonificatieJuridice: { dataType: "Decimal", defaultValue: 0 },
				tVAPosesie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				calculDiferentaCursMoneda: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				calculDiferentaCurs: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenitMfpJuridice: { dataType: "Int32", defaultValue: 0 },
				idVenitMfpFizice: { dataType: "Int32", defaultValue: 0 },
				zileGratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				faraScadentaFacturaZile: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				faraScadentaFactura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cif1SiCif2: { dataType: "Int32", defaultValue: 0 },
				verificareExistentaContracte: { dataType: "Int32", defaultValue: 0 },
				peLocatii: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				pausal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				v10: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				d10: { maxLength: 50, defaultValue: "" },
				numarZecimaleJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarZecimaleFizice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				adresa2: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				v11: { maxLength: 50, defaultValue: "" },
				d11: { maxLength: 50, defaultValue: "" },
				valabilGhiseul: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stingPeScad: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"impotax.VenitDetaliuVariabila": {
			shortName: "VenitDetaliuVariabila",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idVenitDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				denumireVariabila: { maxLength: 100, defaultValue: "" },
				numeCamp: { maxLength: 100, defaultValue: "" },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utilizatorAnulare: { maxLength: 50, defaultValue: "" },
				valoareImplicita: { maxLength: 500, defaultValue: "" },
				prioritate: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"impotax.VenitGrup": {
			shortName: "VenitGrup",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				detaliuGrup: { maxLength: 4000, defaultValue: "" },
				tipGrup: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"impotax.VenitVariabila": {
			shortName: "VenitVariabila",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				numeCamp: { maxLength: 100, defaultValue: "" },
				grupDebitare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"impotax.Versiune": {
			shortName: "Versiune",
			namespace: "impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				version: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stamp: { dataType: "DateTime", isNullable: false },
			}
		},
		"invatamant.GrupaCopil": {
			shortName: "GrupaCopil",
			namespace: "invatamant",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaCopil: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaParinte: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaReprezentantLegal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isProgramPrelungit: { dataType: "Boolean", isNullable: false },
				idValoareDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarDosar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataDebitare: { dataType: "DateTime" },
				necesitaRedebitare: { dataType: "Boolean", isNullable: false },
				isContinuitate: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				dataCreare: { dataType: "DateTime" },
			}
		},
		"invatamant.GrupaCopilAbsente_DeSters": {
			shortName: "GrupaCopilAbsente_DeSters",
			namespace: "invatamant",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idGrupaCopil: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStart: { dataType: "DateTime", isNullable: false },
				dataStop: { dataType: "DateTime", isNullable: false },
			}
		},
		"invatamant.GrupaCopilPrezenta": {
			shortName: "GrupaCopilPrezenta",
			namespace: "invatamant",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idGrupaCopil: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anLuna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z1: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z2: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z3: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z4: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z5: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z6: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z7: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z8: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z9: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z10: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z11: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z12: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z13: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z14: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z15: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z16: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z17: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z18: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z19: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z20: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z21: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z22: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z23: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z24: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z25: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z26: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z27: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z28: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z29: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z30: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z31: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				totalZile: { dataType: "Int32", defaultValue: 0 },
				totalZilePosibil: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tarifPeZiAplicat: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"invatamant.Luna": {
			shortName: "Luna",
			namespace: "invatamant",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anLuna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				lunaInchisa: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"invatamant.MaskZilePrezenta": {
			shortName: "MaskZilePrezenta",
			namespace: "invatamant",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "ID-ul unitatii  {Sigma.persoan" } },
				anLuna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z1: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z2: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z3: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z4: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z5: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z6: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z7: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z8: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z9: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z10: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z11: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z12: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z13: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z14: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z15: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z16: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z17: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z18: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z19: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z20: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z21: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z22: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z23: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z24: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z25: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z26: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z27: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z28: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z29: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z30: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z31: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				totalZile: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"invatamant.Vacanta": {
			shortName: "Vacanta",
			namespace: "invatamant",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				dataStart: { dataType: "DateTime", isNullable: false },
				dataStop: { dataType: "DateTime", isNullable: false },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 250, defaultValue: "" },
			}
		},
		"invatamant.ValoareDebit": {
			shortName: "ValoareDebit",
			namespace: "invatamant",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"invatamant.Versiune": {
			shortName: "Versiune",
			namespace: "invatamant",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				version: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stamp: { dataType: "DateTime", isNullable: false },
			}
		},
		"invatamantistoric.GrupaCopil": {
			shortName: "GrupaCopil",
			namespace: "invatamantistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaCopil: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaParinte: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaReprezentantLegal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isProgramPrelungit: { dataType: "Boolean", isNullable: false },
				idGrupaCopil: { dataType: "Int32" },
				idValoareDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				numarDosar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataDebitare: { dataType: "DateTime" },
				necesitaRedebitare: { dataType: "Boolean", isNullable: false },
				isContinuitate: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				dataCreare: { dataType: "DateTime" },
			}
		},
		"invatamantistoric.ValoareDebit": {
			shortName: "ValoareDebit",
			namespace: "invatamantistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idValoareDebit: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"inventare.Amortizare": {
			shortName: "Amortizare",
			namespace: "inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anLuna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				soldInitial: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareAmortizare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				soldFinal: { dataType: "Decimal", defaultValue: 0 },
				durata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"inventare.AmortizareEstimare": {
			shortName: "AmortizareEstimare",
			namespace: "inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anLuna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valoare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareAmortizata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareRamasa: { dataType: "Decimal", defaultValue: 0 },
				valoareAmortizareLuna: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				durata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				durataAmortizata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				durataRamasa: { dataType: "Int32", defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 200, defaultValue: "" },
			}
		},
		"inventare.DocumentConsumAlimenteSuplimentare": {
			shortName: "DocumentConsumAlimenteSuplimentare",
			namespace: "inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusIntrare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretGestiune: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareGestiune: { dataType: "Decimal", defaultValue: 0 },
				dataExpirare: { dataType: "DateTime" },
				lot: { maxLength: 50, defaultValue: "" },
				idCategorieBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarMeniuriServit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarMeniuriPreparat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarMeniuriEstimat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"inventare.DocumentPropunereCasare": {
			shortName: "DocumentPropunereCasare",
			namespace: "inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idComisiaInventariere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"inventare.DocumentPropunereCasareProdus": {
			shortName: "DocumentPropunereCasareProdus",
			namespace: "inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretGestiune: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareGestiune: { dataType: "Decimal", defaultValue: 0 },
				cantitateIesita: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				disponibil: { dataType: "Decimal", defaultValue: 0 },
				motivCasare: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"inventare.DocumentReevaluarePropunere": {
			shortName: "DocumentReevaluarePropunere",
			namespace: "inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusInfluentaValoare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusInfluentaValoareGrup: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valoareIndicePretConsum: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareActualizareIndicePretConsum: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareInfluentaComisie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareActualizare: { dataType: "Decimal", defaultValue: 0 },
				explicatie: { maxLength: 300, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"inventare.Produs": {
			shortName: "Produs",
			namespace: "inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cod: { maxLength: 50, defaultValue: "" },
				numarInventar: { maxLength: 50, defaultValue: "" },
				numarInventarNumeric: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				idProdusTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusDetaliuTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusClasificare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusCategorie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusModOrganizare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusTipFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idAliment: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitateAmbalaj: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isConsumFractionat: { dataType: "Boolean", isNullable: false },
				idUnitateMasura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataEvidenta: { dataType: "DateTime" },
				dataPIF: { dataType: "DateTime" },
				dataIncepereAmortizare: { dataType: "DateTime" },
				durataUtilizare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				domeniuApartinator: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", defaultValue: 0 },
				caracteristici: { maxLength: 500, defaultValue: "" },
				idCPV: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareIntrareActualizata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareIntrareActualizataLei: { dataType: "Decimal", defaultValue: 0 },
				valoareIntrareAmortizata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareIntrareAmortizataLei: { dataType: "Decimal", defaultValue: 0 },
				valoareIntrareRamasa: { dataType: "Decimal", defaultValue: 0 },
				valoareIntrareRamasaLei: { dataType: "Decimal", defaultValue: 0 },
				soldInitialData: { dataType: "DateTime" },
				calculEstimareDataPIF: { dataType: "Boolean", isNullable: false },
				faraEstimareAmortizare: { dataType: "Boolean", isNullable: false },
				faraReevaluare: { dataType: "Boolean", isNullable: false },
				faraAmortizare: { dataType: "Boolean", isNullable: false },
				inCursDeExecutie: { dataType: "Boolean", isNullable: false },
				necorporal: { dataType: "Boolean", isNullable: false },
				mijlocFixNaturaObiectInventar: { dataType: "Boolean", isNullable: false },
				inAfaraBilantului: { dataType: "Boolean", isNullable: false },
				importDataJson: { defaultValue: "" },
				importDataTip: { maxLength: 100, defaultValue: "" },
				importDataId: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valoareIesire: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareIesireLei: { dataType: "Decimal", defaultValue: 0 },
				isAmortizabil: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numeDocumentProvenienta: { maxLength: 100, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				lenCod: { dataType: "Int32", defaultValue: 0 },
				lenNume: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"inventare.ProdusBalanta": {
			shortName: "ProdusBalanta",
			namespace: "inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentMiscare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentInfluentaValoare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaPrimire: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaPredare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaPrimitor: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaPredator: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusInfluentaValoare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusIntrare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusIesire: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valoareProdus: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareInfluenta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareInfluentaReevaluare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareSoldCont105: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				codMiscare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isInfluentaValoare: { dataType: "Boolean", isNullable: false },
				isInfluentaValoareReevaluare: { dataType: "Boolean", isNullable: false },
				isIntrare: { dataType: "Boolean", isNullable: false },
				isTransfer: { dataType: "Boolean", isNullable: false },
				isIesire: { dataType: "Boolean", isNullable: false },
				explicatie: { maxLength: 200, defaultValue: "" },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"inventare.ProdusCaracteristici": {
			shortName: "ProdusCaracteristici",
			namespace: "inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				caracteristici: { maxLength: 2000, defaultValue: "" },
				numarTopo: { maxLength: 50, defaultValue: "" },
				numarCarteFunciara: { maxLength: 50, defaultValue: "" },
				numarCadastral: { maxLength: 50, defaultValue: "" },
				suprafata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				hotarareConsiliuLocal: { maxLength: 50, defaultValue: "" },
				hotarareGuvern: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"inventare.ProdusCategorie": {
			shortName: "ProdusCategorie",
			namespace: "inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 30, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				idCPV: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"inventare.ProdusIesire": {
			shortName: "ProdusIesire",
			namespace: "inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusIntrare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 100, defaultValue: "" },
				codIntrareIesire: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentPropunereCasareProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentFurnizorClientDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				importDataJson: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"inventare.ProdusInfluentaValoare": {
			shortName: "ProdusInfluentaValoare",
			namespace: "inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProdus: { dataType: "Int32", defaultValue: 0 },
				idProdusInfluentaValoareParent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusInfluentaValoareGrup: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentReevaluare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentNotaContabila: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentFurnizorClientDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isReevaluareManuala: { dataType: "Boolean", isNullable: false },
				dataAplicare: { dataType: "DateTime" },
				explicatie: { maxLength: 250, defaultValue: "" },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareLei: { dataType: "Decimal", defaultValue: 0 },
				valoareAmortizata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareAmortizataLei: { dataType: "Decimal", defaultValue: 0 },
				valoareRamasa: { dataType: "Decimal", defaultValue: 0 },
				valoareRamasaLei: { dataType: "Decimal", defaultValue: 0 },
				coeficientActualizare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dataReferintaCoeficientActualizare: { dataType: "DateTime" },
				dataIncepereAmortizare: { dataType: "DateTime" },
				durataAmortizare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isExtracontabil: { dataType: "Boolean", isNullable: false },
				importDataJson: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"inventare.ProdusIntrare": {
			shortName: "ProdusIntrare",
			namespace: "inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusIntrareGrup: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaPrimitor: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretGestiune: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretGestiuneLei: { dataType: "Decimal", defaultValue: 0 },
				valoareIntrareAmortizata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareIntrareAmortizataLei: { dataType: "Decimal", defaultValue: 0 },
				valoareIntrareRamasa: { dataType: "Decimal", defaultValue: 0 },
				valoareIntrareRamasaLei: { dataType: "Decimal", defaultValue: 0 },
				valoareGestiune: { dataType: "Decimal", defaultValue: 0 },
				valoareGestiuneLei: { dataType: "Decimal", defaultValue: 0 },
				adaosComercialProcent: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				adaosComercialSuma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				adaosComercialSumaLei: { dataType: "Decimal", defaultValue: 0 },
				pretUnitarVanzare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarVanzareLei: { dataType: "Decimal", defaultValue: 0 },
				idDocumentFurnizorClientDetaliuTipVanzare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tvaVanzare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvaVanzareLei: { dataType: "Decimal", defaultValue: 0 },
				pretVanzare: { dataType: "Decimal", defaultValue: 0 },
				pretVanzareLei: { dataType: "Decimal", defaultValue: 0 },
				valoareUnitaraVanzare: { dataType: "Decimal", defaultValue: 0 },
				valoareUnitaraVanzareLei: { dataType: "Decimal", defaultValue: 0 },
				valoareTvaVanzare: { dataType: "Decimal", defaultValue: 0 },
				valoareTvaVanzareLei: { dataType: "Decimal", defaultValue: 0 },
				valoareVanzare: { dataType: "Decimal", defaultValue: 0 },
				valoareVanzareLei: { dataType: "Decimal", defaultValue: 0 },
				idDocumentFurnizorClientDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataExpirare: { dataType: "DateTime" },
				lot: { maxLength: 50, defaultValue: "" },
				cantitateIesitaAn: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codIntrareIesire: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				importDataJson: { defaultValue: "" },
				importDataTip: { maxLength: 100, defaultValue: "" },
				importDataId: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idPersoanaConsum: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaTransfer: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codModDistribuireClasificatieMultipla: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"inventare.ProdusModOrganizare": {
			shortName: "ProdusModOrganizare",
			namespace: "inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 30, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				importDataJson: { defaultValue: "" },
				importDataTip: { maxLength: 100, defaultValue: "" },
				importDataId: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"inventare.ProdusTipFinantare": {
			shortName: "ProdusTipFinantare",
			namespace: "inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 30, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				importDataJson: { defaultValue: "" },
				importDataTip: { maxLength: 100, defaultValue: "" },
				importDataId: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"inventare.Versiune": {
			shortName: "Versiune",
			namespace: "inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				version: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stamp: { dataType: "DateTime", isNullable: false },
			}
		},
		"inventareistoric.Produs": {
			shortName: "Produs",
			namespace: "inventareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cod: { maxLength: 50, defaultValue: "" },
				numarInventar: { maxLength: 50, defaultValue: "" },
				numarInventarNumeric: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				idProdusTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusDetaliuTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusClasificare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusCategorie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusModOrganizare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusTipFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idAliment: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitateAmbalaj: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isConsumFractionat: { dataType: "Boolean", isNullable: false },
				idUnitateMasura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataEvidenta: { dataType: "DateTime" },
				dataPIF: { dataType: "DateTime" },
				dataIncepereAmortizare: { dataType: "DateTime" },
				durataUtilizare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				domeniuApartinator: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdus: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				idClasificatieEconomica: { dataType: "Int32", defaultValue: 0 },
				caracteristici: { maxLength: 500, defaultValue: "" },
				idCPV: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareIntrareActualizata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareIntrareActualizataLei: { dataType: "Decimal", defaultValue: 0 },
				valoareIntrareAmortizata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareIntrareAmortizataLei: { dataType: "Decimal", defaultValue: 0 },
				valoareIntrareRamasa: { dataType: "Decimal", defaultValue: 0 },
				valoareIntrareRamasaLei: { dataType: "Decimal", defaultValue: 0 },
				soldInitialData: { dataType: "DateTime" },
				calculEstimareDataPIF: { dataType: "Boolean", isNullable: false },
				faraEstimareAmortizare: { dataType: "Boolean", isNullable: false },
				faraReevaluare: { dataType: "Boolean", isNullable: false },
				faraAmortizare: { dataType: "Boolean", isNullable: false },
				inCursDeExecutie: { dataType: "Boolean", isNullable: false },
				necorporal: { dataType: "Boolean", isNullable: false },
				mijlocFixNaturaObiectInventar: { dataType: "Boolean", isNullable: false },
				inAfaraBilantului: { dataType: "Boolean", isNullable: false },
				importDataJson: { defaultValue: "" },
				importDataTip: { maxLength: 100, defaultValue: "" },
				importDataId: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valoareIesire: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareIesireLei: { dataType: "Decimal", defaultValue: 0 },
				isAmortizabil: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numeDocumentProvenienta: { maxLength: 100, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				lenCod: { dataType: "Int32", defaultValue: 0 },
				lenNume: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"inventareistoric.ProdusCaracteristici": {
			shortName: "ProdusCaracteristici",
			namespace: "inventareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				caracteristici: { maxLength: 2000, defaultValue: "" },
				numarTopo: { maxLength: 50, defaultValue: "" },
				numarCarteFunciara: { maxLength: 50, defaultValue: "" },
				numarCadastral: { maxLength: 50, defaultValue: "" },
				suprafata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				hotarareConsiliuLocal: { maxLength: 50, defaultValue: "" },
				hotarareGuvern: { maxLength: 50, defaultValue: "" },
				idProdusCaracteristici: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"investitii.Obiectiv": {
			shortName: "Obiectiv",
			namespace: "investitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isNecorporal: { dataType: "Boolean", isNullable: false },
				dataStart: { dataType: "DateTime" },
				dataStopEstimata: { dataType: "DateTime" },
				idObiectivParinte: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valoareFacturataInitial: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoarePlatitaInitial: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				soldInitial: { dataType: "Decimal", defaultValue: 0 },
				dataFinalizareExecutie: { dataType: "DateTime" },
				isReceptionat: { dataType: "Boolean", isNullable: false },
				idObiectivSubObiectivEcubInvest: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idObiectivEcubInvest: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idIRIDEcubInvest: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				codObiectivSubObiectivEcubInvest: { maxLength: 50, defaultValue: "" },
				codAnaliticContabilitate: { maxLength: 50, defaultValue: "" },
			}
		},
		"investitii.Versiune": {
			shortName: "Versiune",
			namespace: "investitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				version: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stamp: { dataType: "DateTime", isNullable: false },
			}
		},
		"investitiiistoric.Obiectiv": {
			shortName: "Obiectiv",
			namespace: "investitiiistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isNecorporal: { dataType: "Boolean", isNullable: false },
				dataStart: { dataType: "DateTime" },
				dataStopEstimata: { dataType: "DateTime" },
				idObiectivParinte: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idObiectiv: { dataType: "Int32" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				valoareFacturataInitial: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoarePlatitaInitial: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				soldInitial: { dataType: "Decimal", defaultValue: 0 },
				dataFinalizareExecutie: { dataType: "DateTime" },
				isReceptionat: { dataType: "Boolean", isNullable: false },
				idObiectivSubObiectivEcubInvest: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idObiectivEcubInvest: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idIRIDEcubInvest: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				codObiectivSubObiectivEcubInvest: { maxLength: 50, defaultValue: "" },
				codAnaliticContabilitate: { maxLength: 50, defaultValue: "" },
			}
		},
		"log.AuditDDL": {
			shortName: "AuditDDL",
			namespace: "log",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				userName: { maxLength: 250, defaultValue: "" },
				eventDate: { dataType: "DateTime" },
				eventType: { maxLength: 50, defaultValue: "" },
				databaseName: { maxLength: 250, defaultValue: "" },
				schemaName: { maxLength: 250, defaultValue: "" },
				objectName: { maxLength: 250, defaultValue: "" },
				objectType: { maxLength: 25, defaultValue: "" },
				script: { defaultValue: "" },
			}
		},
		"log.ImportCrediteBugetareErrorReport": {
			shortName: "ImportCrediteBugetareErrorReport",
			namespace: "log",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				userName: { maxLength: 50, defaultValue: "" },
				procedureName: { defaultValue: "" },
				params: { defaultValue: "" },
				stamp: { dataType: "DateTime" },
				errorMessage: { defaultValue: "" },
			}
		},
		"log.OprireDeclansatori": {
			shortName: "OprireDeclansatori",
			namespace: "log",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idSesiune: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Identificatorul sesiunii proce" } },
				numeDeclansator: { maxLength: 150, defaultValue: "", custom: { MS_Description: "Numele trigerului care se vrea" } },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"log.ProblemaUnitate": {
			shortName: "ProblemaUnitate",
			namespace: "log",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codSursa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codTipProblema: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codProblema: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				flagEroare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				mesaj: { defaultValue: "" },
				detaliu: { defaultValue: "" },
				idProblemaUnitateParinte: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"log.Query": {
			shortName: "Query",
			namespace: "log",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				requestGuid: { defaultValue: "" },
				executionTime: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				errorNumber: { dataType: "Int32", defaultValue: 0 },
				errorMessage: { defaultValue: "" },
				queryName: { maxLength: 512, defaultValue: "" },
				queryText: { defaultValue: "" },
				userName: { maxLength: 128, defaultValue: "" },
				stamp: { dataType: "DateTime" },
			}
		},
		"log.Report": {
			shortName: "Report",
			namespace: "log",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				userName: { maxLength: 50, defaultValue: "" },
				stamp: { dataType: "DateTime" },
				reportName: { defaultValue: "" },
				reportPath: { defaultValue: "" },
				params: { defaultValue: "" },
				isReport: { dataType: "Boolean" },
			}
		},
		"log.Request": {
			shortName: "Request",
			namespace: "log",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				userName: { maxLength: 128, defaultValue: "" },
				clientDevice: { maxLength: 50, defaultValue: "" },
				clientAddress: { maxLength: 50, defaultValue: "" },
				clientUrl: { defaultValue: "" },
				serverUrl: { defaultValue: "" },
				method: { maxLength: 50, defaultValue: "" },
				params: { defaultValue: "" },
				post: { defaultValue: "" },
				time: { dataType: "DateTime", isNullable: false },
				delay: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				error: { defaultValue: "" },
				guid: { defaultValue: null },
				saveChangesSummary: { defaultValue: "" },
			}
		},
		"log.Resursa": {
			shortName: "Resursa",
			namespace: "log",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				parentId: { maxLength: 892, defaultValue: "" },
				level: { dataType: "Int32", defaultValue: 0 },
				idResursaControl: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 200, defaultValue: "" },
				nivelAcces: { dataType: "Int32", defaultValue: 0 },
				customAcces: { dataType: "Boolean" },
				cod: { maxLength: 50, defaultValue: "" },
				type: { maxLength: 3, defaultValue: "" },
				icon: { maxLength: 50, defaultValue: "" },
				url: { maxLength: 200, defaultValue: "" },
				html: { maxLength: 200, defaultValue: "" },
				options: { defaultValue: "" },
				tooltip: { maxLength: 200, defaultValue: "" },
				searchQuery: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"log.StoredProcedure": {
			shortName: "StoredProcedure",
			namespace: "log",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				userName: { maxLength: 128, defaultValue: "" },
				procedureName: { maxLength: 150, defaultValue: "" },
				parameters: { defaultValue: "" },
				startTime: { dataType: "DateTime" },
				endTime: { dataType: "DateTime" },
				description: { defaultValue: "" },
				percentProgres: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"log.ThirdAppRequestService": {
			shortName: "ThirdAppRequestService",
			namespace: "log",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				url: { maxLength: 250, defaultValue: "", custom: { MS_Description: "Url-ul apelat din Sigma" } },
				request: { defaultValue: "", custom: { MS_Description: "Json trimis ca request nvarcha" } },
				requestEncrypted: { defaultValue: "", custom: { MS_Description: "Completat in cazul in care vom" } },
				response: { defaultValue: "", custom: { MS_Description: "Raspunsul serviciului - json" } },
				responseEncrypted: { defaultValue: "", custom: { MS_Description: "Se va completa in care vom ave" } },
				error: { maxLength: 250, defaultValue: "", custom: { MS_Description: "text eroare" } },
				date: { dataType: "DateTime" },
				requestDescription: { maxLength: 250, defaultValue: "", custom: { MS_Description: "descrierea serviciului apelat " } },
				requestSoftware: { maxLength: 150, defaultValue: "", custom: { MS_Description: "gen Globalpay - aplicatia din " } },
				other: { maxLength: 250, defaultValue: "", custom: { MS_Description: "alte observatii gen institutie" } },
			}
		},
		"log.Versiune": {
			shortName: "Versiune",
			namespace: "log",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				version: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stamp: { dataType: "DateTime", isNullable: false },
			}
		},
		"nomenclatoare.AsociereContBancaTipBuget": {
			shortName: "AsociereContBancaTipBuget",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCont: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"nomenclatoare.CAEN": {
			shortName: "CAEN",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				sectiune: { maxLength: 10, defaultValue: "" },
				diviziune: { maxLength: 10, defaultValue: "" },
				grupa: { maxLength: 10, defaultValue: "" },
				clasa: { maxLength: 8, defaultValue: "" },
				codCaen: { maxLength: 8, defaultValue: "" },
				nume: { maxLength: 200, defaultValue: "" },
				codCaenRev1: { maxLength: 500, defaultValue: "" },
				isic4: { maxLength: 200, defaultValue: "" },
				isLeafNode: { dataType: "Boolean", isNullable: false },
			}
		},
		"nomenclatoare.CategorieVenit": {
			shortName: "CategorieVenit",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "", custom: { MS_Description: "NUmele categoriei de venit (Sa" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.CodProblemaUnitateTip": {
			shortName: "CodProblemaUnitateTip",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codSursa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codTipProblema: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codProblema: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 150, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				isGenerareContab: { dataType: "Boolean", isNullable: false },
			}
		},
		"nomenclatoare.ComisiaTip": {
			shortName: "ComisiaTip",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"nomenclatoare.CotaTva": {
			shortName: "CotaTva",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cota: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"nomenclatoare.CPV": {
			shortName: "CPV",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 20, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"nomenclatoare.CrediteBugetareTip": {
			shortName: "CrediteBugetareTip",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				idContSintetic: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numeJurnal: { maxLength: 50, defaultValue: "" },
			}
		},
		"nomenclatoare.DocumentFurnizorClientDetaliuTip": {
			shortName: "DocumentFurnizorClientDetaliuTip",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				idTipTva: { dataType: "Int32", defaultValue: 0 },
				idCotaTva: { dataType: "Int32", defaultValue: 0 },
				isTvaLaIncasare: { dataType: "Boolean", isNullable: false },
				idDocumentTip: { dataType: "Int32", defaultValue: 0 },
				isIesire: { dataType: "Boolean", isNullable: false },
				idContTVA: { dataType: "Int32", defaultValue: 0 },
				idContTaxareInversa: { dataType: "Int32", defaultValue: 0 },
				idSiefTipTVA: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				eFacturaCodCategorieTva: { maxLength: 10, defaultValue: "" },
				eFacturaCotaTVA: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isDefault: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"nomenclatoare.DocumentOperatiuneTip": {
			shortName: "DocumentOperatiuneTip",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de document de care apar" } },
				idDocumentSubTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 150, defaultValue: "", custom: { MS_Description: "Numele operatiunii" } },
				explicatie: { maxLength: 250, defaultValue: "", custom: { MS_Description: "Explicatie pentru aceasta oper" } },
				categoria: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Intrare / Miscare / Iesire/  S" } },
				isVenit: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Flag pentru venituri" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.DocumentStareTip": {
			shortName: "DocumentStareTip",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "", custom: { MS_Description: "Denumirea rezolutiei" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.DocumentSubTip": {
			shortName: "DocumentSubTip",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de care apartine {Sigma." } },
				nume: { maxLength: 150, defaultValue: "" },
				isIntrare: { dataType: "Boolean", isNullable: false },
				isMiscare: { dataType: "Boolean", isNullable: false },
				isIesire: { dataType: "Boolean", isNullable: false },
				isVizibilEcub: { dataType: "Boolean", isNullable: false },
				isVizibilContracte: { dataType: "Boolean", isNullable: false },
				isVizibilCid: { dataType: "Boolean", isNullable: false },
				isPlata: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.DocumentSursaTemplate": {
			shortName: "DocumentSursaTemplate",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de document: copil {Sigm" } },
				idDocumentSubTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentTipSursa: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de document pentru Docum" } },
				idDocumentSubTipSursa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codGrup: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				ordineAfisare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.DocumentTip": {
			shortName: "DocumentTip",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "", custom: { MS_Description: "Numele tipului (ex. Factura)" } },
				isIntrare: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "1-daca este document de intrar" } },
				isMiscare: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "1 - daca este document intern " } },
				isIesire: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "1-daca este document de iesire" } },
				isVizibilEcub: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "1-daca tipul de document este " } },
				isVizibilCid: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "1-daca tipul de document este " } },
				isPersoanaDocumentAtasat: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "vom tine aici cele care tin de" } },
				isPlata: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.GenerareContabOperatiuneProdusValoareTip": {
			shortName: "GenerareContabOperatiuneProdusValoareTip",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				idDocumentOperatiuneTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusDetaliuTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareContabValoareTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numeScurt: { maxLength: 100, defaultValue: "" },
				ordineAfisare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.GenerareContabValoareTip": {
			shortName: "GenerareContabValoareTip",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.NaturaPlata": {
			shortName: "NaturaPlata",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.NumarTextNivele": {
			shortName: "NumarTextNivele",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cifra: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nr: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				text: { maxLength: 25, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.NumarTextSute": {
			shortName: "NumarTextSute",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cifra: { dataType: "Int32", defaultValue: 0 },
				text: { maxLength: 25, defaultValue: "" },
				biti: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.NumarTextSuteZeciUnitati": {
			shortName: "NumarTextSuteZeciUnitati",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				bitiSute: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				bitiZeci: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				bitiUnitati: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				text: { maxLength: 25, defaultValue: "" },
				textX: { maxLength: 25, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.NumarTextUnitati": {
			shortName: "NumarTextUnitati",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cifra: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				text: { maxLength: 25, defaultValue: "" },
				biti: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.NumarTextZeci": {
			shortName: "NumarTextZeci",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cifra: { dataType: "Int32", defaultValue: 0 },
				text: { maxLength: 25, defaultValue: "" },
				biti: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.PersoanaIerarhieTip": {
			shortName: "PersoanaIerarhieTip",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				numeIerarhieInversa: { maxLength: 150, defaultValue: "" },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				explicatie: { maxLength: 250, defaultValue: "" },
			}
		},
		"nomenclatoare.PlataNumerarTip": {
			shortName: "PlataNumerarTip",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				idContSinteticCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContSinteticCheltuialaCredit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContSinteticPlataNumerarDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContSinteticPlataNumerarCredit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isPlataDirecta: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"nomenclatoare.ProdusDetaliuTip": {
			shortName: "ProdusDetaliuTip",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProdusTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de produs de care aparti" } },
				nume: { maxLength: 100, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.ProdusGrupTip": {
			shortName: "ProdusGrupTip",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.ProdusTip": {
			shortName: "ProdusTip",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProdusGrupTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Grupul din care face parte tip" } },
				nume: { maxLength: 100, defaultValue: "" },
				codClasificareEconomica: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.RetinereSalariiTip": {
			shortName: "RetinereSalariiTip",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				numeScurt: { maxLength: 150, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				isTotalObligatii: { dataType: "Boolean", isNullable: false },
				isFNUASS: { dataType: "Boolean", isNullable: false },
				isRestPlata: { dataType: "Boolean", isNullable: false },
			}
		},
		"nomenclatoare.RetinereSalariiTipPersonalizare": {
			shortName: "RetinereSalariiTipPersonalizare",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idRetinereSalariiTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 150, defaultValue: "" },
			}
		},
		"nomenclatoare.SarbatoareLegala": {
			shortName: "SarbatoareLegala",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				data: { dataType: "DateTime", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.ServiciuTip": {
			shortName: "ServiciuTip",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				idContSinteticCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isImobilizari: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"nomenclatoare.TipInchidere": {
			shortName: "TipInchidere",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 200, defaultValue: "" },
				ordine: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"nomenclatoare.TipModalitateAchizitie": {
			shortName: "TipModalitateAchizitie",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.TipObiectAchizitie": {
			shortName: "TipObiectAchizitie",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.TipProceduraAchizitie": {
			shortName: "TipProceduraAchizitie",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				isAchizitieDirecta: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.TipTva": {
			shortName: "TipTva",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"nomenclatoare.UnitateMasura": {
			shortName: "UnitateMasura",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "", custom: { MS_Description: "Denumirea unitatii de masura (" } },
				cod: { maxLength: 10, defaultValue: "", custom: { MS_Description: "Codificarea unitatii de masura" } },
				numeSingular: { maxLength: 150, defaultValue: "", custom: { MS_Description: "Denumirea unitatii de masura p" } },
				codUnitateMasuraBt130: { maxLength: 10, defaultValue: "", custom: { MS_Description: "Codul din tabela {sigma.eFactu" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.UtilitatiTip": {
			shortName: "UtilitatiTip",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				idContSinteticCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"nomenclatoare.Valuta": {
			shortName: "Valuta",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				simbol: { maxLength: 10, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoare.Venit": {
			shortName: "Venit",
			namespace: "nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "", custom: { MS_Description: "Denumirea tipului de venit" } },
				actualizabilAnual: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Valoare 1 pentru Impozite....(" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoareistoric.AsociereContBancaTipBuget": {
			shortName: "AsociereContBancaTipBuget",
			namespace: "nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "bugetIstoric.SectorBugetar.IDS" } },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "bugetIstoric.SursaFinantare.ID" } },
				idCont: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				idAsociereContBancaTipBuget: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"nomenclatoareistoric.CAEN": {
			shortName: "CAEN",
			namespace: "nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				sectiune: { maxLength: 10, defaultValue: "" },
				diviziune: { maxLength: 10, defaultValue: "" },
				grupa: { maxLength: 10, defaultValue: "" },
				clasa: { maxLength: 8, defaultValue: "" },
				codCaen: { maxLength: 8, defaultValue: "" },
				nume: { maxLength: 200, defaultValue: "" },
				codCaenRev1: { maxLength: 500, defaultValue: "" },
				isic4: { maxLength: 200, defaultValue: "" },
				idCAEN: { dataType: "Int32" },
				isLeafNode: { dataType: "Boolean", isNullable: false },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoareistoric.CotaTva": {
			shortName: "CotaTva",
			namespace: "nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cota: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Valoarea cotei tva" } },
				nume: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Denumirea cotei de tva" } },
				idCotaTva: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoareistoric.CPV": {
			shortName: "CPV",
			namespace: "nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 20, defaultValue: "", custom: { MS_Description: "Codul tipului de produs/servic" } },
				nume: { maxLength: 250, defaultValue: "", custom: { MS_Description: "Numele tipului de produs/servi" } },
				idCPV: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoareistoric.DocumentFurnizorClientDetaliuTip": {
			shortName: "DocumentFurnizorClientDetaliuTip",
			namespace: "nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "", custom: { MS_Description: "Denumirea tipului de achizitie" } },
				idTipTva: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Id-ul tipului de TVA {Sigma.no" } },
				idCotaTva: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Id cota tva din tabela {Sigma." } },
				isTvaLaIncasare: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "0- nu este tva la incasare, 1 " } },
				idDocumentTip: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Tipul de documente pentru care" } },
				isIesire: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "1 - se aplica pentru livrare /" } },
				idContTVA: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Contul de tva{Sigma-Contab.con" } },
				idContTaxareInversa: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Contul pentru taxare inversa {" } },
				idSiefTipTVA: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentFurnizorClientDetaliuTip: { dataType: "Int32" },
				eFacturaCodCategorieTva: { maxLength: 10, defaultValue: "" },
				eFacturaCotaTVA: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isDefault: { dataType: "Boolean", isNullable: false },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoareistoric.PlataNumerarTip": {
			shortName: "PlataNumerarTip",
			namespace: "nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				idContSinteticCheltuiala: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Contul sintetic de obligatii d" } },
				idContSinteticCheltuialaCredit: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Contul sintetic de obligatii c" } },
				idContSinteticPlataNumerarDebit: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Nu se foloseste  - Contul sint" } },
				idContSinteticPlataNumerarCredit: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Nu se foloseste  - Contul sint" } },
				idNaturaPlata: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Natura platii {Sigma.nomenclat" } },
				idClasificatieEconomica: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Id comun {Sigma-Buget.bugetIst" } },
				isPlataDirecta: { dataType: "Boolean", custom: { MS_Description: "Flag pentru platile directe in" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				idPlataNumerarTip: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"nomenclatoareistoric.RetinereSalariiTip": {
			shortName: "RetinereSalariiTip",
			namespace: "nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				numeScurt: { maxLength: 150, defaultValue: "", custom: { MS_Description: "Denumire scurta" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				idRetinereSalariiTip: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				isTotalObligatii: { dataType: "Boolean", isNullable: false },
				isFNUASS: { dataType: "Boolean", isNullable: false },
				isRestPlata: { dataType: "Boolean", isNullable: false },
			}
		},
		"nomenclatoareistoric.RetinereSalariiTipPersonalizare": {
			shortName: "RetinereSalariiTipPersonalizare",
			namespace: "nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idRetinereSalariiTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "{Sigma.nomenclatoareIstoric.Re" } },
				nume: { maxLength: 150, defaultValue: "" },
				idRetinereSalariiTipPersonalizare: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"nomenclatoareistoric.ServiciuTip": {
			shortName: "ServiciuTip",
			namespace: "nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				idContSinteticCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Contul sintetic de cheltuiala " } },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Natura platii {Sigma.nomenclat" } },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id comun {Sigma-Buget.bugetIst" } },
				isImobilizari: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Pentru diverse servicii care a" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				idServiciuTip: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"nomenclatoareistoric.TipTva": {
			shortName: "TipTva",
			namespace: "nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				idTipTva: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"nomenclatoareistoric.UtilitatiTip": {
			shortName: "UtilitatiTip",
			namespace: "nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				idContSinteticCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Contul sintetic de cheltuiala " } },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Natura platii {Sigma.nomenclat" } },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id comun {Sigma-Buget.bugetIst" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				idUtilitatiTip: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"partener.Localitate": {
			shortName: "Localitate",
			namespace: "partener",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
			}
		},
		"partener.Partener": {
			shortName: "Partener",
			namespace: "partener",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cif: { maxLength: 15, defaultValue: "" },
				nume: { maxLength: 251, defaultValue: "" },
				atrf: { maxLength: 2, defaultValue: "" },
				idTara: { maxLength: 2, defaultValue: "" },
				isRegimSpecialTva: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idRegiune: { dataType: "Int32", defaultValue: 0 },
				idLocalitate: { dataType: "Int32", defaultValue: 0 },
				idStrada: { dataType: "Int32", defaultValue: 0 },
				strada: { maxLength: 50, defaultValue: "" },
				numar: { maxLength: 30, defaultValue: "" },
				bloc: { maxLength: 10, defaultValue: "" },
				apartament: { maxLength: 10, defaultValue: "" },
				cui: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"partener.Regiune": {
			shortName: "Regiune",
			namespace: "partener",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				cod: { maxLength: 20, defaultValue: "" },
			}
		},
		"persoane.Comisia": {
			shortName: "Comisia",
			namespace: "persoane",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Unitatea pentru care se define" } },
				idComisiaTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul comisiei: {Sigma.nomencl" } },
				nume: { maxLength: 150, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"persoane.ComisiaPersoana": {
			shortName: "ComisiaPersoana",
			namespace: "persoane",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idComisia: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"persoane.ControlorCFP": {
			shortName: "ControlorCFP",
			namespace: "persoane",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"persoane.CuiSincronizareAnaf": {
			shortName: "CuiSincronizareAnaf",
			namespace: "persoane",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cui: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"persoane.Persoana": {
			shortName: "Persoana",
			namespace: "persoane",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				isBanca: { dataType: "Boolean", isNullable: false },
				idPersoanaFormaOrganizare: { dataType: "Int32", defaultValue: 0 },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				codRol: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				coduriCaen: { maxLength: 200, defaultValue: "" },
				idParinte: { dataType: "Int32", defaultValue: 0 },
				cui: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cuiNonRo: { maxLength: 20, defaultValue: "" },
				nume: { maxLength: 100, defaultValue: "" },
				prenume: { maxLength: 150, defaultValue: "" },
				numePrenume: { maxLength: 251, defaultValue: "" },
				prenumeMama: { maxLength: 150, defaultValue: "" },
				prenumeTata: { maxLength: 150, defaultValue: "" },
				sex: { maxLength: 1, defaultValue: "" },
				dataDeces: { dataType: "DateTime" },
				telefon: { maxLength: 50, defaultValue: "" },
				email: { maxLength: 50, defaultValue: "" },
				actTip: { maxLength: 50, defaultValue: "" },
				actSerieNumar: { maxLength: 50, defaultValue: "" },
				actDataEliberare: { dataType: "DateTime" },
				actDataExpirare: { dataType: "DateTime" },
				actEliberatDe: { maxLength: 50, defaultValue: "" },
				atributFiscal: { maxLength: 10, defaultValue: "" },
				idPersoanaContact: { dataType: "Int32", defaultValue: 0 },
				idPersoanaReprezentant: { dataType: "Int32", defaultValue: 0 },
				idPersoanaOcupatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				ocupatie: { maxLength: 50, defaultValue: "" },
				angajator: { maxLength: 50, defaultValue: "" },
				functieOcupata: { maxLength: 50, defaultValue: "" },
				idPersoanaNivelStudii: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nivelStudii: { maxLength: 50, defaultValue: "" },
				adresa: { maxLength: 150, defaultValue: "" },
				adresaDetaliu: { maxLength: 50, defaultValue: "" },
				idTara: { dataType: "Int32", defaultValue: 0 },
				idJudet: { dataType: "Int32", defaultValue: 0 },
				idLocalitate: { dataType: "Int32", defaultValue: 0 },
				idStrada: { dataType: "Int32", defaultValue: 0 },
				idNumarPostal: { dataType: "Int32", defaultValue: 0 },
				idBloc: { dataType: "Int32", defaultValue: 0 },
				idScara: { dataType: "Int32", defaultValue: 0 },
				idApartament: { dataType: "Int32", defaultValue: 0 },
				codPostal: { maxLength: 10, defaultValue: "" },
				idEtnie: { dataType: "Int32", defaultValue: 0 },
				idReligie: { dataType: "Int32", defaultValue: 0 },
				idNationalitate: { dataType: "Int32", defaultValue: 0 },
				cetatenie: { maxLength: 20, defaultValue: "" },
				vizibilImpotax: { dataType: "Boolean", isNullable: false },
				vizibilEcub: { dataType: "Boolean", isNullable: false },
				vizibilAgroregis: { dataType: "Boolean", isNullable: false },
				vizibilResum: { dataType: "Boolean", isNullable: false },
				bancaCodSwift: { maxLength: 10, defaultValue: "" },
				bancaCodBic: { maxLength: 10, defaultValue: "" },
				isPlatitorTVA: { dataType: "Boolean", isNullable: false },
				isSistemTVAIncasare: { dataType: "Boolean", isNullable: false },
				idTaraFapt: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idJudetFapt: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idLocalitateFapt: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idStradaFapt: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idNumarPostalFapt: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBlocFapt: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idScaraFapt: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idApartamentFapt: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codPostalFapt: { maxLength: 10, defaultValue: "" },
				adresaFapt: { maxLength: 250, defaultValue: "" },
				adresaDetaliuFapt: { maxLength: 50, defaultValue: "" },
				idTaraCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idJudetCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idLocalitateCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idStradaCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idNumarPostalCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBlocCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idScaraCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idApartamentCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codPostalCorespondenta: { maxLength: 10, defaultValue: "" },
				adresaCorespondenta: { maxLength: 250, defaultValue: "" },
				adresaDetaliuCorespondenta: { maxLength: 50, defaultValue: "" },
				dataExpirareFlotant: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isEmitereFacturaSistemRoEfactura: { dataType: "Boolean", isNullable: false },
			}
		},
		"persoane.PersoanaBancaIban": {
			shortName: "PersoanaBancaIban",
			namespace: "persoane",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", defaultValue: 0 },
				idPersoanaBanca: { dataType: "Int32", defaultValue: 0 },
				iBAN: { maxLength: 24, defaultValue: "" },
				isVenit: { dataType: "Boolean", isNullable: false },
				isPrincipal: { dataType: "Boolean", isNullable: false },
				isContPlatitor: { dataType: "Boolean", isNullable: false },
				isContBeneficiar: { dataType: "Boolean", isNullable: false },
				isContMandat: { dataType: "Boolean", isNullable: false },
				isExtrabugetar: { dataType: "Boolean", isNullable: false },
				codBanca: { maxLength: 4, defaultValue: "" },
				numeBeneficiar: { maxLength: 250, defaultValue: "" },
				analiticContabilitate: { maxLength: 100, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"persoane.PersoanaCategorieVenit": {
			shortName: "PersoanaCategorieVenit",
			namespace: "persoane",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCategorieVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"persoane.PersoanaDocument": {
			shortName: "PersoanaDocument",
			namespace: "persoane",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				stream_idPersoanaDocumentAtasat: { defaultValue: null },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"persoane.PersoanaEtnie": {
			shortName: "PersoanaEtnie",
			namespace: "persoane",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 200, defaultValue: "" },
			}
		},
		"persoane.PersoanaFormaOrganizare": {
			shortName: "PersoanaFormaOrganizare",
			namespace: "persoane",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				codFormaOrganizare: { maxLength: 3, defaultValue: "", custom: { MS_Description: "sintetic de la PersoanaFormaOr" } },
			}
		},
		"persoane.PersoanaIerarhie": {
			shortName: "PersoanaIerarhie",
			namespace: "persoane",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaIerarhieTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				treeLevel: { dataType: "Int32", defaultValue: 0 },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"persoane.PersoanaNationalitate": {
			shortName: "PersoanaNationalitate",
			namespace: "persoane",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 200, defaultValue: "" },
			}
		},
		"persoane.PersoanaNivelStudii": {
			shortName: "PersoanaNivelStudii",
			namespace: "persoane",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
			}
		},
		"persoane.PersoanaOcupatie": {
			shortName: "PersoanaOcupatie",
			namespace: "persoane",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
			}
		},
		"persoane.PersoanaReligie": {
			shortName: "PersoanaReligie",
			namespace: "persoane",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 200, defaultValue: "" },
			}
		},
		"persoane.PersoanaUtilitati": {
			shortName: "PersoanaUtilitati",
			namespace: "persoane",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Persoana pentru care se asocia" } },
				idUtilitatiTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul utilitatii furnizate: {S" } },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Unitatea {Sigma.persoaneIstori" } },
			}
		},
		"persoaneistoric.ComisiaPersoana": {
			shortName: "ComisiaPersoana",
			namespace: "persoaneistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idComisia: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Comisia de care apartine perso" } },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Persoana din cadrul comisiei: " } },
				idComisiaPersoana: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"persoaneistoric.ControlorCFP": {
			shortName: "ControlorCFP",
			namespace: "persoaneistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul unic al persoanei din ta" } },
				idControlorCfp: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				idPersoanaUnitate: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Unitatea pentru care se define" } },
			}
		},
		"persoaneistoric.Persoana": {
			shortName: "Persoana",
			namespace: "persoaneistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", custom: { MS_Description: "Identificatorul unic al persoa" } },
				isBanca: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Marcheaza faptul ca entitatea " } },
				idPersoanaFormaOrganizare: { dataType: "Int32", defaultValue: 0 },
				codFormaOrganizare: { maxLength: 3, defaultValue: "", custom: { Caption: "Forma de organizare", MS_Description: "sintetic de la PersoanaFormaOr" } },
				codRol: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Cod Rol pentru impotax" } },
				coduriCaen: { maxLength: 200, defaultValue: "", custom: { MS_Description: "Lista codurilor de activitati " } },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				idParinte: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Se completeaza daca este filia" } },
				cui: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "CUI sau CNP" } },
				cuiNonRo: { maxLength: 20, defaultValue: "", custom: { MS_Description: "Cod Unic de Identificare pentr" } },
				nume: { maxLength: 100, defaultValue: "" },
				prenume: { maxLength: 150, defaultValue: "" },
				numePrenume: { maxLength: 251, defaultValue: "" },
				prenumeMama: { maxLength: 150, defaultValue: "" },
				prenumeTata: { maxLength: 150, defaultValue: "" },
				sex: { maxLength: 1, defaultValue: "" },
				dataDeces: { dataType: "DateTime" },
				telefon: { maxLength: 50, defaultValue: "" },
				email: { maxLength: 50, defaultValue: "" },
				actTip: { maxLength: 50, defaultValue: "" },
				actSerieNumar: { maxLength: 50, defaultValue: "", custom: { MS_Description: "J24/... .. pt juridice = numar" } },
				actDataEliberare: { dataType: "DateTime" },
				actDataExpirare: { dataType: "DateTime" },
				actEliberatDe: { maxLength: 50, defaultValue: "" },
				atributFiscal: { maxLength: 10, defaultValue: "", custom: { MS_Description: "RO - platitor TVA etc" } },
				idPersoanaContact: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "{Sigma.persoaneIstoric.Persoan" } },
				idPersoanaReprezentant: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "{Sigma.persoaneIstoric.Persoan" } },
				idPersoanaOcupatie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "{Sigma.persoane.PersoanaOcupat" } },
				ocupatie: { maxLength: 50, defaultValue: "", custom: { MS_Description: "nu se mai foloseste - inlocuit" } },
				angajator: { maxLength: 50, defaultValue: "" },
				functieOcupata: { maxLength: 50, defaultValue: "" },
				idPersoanaNivelStudii: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "{Sigma.persoane.PersoanaNivelS" } },
				nivelStudii: { maxLength: 50, defaultValue: "", custom: { MS_Description: "nu se mai foloseste - inlocuit" } },
				adresa: { maxLength: 250, defaultValue: "", custom: { MS_Description: "Text in clar reprezentand: adr" } },
				adresaDetaliu: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Detaliu adresa adaugat de util" } },
				idTara: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "adreseIstoric.Tara.IdTara" } },
				idJudet: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "adreseIstoric.Judet.IdJudet" } },
				idLocalitate: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "adreseIstoric.Localitate.IdLoc" } },
				idStrada: { dataType: "Int32", defaultValue: 0, custom: { Caption: "Strada", MS_Description: "adreseIstoric.Strada.Id" } },
				idNumarPostal: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "adreseIstoric.NumarPostal.Id" } },
				idBloc: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "adreseIstoric.Bloc.Id" } },
				idScara: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "adreseScara.scara.Id" } },
				idApartament: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "adreseIstoric.Apartament.Id" } },
				codPostal: { maxLength: 10, defaultValue: "", custom: { Caption: "Cod Poștal" } },
				idEtnie: { dataType: "Int32", defaultValue: 0 },
				idReligie: { dataType: "Int32", defaultValue: 0 },
				idNationalitate: { dataType: "Int32", defaultValue: 0 },
				cetatenie: { maxLength: 20, defaultValue: "" },
				vizibilImpotax: { dataType: "Boolean", isNullable: false },
				vizibilEcub: { dataType: "Boolean", isNullable: false },
				vizibilAgroregis: { dataType: "Boolean", isNullable: false },
				vizibilResum: { dataType: "Boolean", isNullable: false },
				bancaCodSwift: { maxLength: 10, defaultValue: "", custom: { MS_Description: "Se completeaza in cazul entita" } },
				bancaCodBic: { maxLength: 10, defaultValue: "", custom: { MS_Description: "Se completeaza in cazul entita" } },
				isPlatitorTVA: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Unitatea de lucru sau Partener" } },
				isSistemTVAIncasare: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Unitatea de lucru sau Partener" } },
				idTaraFapt: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "adreseIstoric.Tara.IdTara" } },
				idJudetFapt: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "adreseIstoric.Judet.IdJudet" } },
				idLocalitateFapt: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "adreseIstoric.Localitate.IdLoc" } },
				idStradaFapt: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "adreseIstoric.Strada.Id" } },
				idNumarPostalFapt: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "adreseIstoric.NumarPostal.Id" } },
				idBlocFapt: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "adreseIstoric.Bloc.Id" } },
				idScaraFapt: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "adreseScara.scara.Id" } },
				idApartamentFapt: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "adreseIstoric.Apartament.Id" } },
				codPostalFapt: { maxLength: 10, defaultValue: "" },
				adresaFapt: { maxLength: 250, defaultValue: "" },
				adresaDetaliuFapt: { maxLength: 50, defaultValue: "" },
				idTaraCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "adreseIstoric.Tara.IdTara" } },
				idJudetCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "adreseIstoric.Judet.IdJudet" } },
				idLocalitateCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "adreseIstoric.Localitate.IdLoc" } },
				idStradaCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "adreseIstoric.Strada.Id" } },
				idNumarPostalCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "adreseIstoric.NumarPostal.Id" } },
				idBlocCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "adreseIstoric.Bloc.Id" } },
				idScaraCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "adreseScara.scara.Id" } },
				idApartamentCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "adreseIstoric.Apartament.Id" } },
				codPostalCorespondenta: { maxLength: 10, defaultValue: "" },
				adresaCorespondenta: { maxLength: 250, defaultValue: "" },
				adresaDetaliuCorespondenta: { maxLength: 50, defaultValue: "" },
				dataExpirareFlotant: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				idImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isEmitereFacturaSistemRoEfactura: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Persoana este inrolata in sist" } },
			}
		},
		"persoaneistoric.PersoanaBancaIban": {
			shortName: "PersoanaBancaIban",
			namespace: "persoaneistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Legatura spre persoana prin id" } },
				idPersoanaBanca: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Legatura spre banca prin idCom" } },
				iBAN: { maxLength: 24, defaultValue: "" },
				isVenit: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Cont IBAN de venit" } },
				isPrincipal: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Contul IBAN cel mai utilizat" } },
				isContPlatitor: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Flag pentru platitor (se utili" } },
				isContBeneficiar: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Marcaj pentru cont beneficiar" } },
				isContMandat: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Marcaj pentru cont mandat" } },
				isExtrabugetar: { dataType: "Boolean", isNullable: false },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				idPersoanaBancaIban: { dataType: "Int32" },
				codBanca: { maxLength: 4, defaultValue: "", custom: { MS_Description: "Se detremina din IBAN" } },
				numeBeneficiar: { maxLength: 250, defaultValue: "", custom: { MS_Description: "In cazul conturilor proprii cu" } },
				analiticContabilitate: { maxLength: 100, defaultValue: "", custom: { MS_Description: "Analitic personalizat pentru g" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"persoaneistoric.PersoanaCategorieVenit": {
			shortName: "PersoanaCategorieVenit",
			namespace: "persoaneistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "id comun {Sigma.persoaneIstori" } },
				idCategorieVenit: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "{Sigma.nomenclatoare.Categorie" } },
				idPersoanaCategorieVenit: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"persoaneistoric.PersoanaDocument": {
			shortName: "PersoanaDocument",
			namespace: "persoaneistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				stream_idPersoanaDocumentAtasat: { defaultValue: null, custom: { MS_Description: "Referinta la Sigma filetables " } },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Documentul din registrul de do" } },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "persoaneIstoric.Persoana.IdPer" } },
				idPersoanaDocument: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"persoaneistoric.PersoanaIerarhie": {
			shortName: "PersoanaIerarhie",
			namespace: "persoaneistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaIerarhieTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "{Sigma.nomenclatoare.PersoaneI" } },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				treeLevel: { dataType: "Int32", defaultValue: 0 },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				idPersoanaIerarhie: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"produs.ProdusRaportare": {
			shortName: "ProdusRaportare",
			namespace: "produs",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 50, defaultValue: "" },
				nume: { maxLength: 200, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"raportarifinanciare.Anexa_19_rows_old": {
			shortName: "Anexa_19_rows_old",
			namespace: "raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codRand: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				denumire: { maxLength: 200, defaultValue: "" },
				articol: { maxLength: 6, defaultValue: "" },
				codVenit: { maxLength: 6, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"raportarifinanciare.Anexa30SintezaPlatiArierate": {
			shortName: "Anexa30SintezaPlatiArierate",
			namespace: "raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				luna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				r1c1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r1c2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r1c3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r1c4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r1c5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r1c6: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r2c1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r2c2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r2c3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r2c4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r2c5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r2c6: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r3c1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r3c2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r3c3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r3c4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r3c5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r3c6: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
			}
		},
		"raportarifinanciare.Anexa30SintezaPlatiRest": {
			shortName: "Anexa30SintezaPlatiRest",
			namespace: "raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				luna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				r1c1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r1c2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r1c3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r1c4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r1c5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r1c6: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r1c7: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r1c8: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r2c1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r2c2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r2c3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r2c4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r2c5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r2c6: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r2c7: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r2c8: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r3c1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r3c2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r3c3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r3c4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r3c5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r3c6: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r3c7: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				r3c8: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
			}
		},
		"raportarifinanciare.ExtrasContTrezo": {
			shortName: "ExtrasContTrezo",
			namespace: "raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nrExtras: { maxLength: 50, defaultValue: "" },
				dataExtras: { dataType: "DateTime" },
				denumireEp: { maxLength: 250, defaultValue: "" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				continutXml: { defaultValue: "" },
				utilizatorAdaugare: { defaultValue: "" },
				dataAdaugare: { dataType: "DateTime", isNullable: false },
				utilizatorActualizare: { defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
			}
		},
		"raportarifinanciare.ExtrasContTrezoDetalii": {
			shortName: "ExtrasContTrezoDetalii",
			namespace: "raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idExtrasContTrezo: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nrOP: { maxLength: 50, defaultValue: "" },
				codIban: { maxLength: 50, defaultValue: "" },
				cont: { maxLength: 50, defaultValue: "" },
				soldPrecedentSumaD: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				soldPrecedentSumaC: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajZiSumaD: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajZiSumaC: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				totalSumeSumaD: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				totalSumeSumaC: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				soldFinalSumaD: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				soldFinalSumaC: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", defaultValue: 0 },
				idClasificatieFunctionala: { dataType: "Int32", defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", defaultValue: 0 },
				tipIban: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"raportarifinanciare.ExtrasContTrezoDetaliiMisc": {
			shortName: "ExtrasContTrezoDetaliiMisc",
			namespace: "raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idExtrasContTrezoDetalii: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarDoc: { maxLength: 50, defaultValue: "" },
				dataDoc: { dataType: "DateTime" },
				dataBan: { dataType: "DateTime" },
				nrRefDest: { maxLength: 50, defaultValue: "" },
				ibanBfPl: { maxLength: 50, defaultValue: "" },
				platitor: { maxLength: 50, defaultValue: "" },
				numePb: { maxLength: 50, defaultValue: "" },
				sumaD: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaC: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				codContract: { defaultValue: "" },
				randContract: { defaultValue: "" },
				explicatii: { maxLength: 250, defaultValue: "" },
				generareExecutieBugetara: { dataType: "Boolean", isNullable: false },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCompartiment: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSumaExecutie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentIncasare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isDeleted: { dataType: "Boolean", isNullable: false },
				codDocumentTipAsociat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentOPAsociat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"raportarifinanciare.ExtrasContTrezoLichidari": {
			shortName: "ExtrasContTrezoLichidari",
			namespace: "raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idExtrasContTrezoDetaliiMisc: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentLichidare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"raportarifinanciare.ExtrasContTrezoSoldInitial": {
			shortName: "ExtrasContTrezoSoldInitial",
			namespace: "raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codIban: { maxLength: 50, defaultValue: "" },
				cont: { maxLength: 50, defaultValue: "" },
				sumaD: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaC: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tipIban: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"raportarifinanciare.ExtrasContTrezoSumaCredit": {
			shortName: "ExtrasContTrezoSumaCredit",
			namespace: "raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cod: { maxLength: 50, defaultValue: "" },
				capitol: { maxLength: 2, defaultValue: "" },
				titlu: { maxLength: 2, defaultValue: "" },
				sumaCredit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"raportarifinanciare.Formular": {
			shortName: "Formular",
			namespace: "raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { defaultValue: "" },
				codFormular: { defaultValue: "" },
				raportare: { defaultValue: "" },
				codFormularTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"raportarifinanciare.FormularStatus": {
			shortName: "FormularStatus",
			namespace: "raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anul: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				luna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormular: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codUnic: { maxLength: 1000, defaultValue: "" },
				uploadDate: { dataType: "DateTime" },
				downloadDate: { dataType: "DateTime" },
				idStatus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				errors: { defaultValue: "" },
				filepath: { defaultValue: "" },
				params: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"raportarifinanciare.LogGenerareXML": {
			shortName: "LogGenerareXML",
			namespace: "raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				xml: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"raportarifinanciare.RegistruSchemaXSD": {
			shortName: "RegistruSchemaXSD",
			namespace: "raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				link: { maxLength: 200, defaultValue: "" },
				versiune: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				versiuneAnaf: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dataVerificare: { dataType: "DateTime" },
				mail: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"raportarifinanciareistoric.ExtrasContTrezoSumaCredit": {
			shortName: "ExtrasContTrezoSumaCredit",
			namespace: "raportarifinanciareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cod: { maxLength: 50, defaultValue: "" },
				capitol: { maxLength: 2, defaultValue: "" },
				titlu: { maxLength: 2, defaultValue: "" },
				sumaCredit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idExtrasContTrezoSumaCredit: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"registre.CursValutar": {
			shortName: "CursValutar",
			namespace: "registre",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Valuta de referinta {Sigma.nom" } },
				data: { dataType: "DateTime", isNullable: false },
				valoare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
			}
		},
		"registre.IndicePretConsum": {
			shortName: "IndicePretConsum",
			namespace: "registre",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Unitatea, Id Persoana id comun" } },
				anLunaCurent: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Anul/luna pentru care sa actua" } },
				anLunaReferinta: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Anul/luna din trecut la care s" } },
				valoare: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Valoare indice pret de consum" } },
			}
		},
		"registre.LocatieSpatiuTip": {
			shortName: "LocatieSpatiuTip",
			namespace: "registre",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
			}
		},
		"registre.LocatieTip": {
			shortName: "LocatieTip",
			namespace: "registre",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
			}
		},
		"registre.OperatiuneProprieTip": {
			shortName: "OperatiuneProprieTip",
			namespace: "registre",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"registre.OperatiuneProprieTipDetaliu": {
			shortName: "OperatiuneProprieTipDetaliu",
			namespace: "registre",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOperatiuneProprieTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu operatiunea propri" } },
				nume: { maxLength: 250, defaultValue: "" },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Centrul de cheltuiala - Id Com" } },
				idContAnaliticDebit: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Contul debit - Id Comun {Sigma" } },
				idContAnaliticCredit: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Contul credit - Id Comun {Sigm" } },
				idJurnal: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Jurnalul (tabela Jurnal) din c" } },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Suma propusa " } },
				isObligatie: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "flag pentru obligatie de plata" } },
				isGenerareExecutieBugetaraAL: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "flag pentru generare executie " } },
				isPlata: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Flag pentru operatiune de tip " } },
				isGenerareExecutieBugetaraO: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "flag pentru generare executie " } },
				isGenerarePlataVirament: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "flag pentru generare plata de " } },
				codTipInregistrare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Cod pentru tipul inregistrarii" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"registre.PlataNumerarTipDetaliu": {
			shortName: "PlataNumerarTipDetaliu",
			namespace: "registre",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPlataNumerarTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 150, defaultValue: "" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticContabilitate: { maxLength: 100, defaultValue: "" },
				idContSinteticObligatieDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContSinteticObligatieCredit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isNotaContabilaPlataDirectaFaraObligatie: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"registre.PostNume": {
			shortName: "PostNume",
			namespace: "registre",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 200, defaultValue: "" },
			}
		},
		"registre.ProdusClasificare": {
			shortName: "ProdusClasificare",
			namespace: "registre",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				cod: { maxLength: 100, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				idProdusGrupTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusDetaliuTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				isClasaMagazieObiectInventar: { dataType: "Boolean", isNullable: false },
				durataMinima: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				durataMaxima: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContSinteticStoc: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContSinteticAmortizare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContSinteticCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticContabilitate: { maxLength: 100, defaultValue: "" },
			}
		},
		"registre.VenitDetaliu": {
			shortName: "VenitDetaliu",
			namespace: "registre",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 150, defaultValue: "" },
				actualizabilAnual: { dataType: "Boolean", isNullable: false },
				cuDobanda: { dataType: "Boolean", isNullable: false },
				cuDebit: { dataType: "Boolean", isNullable: false },
				cuFormula: { dataType: "Boolean", isNullable: false },
				valoareFixa: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				procentBonificatieFizice: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				procentBonificatieJuridice: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cumulatFizice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cumulatJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				contTrezorerieFizice: { maxLength: 50, defaultValue: "" },
				idClasificatieFunctionalaFizice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				contTrezorerieJuridice: { maxLength: 50, defaultValue: "" },
				idClasificatieFunctionalaJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				uM: { maxLength: 5, defaultValue: "" },
				formula1: { maxLength: 254, defaultValue: "" },
				formula2: { maxLength: 255, defaultValue: "" },
				formula3: { maxLength: 254, defaultValue: "" },
				formula4: { maxLength: 254, defaultValue: "" },
				formula5: { maxLength: 254, defaultValue: "" },
				modDebitare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				grupDebitare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cuTVA: { dataType: "Boolean", isNullable: false },
				tipScadenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				pornireOprireCalcul: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				formulaAnAnteriorDiferentaIndexare: { maxLength: 100, defaultValue: "" },
				formulaAnCurentDiferentaIndexare: { maxLength: 100, defaultValue: "" },
				cuDiferentaIndexare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				taxareInversa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenitBazaDetaliu: { dataType: "Int32", defaultValue: 0 },
				idDetaliuVenit: { dataType: "Int32", defaultValue: 0 },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				cuFactura: { dataType: "Boolean" },
				tipDebitareAlteDebite: { dataType: "Int32", defaultValue: 0 },
				contIBANFizice: { maxLength: 50, defaultValue: "" },
				contIBANJuridice: { maxLength: 50, defaultValue: "" },
				valabilPosta: { dataType: "Boolean" },
				tVAProcent: { dataType: "Decimal", defaultValue: 0 },
				expValid: { maxLength: 15, defaultValue: "" },
				expExpir: { maxLength: 15, defaultValue: "" },
				faraDenumireVenitInFacturi: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursDinValoriCalcul: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				denumireVirament1: { maxLength: 25, defaultValue: "" },
				denumireVirament2: { maxLength: 25, defaultValue: "" },
				procentVirament1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				procentVirament2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				categorieVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				debitareExpirate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				facturareSeparata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				perioadaScadenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				perioadaScadentaLuna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				perioadaScadentaZi: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCassaFizice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCassaMajorariFizice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCassaJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCassaMajorariJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cif2SiDen2: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cuLimitareDobanda: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				inactiv: { dataType: "Boolean", isNullable: false },
				minimBonificatieFizice: { dataType: "Decimal", defaultValue: 0 },
				minimBonificatieJuridice: { dataType: "Decimal", defaultValue: 0 },
				tVAPosesie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				calculDiferentaCursMoneda: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				calculDiferentaCurs: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenitMfpJuridice: { dataType: "Int32", defaultValue: 0 },
				idVenitMfpFizice: { dataType: "Int32", defaultValue: 0 },
				zileGratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				faraScadentaFacturaZile: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				faraScadentaFactura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cif1SiCif2: { dataType: "Int32", defaultValue: 0 },
				verificareExistentaContracte: { dataType: "Int32", defaultValue: 0 },
				peLocatii: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				pausal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarZecimaleJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarZecimaleFizice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				adresa2: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valabilGhiseul: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stingPeScad: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIbanFizice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIbanJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				modGenerareNotaContabilaIndividual: { dataType: "Boolean", isNullable: false },
				idJurnal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idJurnalIncasareNumerar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idJurnalIncasareBanca: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContObligatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContBanca: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isTertiDecontareUlterioara: { dataType: "Boolean", isNullable: false },
				idVenitDetaliuParinte: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				procentDecontareTerti: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaDecontareTerti: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				codAnaliticContabilitateFizice: { maxLength: 50, defaultValue: "" },
				codAnaliticContabilitateJuridice: { maxLength: 50, defaultValue: "" },
				codAnaliticContabilitateFiziceBanca: { maxLength: 50, defaultValue: "" },
				codAnaliticContabilitateJuridiceBanca: { maxLength: 50, defaultValue: "" },
				isOperatiuneCheltuiala: { dataType: "Boolean", isNullable: false },
				isFaraClasificatieBugetara: { dataType: "Boolean", isNullable: false },
				isRefacturareUtilitati: { dataType: "Boolean", isNullable: false },
				idUtilitatiTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				zonaComerciala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				verificareRestante: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				verificareOnline: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				facturareCasierie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				bonitateOnlineFizice: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				bonitateOnlineJuridice: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				semnatura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentTipGarantie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isIncasareFacturaFurnizorRetur: { dataType: "Boolean", isNullable: false },
				dataMaxFacturareIncasare: { dataType: "DateTime" },
				isNCRestituireDiminuareSuprasolvire: { dataType: "Boolean", isNullable: false },
				isGenerareNotaObligatieDispozitieIncasareSiBanca: { dataType: "Boolean", isNullable: false },
				isGenerareNotaContObligatieFaraCodVenit: { dataType: "Boolean", isNullable: false },
				isGenerareNotaContBancaFaraClasificatie: { dataType: "Boolean", isNullable: false },
				isPenalitateContractRetinutaLaPlata: { dataType: "Boolean", isNullable: false },
			}
		},
		"registreistoric.OperatiuneProprieTip": {
			shortName: "OperatiuneProprieTip",
			namespace: "registreistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Activitatea economica - Id Com" } },
				idOperatiuneProprieTip: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"registreistoric.PlataNumerarTipDetaliu": {
			shortName: "PlataNumerarTipDetaliu",
			namespace: "registreistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPlataNumerarTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul de legatura cu nomenclat" } },
				nume: { maxLength: 150, defaultValue: "", custom: { MS_Description: "Numele personalizat la client" } },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Unitatea - Id Comun {Sigma.per" } },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Activitatea economica - Id Com" } },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Centrul de cheltuiala - Id Com" } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul comun din buget {Sigma-B" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul comun din clasificatia f" } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul comun din clasificaţia e" } },
				idPlataNumerarTipDetaliu: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				analiticContabilitate: { maxLength: 100, defaultValue: "", custom: { MS_Description: "Analiticul pentru generarea no" } },
				idContSinteticObligatieDebit: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Contul sintetic de obligatii d" } },
				idContSinteticObligatieCredit: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Contul sintetic de obligatii c" } },
				isNotaContabilaPlataDirectaFaraObligatie: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Se fooseste la generarea in co" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"registreistoric.ProdusClasificare": {
			shortName: "ProdusClasificare",
			namespace: "registreistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				cod: { maxLength: 100, defaultValue: "", custom: { MS_Description: "Codul de clasificare" } },
				nume: { maxLength: 250, defaultValue: "", custom: { MS_Description: "Numele clasificarii" } },
				idProdusGrupTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Grupul de tipuri de produs: {S" } },
				idProdusTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de produs: {Sigma.nomenc" } },
				idProdusDetaliuTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de produs detaliat {Sigm" } },
				idProdusClasificare: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				isClasaMagazieObiectInventar: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Marcheaza clasa de magazie pen" } },
				durataMinima: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				durataMaxima: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia economica {Sigma-" } },
				idContSinteticStoc: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Contul sintetic de Stoc / MF {" } },
				idContSinteticAmortizare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Contul sintetic de amortizare " } },
				idContSinteticCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Contul sintetic de cheltuiala " } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul unitatii, Id comun {Sigm" } },
				analiticContabilitate: { maxLength: 100, defaultValue: "", custom: { MS_Description: "Analitic pentru generarea in c" } },
			}
		},
		"registreistoric.VenitDetaliu": {
			shortName: "VenitDetaliu",
			namespace: "registreistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu tipul de venit pe " } },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Unitatea de lucru - legatura c" } },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id-ul activitatii economice, I" } },
				nume: { maxLength: 150, defaultValue: "", custom: { MS_Description: "Denumirea tipului de venit in " } },
				actualizabilAnual: { dataType: "Boolean", isNullable: false },
				idVenitDetaliu: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				cuDobanda: { dataType: "Boolean", isNullable: false },
				cuDebit: { dataType: "Boolean", isNullable: false },
				cuFormula: { dataType: "Boolean", isNullable: false },
				valoareFixa: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Valoarea taxei ...pe zi/luna/a" } },
				procentBonificatieFizice: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				procentBonificatieJuridice: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cumulatFizice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cumulatJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				contTrezorerieFizice: { maxLength: 50, defaultValue: "" },
				idClasificatieFunctionalaFizice: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu Clasificatia Funct" } },
				contTrezorerieJuridice: { maxLength: 50, defaultValue: "" },
				idClasificatieFunctionalaJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu Clasificatia Funct" } },
				uM: { maxLength: 5, defaultValue: "" },
				formula1: { maxLength: 254, defaultValue: "" },
				formula2: { maxLength: 255, defaultValue: "" },
				formula3: { maxLength: 254, defaultValue: "" },
				formula4: { maxLength: 254, defaultValue: "" },
				formula5: { maxLength: 254, defaultValue: "" },
				modDebitare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				grupDebitare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cuTVA: { dataType: "Boolean", isNullable: false },
				tipScadenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				pornireOprireCalcul: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				formulaAnAnteriorDiferentaIndexare: { maxLength: 100, defaultValue: "" },
				formulaAnCurentDiferentaIndexare: { maxLength: 100, defaultValue: "" },
				cuDiferentaIndexare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				taxareInversa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenitBazaDetaliu: { dataType: "Int32", defaultValue: 0 },
				idDetaliuVenit: { dataType: "Int32", defaultValue: 0 },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				cuFactura: { dataType: "Boolean" },
				tipDebitareAlteDebite: { dataType: "Int32", defaultValue: 0 },
				contIBANFizice: { maxLength: 50, defaultValue: "" },
				contIBANJuridice: { maxLength: 50, defaultValue: "" },
				valabilPosta: { dataType: "Boolean" },
				tVAProcent: { dataType: "Decimal", defaultValue: 0 },
				expValid: { maxLength: 15, defaultValue: "" },
				expExpir: { maxLength: 15, defaultValue: "" },
				faraDenumireVenitInFacturi: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursDinValoriCalcul: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				denumireVirament1: { maxLength: 25, defaultValue: "" },
				denumireVirament2: { maxLength: 25, defaultValue: "" },
				procentVirament1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				procentVirament2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				categorieVenit: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "1 contract, 2 impozite " } },
				debitareExpirate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				facturareSeparata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				perioadaScadenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				perioadaScadentaLuna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				perioadaScadentaZi: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCassaFizice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCassaMajorariFizice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCassaJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCassaMajorariJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cif2SiDen2: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cuLimitareDobanda: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				inactiv: { dataType: "Boolean", isNullable: false },
				minimBonificatieFizice: { dataType: "Decimal", defaultValue: 0 },
				minimBonificatieJuridice: { dataType: "Decimal", defaultValue: 0 },
				tVAPosesie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				calculDiferentaCursMoneda: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				calculDiferentaCurs: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenitMfpJuridice: { dataType: "Int32", defaultValue: 0 },
				idVenitMfpFizice: { dataType: "Int32", defaultValue: 0 },
				zileGratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				faraScadentaFacturaZile: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				faraScadentaFactura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cif1SiCif2: { dataType: "Int32", defaultValue: 0 },
				verificareExistentaContracte: { dataType: "Int32", defaultValue: 0 },
				peLocatii: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				pausal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarZecimaleJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarZecimaleFizice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				adresa2: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valabilGhiseul: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stingPeScad: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIbanFizice: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Iban trezorerie a institutiei " } },
				idPersoanaBancaIbanJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Iban trezorerie a institutiei " } },
				modGenerareNotaContabilaIndividual: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "0 - global / 1 - individual (a" } },
				idJurnal: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id Jurnal pentru obligatii fac" } },
				idJurnalIncasareNumerar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id Jurnal pentru incasrile pri" } },
				idJurnalIncasareBanca: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id Jurnal pentru incasri prin " } },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: " Id Comun (Sigma-Buget.bugetIs" } },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: " Id Comun (Sigma-Buget.bugetIs" } },
				idContVenit: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id cont sintetic de venit  - I" } },
				idContObligatie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id cont sintetic de obligatie " } },
				idContBanca: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Id cont sintetic de banca (ins" } },
				isTertiDecontareUlterioara: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Flaf pentru tip de venit cores" } },
				idVenitDetaliuParinte: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de venit de care apartin" } },
				procentDecontareTerti: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Procentul care ca fi folosit l" } },
				sumaDecontareTerti: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Suma care ca fi folosita in ca" } },
				codAnaliticContabilitateFizice: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Analitic personalizat in conta" } },
				codAnaliticContabilitateJuridice: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Analitic personalizat in conta" } },
				codAnaliticContabilitateFiziceBanca: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Analitic personalizat in conta" } },
				codAnaliticContabilitateJuridiceBanca: { maxLength: 50, defaultValue: "", custom: { MS_Description: "Analitic personalizat in conta" } },
				isOperatiuneCheltuiala: { dataType: "Boolean", isNullable: false },
				isFaraClasificatieBugetara: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "La generarea in contabilitate," } },
				isRefacturareUtilitati: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Pentru refacturare utilitati. " } },
				idUtilitatiTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Legatura cu nom utilitati prin" } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Bugetul asociat {Sigma-Buget.b" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia functionala {Sigm" } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia economica {Sigma-" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				zonaComerciala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				verificareRestante: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				verificareOnline: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				facturareCasierie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				bonitateOnlineFizice: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				bonitateOnlineJuridice: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				semnatura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentTipGarantie: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Tipul de garantie{Sigma.nomenc" } },
				isIncasareFacturaFurnizorRetur: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Incasari pentru retur factura " } },
				dataMaxFacturareIncasare: { dataType: "DateTime", custom: { MS_Description: "Data maxima pana la care se po" } },
				isNCRestituireDiminuareSuprasolvire: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Generare in contabilitate a re" } },
				isGenerareNotaObligatieDispozitieIncasareSiBanca: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Pentru Dispozitii de incasare " } },
				isGenerareNotaContObligatieFaraCodVenit: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Generare Nota Contabila fara c" } },
				isGenerareNotaContBancaFaraClasificatie: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Generare Nota Contabila fara c" } },
				isPenalitateContractRetinutaLaPlata: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "tip de venit dedicat pentru re" } },
			}
		},
		"resal.Functia": {
			shortName: "Functia",
			namespace: "resal",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				cod: { maxLength: 50, defaultValue: "" },
				nume: { maxLength: 150, defaultValue: "" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
			}
		},
		"resal.Versiune": {
			shortName: "Versiune",
			namespace: "resal",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				version: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stamp: { dataType: "DateTime", isNullable: false },
			}
		},
		"resalistoric.Functia": {
			shortName: "Functia",
			namespace: "resalistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				cod: { maxLength: 50, defaultValue: "" },
				nume: { maxLength: 150, defaultValue: "" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				idFunctia: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"salarii.SurseFinantareTest": {
			shortName: "SurseFinantareTest",
			namespace: "salarii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
			}
		},
		"sief.Declaratie": {
			shortName: "Declaratie",
			namespace: "sief",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				uid: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tip: { maxLength: 1, defaultValue: "" },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				perioada: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isTvaIncasare: { dataType: "Boolean", isNullable: false },
				solicit: { dataType: "Boolean", isNullable: false },
				op_efectuate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tip_intocmit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				den_intocmit: { maxLength: 100, defaultValue: "" },
				cif_intocmit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				calitate_intocmit: { maxLength: 100, defaultValue: "" },
				functie_intocmit: { maxLength: 100, defaultValue: "" },
				optiune: { dataType: "Boolean", isNullable: false },
				schimb_optiune: { dataType: "Boolean", isNullable: false },
				nrcui1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				nrcui2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				nrcui3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				nrcui4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				nr_bf_i1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				incasari_i1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				incasari_i2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				nrfacturi_terti: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				nrfacturi_benef: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				nrfacturi: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				nrfacturil_pf: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				nrfacturils_pf: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				val_ls_pf: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvaded24: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvaded19: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvaded20: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvaded9: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvaded5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvadedai24: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvadedai19: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvadedai20: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvadedai9: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvadedai5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvacol24: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvacol19: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvacol20: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvacol9: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvacol5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				incasari_ag: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				costuri_ag: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				marja_ag: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tva_ag: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pret_vanzare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pret_cumparare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				marja_antic: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tva_antic: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				achizitiipe: { dataType: "Boolean", isNullable: false },
				achizitiicr: { dataType: "Boolean", isNullable: false },
				achizitiicb: { dataType: "Boolean", isNullable: false },
				achizitiici: { dataType: "Boolean", isNullable: false },
				achizitiia: { dataType: "Boolean", isNullable: false },
				achizitiib24: { dataType: "Boolean", isNullable: false },
				achizitiib20: { dataType: "Boolean", isNullable: false },
				achizitiib19: { dataType: "Boolean", isNullable: false },
				achizitiib9: { dataType: "Boolean", isNullable: false },
				achizitiib5: { dataType: "Boolean", isNullable: false },
				achizitiis24: { dataType: "Boolean", isNullable: false },
				achizitiis20: { dataType: "Boolean", isNullable: false },
				achizitiis19: { dataType: "Boolean", isNullable: false },
				achizitiis9: { dataType: "Boolean", isNullable: false },
				achizitiis5: { dataType: "Boolean", isNullable: false },
				importb: { dataType: "Boolean", isNullable: false },
				acinecorp: { dataType: "Boolean", isNullable: false },
				livraribi: { dataType: "Boolean", isNullable: false },
				bun24: { dataType: "Boolean", isNullable: false },
				bun20: { dataType: "Boolean", isNullable: false },
				bun19: { dataType: "Boolean", isNullable: false },
				bun9: { dataType: "Boolean", isNullable: false },
				bun5: { dataType: "Boolean", isNullable: false },
				valoarescutit: { dataType: "Boolean", isNullable: false },
				bunti: { dataType: "Boolean", isNullable: false },
				prest24: { dataType: "Boolean", isNullable: false },
				prest20: { dataType: "Boolean", isNullable: false },
				prest19: { dataType: "Boolean", isNullable: false },
				prest9: { dataType: "Boolean", isNullable: false },
				prest5: { dataType: "Boolean", isNullable: false },
				prestscutit: { dataType: "Boolean", isNullable: false },
				lintra: { dataType: "Boolean", isNullable: false },
				prestintra: { dataType: "Boolean", isNullable: false },
				export: { dataType: "Boolean", isNullable: false },
				livinecorp: { dataType: "Boolean", isNullable: false },
				efectuat: { dataType: "Boolean", isNullable: false },
				prsAfiliat: { dataType: "Boolean", isNullable: false },
			}
		},
		"sief.DeclaratieFactura": {
			shortName: "DeclaratieFactura",
			namespace: "sief",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idUser: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stamp: { dataType: "DateTime", isNullable: false },
				uid: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDeclaratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tip_factura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				serie: { maxLength: 10, defaultValue: "" },
				nr: { maxLength: 20, defaultValue: "" },
				baza24: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				baza20: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				baza19: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				baza9: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				baza5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tva5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tva9: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tva19: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tva20: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tva24: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
			}
		},
		"sief.DeclaratieOp2": {
			shortName: "DeclaratieOp2",
			namespace: "sief",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idUser: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stamp: { dataType: "DateTime", isNullable: false },
				uid: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDeclaratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tip_op2: { maxLength: 2, defaultValue: "" },
				luna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nramef: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nrbf: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				total: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				baza20: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				baza9: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				baza5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tva20: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tva9: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tva5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				baza19: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tva19: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
			}
		},
		"sief.DeclaratieSerieFactura": {
			shortName: "DeclaratieSerieFactura",
			namespace: "sief",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idUser: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stamp: { dataType: "DateTime" },
				uid: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDeclaratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				seriei: { maxLength: 10, defaultValue: "" },
				nri: { maxLength: 20, defaultValue: "" },
				nrf: { maxLength: 20, defaultValue: "" },
				den: { maxLength: 100, defaultValue: "" },
				cui: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
			}
		},
		"sief.TipDocument": {
			shortName: "TipDocument",
			namespace: "sief",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				isTvaIncasare: { dataType: "Int32", defaultValue: 0 },
				isFactura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isBorderouAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isCarnetComercializare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isContract: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isAltDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isFacturaSimplificata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isFacturaSimplificataCod: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isBonFiscal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isBonFiscalCod: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"sief.TipTva": {
			shortName: "TipTva",
			namespace: "sief",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				procent: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isVanzare: { dataType: "Boolean" },
				isCumparare: { dataType: "Boolean" },
				idUser: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stamp: { dataType: "DateTime", isNullable: false },
				lunaInceput: { dataType: "Int32", defaultValue: 0 },
				lunaSfarsit: { dataType: "Int32", defaultValue: 0 },
				descriere: { maxLength: 200, defaultValue: "" },
				isVirtual: { dataType: "Boolean" },
				isCurent: { dataType: "Boolean", isNullable: false },
				isNedeductibil: { dataType: "Boolean", isNullable: false },
				idSaft: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codEfactura: { maxLength: 10, defaultValue: "" },
				codScutire: { maxLength: 50, defaultValue: "" },
				codSafT: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"situatiipersonal.Formular": {
			shortName: "Formular",
			namespace: "situatiipersonal",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				iD: { dataType: "Int32", isPartOfKey: true },
				nrrnd: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nrrndf: { maxLength: 10, defaultValue: "" },
				denumire: { maxLength: 200, defaultValue: "" },
				cod: { maxLength: 20, defaultValue: "" },
				um: { maxLength: 50, defaultValue: "" },
				tip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anexa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				trimestru: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sursa: { maxLength: 200, defaultValue: "" },
				titlu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"unitati.Functie": {
			shortName: "Functie",
			namespace: "unitati",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
			}
		},
		"unitati.OperatiuneActivitateEconomicaClasificatieBugetara": {
			shortName: "OperatiuneActivitateEconomicaClasificatieBugetara",
			namespace: "unitati",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "", custom: { MS_Description: "Denumirea operatiunii" } },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Poate fi 0 sau completat  {Sig" } },
				isProiect: { dataType: "Boolean", isNullable: false },
				dataStart: { dataType: "DateTime" },
				dataStop: { dataType: "DateTime" },
				dataStopOperare: { dataType: "DateTime", custom: { MS_Description: "Data pana la care se mai pot o" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"unitati.OperatiuneActivitateEconomicaClasificatieBugetaraDetaliu": {
			shortName: "OperatiuneActivitateEconomicaClasificatieBugetaraDetaliu",
			namespace: "unitati",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOperatiuneActivitateEconomicaClasificatieBugetara: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "{Sigma.unitati.OperatiuneActiv" } },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: " {Sigma.unitatiIstoric.Organiz" } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Bugetul asociat {Sigma-Buget.b" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia functionala asoci" } },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Articolul: {Sigma-Buget.bugetI" } },
				procent: { dataType: "Decimal", isNullable: false, defaultValue: 0, custom: { MS_Description: "Procentul pentru distribuirea " } },
				isRetinereGarantieBunaExecutie: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Pe aceasta AE+clasificatie est" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				codModDistribuireClasificatieMultipla: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Modul de distribuire pentru cl" } },
			}
		},
		"unitati.OrganizareInstitutionala": {
			shortName: "OrganizareInstitutionala",
			namespace: "unitati",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				treeLevel: { dataType: "Int32", defaultValue: 0 },
				parentHierarchyIdChar: { defaultValue: "" },
				nume: { maxLength: 150, defaultValue: "" },
				isActivitateProprie: { dataType: "Boolean" },
				isConformHcl: { dataType: "Boolean" },
				isVizibilSalarii: { dataType: "Boolean" },
				isVizibilGestiuni: { dataType: "Boolean" },
				isVizibilMijloaceFixe: { dataType: "Boolean" },
				isVizibilContabilitate: { dataType: "Boolean" },
				isVizibilImpotax: { dataType: "Boolean" },
				isVizibilRegistruAgricol: { dataType: "Boolean" },
				isVizibilAsisoc: { dataType: "Boolean" },
				isVizibilInvatamant: { dataType: "Boolean" },
				isMagazie: { dataType: "Boolean", isNullable: false },
				isPost: { dataType: "Boolean", isNullable: false },
				isCasierie: { dataType: "Boolean", isNullable: false },
				isCentruCheltuiala: { dataType: "Boolean", isNullable: false },
				isCantina: { dataType: "Boolean", isNullable: false },
				idPostNume: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isPlatitorTVA: { dataType: "Boolean" },
				isSistemTVAIncasare: { dataType: "Boolean" },
				isRaportatFinanciar: { dataType: "Boolean" },
				isProiect: { dataType: "Boolean", isNullable: false },
				isComert: { dataType: "Boolean", isNullable: false },
				codSMIS: { maxLength: 50, defaultValue: "" },
				codFiscalTVA: { maxLength: 50, defaultValue: "" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				importDataJson: { defaultValue: "" },
				isOrdonatorPrincipal: { dataType: "Boolean", isNullable: false },
				importEcubMifixId: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				importEcubGestStocId: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				codFiscalImpozitSalar: { maxLength: 50, defaultValue: "" },
			}
		},
		"unitati.OrganizareInstitutionalaBugetClasificatieFunctionala": {
			shortName: "OrganizareInstitutionalaBugetClasificatieFunctionala",
			namespace: "unitati",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Nodul  pentru care se asociaza" } },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Bugetul asociat {Sigma-Buget.b" } },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Clasificatia asociata {Sigma-B" } },
				codAnaliticContabilitate: { maxLength: 50, defaultValue: "" },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Activitatea economica cu care " } },
				idPersoanaResponsabil: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Persoana care este atasata de " } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"unitati.Post": {
			shortName: "Post",
			namespace: "unitati",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionala: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"unitati.UnitateSectorBugetarOpcOsc": {
			shortName: "UnitateSectorBugetarOpcOsc",
			namespace: "unitati",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Unitatea, cheie comuna {Sigma." } },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Sectorul bugetar, cheie comuna" } },
				idCodProgramBugetarPropus: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Codul de program bugetar, id c" } },
				cuiOpcOsc: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Codul fiscal al Ordonatorului " } },
				numeOpcOsc: { maxLength: 250, defaultValue: "", custom: { MS_Description: "Numele Ordonatorului Principal" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
			}
		},
		"unitati.UnitateSetare": {
			shortName: "UnitateSetare",
			namespace: "unitati",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isOrdonatorPrincipal: { dataType: "Boolean", isNullable: false },
				numeOrdonatorPrincipalCredite: { maxLength: 50, defaultValue: "" },
				numeDirectorGeneral: { maxLength: 50, defaultValue: "" },
				numeAdministratorPublic: { maxLength: 100, defaultValue: "" },
				sumaMaximaSemnatura: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				numeInstitutiePublicaIerarhicSuperioara: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				grupDarsBilant: { maxLength: 50, defaultValue: "" },
				urlCID: { defaultValue: "" },
				codUnitateTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"unitatiistoric.OrganizareInstitutionala": {
			shortName: "OrganizareInstitutionala",
			namespace: "unitatiistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", defaultValue: 0, custom: { Caption: "Unitate", MS_Description: "Id-ul comun al persoanei unita" } },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				treeLevel: { dataType: "Int32", defaultValue: 0 },
				parentHierarchyIdChar: { defaultValue: "" },
				nume: { maxLength: 150, defaultValue: "" },
				idOrganizareInstitutionala: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				isActivitateProprie: { dataType: "Boolean" },
				isConformHcl: { dataType: "Boolean" },
				isVizibilSalarii: { dataType: "Boolean" },
				isVizibilGestiuni: { dataType: "Boolean" },
				isVizibilMijloaceFixe: { dataType: "Boolean" },
				isVizibilContabilitate: { dataType: "Boolean" },
				isVizibilImpotax: { dataType: "Boolean" },
				isVizibilRegistruAgricol: { dataType: "Boolean" },
				isVizibilAsisoc: { dataType: "Boolean" },
				isVizibilInvatamant: { dataType: "Boolean" },
				isMagazie: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Marcheaza nodul ca fiind de ti" } },
				isPost: { dataType: "Boolean", isNullable: false },
				isCasierie: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Marcaj pentru Casierie" } },
				isCentruCheltuiala: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Pentru contabilitate: nodurile" } },
				isCantina: { dataType: "Boolean", isNullable: false },
				idPostNume: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Se foloseste doar pentru postu" } },
				isPlatitorTVA: { dataType: "Boolean" },
				isSistemTVAIncasare: { dataType: "Boolean" },
				isRaportatFinanciar: { dataType: "Boolean", custom: { MS_Description: "activiatea economica pentru ca" } },
				isProiect: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Marcaj nod de tip proiect (de " } },
				isComert: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "Flag pentru gestiuni de tip ma" } },
				codSMIS: { maxLength: 50, defaultValue: "", custom: { MS_Description: "codul SMIS pentru proiecte" } },
				codFiscalTVA: { maxLength: 50, defaultValue: "" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				importDataJson: { defaultValue: "" },
				isOrdonatorPrincipal: { dataType: "Boolean", isNullable: false, custom: { MS_Description: "1 - este ordonator principal d" } },
				importEcubMifixId: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Conversii/import de date: Id-u" } },
				importEcubGestStocId: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "Conversii/import de date: Id-u" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				codFiscalImpozitSalar: { maxLength: 50, defaultValue: "" },
			}
		},
		"unitatiistoric.Post": {
			shortName: "Post",
			namespace: "unitatiistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPost: { dataType: "Int32" },
				idOrganizareInstitutionala: { dataType: "Int32", defaultValue: 0, custom: { MS_Description: "Id-ul unic nodului (departamen" } },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"unitatiistoric.UnitateSetare": {
			shortName: "UnitateSetare",
			namespace: "unitatiistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { Caption: "Unitate", MS_Description: "Id-ul comun al persoanei unita" } },
				isOrdonatorPrincipal: { dataType: "Boolean", isNullable: false },
				numeOrdonatorPrincipalCredite: { maxLength: 50, defaultValue: "" },
				numeDirectorGeneral: { maxLength: 50, defaultValue: "" },
				numeAdministratorPublic: { maxLength: 100, defaultValue: "" },
				sumaMaximaSemnatura: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idUnitateSetare: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				numeInstitutiePublicaIerarhicSuperioara: { maxLength: 50, defaultValue: "", custom: { MS_Description: "numele institutiei publice ier" } },
				userNameActualizare: { maxLength: 256, defaultValue: "", custom: { MS_Description: "utilizatorul care a facut ulti" } },
				dataActualizare: { dataType: "DateTime", custom: { MS_Description: "data si ora la care s-a facut " } },
				grupDarsBilant: { maxLength: 50, defaultValue: "", custom: { MS_Description: "folosit pentru grupurile din D" } },
				urlCID: { defaultValue: "", custom: { MS_Description: "Url pentru legatura cu CID" } },
				codUnitateTip: { dataType: "Int32", isNullable: false, defaultValue: 0, custom: { MS_Description: "401 Consilii, 501 Municipii , " } },
			}
		},
		"vehicol.Auto": {
			shortName: "Auto",
			namespace: "vehicol",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				numarIdentificare: { maxLength: 20, defaultValue: "" },
				idAutoCategorieRAR: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idAutoCategorieFiscal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idAutoModel: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				idAuto: { dataType: "Int32" },
			}
		},
		"vehicol.AutoCategorieFiscal": {
			shortName: "AutoCategorieFiscal",
			namespace: "vehicol",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
			}
		},
		"vehicol.AutoCategorieRAR": {
			shortName: "AutoCategorieRAR",
			namespace: "vehicol",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
			}
		},
		"vehicol.AutoMarca": {
			shortName: "AutoMarca",
			namespace: "vehicol",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
			}
		},
		"vehicol.AutoModel": {
			shortName: "AutoModel",
			namespace: "vehicol",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idAutoMarca: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 50, defaultValue: "" },
			}
		},
		"vehicol.AutoPersoana": {
			shortName: "AutoPersoana",
			namespace: "vehicol",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idAuto: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormaDetinere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarInmatriculare: { maxLength: 10, defaultValue: "" },
				dataInceputDetinere: { dataType: "DateTime" },
				dataSfarsitDetinere: { dataType: "DateTime" },
			}
		},
		"vehicol.test": {
			shortName: "test",
			namespace: "vehicol",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codRol: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				coduriCaen: { maxLength: 200, defaultValue: "" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				idPersoanaTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idParinte: { dataType: "Int32", defaultValue: 0 },
				cUI: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 100, defaultValue: "" },
				prenume: { maxLength: 150, defaultValue: "" },
				prenumeMama: { maxLength: 150, defaultValue: "" },
				prenumeTata: { maxLength: 150, defaultValue: "" },
				sex: { maxLength: 1, defaultValue: "" },
				dataDeces: { dataType: "DateTime" },
				telefon: { maxLength: 10, defaultValue: "" },
				email: { maxLength: 50, defaultValue: "" },
				actTip: { maxLength: 20, defaultValue: "" },
				actSerieNumar: { maxLength: 50, defaultValue: "" },
				actDataEliberare: { dataType: "DateTime" },
				actDataExpirare: { dataType: "DateTime" },
				actEliberatDe: { maxLength: 50, defaultValue: "" },
				atributFiscal: { maxLength: 10, defaultValue: "" },
				idPersoanaContact: { dataType: "Int32", defaultValue: 0 },
				idPersoanaReprezentant: { dataType: "Int32", defaultValue: 0 },
				ocupatie: { maxLength: 50, defaultValue: "" },
				angajator: { maxLength: 50, defaultValue: "" },
				functieOcupata: { maxLength: 50, defaultValue: "" },
				nivelStudii: { maxLength: 50, defaultValue: "" },
				adresa: { maxLength: 150, defaultValue: "" },
				idTara: { dataType: "Int32", defaultValue: 0 },
				idLocalitate: { dataType: "Int32", defaultValue: 0 },
				idStrada: { dataType: "Int32", defaultValue: 0 },
				idNumarPostal: { dataType: "Int32", defaultValue: 0 },
				idBloc: { dataType: "Int32", defaultValue: 0 },
				idScara: { dataType: "Int32", defaultValue: 0 },
				idApartament: { dataType: "Int32", defaultValue: 0 },
				codPostal: { maxLength: 10, defaultValue: "" },
				idEtnie: { dataType: "Int32", defaultValue: 0 },
				idReligie: { dataType: "Int32", defaultValue: 0 },
				idNationalitate: { dataType: "Int32", defaultValue: 0 },
				cetatenie: { maxLength: 20, defaultValue: "" },
				vizibilImpotax: { dataType: "Boolean", isNullable: false },
				vizibilEcub: { dataType: "Boolean", isNullable: false },
				vizibilAgroregis: { dataType: "Boolean", isNullable: false },
				vizibilResum: { dataType: "Boolean", isNullable: false },
			}
		},
		"vehicoldb.Auto": {
			shortName: "Auto",
			namespace: "vehicoldb",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				numarIdentificare: { maxLength: 20, defaultValue: "" },
				idAutoCategorieRAR: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idAutoCategorieFiscal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idAutoModel: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				idAuto: { dataType: "Int32" },
			}
		},
		"w_achizitii.Achizitie": {
			shortName: "Achizitie",
			namespace: "w_achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				numarAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipProceduraAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStartProcedura: { dataType: "DateTime" },
				dataStopProcedura: { dataType: "DateTime" },
				idPersoanaResponsabila: { dataType: "Int32", defaultValue: 0 },
				idCPV: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anuntParticipare: { dataType: "Boolean", isNullable: false },
				publicatSite: { dataType: "Boolean", isNullable: false },
				publicatSEAP: { dataType: "Boolean", isNullable: false },
				publicatSEAPNr: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				publicatSEAPData: { dataType: "DateTime" },
				idProgramPozitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipObiectAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutarEstimat: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareEstimataValuta: { dataType: "Decimal", defaultValue: 0 },
				valoareEstimataLei: { dataType: "Decimal", defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareValuta: { dataType: "Decimal", defaultValue: 0 },
				valoareLei: { dataType: "Decimal", defaultValue: 0 },
				idCriteriu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				proceduraFinalizata: { dataType: "Boolean", isNullable: false },
				idValuta: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				procentGarantieParticipareLicitatie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareValutaGarantieParticipareLicitatie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareLeiGarantieParticipareLicitatie: { dataType: "Decimal", defaultValue: 0 },
			}
		},
		"w_achizitii.AchizitieOferta": {
			shortName: "AchizitieOferta",
			namespace: "w_achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaOfertant: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaReprezentant: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valoareLei: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				punctaj: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isCastigator: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_achizitii.AchizitieSursaFinantare": {
			shortName: "AchizitieSursaFinantare",
			namespace: "w_achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				procent: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_achizitii.AnBlocarePropuneri": {
			shortName: "AnBlocarePropuneri",
			namespace: "w_achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUtilizatorBlocare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataBlocare: { dataType: "DateTime", isNullable: false },
				isBlocat: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_achizitii.Criteriu": {
			shortName: "Criteriu",
			namespace: "w_achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_achizitii.DocumentReferatNecesitate": {
			shortName: "DocumentReferatNecesitate",
			namespace: "w_achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCPV: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				rezolutie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentTipJustificativ: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipModalitateAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaOfertant: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				apareSite: { dataType: "Boolean", isNullable: false },
				apareSeap: { dataType: "Boolean", isNullable: false },
				idProgramPozitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_achizitii.DocumentReferatNecesitateSursaFinantare": {
			shortName: "DocumentReferatNecesitateSursaFinantare",
			namespace: "w_achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentReferatNecesitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				procent: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_achizitii.DocumentReferatPropunere": {
			shortName: "DocumentReferatPropunere",
			namespace: "w_achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", defaultValue: 0 },
				idOrganizareInstitutionalaEmitent: { dataType: "Int32", defaultValue: 0 },
				idPersoanaEmitent: { dataType: "Int32", defaultValue: 0 },
				dataAdaugare: { dataType: "DateTime" },
				an: { dataType: "Int32", defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dataStartProcedura: { dataType: "DateTime" },
				dataStopProcedura: { dataType: "DateTime" },
				isCerereProcedura: { dataType: "Boolean", isNullable: false },
				numarZileProcedura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", defaultValue: 0 },
				titlu: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				isContract: { dataType: "Boolean", isNullable: false },
				sumaCumpararilorDirecte: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idPersoanaVerificare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaIncadrareBugetara: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCPV: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_achizitii.ProceduraTipEtapa": {
			shortName: "ProceduraTipEtapa",
			namespace: "w_achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idTipProceduraAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 50, defaultValue: "" },
				numarOrdine: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isOptionala: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_achizitii.ProceduraTipEtapaDocumentTip": {
			shortName: "ProceduraTipEtapaDocumentTip",
			namespace: "w_achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				idProceduraTipEtapa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_achizitii.ProceduraTipEtapaDocumentTipModel": {
			shortName: "ProceduraTipEtapaDocumentTipModel",
			namespace: "w_achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProceduraTipEtapaDocumentTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_achizitii.Program": {
			shortName: "Program",
			namespace: "w_achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaProiect: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataProgram: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_achizitii.ProgramPozitieReferatNecesitateDetaliu": {
			shortName: "ProgramPozitieReferatNecesitateDetaliu",
			namespace: "w_achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProgramPozitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentReferatNecesitateDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_achizitii.ProgramPozitieReferatPropunereDetaliu": {
			shortName: "ProgramPozitieReferatPropunereDetaliu",
			namespace: "w_achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProgramPozitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentReferatPropunereDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dataStartProcedura: { dataType: "DateTime" },
				dataStopProcedura: { dataType: "DateTime" },
				isCerereProcedura: { dataType: "Boolean", isNullable: false },
				numarZileProcedura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_achizitii.ProgramPozitieSursaFinantare": {
			shortName: "ProgramPozitieSursaFinantare",
			namespace: "w_achizitii",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProgramPozitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				procent: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_achizitiiistoric.AchizitieDetaliu": {
			shortName: "AchizitieDetaliu",
			namespace: "w_achizitiiistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentReferatNecesitateDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCodCPV: { dataType: "Int32", defaultValue: 0 },
				descriereProdus: { maxLength: 150, defaultValue: "" },
				caracteristiciProdus: { maxLength: 250, defaultValue: "" },
				idUnitateMasura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarLei: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", defaultValue: 0 },
				pretUnitarValuta: { dataType: "Decimal", defaultValue: 0 },
				valoareLei: { dataType: "Decimal", defaultValue: 0 },
				valoareValuta: { dataType: "Decimal", defaultValue: 0 },
				idAchizitieDetaliu: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_achizitiiistoric.DocumentReferatNecesitateDetaliu": {
			shortName: "DocumentReferatNecesitateDetaliu",
			namespace: "w_achizitiiistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentReferatNecesitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProgramPozitieReferatPropunereDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				descriereProdus: { maxLength: 150, defaultValue: "" },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCPV: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idUnitateMasura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				caracteristici: { maxLength: 250, defaultValue: "" },
				justificare: { maxLength: 250, defaultValue: "" },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarLei: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarValuta: { dataType: "Decimal", defaultValue: 0 },
				valoareLei: { dataType: "Decimal", defaultValue: 0 },
				valoareValuta: { dataType: "Decimal", defaultValue: 0 },
				idDocumentReferatNecesitateDetaliu: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				rezolutie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_achizitiiistoric.DocumentReferatPropunereDetaliu": {
			shortName: "DocumentReferatPropunereDetaliu",
			namespace: "w_achizitiiistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentReferatPropunere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				descriere: { maxLength: 250, defaultValue: "" },
				idProdusCategorie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCPV: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idUnitateMasura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarLei: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarValuta: { dataType: "Decimal", defaultValue: 0 },
				valoareLei: { dataType: "Decimal", defaultValue: 0 },
				valoareValuta: { dataType: "Decimal", defaultValue: 0 },
				dataNecesitate: { dataType: "DateTime", isNullable: false },
				idDocumentReferatPropunereDetaliu: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				dataStartProcedura: { dataType: "DateTime" },
				dataStopProcedura: { dataType: "DateTime" },
				isCerereProcedura: { dataType: "Boolean", isNullable: false },
				numarZileProcedura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				justificare: { defaultValue: "" },
				modDeCalculAlValoriiEstimate: { defaultValue: "" },
				idBuget: { dataType: "Int32", defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_achizitiiistoric.ProgramPozitie": {
			shortName: "ProgramPozitie",
			namespace: "w_achizitiiistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 255, defaultValue: "" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipObiectAchizitie: { dataType: "Int32", defaultValue: 0 },
				idTipProceduraAchizitie: { dataType: "Int32", defaultValue: 0 },
				idCPV: { dataType: "Int32", defaultValue: 0 },
				idPersoanaResponsabil: { dataType: "Int32", defaultValue: 0 },
				idObiectivInvestitie: { dataType: "Int32", defaultValue: 0 },
				numarCurent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codTipLimitareSuma: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isAnuntIntentie: { dataType: "Boolean", isNullable: false },
				isVerificareProcedurala: { dataType: "Boolean", isNullable: false },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareLei: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareEuro: { dataType: "Decimal", defaultValue: 0 },
				idProgramPozitie: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userEmitent: { maxLength: 100, defaultValue: "" },
				idProgram: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStartProcedura: { dataType: "DateTime" },
				dataStopProcedura: { dataType: "DateTime" },
				modDesfasurareProcedura: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_admin.HelpPageTemplate": {
			shortName: "HelpPageTemplate",
			namespace: "w_admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				template: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_admin.Notificare": {
			shortName: "Notificare",
			namespace: "w_admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				mesaj: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_admin.RefreshToken": {
			shortName: "RefreshToken",
			namespace: "w_admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				uUId: { maxLength: 128, defaultValue: "" },
				subject: { maxLength: 50, defaultValue: "" },
				idClient: { maxLength: 50, defaultValue: "" },
				issuedUtc: { dataType: "DateTime" },
				expiresUtc: { dataType: "DateTime" },
				protectedTicket: { defaultValue: "" },
				refreshTicket: { defaultValue: "" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_admin.RelatiiTabele": {
			shortName: "RelatiiTabele",
			namespace: "w_admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				relationType: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				relationTypeUpdateIdUnic: { dataType: "Boolean", isNullable: false },
				databaseNameFK: { maxLength: 200, defaultValue: "" },
				schemaNameFK: { maxLength: 200, defaultValue: "" },
				tableNameFK: { maxLength: 200, defaultValue: "" },
				fieldNameFK: { maxLength: 200, defaultValue: "" },
				databaseNamePK: { maxLength: 200, defaultValue: "" },
				schemaNamePK: { maxLength: 200, defaultValue: "" },
				tableNamePK: { maxLength: 200, defaultValue: "" },
				value: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				errorMessage: { maxLength: 2000, defaultValue: "" },
				whereClause: { maxLength: 500, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_admin.Resursa": {
			shortName: "Resursa",
			namespace: "w_admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				parentId: { maxLength: 892, defaultValue: "" },
				level: { dataType: "Int32", defaultValue: 0 },
				idResursaControl: { dataType: "Int32", defaultValue: 0 },
				idProdusSief: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 200, defaultValue: "" },
				nivelAcces: { dataType: "Int32", defaultValue: 0 },
				customAcces: { dataType: "Boolean", isNullable: false },
				cod: { maxLength: 50, defaultValue: "" },
				type: { maxLength: 3, defaultValue: "" },
				icon: { maxLength: 50, defaultValue: "" },
				url: { maxLength: 200, defaultValue: "" },
				html: { maxLength: 200, defaultValue: "" },
				options: { defaultValue: "" },
				tooltip: { maxLength: 200, defaultValue: "" },
				searchQuery: { defaultValue: "" },
				helpPage: { defaultValue: "" },
				devOnly: { dataType: "Boolean" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				configReadOnly: { dataType: "Boolean", isNullable: false },
				dataActualizare: { dataType: "DateTime" },
				numeComponenta: { maxLength: 50, defaultValue: "" },
				urlDefaultParams: { maxLength: 200, defaultValue: "" },
				tabOptions: { defaultValue: "" },
				searchOptions: { defaultValue: "" },
				isStartMenu: { dataType: "Boolean", isNullable: false },
				isSplitCaret: { dataType: "Boolean", isNullable: false },
			}
		},
		"w_admin.ResursaControl": {
			shortName: "ResursaControl",
			namespace: "w_admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 50, defaultValue: "" },
				type: { maxLength: 3, defaultValue: "" },
				nume: { maxLength: 50, defaultValue: "" },
				definition: { defaultValue: "" },
				caption: { maxLength: 50, defaultValue: "" },
				icon: { maxLength: 50, defaultValue: "" },
				html: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_admin.ResursaRol": {
			shortName: "ResursaRol",
			namespace: "w_admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_admin.Rol": {
			shortName: "Rol",
			namespace: "w_admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				_Id: { maxLength: 128, defaultValue: "" },
				nume: { maxLength: 256, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				isPredefinit: { dataType: "Boolean", isNullable: false },
			}
		},
		"w_admin.RolAcces": {
			shortName: "RolAcces",
			namespace: "w_admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idRol: { maxLength: 128, defaultValue: "" },
				idResursa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nivelAcces: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_admin.Setare": {
			shortName: "Setare",
			namespace: "w_admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idAplicatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idResursa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				codTmp: { maxLength: 50, defaultValue: "" },
				descriere: { maxLength: 250, defaultValue: "" },
				valoare: { defaultValue: "" },
				valoareTip: { maxLength: 50, defaultValue: "" },
				valoareSursa: { defaultValue: "" },
				template: { defaultValue: "" },
				nivelSetare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				categorie: { maxLength: 50, defaultValue: "" },
				cod: { maxLength: 250, defaultValue: "" },
				isUserEditable: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_admin.SetareUnitate": {
			shortName: "SetareUnitate",
			namespace: "w_admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSetare: { dataType: "Int32", defaultValue: 0 },
				valoare: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_admin.SetareUnitateUser": {
			shortName: "SetareUnitateUser",
			namespace: "w_admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idSetare: { dataType: "Int32", defaultValue: 0 },
				idUnitate: { dataType: "Int32", defaultValue: 0 },
				userName: { maxLength: 50, defaultValue: "" },
				valoare: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_admin.SetareUser": {
			shortName: "SetareUser",
			namespace: "w_admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idSetare: { dataType: "Int32", defaultValue: 0 },
				userName: { maxLength: 50, defaultValue: "" },
				valoare: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_admin.User": {
			shortName: "User",
			namespace: "w_admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				_Id: { maxLength: 128, defaultValue: "" },
				nume: { maxLength: 100, defaultValue: "" },
				email: { maxLength: 256, defaultValue: "" },
				emailConfirmed: { dataType: "Boolean", isNullable: false },
				passwordHash: { defaultValue: "" },
				securityStamp: { defaultValue: "" },
				phoneNumber: { defaultValue: "" },
				phoneNumberConfirmed: { dataType: "Boolean" },
				twoFactorEnabled: { dataType: "Boolean", isNullable: false },
				lockoutEndDateUtc: { maxLength: 50, defaultValue: "" },
				lockoutEnabled: { dataType: "Boolean", isNullable: false },
				accessFailedCount: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userName: { maxLength: 256, defaultValue: "" },
				idPersoana: { dataType: "Int32", defaultValue: 0 },
				passwordReset: { dataType: "Boolean" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_admin.UserAcces": {
			shortName: "UserAcces",
			namespace: "w_admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idUser: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userName: { maxLength: 50, defaultValue: "" },
				idResursa: { dataType: "Int32", defaultValue: 0 },
				nivelAcces: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_admin.UserCid": {
			shortName: "UserCid",
			namespace: "w_admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				userName: { maxLength: 256, defaultValue: "" },
				idUser: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUserCid: { dataType: "Int32", isNullable: false },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_admin.UserUnitate": {
			shortName: "UserUnitate",
			namespace: "w_admin",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idUser: { dataType: "Int32", defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", defaultValue: 0 },
				nivelAcces: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_adrese.LocalitateTip": {
			shortName: "LocalitateTip",
			namespace: "w_adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				mediu: { maxLength: 20, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_adrese.Regiune": {
			shortName: "Regiune",
			namespace: "w_adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				siruta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_adrese.StradaTip": {
			shortName: "StradaTip",
			namespace: "w_adrese",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 30, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				descriere: { defaultValue: "" },
			}
		},
		"w_adreseistoric.Apartament": {
			shortName: "Apartament",
			namespace: "w_adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idScara: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 10, defaultValue: "" },
				etaj: { dataType: "Decimal", defaultValue: 0 },
				idApartament: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_adreseistoric.Bloc": {
			shortName: "Bloc",
			namespace: "w_adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idNumarPostal: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 10, defaultValue: "" },
				numarEtaje: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarApartamente: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBloc: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_adreseistoric.Judet": {
			shortName: "Judet",
			namespace: "w_adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idTara: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 50, defaultValue: "" },
				simbol: { maxLength: 3, defaultValue: "" },
				cod: { maxLength: 20, defaultValue: "" },
				idJudet: { dataType: "Int32" },
				gUId: { defaultValue: null },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_adreseistoric.Localitate": {
			shortName: "Localitate",
			namespace: "w_adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idJudet: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 50, defaultValue: "" },
				idLocalitateRang: { dataType: "Int32", defaultValue: 0 },
				idLocalitateUAT: { dataType: "Int32", defaultValue: 0 },
				idLocalitateTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idRegiune: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				rang: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				gUId: { defaultValue: null },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				idLocalitate: { dataType: "Int32" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				import_LocCod: { maxLength: 250, defaultValue: "" },
				import_LocJudCod: { maxLength: 250, defaultValue: "" },
				import_CodPolitie: { maxLength: 250, defaultValue: "" },
				import_CodPolitieTata: { maxLength: 250, defaultValue: "" },
				import_CodSiruta: { maxLength: 250, defaultValue: "" },
				import_CodSirutaTata: { maxLength: 250, defaultValue: "" },
			}
		},
		"w_adreseistoric.NumarPostal": {
			shortName: "NumarPostal",
			namespace: "w_adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idStrada: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 30, defaultValue: "" },
				codPostal: { maxLength: 10, defaultValue: "" },
				idNumarPostal: { dataType: "Int32" },
				gUId: { defaultValue: null },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				userNameActualizareGIS: { maxLength: 256, defaultValue: "" },
				dataActualizareGIS: { dataType: "DateTime" },
				serverActualizareGIS: { maxLength: 256, defaultValue: "" },
				idImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_adreseistoric.NumarPostalZona": {
			shortName: "NumarPostalZona",
			namespace: "w_adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				idNumarPostalZonaTip: { dataType: "Int32", defaultValue: 0 },
				idNumarPostalZona: { dataType: "Int32" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_adreseistoric.Scara": {
			shortName: "Scara",
			namespace: "w_adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBloc: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 10, defaultValue: "" },
				idScara: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_adreseistoric.Strada": {
			shortName: "Strada",
			namespace: "w_adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idLocalitate: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 50, defaultValue: "" },
				cod: { maxLength: 20, defaultValue: "" },
				idStradaTip: { dataType: "Int32", defaultValue: 0 },
				idStrada: { dataType: "Int32" },
				gUId: { defaultValue: null },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				import_LocCod: { maxLength: 250, defaultValue: "" },
				import_LocJudCod: { maxLength: 250, defaultValue: "" },
				import_CodPolitie: { maxLength: 250, defaultValue: "" },
				import_CodPolitieLoc: { maxLength: 250, defaultValue: "" },
				numeSearchText: { maxLength: 2000, defaultValue: "" },
				idImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_adreseistoric.Tara": {
			shortName: "Tara",
			namespace: "w_adreseistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				cetatenie: { maxLength: 50, defaultValue: "" },
				idTara: { dataType: "Int32" },
				codTara: { maxLength: 2, defaultValue: "" },
				codISO: { maxLength: 3, defaultValue: "" },
				uE: { dataType: "Boolean", isNullable: false },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_asisocistoric.HandicapGrad": {
			shortName: "HandicapGrad",
			namespace: "w_asisocistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idHandicapGrad: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"w_asisocistoric.HandicapTip": {
			shortName: "HandicapTip",
			namespace: "w_asisocistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idHandicapTip: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"w_bilant.Formular": {
			shortName: "Formular",
			namespace: "w_bilant",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nrrnd: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nrrndf: { maxLength: 10, defaultValue: "" },
				denumire: { maxLength: 800, defaultValue: "" },
				cod: { maxLength: 20, defaultValue: "" },
				tip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anexa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				trimestru: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sursa: { maxLength: 4000, defaultValue: "" },
				titlu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				decalc: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sursa2: { maxLength: 4000, defaultValue: "" },
				sursa3: { maxLength: 4000, defaultValue: "" },
				sursa4: { maxLength: 4000, defaultValue: "" },
				sursa5: { maxLength: 4000, defaultValue: "" },
				sursa6: { maxLength: 4000, defaultValue: "" },
				sursa7: { maxLength: 4000, defaultValue: "" },
				sursa8: { maxLength: 4000, defaultValue: "" },
				sursa9: { maxLength: 4000, defaultValue: "" },
				sursa10: { maxLength: 4000, defaultValue: "" },
				sursa11: { maxLength: 4000, defaultValue: "" },
				sursa12: { maxLength: 4000, defaultValue: "" },
				sursa13: { maxLength: 4000, defaultValue: "" },
				sursa14: { maxLength: 4000, defaultValue: "" },
				sursa15: { maxLength: 4000, defaultValue: "" },
				nuLista: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_buget.BugetClasificatieFunctionalaSuma": {
			shortName: "BugetClasificatieFunctionalaSuma",
			namespace: "w_buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBugetRectificare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaInitial: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInitialCreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInfluente: { dataType: "Decimal", defaultValue: 0 },
				sumaInfluenteCreditAngajament: { dataType: "Decimal", defaultValue: 0 },
				sumaTotal: { dataType: "Decimal", defaultValue: 0 },
				sumaTotalCreditAngajament: { dataType: "Decimal", defaultValue: 0 },
				sumaTotalCorectie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTotalCorectieCreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInfluenteTrimestru1: { dataType: "Decimal", defaultValue: 0 },
				sumaInfluenteTrimestru1CreditAngajament: { dataType: "Decimal", defaultValue: 0 },
				sumaInitialTrimestru1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInitialTrimestru1CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru1CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInfluenteTrimestru2: { dataType: "Decimal", defaultValue: 0 },
				sumaInfluenteTrimestru2CreditAngajament: { dataType: "Decimal", defaultValue: 0 },
				sumaInitialTrimestru2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInitialTrimestru2CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru2CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInfluenteTrimestru3: { dataType: "Decimal", defaultValue: 0 },
				sumaInfluenteTrimestru3CreditAngajament: { dataType: "Decimal", defaultValue: 0 },
				sumaInitialTrimestru3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInitialTrimestru3CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru3CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInfluenteTrimestru4: { dataType: "Decimal", defaultValue: 0 },
				sumaInfluenteTrimestru4CreditAngajament: { dataType: "Decimal", defaultValue: 0 },
				sumaInitialTrimestru4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInitialTrimestru4CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru4CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaRestanta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn1CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn2CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn3CreditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				creditAngajamentSoldInitial: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				creditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				creditAngajamentTotal: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_buget.BugetRectificare": {
			shortName: "BugetRectificare",
			namespace: "w_buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBuget: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 50, defaultValue: "" },
				trimestru: { dataType: "Int32", defaultValue: 0 },
				dataRectificare: { dataType: "DateTime", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				dataAnulare: { dataType: "DateTime" },
				isPropunereDeRectificare: { dataType: "Boolean", isNullable: false },
				isBugetProvizoriu: { dataType: "Boolean", isNullable: false },
				importDataJson: { defaultValue: "" },
				idBugetRectificareSursa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentInregistrareCrediteBugetare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentInregistrareCrediteBugetareVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				initial: { dataType: "Boolean", isNullable: false },
				dataAprobare: { dataType: "DateTime" },
			}
		},
		"w_buget.BugetSuma": {
			shortName: "BugetSuma",
			namespace: "w_buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBugetRectificare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaInitial: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaInfluente: { dataType: "Decimal", defaultValue: 0 },
				sumaTotal: { dataType: "Decimal", defaultValue: 0 },
				sumaTotalCorectie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTrimestru4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaRestanta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				estimariAn3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				creditAngajamentSoldInitial: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				creditAngajament: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				creditAngajamentTotal: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_buget.DocumentAngajamentBugetar": {
			shortName: "DocumentAngajamentBugetar",
			namespace: "w_buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				scop: { maxLength: 100, defaultValue: "" },
				ibanPartener: { maxLength: 24, defaultValue: "" },
				isGlobal: { dataType: "Boolean", isNullable: false },
				explicatie: { maxLength: 250, defaultValue: "" },
				idCodProgramBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codProgramInvestitie: { maxLength: 10, defaultValue: "" },
				idObiectivInvestitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idObiectivInvestitieSubobiectiv: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isCreditAngajament: { dataType: "Boolean", isNullable: false },
				isIdenticPropunereCheltuiala: { dataType: "Boolean", isNullable: false },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isCuSold: { dataType: "Boolean", isNullable: false },
				soldNeplatit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_buget.DocumentAngajamentBugetarSuplimentare": {
			shortName: "DocumentAngajamentBugetarSuplimentare",
			namespace: "w_buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				modSuplimentare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_buget.DocumentAngajamentLegal": {
			shortName: "DocumentAngajamentLegal",
			namespace: "w_buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 250, defaultValue: "" },
				idLucrare: { dataType: "Int32", defaultValue: 0 },
				idAchizitie: { dataType: "Int32", defaultValue: 0 },
				codAngajament: { maxLength: 50, defaultValue: "" },
				codAngajamentInitial: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajamentInitial: { maxLength: 50, defaultValue: "" },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				soldNeplatit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isGlobal: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_buget.DocumentAngajamentLegalSuplimentare": {
			shortName: "DocumentAngajamentLegalSuplimentare",
			namespace: "w_buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				modSuplimentare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_buget.DocumentCerereCredite": {
			shortName: "DocumentCerereCredite",
			namespace: "w_buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataDreptUtilizare: { dataType: "DateTime" },
				tipSectiune: { maxLength: 1, defaultValue: "" },
				tipCerere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				explicatieNotaJustificativa: { defaultValue: "" },
			}
		},
		"w_buget.DocumentCerereCrediteSuma": {
			shortName: "DocumentCerereCrediteSuma",
			namespace: "w_buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaValuta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", defaultValue: 0 },
				sumaLei: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_buget.DocumentDeschidereCredite": {
			shortName: "DocumentDeschidereCredite",
			namespace: "w_buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				scop: { maxLength: 100, defaultValue: "" },
				explicatie: { maxLength: 200, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_buget.DocumentDeschidereCrediteSuma": {
			shortName: "DocumentDeschidereCrediteSuma",
			namespace: "w_buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaValuta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", defaultValue: 0 },
				sumaLei: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_buget.DocumentLichidare": {
			shortName: "DocumentLichidare",
			namespace: "w_buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 250, defaultValue: "" },
				codModGenerare: { dataType: "Int32", defaultValue: 0 },
				isCuSold: { dataType: "Boolean", isNullable: false },
				soldNeplatit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isViramentDirect: { dataType: "Boolean", isNullable: false },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isTransferIntreConturi: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idPlataNumerarTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_buget.DocumentOrdonantare": {
			shortName: "DocumentOrdonantare",
			namespace: "w_buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 250, defaultValue: "" },
				idPersoanaBancaPlatitor: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				ibanPlatitor: { maxLength: 24, defaultValue: "" },
				isAvans: { dataType: "Boolean", isNullable: false },
				modPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentDeviz: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIbanBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isPenalitateContractRetinutaLaPlata: { dataType: "Boolean", isNullable: false },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isCuSold: { dataType: "Boolean", isNullable: false },
				soldNeplatit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_buget.DocumentPropunereCheltuiala": {
			shortName: "DocumentPropunereCheltuiala",
			namespace: "w_buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				scop: { maxLength: 100, defaultValue: "" },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_buget.SumaExecutie": {
			shortName: "SumaExecutie",
			namespace: "w_buget",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaValuta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaLei: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_bugetistoric.AsociereCapitolSectorSursa": {
			shortName: "AsociereCapitolSectorSursa",
			namespace: "w_bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codCapitol: { maxLength: 2, defaultValue: "" },
				idAsociereCapitolSectorSursa: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_bugetistoric.Buget": {
			shortName: "Buget",
			namespace: "w_bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 150, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idBuget: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				importDataJson: { defaultValue: "" },
				isInLei: { dataType: "Boolean", isNullable: false },
				isCrediteAngajament: { dataType: "Boolean", isNullable: false },
				versiuneFormularBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				versiuneFormularDetaliere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_bugetistoric.BugetClasificatieFunctionala": {
			shortName: "BugetClasificatieFunctionala",
			namespace: "w_bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBuget: { dataType: "Int32", defaultValue: 0 },
				idClasificatieFunctionala: { dataType: "Int32", defaultValue: 0 },
				capitol: { maxLength: 10, defaultValue: "" },
				sursa: { maxLength: 10, defaultValue: "" },
				subcapitol: { maxLength: 10, defaultValue: "" },
				paragraf: { maxLength: 50, defaultValue: "" },
				codSufix: { maxLength: 100, defaultValue: "" },
				codClasificatie: { maxLength: 70, defaultValue: "" },
				codClasificatiePersonalizare: { maxLength: 170, defaultValue: "" },
				codClasificatieSursa: { maxLength: 80, defaultValue: "" },
				codClasificatieSursaPersonalizare: { maxLength: 180, defaultValue: "" },
				nume: { maxLength: 400, defaultValue: "" },
				codIBAN: { defaultValue: "" },
				codIBANPersonalizare: { defaultValue: "" },
				cui: { dataType: "Int32", defaultValue: 0 },
				numeDeschidereCredite: { maxLength: 400, defaultValue: "" },
				isDeschidereCredite: { dataType: "Boolean", isNullable: false },
				isExportSICC: { dataType: "Boolean", isNullable: false },
				forexeBugApareInBugetIndividual: { dataType: "Boolean", isNullable: false },
				idBugetClasificatieFunctionala: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				trezoreriaOperativa: { maxLength: 400, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_bugetistoric.BugetClasificatieFunctionalaFormulaPersonalizare": {
			shortName: "BugetClasificatieFunctionalaFormulaPersonalizare",
			namespace: "w_bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBugetClasificatieFunctionalaPersonalizareParent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionalaChild: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionalaFormulaPersonalizare: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_bugetistoric.BugetClasificatieFunctionalaPersonalizare": {
			shortName: "BugetClasificatieFunctionalaPersonalizare",
			namespace: "w_bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				capitol: { maxLength: 10, defaultValue: "" },
				sursa: { maxLength: 10, defaultValue: "" },
				subcapitol: { maxLength: 10, defaultValue: "" },
				paragraf: { maxLength: 50, defaultValue: "" },
				codSufix: { maxLength: 100, defaultValue: "" },
				codClasificatie: { maxLength: 70, defaultValue: "" },
				codClasificatiePersonalizare: { maxLength: 170, defaultValue: "" },
				codClasificatieSursa: { maxLength: 80, defaultValue: "" },
				codClasificatieSursaPersonalizare: { maxLength: 180, defaultValue: "" },
				nume: { maxLength: 400, defaultValue: "" },
				idBugetClasificatieFunctionalaPersonalizare: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_bugetistoric.BugetClasificatieFunctionalaTree": {
			shortName: "BugetClasificatieFunctionalaTree",
			namespace: "w_bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBugetClasificatieFunctionala: { dataType: "Int32", defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				hierarchyIdParentId: { maxLength: 4000, defaultValue: "" },
				idBugetClasificatieFunctionalaTree: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				isLeafNode: { dataType: "Boolean", isNullable: false },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				importDataJson: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_bugetistoric.ClasificatieEconomica": {
			shortName: "ClasificatieEconomica",
			namespace: "w_bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				titlu: { maxLength: 2, defaultValue: "" },
				articol: { maxLength: 5, defaultValue: "" },
				alineat: { maxLength: 50, defaultValue: "" },
				codClasificatie: { maxLength: 57, defaultValue: "" },
				nume: { maxLength: 400, defaultValue: "" },
				isVenit: { dataType: "Boolean", isNullable: false },
				tipSectiune: { maxLength: 1, defaultValue: "" },
				idClasificatieEconomica: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_bugetistoric.ClasificatieFunctionala": {
			shortName: "ClasificatieFunctionala",
			namespace: "w_bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				capitol: { maxLength: 10, defaultValue: "" },
				sursa: { maxLength: 10, defaultValue: "" },
				subcapitol: { maxLength: 10, defaultValue: "" },
				paragraf: { maxLength: 100, defaultValue: "" },
				codClasificatie: { maxLength: 120, defaultValue: "" },
				codClasificatieSursa: { maxLength: 130, defaultValue: "" },
				nume: { maxLength: 400, defaultValue: "" },
				isVenit: { dataType: "Boolean", isNullable: false },
				idClasificatieFunctionala: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_bugetistoric.ClasificatieFunctionalaTree": {
			shortName: "ClasificatieFunctionalaTree",
			namespace: "w_bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idClasificatieFunctionala: { dataType: "Int32", defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				idClasificatieFunctionalaTree: { dataType: "Int32" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_bugetistoric.CodProgramBugetar": {
			shortName: "CodProgramBugetar",
			namespace: "w_bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 10, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cuiOpcOsc: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numeOpcOsc: { maxLength: 250, defaultValue: "" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_bugetistoric.FormularBuget": {
			shortName: "FormularBuget",
			namespace: "w_bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				capitol: { maxLength: 10, defaultValue: "" },
				sursa: { maxLength: 10, defaultValue: "" },
				subcapitol: { maxLength: 10, defaultValue: "" },
				paragraf: { maxLength: 50, defaultValue: "" },
				codClasificatie: { maxLength: 70, defaultValue: "" },
				codClasificatieSursa: { maxLength: 80, defaultValue: "" },
				codIBAN: { defaultValue: "" },
				tipSectiune: { maxLength: 1, defaultValue: "" },
				nume: { maxLength: 400, defaultValue: "" },
				idClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isVenit: { dataType: "Boolean", isNullable: false },
				isExtindereDinDetaliere: { dataType: "Boolean", isNullable: false },
				formulaExtindereDinDetaliere: { maxLength: 50, defaultValue: "" },
				formulaEstimari: { maxLength: 50, defaultValue: "" },
				idFormularBuget: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_bugetistoric.FormularBugetFormula": {
			shortName: "FormularBugetFormula",
			namespace: "w_bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idFormularBugetParent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularBugetChild: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sign: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularBugetFormula: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_bugetistoric.FormularBugetTree": {
			shortName: "FormularBugetTree",
			namespace: "w_bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idFormularBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				sign: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isLeafNode: { dataType: "Boolean", isNullable: false },
				idFormularBugetTree: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_bugetistoric.FormularClasificatieEconomica": {
			shortName: "FormularClasificatieEconomica",
			namespace: "w_bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				titlu: { maxLength: 2, defaultValue: "" },
				sursa: { maxLength: 10, defaultValue: "" },
				articol: { maxLength: 5, defaultValue: "" },
				alineat: { maxLength: 50, defaultValue: "" },
				codSufix: { maxLength: 100, defaultValue: "" },
				nume: { maxLength: 400, defaultValue: "" },
				isVenit: { dataType: "Boolean", isNullable: false },
				tipSectiune: { maxLength: 1, defaultValue: "" },
				idClasificatieEconomica: { dataType: "Int32", defaultValue: 0 },
				idClasificatieFunctionala: { dataType: "Int32", defaultValue: 0 },
				codClasificatie: { maxLength: 57, defaultValue: "" },
				codClasificatieSursa: { maxLength: 67, defaultValue: "" },
				codIBAN: { defaultValue: "" },
				isExtindereInBuget: { dataType: "Boolean", isNullable: false },
				isDeschidereCredite: { dataType: "Boolean", isNullable: false },
				isListareAfisareBold: { dataType: "Boolean", isNullable: false },
				isListareFaraAfisare: { dataType: "Boolean", isNullable: false },
				formulaEstimari: { maxLength: 50, defaultValue: "" },
				idFormularClasificatieEconomica: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				codClasificatieSursaPersonalizare: { maxLength: 167, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idFormularClasificatieEconomicaTitlu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_bugetistoric.FormularClasificatieEconomicaFormula": {
			shortName: "FormularClasificatieEconomicaFormula",
			namespace: "w_bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idFormularClasificatieEconomicaParent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomicaChild: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sign: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomicaFormula: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_bugetistoric.FormularClasificatieEconomicaTree": {
			shortName: "FormularClasificatieEconomicaTree",
			namespace: "w_bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				sign: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomicaTree: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				isLeafNode: { dataType: "Boolean", isNullable: false },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_bugetistoric.SectorBugetar": {
			shortName: "SectorBugetar",
			namespace: "w_bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 10, defaultValue: "" },
				codIBAN: { maxLength: 10, defaultValue: "" },
				nume: { maxLength: 50, defaultValue: "" },
				idSectorBugetar: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_bugetistoric.SursaFinantare": {
			shortName: "SursaFinantare",
			namespace: "w_bugetistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 10, defaultValue: "" },
				nume: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idSursaFinantare: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				formular: { maxLength: 50, defaultValue: "" },
			}
		},
		"w_cantina.Alergen": {
			shortName: "Alergen",
			namespace: "w_cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				denumire: { maxLength: 250, defaultValue: "" },
				descriere: { maxLength: 1000, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idCategorieAlergen: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_cantina.Aliment": {
			shortName: "Aliment",
			namespace: "w_cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				idUnitateMasura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				scazamant: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_cantina.CategorieBeneficiar": {
			shortName: "CategorieBeneficiar",
			namespace: "w_cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_cantina.MasaTip": {
			shortName: "MasaTip",
			namespace: "w_cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_cantina.MeniuPlanificat": {
			shortName: "MeniuPlanificat",
			namespace: "w_cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				data: { dataType: "DateTime" },
				idMeniuZi: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCategorieBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				alergeni: { dataType: "Decimal", defaultValue: 0 },
				lipide: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				glucide: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				calorii: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				proteine: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				numarMeniuriEstimat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarMeniuriServit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarMeniuriPreparat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_cantina.MeniuPlanificatDetaliu": {
			shortName: "MeniuPlanificatDetaliu",
			namespace: "w_cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idMeniuPlanificat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idMasaTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarMeniuriEstimat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarMeniuriServit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarMeniuriPreparat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_cantina.MeniuZi": {
			shortName: "MeniuZi",
			namespace: "w_cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_cantina.MeniuZiCategorieBeneficiar": {
			shortName: "MeniuZiCategorieBeneficiar",
			namespace: "w_cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idMeniuZi: { dataType: "Int32", defaultValue: 0 },
				idCategorieBeneficiar: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_cantina.MeniuZiPreparat": {
			shortName: "MeniuZiPreparat",
			namespace: "w_cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idMeniuZi: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPreparat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOraServire: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idMasaTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_cantina.OraServireCategorieBeneficiar": {
			shortName: "OraServireCategorieBeneficiar",
			namespace: "w_cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOraServire: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCategorieBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_cantina.Preparat": {
			shortName: "Preparat",
			namespace: "w_cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_cantina.PreparatAliment": {
			shortName: "PreparatAliment",
			namespace: "w_cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPreparat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idAliment: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_cantina.PreparatCategorie": {
			shortName: "PreparatCategorie",
			namespace: "w_cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPreparat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCategorie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_cantina.PreparatCategorieBeneficiar": {
			shortName: "PreparatCategorieBeneficiar",
			namespace: "w_cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPreparat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCategorieBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_cantina.SemnaturaListaZilnicaAlimente": {
			shortName: "SemnaturaListaZilnicaAlimente",
			namespace: "w_cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionalaCantina: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataSemnatura: { dataType: "DateTime" },
				idPersoanaSefCompartiment: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaIntocmit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBucatar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaCalculat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaGestionar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaConfirmat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_cantina.SoldInitial": {
			shortName: "SoldInitial",
			namespace: "w_cantina",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCategorieBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valoareDrepturi: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareEliberata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_cantinaistoric.CategorieBeneficiarOrganizareInstitutionala": {
			shortName: "CategorieBeneficiarOrganizareInstitutionala",
			namespace: "w_cantinaistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idCategorieBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isFaraCalculEconomiiDepasiri: { dataType: "Boolean", isNullable: false },
				idCategorieBeneficiarOrganizareInstitutionala: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_contab.Balanta": {
			shortName: "Balanta",
			namespace: "w_contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idCont: { dataType: "Int32", defaultValue: 0 },
				soldInitialDebit: { dataType: "Decimal", defaultValue: 0 },
				soldInitialCredit: { dataType: "Decimal", defaultValue: 0 },
				soldInitialDebitCalculat: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				soldInitialCreditCalculat: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				soldInitialDebitAjustare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				soldInitialCreditAjustare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit6: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit6: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit7: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit7: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit8: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit8: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit9: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit9: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit10: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit10: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit11: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit11: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajDebit12: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajCredit12: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit3: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit4: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit5: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit6: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit6: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit7: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit7: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit8: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit8: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit9: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit9: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit10: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit10: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit11: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit11: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilDebit12: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajAjustabilCredit12: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajTotalDebit: { dataType: "Decimal", defaultValue: 0 },
				rulajTotalCredit: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_contab.DocumentFurnizorClientSoldCont": {
			shortName: "DocumentFurnizorClientSoldCont",
			namespace: "w_contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentOperatiuneTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContAnaliticDebit: { dataType: "Int32", defaultValue: 0 },
				idContAnaliticCredit: { dataType: "Int32", defaultValue: 0 },
				idJurnal: { dataType: "Int32", defaultValue: 0 },
				sumaObligatie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaObligatieCuTVA: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaObligatieTVA: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaObligatieFaraTVA: { dataType: "Decimal", defaultValue: 0 },
				sumaPlata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaPlataCuTVA: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaPlataTVA: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaPlataFaraTVA: { dataType: "Decimal", defaultValue: 0 },
				sumaTotalPlata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTotalPlataCuTVA: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTotalPlataTVA: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTotalPlataFaraTVA: { dataType: "Decimal", defaultValue: 0 },
				sumaSold: { dataType: "Decimal", defaultValue: 0 },
				sumaSoldCuTVA: { dataType: "Decimal", defaultValue: 0 },
				sumaSoldTVA: { dataType: "Decimal", defaultValue: 0 },
				sumaSoldFaraTVA: { dataType: "Decimal", defaultValue: 0 },
				idDocumentFurnizorClientDetaliuTip: { dataType: "Int32", defaultValue: 0 },
				isDocumentCuSold: { dataType: "Boolean", isNullable: false },
				explicatie: { defaultValue: "" },
				isGenerat: { dataType: "Boolean", isNullable: false },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isNumerar: { dataType: "Boolean", isNullable: false },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				isCuGarantie: { dataType: "Boolean", isNullable: false },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCodProgramBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codTipInregistrare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusRaportareSectorAgricol: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusRaportareTaxareInversa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareContabOperatiuneProdusValoareTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_contab.Nota": {
			shortName: "Nota",
			namespace: "w_contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idJurnal: { dataType: "Int32", defaultValue: 0 },
				idContDebit: { dataType: "Int32", defaultValue: 0 },
				idContCredit: { dataType: "Int32", defaultValue: 0 },
				suma: { dataType: "Decimal", defaultValue: 0 },
				anLuna: { dataType: "Int32", defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", defaultValue: 0 },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocument: { dataType: "Int32", defaultValue: 0 },
				idDocumentSursa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentFurnizorClientDetaliuTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cotaTVA: { dataType: "Int32", defaultValue: 0 },
				idNotaParinte: { dataType: "Int32", defaultValue: 0 },
				numarNota: { maxLength: 50, defaultValue: "" },
				dataNota: { dataType: "DateTime" },
				explicatie: { defaultValue: "" },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaValuta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isGeneratAutomat: { dataType: "Boolean", isNullable: false },
				idNaturaPlata: { dataType: "Int32", defaultValue: 0 },
				importDataJson: { defaultValue: "" },
				importDataTip: { maxLength: 100, defaultValue: "" },
				importDataId: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				codTipInregistrare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusRaportareSectorAgricol: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusRaportareTaxareInversa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareContabOperatiuneProdusValoareTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaAlgoritm: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isPersonalizare: { dataType: "Boolean", isNullable: false },
			}
		},
		"w_contab.NotaPropusa": {
			shortName: "NotaPropusa",
			namespace: "w_contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idJurnal: { dataType: "Int32", defaultValue: 0 },
				idContDebit: { dataType: "Int32", defaultValue: 0 },
				idContCredit: { dataType: "Int32", defaultValue: 0 },
				suma: { dataType: "Decimal", defaultValue: 0 },
				anLuna: { dataType: "Int32", defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", defaultValue: 0 },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocument: { dataType: "Int32", defaultValue: 0 },
				idDocumentSursa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentFurnizorClientDetaliuTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cotaTVA: { dataType: "Int32", defaultValue: 0 },
				idNotaParinte: { dataType: "Int32", defaultValue: 0 },
				numarNota: { maxLength: 50, defaultValue: "" },
				dataNota: { dataType: "DateTime" },
				explicatie: { defaultValue: "" },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaValuta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idNaturaPlata: { dataType: "Int32", defaultValue: 0 },
				codTipInregistrare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusRaportareSectorAgricol: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusRaportareTaxareInversa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareContabOperatiuneProdusValoareTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaAlgoritm: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isPersonalizare: { dataType: "Boolean", isNullable: false },
			}
		},
		"w_contab.TipInchidere": {
			shortName: "TipInchidere",
			namespace: "w_contab",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 200, defaultValue: "" },
				ordine: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_contabistoric.AlgoritmInchidereConturi": {
			shortName: "AlgoritmInchidereConturi",
			namespace: "w_contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idContInchis: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContInchidere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isInchidereLuna: { dataType: "Boolean", isNullable: false },
				isInchidereAn: { dataType: "Boolean", isNullable: false },
				isInchidereTrimestru: { dataType: "Boolean", isNullable: false },
				isInchidereOrdonatorPrincipal: { dataType: "Boolean" },
				isInchidereOrdonatorSecundar: { dataType: "Boolean" },
				idTipInchidere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idAlgoritmInchidereConturi: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_contabistoric.AsociereActivitateEconomicaArticol": {
			shortName: "AsociereActivitateEconomicaArticol",
			namespace: "w_contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idAsociereActivitateEconomicaArticol: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_contabistoric.AsociereContCrediteBugetare": {
			shortName: "AsociereContCrediteBugetare",
			namespace: "w_contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idCont: { dataType: "Int32", defaultValue: 0 },
				idCrediteBugetareTip: { dataType: "Int32", defaultValue: 0 },
				idJurnal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idAsociereContCrediteBugetare: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_contabistoric.Cont": {
			shortName: "Cont",
			namespace: "w_contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				cod: { maxLength: 3, defaultValue: "" },
				sintetic2: { maxLength: 2, defaultValue: "" },
				sintetic3: { maxLength: 2, defaultValue: "" },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				eSA: { maxLength: 5, defaultValue: "" },
				analiticClient: { maxLength: 50, defaultValue: "" },
				codSufixBugetClasificatieFunctionala: { maxLength: 50, defaultValue: "" },
				codSufixFormularClasificatieEconomica: { maxLength: 50, defaultValue: "" },
				functie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isInafaraBilantului: { dataType: "Boolean", isNullable: false },
				isClasificatieCheltuieli: { dataType: "Boolean", isNullable: false },
				isClasificatieVenituri: { dataType: "Boolean", isNullable: false },
				isContIncasare: { dataType: "Boolean", isNullable: false },
				isContFinantare: { dataType: "Boolean", isNullable: false },
				isFaraClasificatie: { dataType: "Boolean", isNullable: false },
				isContTVANeexigibil: { dataType: "Boolean", isNullable: false },
				isContValuta: { dataType: "Boolean", isNullable: false },
				isContUrmaritInSoldInitial: { dataType: "Boolean" },
				isContExcedent: { dataType: "Boolean", isNullable: false },
				isContBanca: { dataType: "Boolean", isNullable: false },
				isContNeamortizabil: { dataType: "Boolean", isNullable: false },
				isContAmortizabil: { dataType: "Boolean", isNullable: false },
				isContProgrameInformatice: { dataType: "Boolean", isNullable: false },
				isContAlteActive: { dataType: "Boolean", isNullable: false },
				isClasificatieCheltuieliCapitolSiTitlu: { dataType: "Boolean", isNullable: false },
				domeniulApartinator: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tipSectiune: { maxLength: 1, defaultValue: "" },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isGeneratAutomat: { dataType: "Boolean", isNullable: false },
				idCont: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_contabistoric.GenerareNotaAlgoritm": {
			shortName: "GenerareNotaAlgoritm",
			namespace: "w_contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaOperatiuneContTemplate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaOperatiuneContTemplateDefault: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idJurnal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusGrupTip: { dataType: "Int32", defaultValue: 0 },
				idOrganizareInstitutionalaGestiune: { dataType: "Int32", defaultValue: 0 },
				idGenerareNotaAlgoritm: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_contabistoric.GenerareNotaContTemplate": {
			shortName: "GenerareNotaContTemplate",
			namespace: "w_contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idGenerareNotaContTemplateDefault: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				idContSintetic: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sinteticModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sectorBugetarModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sursaFinantareModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				clasificatieFunctionalaModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				clasificatieEconomicaModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticCustom: { maxLength: 100, defaultValue: "" },
				analiticPozitieGestiune: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieClasificareProduse: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieTVA: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieTipProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieTipVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieObiectivInvestitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isVenit: { dataType: "Boolean", isNullable: false },
				isManual: { dataType: "Boolean", isNullable: false },
				idGenerareNotaContTemplate: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_contabistoric.GenerareNotaContTemplateDefault": {
			shortName: "GenerareNotaContTemplateDefault",
			namespace: "w_contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				idContSintetic: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sinteticModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sectorBugetarModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sursaFinantareModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				clasificatieFunctionalaModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				clasificatieEconomicaModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticCustom: { maxLength: 100, defaultValue: "" },
				analiticPozitieGestiune: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieClasificareProduse: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieTVA: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieTipProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticPozitieTipVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isVenit: { dataType: "Boolean", isNullable: false },
				idGenerareNotaContTemplateDefault: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_contabistoric.GenerareNotaOperatiuneContTemplate": {
			shortName: "GenerareNotaOperatiuneContTemplate",
			namespace: "w_contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idGenerareNotaOperatiuneContTemplateDefault: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareContabOperatiuneProdusValoareTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaContTemplateDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaContTemplateCredit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaOperatiuneContTemplate: { dataType: "Int32" },
				isManual: { dataType: "Boolean", isNullable: false },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_contabistoric.GenerareNotaOperatiuneContTemplateDefault": {
			shortName: "GenerareNotaOperatiuneContTemplateDefault",
			namespace: "w_contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idGenerareContabOperatiuneProdusValoareTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaContTemplateDefaultDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaContTemplateDefaultCredit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareNotaOperatiuneContTemplateDefault: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_contabistoric.Jurnal": {
			shortName: "Jurnal",
			namespace: "w_contabistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				idContUrmarit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idJurnal: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				importDataJson: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_dareseama.AIndex": {
			shortName: "AIndex",
			namespace: "w_dareseama",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				denumire: { maxLength: 800, defaultValue: "" },
				cod: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tipf: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cod_f: { maxLength: 10, defaultValue: "" },
				cod_c: { maxLength: 10, defaultValue: "" },
				raport: { maxLength: 50, defaultValue: "" },
				col1: { maxLength: 50, defaultValue: "" },
				col2: { maxLength: 50, defaultValue: "" },
				col3: { maxLength: 50, defaultValue: "" },
				col4: { maxLength: 50, defaultValue: "" },
				col5: { maxLength: 50, defaultValue: "" },
				col6: { maxLength: 50, defaultValue: "" },
				col7: { maxLength: 50, defaultValue: "" },
				col8: { maxLength: 50, defaultValue: "" },
				col9: { maxLength: 50, defaultValue: "" },
				col10: { maxLength: 50, defaultValue: "" },
				col11: { maxLength: 50, defaultValue: "" },
				col12: { maxLength: 50, defaultValue: "" },
				col13: { maxLength: 50, defaultValue: "" },
				col14: { maxLength: 50, defaultValue: "" },
				col15: { maxLength: 50, defaultValue: "" },
				transfer: { maxLength: 250, defaultValue: "" },
				vonline: { maxLength: 250, defaultValue: "" },
				tipb: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				spdate: { maxLength: 100, defaultValue: "" },
				transferAn: { maxLength: 250, defaultValue: "" },
				b1: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b2: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b3: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b4: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b5: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b6: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b7: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b8: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b9: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b10: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b11: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b12: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b13: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b14: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				b15: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				surseM: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sume: { maxLength: 800, defaultValue: "" },
				anexa_calcul: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumeAX: { maxLength: 800, defaultValue: "" },
				trim4: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tipRaportare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stampFormulare: { dataType: "DateTime", isNullable: false },
				stampRapoarte: { dataType: "DateTime", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_dareseama.Formular": {
			shortName: "Formular",
			namespace: "w_dareseama",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nrrnd: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nrrndf: { maxLength: 10, defaultValue: "" },
				denumire: { maxLength: 800, defaultValue: "" },
				cod: { maxLength: 20, defaultValue: "" },
				tip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anexa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				trimestru: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sursa: { maxLength: 4000, defaultValue: "" },
				titlu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				decalc: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sursa2: { maxLength: 4000, defaultValue: "" },
				sursa3: { maxLength: 4000, defaultValue: "" },
				sursa4: { maxLength: 4000, defaultValue: "" },
				sursa5: { maxLength: 4000, defaultValue: "" },
				sursa6: { maxLength: 4000, defaultValue: "" },
				sursa7: { maxLength: 4000, defaultValue: "" },
				sursa8: { maxLength: 4000, defaultValue: "" },
				sursa9: { maxLength: 4000, defaultValue: "" },
				sursa10: { maxLength: 4000, defaultValue: "" },
				sursa11: { maxLength: 4000, defaultValue: "" },
				sursa12: { maxLength: 4000, defaultValue: "" },
				sursa13: { maxLength: 4000, defaultValue: "" },
				sursa14: { maxLength: 4000, defaultValue: "" },
				sursa15: { maxLength: 4000, defaultValue: "" },
				nuLista: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_dataservice.DataUpdateLog": {
			shortName: "DataUpdateLog",
			namespace: "w_dataservice",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				databaseName: { maxLength: 100, defaultValue: "" },
				schemaName: { maxLength: 100, defaultValue: "" },
				tableName: { maxLength: 100, defaultValue: "" },
				descriere: { maxLength: 500, defaultValue: "" },
				explicatie: { defaultValue: "" },
				data: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.Document": {
			shortName: "Document",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				seria: { maxLength: 10, defaultValue: "" },
				numarDocument: { maxLength: 18, defaultValue: "" },
				numarDocumentNumeric: { dataType: "Int32", defaultValue: 0 },
				seriaSufix: { maxLength: 10, defaultValue: "" },
				dataDocument: { dataType: "DateTime" },
				numarInregistrare: { dataType: "Int32", defaultValue: 0 },
				dataInregistrare: { dataType: "DateTime" },
				nume: { maxLength: 250, defaultValue: "" },
				numarInregistrareExtern: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataInregistrareExtern: { dataType: "DateTime" },
				idDocumentTip: { dataType: "Int32", defaultValue: 0 },
				idDocumentSubTip: { dataType: "Int32", defaultValue: 0 },
				categoria: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", defaultValue: 0 },
				idPersoanaPartener: { dataType: "Int32", defaultValue: 0 },
				idPersoanaResponsabil: { dataType: "Int32", defaultValue: 0 },
				importDataJson: { defaultValue: "" },
				importDataTip: { maxLength: 100, defaultValue: "" },
				importDataId: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				importDataTipParent: { maxLength: 100, defaultValue: "" },
				importDataIdParent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentAvansSpreDecontare": {
			shortName: "DocumentAvansSpreDecontare",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isDecontDirectFaraAvansDecontare: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentAvansSpreDecontareVirament": {
			shortName: "DocumentAvansSpreDecontareVirament",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataDocumentVirament: { dataType: "DateTime" },
				numarDocumentVirament: { maxLength: 18, defaultValue: "" },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIban: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIbanBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 100, defaultValue: "" },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				idSumaExecutie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentBorderouIncasareDetaliu": {
			shortName: "DocumentBorderouIncasareDetaliu",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", defaultValue: 0 },
				idDocumentIncasare: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentCecRidicareNumerar": {
			shortName: "DocumentCecRidicareNumerar",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCasierie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIban: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIbanContMandat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCodProgramBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codProgram: { maxLength: 50, defaultValue: "" },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				explicatie: { maxLength: 100, defaultValue: "" },
				listat: { dataType: "Boolean", isNullable: false },
				validatTrezorerie: { dataType: "Boolean", isNullable: false },
				idDocumentStatPlataNumerar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idDocumentAngajamentLegalGlobal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isFaraGenerareExecutieBugetara: { dataType: "Boolean", isNullable: false },
			}
		},
		"w_documente.DocumentCentralizatorOPIncasareDetaliu": {
			shortName: "DocumentCentralizatorOPIncasareDetaliu",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentIncasare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentComanda": {
			shortName: "DocumentComanda",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", defaultValue: 0 },
				valoareValuta: { dataType: "Decimal", defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareLei: { dataType: "Decimal", defaultValue: 0 },
				dataExpirare: { dataType: "DateTime" },
				idAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentContract": {
			shortName: "DocumentContract",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", defaultValue: 0 },
				valoareValuta: { dataType: "Decimal", defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isFaraValoare: { dataType: "Boolean", isNullable: false },
				valoareLei: { dataType: "Decimal", defaultValue: 0 },
				dataExpirare: { dataType: "DateTime" },
				idObiectivInvestitie: { dataType: "Int32", defaultValue: 0 },
				idAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOperatiuneCFP: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isCrediteAngajament: { dataType: "Boolean", isNullable: false },
				perioadaAniCrediteAngajament: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataIncepereExecutie: { dataType: "DateTime" },
				dataFinalizareExecutie: { dataType: "DateTime" },
				codAngajament: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idPersoanaBancaIbanGarantieDeBunaExecutie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				procentGarantieBunaExecutie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareValutaGarantieBunaExecutie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareLeiGarantieBunaExecutie: { dataType: "Decimal", defaultValue: 0 },
				valoareValutaExecutata: { dataType: "Decimal", defaultValue: 0 },
				valoareLeiExecutata: { dataType: "Decimal", defaultValue: 0 },
				valoareValutaRamasaDeExecutat: { dataType: "Decimal", defaultValue: 0 },
				valoareLeiRamasDeExecutat: { dataType: "Decimal", defaultValue: 0 },
				valoareValutaContractFaraTVA: { dataType: "Decimal", defaultValue: 0 },
				valoareLeiContractFaraTVA: { dataType: "Decimal", defaultValue: 0 },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
			}
		},
		"w_documente.DocumentContractClasificatieBugetaraAnSume": {
			shortName: "DocumentContractClasificatieBugetaraAnSume",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				valoareValuta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareLei: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentDebitNota": {
			shortName: "DocumentDebitNota",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isCreareDebit: { dataType: "Boolean", isNullable: false },
				isAnulareDebit: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentDecontCheltuiala": {
			shortName: "DocumentDecontCheltuiala",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				delegatPentru: { defaultValue: "" },
				delegatLa: { defaultValue: "" },
				durataDeplasareDataStart: { dataType: "DateTime" },
				durataDeplasareDataStop: { dataType: "DateTime" },
				legitimatCu: { defaultValue: "" },
				ziOraPlecare: { dataType: "DateTime" },
				ziOraSosire: { dataType: "DateTime" },
				dataDepunereDecont: { dataType: "DateTime" },
				sumaPenalizare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				diurnaTarifZi: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				diurnaNumarZile: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				diurnaSuma: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentDispozitieIncasare": {
			shortName: "DocumentDispozitieIncasare",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPlataNumerarTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				explicatie: { defaultValue: "" },
				dataIncasare: { dataType: "DateTime" },
				userNameIncasare: { maxLength: 250, defaultValue: "" },
				idOrganizareInstitutionalaCasierie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnic: { maxLength: 50, defaultValue: "" },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isAfterDecont: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentDispozitieIncasareDistribuireSume": {
			shortName: "DocumentDispozitieIncasareDistribuireSume",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIban: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnic: { maxLength: 50, defaultValue: "" },
				idIncasare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentDispozitiePlata": {
			shortName: "DocumentDispozitiePlata",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPlataNumerarTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				explicatie: { defaultValue: "" },
				dataPlata: { dataType: "DateTime" },
				userNamePlata: { maxLength: 250, defaultValue: "" },
				idOrganizareInstitutionalaCasierie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnic: { maxLength: 50, defaultValue: "" },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isAfterDecont: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentDispozitiePlataDistribuireSume": {
			shortName: "DocumentDispozitiePlataDistribuireSume",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idIncasare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIban: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnic: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idDocumentCecRidicareNumerar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isGenerareExecutieBugetara: { dataType: "Boolean", isNullable: false },
				idDocumentAngajamentLegalGlobal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_documente.DocumentDocumentPlata": {
			shortName: "DocumentDocumentPlata",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentFlow": {
			shortName: "DocumentFlow",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaExpeditor: { dataType: "Int32", defaultValue: 0 },
				idPersoanaDestinatar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaExpeditor: { dataType: "Int32", defaultValue: 0 },
				idOrganizareInstitutionalaDestinatar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataIntrareDestinatar: { dataType: "DateTime" },
				dataIesireExpeditor: { dataType: "DateTime" },
				observatie: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentFoaieVarsamant": {
			shortName: "DocumentFoaieVarsamant",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCasierie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIban: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIbanContMandat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaPlatitor: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				beneficiar: { maxLength: 100, defaultValue: "" },
				idCodProgramBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codProgram: { maxLength: 50, defaultValue: "" },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				numarEvidentaPlata: { maxLength: 50, defaultValue: "" },
				explicatie: { maxLength: 100, defaultValue: "" },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isInclusivDataCurenta: { dataType: "Boolean", isNullable: false },
				validatTrezorerie: { dataType: "Boolean", isNullable: false },
				idDocumentStatPlataNumerar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				isCuiUnitatePlatitor: { dataType: "Boolean", isNullable: false },
				numePlatitorPersonalizat: { maxLength: 30, defaultValue: "" },
				idDocumentAngajamentLegalGlobal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_documente.DocumentFoaieVarsamantIncasareCheltuiala": {
			shortName: "DocumentFoaieVarsamantIncasareCheltuiala",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentFoaieVarsamant: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idIncasare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentFurnizorClient": {
			shortName: "DocumentFurnizorClient",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", defaultValue: 0 },
				idEFactura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codStatusEFactura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentOperatiuneTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentOperatiuneTipSoldInitial: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOperatiuneActivitateEconomicaClasificatieBugetara: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				seria: { maxLength: 10, defaultValue: "" },
				idPersoanaDelegat: { dataType: "Int32", defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareFaraTva: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareFaraTvaLei: { dataType: "Decimal", defaultValue: 0 },
				valoareTva: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareTvaLei: { dataType: "Decimal", defaultValue: 0 },
				valoareTotal: { dataType: "Decimal", defaultValue: 0 },
				valoareTotalLei: { dataType: "Decimal", defaultValue: 0 },
				dataScadenta: { dataType: "DateTime" },
				idPersoanaBancaIban: { dataType: "Int32", defaultValue: 0 },
				mijlocTransport: { maxLength: 100, defaultValue: "" },
				idAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isGenerareLichidare: { dataType: "Boolean", isNullable: false },
				isGenerareOrdonantare: { dataType: "Boolean", isNullable: false },
				generareOrdonantarePlata: { dataType: "DateTime" },
				isGenerarePlata: { dataType: "Boolean", isNullable: false },
				generarePlataData: { dataType: "DateTime" },
				generarePlataExplicatie: { maxLength: 100, defaultValue: "" },
				idDocumentPlataOPME: { dataType: "Int32", defaultValue: 0 },
				isGenerarePlataNumerarAvans: { dataType: "Boolean", isNullable: false },
				valoareNumerarAvans: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareNumerarAvansLei: { dataType: "Decimal", defaultValue: 0 },
				valoareTvaNumerarAvans: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareTvaNumerarAvansLei: { dataType: "Decimal", defaultValue: 0 },
				idDocumentTipNumerarAvans: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarDocumentNumerarAvans: { maxLength: 20, defaultValue: "" },
				dataDocumentNumerarAvans: { dataType: "DateTime" },
				idPersoanaNumerarAvans: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentDispozitiePlataNumerarAvans: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentPlataNumerarAvansSpreDecontareLista: { maxLength: 1000, defaultValue: "" },
				isGenerareAvansSpreDecontare: { dataType: "Boolean", isNullable: false },
				isDecontDirectFaraAvansDecontare: { dataType: "Boolean", isNullable: false },
				jsonParamAvansSpreDecontare: { defaultValue: "" },
				isInLucru: { dataType: "Boolean", isNullable: false },
				explicatie: { maxLength: 100, defaultValue: "" },
				idOrganizareInstitutionalaCompartiment: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				isCuGarantie: { dataType: "Boolean", isNullable: false },
				idPersoanaBancaIbanGarantie: { dataType: "Int32", defaultValue: 0 },
				valoareGarantie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareGarantieLei: { dataType: "Decimal", defaultValue: 0 },
				procentGarantie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isInseratModificatDupaTransmitereSauExclus: { dataType: "Boolean", isNullable: false },
				dataInseratModificatDupaTransmitereSauExclus: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentFurnizorClientDetaliu": {
			shortName: "DocumentFurnizorClientDetaliu",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentFurnizorClientDetaliuTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentFurnizorClientDetaliuGrup: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarCurent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 300, defaultValue: "" },
				numeEFactura: { maxLength: 300, defaultValue: "" },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarValuta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarValutaEFactura: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarLei: { dataType: "Decimal", defaultValue: 0 },
				discountProcent: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				discountSuma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				discountSumaLei: { dataType: "Decimal", defaultValue: 0 },
				pretUnitarValutaDiscount: { dataType: "Decimal", defaultValue: 0 },
				pretUnitarLeiDiscount: { dataType: "Decimal", defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cantitateEFactura: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareBazaLeiRotunjit: { dataType: "Decimal", defaultValue: 0 },
				valoareTva: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareTvaEFactura: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareTvaLei: { dataType: "Decimal", defaultValue: 0 },
				valoareTotal: { dataType: "Decimal", defaultValue: 0 },
				valoareTotalLei: { dataType: "Decimal", defaultValue: 0 },
				idUnitateMasura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnitateMasuraEFactura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isServiciu: { dataType: "Boolean", isNullable: false },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUtilitatiTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idServiciuTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isModernizare: { dataType: "Boolean", isNullable: false },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idObiectivInvestitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idObiectivInvestitieSubobiectiv: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnicDebit: { maxLength: 50, defaultValue: "" },
				idCodProgramBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				idOrganizareInstitutionalaLocFolosintaModernizare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusRaportareSectorAgricol: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusRaportareTaxareInversa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				isDeleted: { dataType: "Boolean", isNullable: false },
				idPersoanaReturFiltru: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isReturIndiferentFurnizorFiltru: { dataType: "Boolean", isNullable: false },
				idDocumentFurnizorClientSoldCont: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCPV: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_documente.DocumentFurnizorClientGenerareExecutieBugetara": {
			shortName: "DocumentFurnizorClientGenerareExecutieBugetara",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCodProgramBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isAngajamentGlobal: { dataType: "Boolean", isNullable: false },
				idDocumentAngajamentBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaDisponibilAngajamentBugetar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idDocumentAngajamentLegal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaDisponibilAngajamentLegal: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isPenalitateContractRetinutaLaPlata: { dataType: "Boolean", isNullable: false },
				idPersoanaBancaIbanPenalitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaPenalitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_documente.DocumentFurnizorRefacturareUtilitati": {
			shortName: "DocumentFurnizorRefacturareUtilitati",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentFurnizorClientDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentFurnizorClientDetaliuGenerat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anLunaRefacturare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaDebitor: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarValuta: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarLei: { dataType: "Decimal", defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareFaraTvaValuta: { dataType: "Decimal", defaultValue: 0 },
				valoareFaraTvaLei: { dataType: "Decimal", defaultValue: 0 },
				valoareTva: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareTotal: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentGarantieBunaExecutie": {
			shortName: "DocumentGarantieBunaExecutie",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idIncasare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaDebit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaCredit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				explicatie: { defaultValue: "" },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idObiectivInvestitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idObiectivInvestitieSubobiectiv: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataExpirare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentGarantieParticipareLicitatie": {
			shortName: "DocumentGarantieParticipareLicitatie",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idAchizitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idIncasare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaDebit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaCredit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				explicatie: { defaultValue: "" },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentJustificativ": {
			shortName: "DocumentJustificativ",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", defaultValue: 0 },
				idDocumentJustificativ: { dataType: "Int32" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentListaZilnicaAlimente": {
			shortName: "DocumentListaZilnicaAlimente",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCategorieBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				efectiv: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valoareDrepturiBeneficiar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareEliberataBeneficiar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareDrepturi: { dataType: "Decimal", defaultValue: 0 },
				valoareEliberata: { dataType: "Decimal", defaultValue: 0 },
				valoareEconomii: { dataType: "Decimal", defaultValue: 0 },
				valoareDepasiri: { dataType: "Decimal", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentMiscareInventar": {
			shortName: "DocumentMiscareInventar",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentOperatiuneTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaPredare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaPrimire: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaPredator: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaPrimitor: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusGrupTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCompartiment: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaDelegat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				mijlocTransport: { maxLength: 100, defaultValue: "" },
				idComisiaReceptie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idComisiaCasare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idComisiaDistrugere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idComisiaInventariere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isGeneratDinCantina: { dataType: "Boolean", isNullable: false },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				documentJustificativ: { maxLength: 250, defaultValue: "" },
				explicatie: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentNotaContabilaCorectieCAB": {
			shortName: "DocumentNotaContabilaCorectieCAB",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				validatTrezorerie: { dataType: "Boolean", isNullable: false },
				listat: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentNotaContabilaCorectieCABDetaliu": {
			shortName: "DocumentNotaContabilaCorectieCABDetaliu",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIban: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codProgram: { maxLength: 50, defaultValue: "" },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				numarOperatiuneInitiala: { maxLength: 50, defaultValue: "" },
				dataOperatiuneInitiala: { dataType: "DateTime" },
				explicatie: { maxLength: 100, defaultValue: "" },
				sumaDebit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaCredit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idExtrasContTrezoDetaliiMisc: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentObligatieSalarii": {
			shortName: "DocumentObligatieSalarii",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataPlata: { dataType: "DateTime" },
				idOrganizareInstitutionalaCompartiment: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPlataNumerarTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isFaraNotaContabilaObligatie: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				isPlataExtrabugetar: { dataType: "Boolean", isNullable: false },
				idPersoanaBancaIbanExtrabugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stampLichidare: { dataType: "DateTime" },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codSufixBugetClasificatieFunctionala: { maxLength: 100, defaultValue: "" },
				isFaraExecutieBugetara: { dataType: "Boolean", isNullable: false },
				idDocumentSubTipAvans: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isPlatiEduSalLaAltaData: { dataType: "Boolean", isNullable: false },
				isExecutieSimplificataAngajamenteGlobale: { dataType: "Boolean", isNullable: false },
			}
		},
		"w_documente.DocumentObligatieSalariiDetaliu": {
			shortName: "DocumentObligatieSalariiDetaliu",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idRetinereSalariiTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idPersoanaBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIbanBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBeneficiarReal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				modPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				eduSalNrOp: { maxLength: 20, defaultValue: "" },
				eduSalDataPlata: { dataType: "DateTime" },
				idSumaExecutie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 250, defaultValue: "" },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				codFiscalBeneficiar: { maxLength: 50, defaultValue: "" },
				idCodProgramBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_documente.DocumentObligatieSalariiDetaliuPlata": {
			shortName: "DocumentObligatieSalariiDetaliuPlata",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentObligatieSalariiDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSumaExecutie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentOperatiuneProprie": {
			shortName: "DocumentOperatiuneProprie",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOperatiuneProprieTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIban: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCompartiment: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentOperatiuneProprieDetaliu": {
			shortName: "DocumentOperatiuneProprieDetaliu",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOperatiuneProprieTipDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaLei: { dataType: "Decimal", defaultValue: 0 },
				sumaTVA: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaTVALei: { dataType: "Decimal", defaultValue: 0 },
				sumaTotal: { dataType: "Decimal", defaultValue: 0 },
				sumaTotalLei: { dataType: "Decimal", defaultValue: 0 },
				idDocumentFurnizorClientDetaliuTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusRaportareSectorAgricol: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusRaportareTaxareInversa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCodProgramBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				idSumaExecutie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idDocumentPlataOPME: { dataType: "Int32", defaultValue: 0 },
				idDocumentAngajamentLegalGlobal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_documente.DocumentOrdinDeplasare": {
			shortName: "DocumentOrdinDeplasare",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				delegatPentru: { defaultValue: "" },
				delegatLa: { defaultValue: "" },
				durataDeplasareDataStart: { dataType: "DateTime" },
				durataDeplasareDataStop: { dataType: "DateTime" },
				legitimatCu: { defaultValue: "" },
				ziOraPlecare: { dataType: "DateTime" },
				ziOraSosire: { dataType: "DateTime" },
				dataDepunereDecont: { dataType: "DateTime" },
				sumaPenalizare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				diurnaTarifZi: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				diurnaNumarZile: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				diurnaSuma: { dataType: "Decimal", defaultValue: 0 },
				idBugetCazare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionalaCazare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomicaCazare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaCazare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idBugetTransportAuto: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionalaTransportAuto: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomicaTransportAuto: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sumaTransportAuto: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentOrdinDeplasareVirament": {
			shortName: "DocumentOrdinDeplasareVirament",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataDocumentVirament: { dataType: "DateTime" },
				numarDocumentVirament: { maxLength: 18, defaultValue: "" },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIban: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIbanBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 100, defaultValue: "" },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				idSumaExecutie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentPlata": {
			shortName: "DocumentPlata",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				seria: { maxLength: 10, defaultValue: "" },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIbanPlatitor: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIbanBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 100, defaultValue: "" },
				listat: { dataType: "Boolean", isNullable: false },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				idDocumentPlataOPME: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isAfterDecont: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codFiscalBeneficiar: { maxLength: 50, defaultValue: "" },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCodProgramBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isIncasareBanca: { dataType: "Boolean", isNullable: false },
			}
		},
		"w_documente.DocumentPlataIncasareCheltuiala": {
			shortName: "DocumentPlataIncasareCheltuiala",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idIncasare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentProcesVerbalObiectivInCurs": {
			shortName: "DocumentProcesVerbalObiectivInCurs",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentOperatiuneTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idObiectiv: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSubObiectiv: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaPrimire: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaPrimitor: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idComisiaReceptie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentReevaluare": {
			shortName: "DocumentReevaluare",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataReevaluarePrecedenta: { dataType: "DateTime" },
				isConformIPC: { dataType: "Boolean", isNullable: false },
				isCuEvaluator: { dataType: "Boolean", isNullable: false },
				isMFInclusivExpirate: { dataType: "Boolean", isNullable: false },
				isMFInclusivIntrariAn: { dataType: "Boolean", isNullable: false },
				isMFInclusivInCursExecutie: { dataType: "Boolean", isNullable: false },
				isMFInclusivNuSeReevalueaza: { dataType: "Boolean", isNullable: false },
				isMFInclusivNecorporale: { dataType: "Boolean", isNullable: false },
				isInfluenteInclusivIntrariAn: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentReglareNotaContabila": {
			shortName: "DocumentReglareNotaContabila",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idObiectivInvestitie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idObiectivInvestitieSubobiectiv: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentSoldCasierie": {
			shortName: "DocumentSoldCasierie",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				iBAN: { maxLength: 50, defaultValue: "" },
				idPersoanaIBAN: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarDocumentIncasare: { maxLength: 18, defaultValue: "" },
				dataInregistrareIncasare: { dataType: "DateTime" },
				numarDocumentPlata: { maxLength: 50, defaultValue: "" },
				dataInregistrarePlata: { dataType: "DateTime" },
				sumaIncasata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaPlatita: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				soldDocumentIncasare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idDocumentCecRidicareNumerar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idIncasare: { dataType: "Int32", defaultValue: 0 },
				idDocumentFoaieVarsamant: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentDispozitiePlataDistribuireSume: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentStatPlataNumerarDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codAngajament: { maxLength: 50, defaultValue: "" },
				indicatorAngajament: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentStare": {
			shortName: "DocumentStare",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentStareTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStare: { dataType: "DateTime" },
				observatie: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentStatPlataNumerar": {
			shortName: "DocumentStatPlataNumerar",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCompartiment: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPlataNumerarTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codModGenerare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.DocumentStatPlataNumerarDetaliu": {
			shortName: "DocumentStatPlataNumerarDetaliu",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dataPlata: { dataType: "DateTime" },
				userNamePlata: { maxLength: 250, defaultValue: "" },
				idOrganizareInstitutionalaCasierie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idDocumentCecRidicareNumerar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isGenerareExecutieBugetara: { dataType: "Boolean", isNullable: false },
				idDocumentAngajamentLegalGlobal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_documente.DocumentSursa": {
			shortName: "DocumentSursa",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", defaultValue: 0 },
				idDocumentSursa: { dataType: "Int32" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.RegistruVizaCFP": {
			shortName: "RegistruVizaCFP",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idControlorCFP: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOperatiuneCFP: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarRegistruVizaCFP: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataRegistruVizaCFP: { dataType: "DateTime" },
				isRefuzVizaCFP: { dataType: "Boolean", isNullable: false },
				nume: { maxLength: 250, defaultValue: "" },
				explicatie: { maxLength: 250, defaultValue: "" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaEmitent: { dataType: "Int32", defaultValue: 0 },
				valoare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				motivRefuzVizaCFP: { maxLength: 250, defaultValue: "" },
				idDocumentTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarDocumentVizat: { maxLength: 20, defaultValue: "" },
				dataDocumentVizat: { dataType: "DateTime" },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isPermisaEditareSectorSursa: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.SetareNumarUrmator": {
			shortName: "SetareNumarUrmator",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				seria: { maxLength: 10, defaultValue: "" },
				numarUrmator: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				numarMaximAlocat: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				modPrefixareNumar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				prefixareNumarValoare: { maxLength: 10, defaultValue: "" },
				modResetareNumar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarCaractere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				seriaSufix: { maxLength: 10, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documente.SetareNumarUrmatorAn": {
			shortName: "SetareNumarUrmatorAn",
			namespace: "w_documente",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				seria: { maxLength: 10, defaultValue: "" },
				numarUrmator: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				numarMaximAlocat: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				modPrefixareNumar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				prefixareNumarValoare: { maxLength: 10, defaultValue: "" },
				modResetareNumar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarCaractere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				seriaSufix: { maxLength: 10, defaultValue: "" },
				an: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documenteistoric.DocumentContractMunca": {
			shortName: "DocumentContractMunca",
			namespace: "w_documenteistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", defaultValue: 0 },
				idPost: { dataType: "Int32", defaultValue: 0 },
				isFunctieBaza: { dataType: "Boolean" },
				isConducatorDepartament: { dataType: "Boolean", isNullable: false },
				idDocument: { dataType: "Int32", defaultValue: 0 },
				idDocumentContractMunca: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documenteistoric.OperatiuneCFP": {
			shortName: "OperatiuneCFP",
			namespace: "w_documenteistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 20, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				idOperatiuneCFP: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documenteistoric.OperatiuneCFPClasificatieEconomica": {
			shortName: "OperatiuneCFPClasificatieEconomica",
			namespace: "w_documenteistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOperatiuneCFP: { dataType: "Int32", defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", defaultValue: 0 },
				isExclusiv: { dataType: "Boolean" },
				idOperatiuneCFPClasificatieEconomica: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_documenteistoric.OperatiuneCFPDocumentSubTip": {
			shortName: "OperatiuneCFPDocumentSubTip",
			namespace: "w_documenteistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentSubTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOperatiuneCFP: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOperatiuneCFPDocumentSubTip: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_efactura.DiscountMotivCodBT140": {
			shortName: "DiscountMotivCodBT140",
			namespace: "w_efactura",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 10, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				descriere: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_efactura.eFactura": {
			shortName: "eFactura",
			namespace: "w_efactura",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cif: { maxLength: 50, defaultValue: "" },
				tip: { maxLength: 250, defaultValue: "" },
				codTipFactura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataCreare: { dataType: "DateTime" },
				dataEmitere: { dataType: "DateTime" },
				idSolicitare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idEfactura: { dataType: "Int32", isNullable: false },
				detaliu: { defaultValue: "" },
				xmlData: { defaultValue: "" },
				cumparator: { maxLength: 250, defaultValue: "" },
				furnizor: { maxLength: 250, defaultValue: "" },
				valoare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareMoneda: { maxLength: 3, defaultValue: "" },
				isListat: { dataType: "Boolean", isNullable: false },
				isMultiplicat: { dataType: "Boolean", isNullable: false },
				notaFactura: { defaultValue: "" },
				nrFactura: { maxLength: 256, defaultValue: "" },
				userNameDescarcare: { maxLength: 256, defaultValue: "" },
				dataDescarcare: { dataType: "DateTime" },
				status: { defaultValue: "" },
				anexaContinut: { defaultValue: "" },
				anexaDenumire: { maxLength: 100, defaultValue: "" },
				anexaTip: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				cIDId: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cIDData: { dataType: "DateTime" },
			}
		},
		"w_efactura.FacturaTipBt3": {
			shortName: "FacturaTipBt3",
			namespace: "w_efactura",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				cod: { maxLength: 10, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_efactura.Fisier": {
			shortName: "Fisier",
			namespace: "w_efactura",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 255, defaultValue: "" },
				cale: { maxLength: 255, defaultValue: "" },
				idEFactura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameDescarcare: { maxLength: 256, defaultValue: "" },
				dataDescarcare: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_efactura.InstructiunePlataBg16": {
			shortName: "InstructiunePlataBg16",
			namespace: "w_efactura",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				cod: { maxLength: 10, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_efactura.MonedaBt5": {
			shortName: "MonedaBt5",
			namespace: "w_efactura",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				cod: { maxLength: 10, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_efactura.TaraBt40": {
			shortName: "TaraBt40",
			namespace: "w_efactura",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				cod: { maxLength: 10, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_efactura.TvaCategorieBt151": {
			shortName: "TvaCategorieBt151",
			namespace: "w_efactura",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				cod: { maxLength: 10, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_efactura.TvaMotivScutireBt121": {
			shortName: "TvaMotivScutireBt121",
			namespace: "w_efactura",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				cod: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_efactura.UnitateMasuraBt130": {
			shortName: "UnitateMasuraBt130",
			namespace: "w_efactura",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				cod: { maxLength: 10, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_ghiseul.BorderouIncasare": {
			shortName: "BorderouIncasare",
			namespace: "w_ghiseul",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				continutCSV: { defaultValue: "" },
				statusCod: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				statusMsg: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_ghiseul.BorderouIncasareDetalii": {
			shortName: "BorderouIncasareDetalii",
			namespace: "w_ghiseul",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentBorderouIncasare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarPlata: { dataType: "Int32", defaultValue: 0 },
				dataPlata: { dataType: "DateTime" },
				cUIInstitutie: { dataType: "Int32", defaultValue: 0 },
				cUIPersoana: { dataType: "Int32", defaultValue: 0 },
				cUIPersoanaPlata: { dataType: "Int32", defaultValue: 0 },
				sumaPlata: { dataType: "Decimal", defaultValue: 0 },
				codTaxaNomenclatorUnic: { maxLength: 100, defaultValue: "" },
				codTaxa: { maxLength: 100, defaultValue: "" },
				descrierePlata: { maxLength: 500, defaultValue: "" },
				numePersoanaPlata: { maxLength: 250, defaultValue: "" },
				adresaPersoanaPlata: { maxLength: 500, defaultValue: "" },
				idPlataGhiseul: { dataType: "Int32", defaultValue: 0 },
				isDeleted: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_impotax.Debit": {
			shortName: "Debit",
			namespace: "w_impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUnic: { maxLength: 50, defaultValue: "" },
				idDeclaratie: { dataType: "Int32", defaultValue: 0 },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 500, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utilizatorAnulare: { maxLength: 50, defaultValue: "" },
				explicatieAnulare: { maxLength: 100, defaultValue: "" },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				an: { dataType: "Int32", defaultValue: 0 },
				valoareImpozabila: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				operatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codAct: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_impotax.DebitDetaliu": {
			shortName: "DebitDetaliu",
			namespace: "w_impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				l1: { dataType: "Decimal", defaultValue: 0 },
				l2: { dataType: "Decimal", defaultValue: 0 },
				l3: { dataType: "Decimal", defaultValue: 0 },
				l4: { dataType: "Decimal", defaultValue: 0 },
				l5: { dataType: "Decimal", defaultValue: 0 },
				l6: { dataType: "Decimal", defaultValue: 0 },
				l7: { dataType: "Decimal", defaultValue: 0 },
				l8: { dataType: "Decimal", defaultValue: 0 },
				l9: { dataType: "Decimal", defaultValue: 0 },
				l10: { dataType: "Decimal", defaultValue: 0 },
				l11: { dataType: "Decimal", defaultValue: 0 },
				l12: { dataType: "Decimal", defaultValue: 0 },
				detaliiSuplimentare: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_impotax.DebitScadenta": {
			shortName: "DebitScadenta",
			namespace: "w_impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dataScadenta: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_impotax.Incasare": {
			shortName: "Incasare",
			namespace: "w_impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idPersoana: { dataType: "Int32", defaultValue: 0 },
				idVenit: { dataType: "Int32", defaultValue: 0 },
				idUnic: { maxLength: 50, defaultValue: "" },
				idIntrare: { dataType: "Int32", defaultValue: 0 },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataPlata: { dataType: "DateTime" },
				dataExtras: { dataType: "DateTime" },
				numarExtras: { maxLength: 25, defaultValue: "" },
				document: { maxLength: 25, defaultValue: "" },
				an: { dataType: "Int32", defaultValue: 0 },
				debit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				ramasita: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dobanda: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				penalitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 100, defaultValue: "" },
				pOSRrn: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idBanca: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBanca: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIban: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utilizatorAnulare: { maxLength: 50, defaultValue: "" },
				dataSomatie: { dataType: "DateTime" },
				numarSomatie: { maxLength: 50, defaultValue: "" },
				bonitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				operatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursa: { dataType: "Int32", defaultValue: 0 },
				idDeclaratie: { dataType: "Int32", defaultValue: 0 },
				pOSFactura: { dataType: "Int32", defaultValue: 0 },
				codAct: { dataType: "Int32", defaultValue: 0 },
				explicatieSuplimentara: { maxLength: 250, defaultValue: "" },
				idEpay: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				incasareFaraPersoana: { dataType: "Int32", defaultValue: 0 },
				idIncasator: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idLocatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idUser: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idDocumentAngajamentLegalGlobal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_impotax.Ramasita": {
			shortName: "Ramasita",
			namespace: "w_impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				idPersoana: { dataType: "Int32", defaultValue: 0 },
				idVenit: { dataType: "Int32", defaultValue: 0 },
				idUnic: { maxLength: 50, defaultValue: "" },
				data: { dataType: "DateTime", isNullable: false },
				an: { dataType: "Int32", defaultValue: 0 },
				debit: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dobanda: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				penalitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 150, defaultValue: "" },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utilizatorAnulare: { maxLength: 50, defaultValue: "" },
				explicatieAnulare: { maxLength: 100, defaultValue: "" },
				dataSomatie: { dataType: "DateTime" },
				numarSomatie: { maxLength: 50, defaultValue: "" },
				dataScadenta: { dataType: "DateTime", isNullable: false },
				anRamasita: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				compensare: { dataType: "Int32", defaultValue: 0 },
				operatie: { dataType: "Int32", defaultValue: 0 },
				codAct: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDeclaratie: { dataType: "Int32", defaultValue: 0 },
				pereche: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				bazaSct: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_impotax.TipAutovehicul": {
			shortName: "TipAutovehicul",
			namespace: "w_impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 75, defaultValue: "" },
				dataCreare: { dataType: "DateTime", isNullable: false },
				utiCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utiAnulare: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_impotax.TipOperatie": {
			shortName: "TipOperatie",
			namespace: "w_impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				dataCreare: { dataType: "DateTime" },
				utiCreare: { maxLength: 50, defaultValue: "" },
				idOperatie: { dataType: "Int32", defaultValue: 0 },
				explicatie: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utiAnulare: { maxLength: 50, defaultValue: "" },
				impotaxPosta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_impotax.TipOperatieGrup": {
			shortName: "TipOperatieGrup",
			namespace: "w_impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_impotax.TipOperatieLegatura": {
			shortName: "TipOperatieLegatura",
			namespace: "w_impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idTipOperatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipOperatieGrup: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_impotax.TipSursa": {
			shortName: "TipSursa",
			namespace: "w_impotax",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				idOp: { dataType: "Int32", defaultValue: 0 },
				dataCreare: { dataType: "DateTime" },
				utiCreare: { maxLength: 50, defaultValue: "" },
				dataAnulare: { dataType: "DateTime" },
				utiAnulare: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_invatamant.GrupaCopilPrezenta": {
			shortName: "GrupaCopilPrezenta",
			namespace: "w_invatamant",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idGrupaCopil: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anLuna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z1: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z2: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z3: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z4: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z5: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z6: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z7: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z8: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z9: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z10: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z11: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z12: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z13: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z14: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z15: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z16: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z17: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z18: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z19: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z20: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z21: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z22: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z23: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z24: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z25: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z26: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z27: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z28: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z29: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z30: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				z31: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				totalZile: { dataType: "Int32", defaultValue: 0 },
				totalZilePosibil: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tarifPeZiAplicat: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_invatamant.Luna": {
			shortName: "Luna",
			namespace: "w_invatamant",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anLuna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				lunaInchisa: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_invatamant.ValoareDebit": {
			shortName: "ValoareDebit",
			namespace: "w_invatamant",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_invatamantistoric.GrupaCopil": {
			shortName: "GrupaCopil",
			namespace: "w_invatamantistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaCopil: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaParinte: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaReprezentantLegal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isProgramPrelungit: { dataType: "Boolean", isNullable: false },
				idGrupaCopil: { dataType: "Int32" },
				idValoareDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				numarDosar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataDebitare: { dataType: "DateTime" },
				necesitaRedebitare: { dataType: "Boolean", isNullable: false },
				isContinuitate: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				dataCreare: { dataType: "DateTime" },
			}
		},
		"w_invatamantistoric.ValoareDebit": {
			shortName: "ValoareDebit",
			namespace: "w_invatamantistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idValoareDebit: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_inventare.DocumentConsumAlimenteSuplimentare": {
			shortName: "DocumentConsumAlimenteSuplimentare",
			namespace: "w_inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusIntrare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretGestiune: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareGestiune: { dataType: "Decimal", defaultValue: 0 },
				dataExpirare: { dataType: "DateTime" },
				lot: { maxLength: 50, defaultValue: "" },
				idCategorieBeneficiar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarMeniuriServit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarMeniuriPreparat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarMeniuriEstimat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_inventare.DocumentPropunereCasare": {
			shortName: "DocumentPropunereCasare",
			namespace: "w_inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idComisiaInventariere: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_inventare.DocumentPropunereCasareProdus": {
			shortName: "DocumentPropunereCasareProdus",
			namespace: "w_inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretGestiune: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareGestiune: { dataType: "Decimal", defaultValue: 0 },
				cantitateIesita: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				disponibil: { dataType: "Decimal", defaultValue: 0 },
				motivCasare: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_inventare.DocumentReevaluarePropunere": {
			shortName: "DocumentReevaluarePropunere",
			namespace: "w_inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusInfluentaValoare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusInfluentaValoareGrup: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valoareIndicePretConsum: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareActualizareIndicePretConsum: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareInfluentaComisie: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareActualizare: { dataType: "Decimal", defaultValue: 0 },
				explicatie: { maxLength: 300, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_inventare.ProdusCategorie": {
			shortName: "ProdusCategorie",
			namespace: "w_inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 30, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				idCPV: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_inventare.ProdusIesire": {
			shortName: "ProdusIesire",
			namespace: "w_inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusIntrare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 100, defaultValue: "" },
				codIntrareIesire: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentPropunereCasareProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentFurnizorClientDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				importDataJson: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_inventare.ProdusInfluentaValoare": {
			shortName: "ProdusInfluentaValoare",
			namespace: "w_inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProdus: { dataType: "Int32", defaultValue: 0 },
				idProdusInfluentaValoareParent: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusInfluentaValoareGrup: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentReevaluare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentNotaContabila: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentFurnizorClientDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isReevaluareManuala: { dataType: "Boolean", isNullable: false },
				dataAplicare: { dataType: "DateTime" },
				explicatie: { maxLength: 250, defaultValue: "" },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareLei: { dataType: "Decimal", defaultValue: 0 },
				valoareAmortizata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareAmortizataLei: { dataType: "Decimal", defaultValue: 0 },
				valoareRamasa: { dataType: "Decimal", defaultValue: 0 },
				valoareRamasaLei: { dataType: "Decimal", defaultValue: 0 },
				coeficientActualizare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dataReferintaCoeficientActualizare: { dataType: "DateTime" },
				dataIncepereAmortizare: { dataType: "DateTime" },
				durataAmortizare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isExtracontabil: { dataType: "Boolean", isNullable: false },
				importDataJson: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_inventare.ProdusIntrare": {
			shortName: "ProdusIntrare",
			namespace: "w_inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusIntrareGrup: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaPrimitor: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitate: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretGestiune: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretGestiuneLei: { dataType: "Decimal", defaultValue: 0 },
				valoareIntrareAmortizata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareIntrareAmortizataLei: { dataType: "Decimal", defaultValue: 0 },
				valoareIntrareRamasa: { dataType: "Decimal", defaultValue: 0 },
				valoareIntrareRamasaLei: { dataType: "Decimal", defaultValue: 0 },
				valoareGestiune: { dataType: "Decimal", defaultValue: 0 },
				valoareGestiuneLei: { dataType: "Decimal", defaultValue: 0 },
				adaosComercialProcent: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				adaosComercialSuma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				adaosComercialSumaLei: { dataType: "Decimal", defaultValue: 0 },
				pretUnitarVanzare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				pretUnitarVanzareLei: { dataType: "Decimal", defaultValue: 0 },
				idDocumentFurnizorClientDetaliuTipVanzare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tvaVanzare: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				tvaVanzareLei: { dataType: "Decimal", defaultValue: 0 },
				pretVanzare: { dataType: "Decimal", defaultValue: 0 },
				pretVanzareLei: { dataType: "Decimal", defaultValue: 0 },
				valoareUnitaraVanzare: { dataType: "Decimal", defaultValue: 0 },
				valoareUnitaraVanzareLei: { dataType: "Decimal", defaultValue: 0 },
				valoareTvaVanzare: { dataType: "Decimal", defaultValue: 0 },
				valoareTvaVanzareLei: { dataType: "Decimal", defaultValue: 0 },
				valoareVanzare: { dataType: "Decimal", defaultValue: 0 },
				valoareVanzareLei: { dataType: "Decimal", defaultValue: 0 },
				idDocumentFurnizorClientDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataExpirare: { dataType: "DateTime" },
				lot: { maxLength: 50, defaultValue: "" },
				cantitateIesitaAn: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codIntrareIesire: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				importDataJson: { defaultValue: "" },
				importDataTip: { maxLength: 100, defaultValue: "" },
				importDataId: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idPersoanaConsum: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaTransfer: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codModDistribuireClasificatieMultipla: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_inventare.ProdusModOrganizare": {
			shortName: "ProdusModOrganizare",
			namespace: "w_inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 30, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				importDataJson: { defaultValue: "" },
				importDataTip: { maxLength: 100, defaultValue: "" },
				importDataId: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_inventare.ProdusTipFinantare": {
			shortName: "ProdusTipFinantare",
			namespace: "w_inventare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 30, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				importDataJson: { defaultValue: "" },
				importDataTip: { maxLength: 100, defaultValue: "" },
				importDataId: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_inventareistoric.Produs": {
			shortName: "Produs",
			namespace: "w_inventareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cod: { maxLength: 50, defaultValue: "" },
				numarInventar: { maxLength: 50, defaultValue: "" },
				numarInventarNumeric: { dataType: "Int32", defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				idProdusTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusDetaliuTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusClasificare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusCategorie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusModOrganizare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusTipFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idAliment: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cantitateAmbalaj: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isConsumFractionat: { dataType: "Boolean", isNullable: false },
				idUnitateMasura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataEvidenta: { dataType: "DateTime" },
				dataPIF: { dataType: "DateTime" },
				dataIncepereAmortizare: { dataType: "DateTime" },
				durataUtilizare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				domeniuApartinator: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdus: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				idClasificatieEconomica: { dataType: "Int32", defaultValue: 0 },
				caracteristici: { maxLength: 500, defaultValue: "" },
				idCPV: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursValutar: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareIntrareActualizata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareIntrareActualizataLei: { dataType: "Decimal", defaultValue: 0 },
				valoareIntrareAmortizata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareIntrareAmortizataLei: { dataType: "Decimal", defaultValue: 0 },
				valoareIntrareRamasa: { dataType: "Decimal", defaultValue: 0 },
				valoareIntrareRamasaLei: { dataType: "Decimal", defaultValue: 0 },
				soldInitialData: { dataType: "DateTime" },
				calculEstimareDataPIF: { dataType: "Boolean", isNullable: false },
				faraEstimareAmortizare: { dataType: "Boolean", isNullable: false },
				faraReevaluare: { dataType: "Boolean", isNullable: false },
				faraAmortizare: { dataType: "Boolean", isNullable: false },
				inCursDeExecutie: { dataType: "Boolean", isNullable: false },
				necorporal: { dataType: "Boolean", isNullable: false },
				mijlocFixNaturaObiectInventar: { dataType: "Boolean", isNullable: false },
				inAfaraBilantului: { dataType: "Boolean", isNullable: false },
				importDataJson: { defaultValue: "" },
				importDataTip: { maxLength: 100, defaultValue: "" },
				importDataId: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valoareIesire: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoareIesireLei: { dataType: "Decimal", defaultValue: 0 },
				isAmortizabil: { dataType: "Int32", defaultValue: 0 },
				numeDocumentProvenienta: { maxLength: 100, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				lenCod: { dataType: "Int32", defaultValue: 0 },
				lenNume: { dataType: "Int32", defaultValue: 0 },
			}
		},
		"w_inventareistoric.ProdusCaracteristici": {
			shortName: "ProdusCaracteristici",
			namespace: "w_inventareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProdus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				caracteristici: { maxLength: 2000, defaultValue: "" },
				numarTopo: { maxLength: 50, defaultValue: "" },
				numarCarteFunciara: { maxLength: 50, defaultValue: "" },
				numarCadastral: { maxLength: 50, defaultValue: "" },
				suprafata: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				hotarareConsiliuLocal: { maxLength: 50, defaultValue: "" },
				hotarareGuvern: { maxLength: 50, defaultValue: "" },
				idProdusCaracteristici: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_investitiiistoric.Obiectiv": {
			shortName: "Obiectiv",
			namespace: "w_investitiiistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isNecorporal: { dataType: "Boolean", isNullable: false },
				dataStart: { dataType: "DateTime" },
				dataStopEstimata: { dataType: "DateTime" },
				idObiectivParinte: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idObiectiv: { dataType: "Int32" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				valoareFacturataInitial: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				valoarePlatitaInitial: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				soldInitial: { dataType: "Decimal", defaultValue: 0 },
				dataFinalizareExecutie: { dataType: "DateTime" },
				isReceptionat: { dataType: "Boolean", isNullable: false },
				idObiectivSubObiectivEcubInvest: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idObiectivEcubInvest: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idIRIDEcubInvest: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				codObiectivSubObiectivEcubInvest: { maxLength: 50, defaultValue: "" },
				codAnaliticContabilitate: { maxLength: 50, defaultValue: "" },
			}
		},
		"w_log.OprireDeclansatori": {
			shortName: "OprireDeclansatori",
			namespace: "w_log",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idSesiune: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_log.ProblemaUnitate": {
			shortName: "ProblemaUnitate",
			namespace: "w_log",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentDetaliu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codSursa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codTipProblema: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codProblema: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				flagEroare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				mesaj: { defaultValue: "" },
				detaliu: { defaultValue: "" },
				idProblemaUnitateParinte: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.CategorieVenit": {
			shortName: "CategorieVenit",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.CodProblemaUnitateTip": {
			shortName: "CodProblemaUnitateTip",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				codSursa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codTipProblema: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codProblema: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 150, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.ComisiaTip": {
			shortName: "ComisiaTip",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.CrediteBugetareTip": {
			shortName: "CrediteBugetareTip",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idContSintetic: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numeJurnal: { maxLength: 50, defaultValue: "" },
			}
		},
		"w_nomenclatoare.DocumentOperatiuneTip": {
			shortName: "DocumentOperatiuneTip",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentSubTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 150, defaultValue: "" },
				explicatie: { maxLength: 250, defaultValue: "" },
				categoria: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isVenit: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.DocumentStareTip": {
			shortName: "DocumentStareTip",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.DocumentSubTip": {
			shortName: "DocumentSubTip",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 150, defaultValue: "" },
				isIntrare: { dataType: "Boolean", isNullable: false },
				isMiscare: { dataType: "Boolean", isNullable: false },
				isIesire: { dataType: "Boolean", isNullable: false },
				isVizibilEcub: { dataType: "Boolean", isNullable: false },
				isVizibilContracte: { dataType: "Boolean", isNullable: false },
				isVizibilCid: { dataType: "Boolean", isNullable: false },
				isPlata: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.DocumentSursaTemplate": {
			shortName: "DocumentSursaTemplate",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocumentTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentTipSursa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codGrup: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				explicatie: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.DocumentTip": {
			shortName: "DocumentTip",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				isIntrare: { dataType: "Boolean", isNullable: false },
				isMiscare: { dataType: "Boolean", isNullable: false },
				isIesire: { dataType: "Boolean", isNullable: false },
				isVizibilEcub: { dataType: "Boolean", isNullable: false },
				isVizibilCid: { dataType: "Boolean", isNullable: false },
				isPersoanaDocumentAtasat: { dataType: "Boolean", isNullable: false },
				isPlata: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.GenerareContabOperatiuneProdusValoareTip": {
			shortName: "GenerareContabOperatiuneProdusValoareTip",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				idDocumentOperatiuneTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusDetaliuTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idGenerareContabValoareTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numeScurt: { maxLength: 100, defaultValue: "" },
				ordineAfisare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.GenerareContabValoareTip": {
			shortName: "GenerareContabValoareTip",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.NaturaPlata": {
			shortName: "NaturaPlata",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.NumarTextNivele": {
			shortName: "NumarTextNivele",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cifra: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nr: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				text: { maxLength: 25, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.NumarTextSute": {
			shortName: "NumarTextSute",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cifra: { dataType: "Int32", defaultValue: 0 },
				text: { maxLength: 25, defaultValue: "" },
				biti: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.NumarTextSuteZeciUnitati": {
			shortName: "NumarTextSuteZeciUnitati",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				bitiSute: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				bitiZeci: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				bitiUnitati: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				text: { maxLength: 25, defaultValue: "" },
				textX: { maxLength: 25, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.NumarTextUnitati": {
			shortName: "NumarTextUnitati",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cifra: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				text: { maxLength: 25, defaultValue: "" },
				biti: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.NumarTextZeci": {
			shortName: "NumarTextZeci",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cifra: { dataType: "Int32", defaultValue: 0 },
				text: { maxLength: 25, defaultValue: "" },
				biti: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.PersoanaIerarhieTip": {
			shortName: "PersoanaIerarhieTip",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				numeIerarhieInversa: { maxLength: 150, defaultValue: "" },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.ProdusDetaliuTip": {
			shortName: "ProdusDetaliuTip",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProdusTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 100, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.ProdusGrupTip": {
			shortName: "ProdusGrupTip",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.ProdusTip": {
			shortName: "ProdusTip",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idProdusGrupTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 100, defaultValue: "" },
				codClasificareEconomica: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.SarbatoareLegala": {
			shortName: "SarbatoareLegala",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				data: { dataType: "DateTime", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.TipModalitateAchizitie": {
			shortName: "TipModalitateAchizitie",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.TipObiectAchizitie": {
			shortName: "TipObiectAchizitie",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.TipProceduraAchizitie": {
			shortName: "TipProceduraAchizitie",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				isAchizitieDirecta: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.UnitateMasura": {
			shortName: "UnitateMasura",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				cod: { maxLength: 10, defaultValue: "" },
				numeSingular: { maxLength: 150, defaultValue: "" },
				codUnitateMasuraBt130: { maxLength: 10, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.Valuta": {
			shortName: "Valuta",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				simbol: { maxLength: 10, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoare.Venit": {
			shortName: "Venit",
			namespace: "w_nomenclatoare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				actualizabilAnual: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoareistoric.AsociereContBancaTipBuget": {
			shortName: "AsociereContBancaTipBuget",
			namespace: "w_nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCont: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoareistoric.CAEN": {
			shortName: "CAEN",
			namespace: "w_nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				sectiune: { maxLength: 10, defaultValue: "" },
				diviziune: { maxLength: 10, defaultValue: "" },
				grupa: { maxLength: 10, defaultValue: "" },
				clasa: { maxLength: 8, defaultValue: "" },
				codCaen: { maxLength: 8, defaultValue: "" },
				nume: { maxLength: 200, defaultValue: "" },
				codCaenRev1: { maxLength: 500, defaultValue: "" },
				isic4: { maxLength: 200, defaultValue: "" },
				idCAEN: { dataType: "Int32" },
				isLeafNode: { dataType: "Boolean", isNullable: false },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoareistoric.CotaTva": {
			shortName: "CotaTva",
			namespace: "w_nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cota: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 50, defaultValue: "" },
				idCotaTva: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoareistoric.CPV": {
			shortName: "CPV",
			namespace: "w_nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 20, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				idCPV: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoareistoric.DocumentFurnizorClientDetaliuTip": {
			shortName: "DocumentFurnizorClientDetaliuTip",
			namespace: "w_nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				idTipTva: { dataType: "Int32", defaultValue: 0 },
				idCotaTva: { dataType: "Int32", defaultValue: 0 },
				isTvaLaIncasare: { dataType: "Boolean", isNullable: false },
				idDocumentTip: { dataType: "Int32", defaultValue: 0 },
				isIesire: { dataType: "Boolean", isNullable: false },
				idContTVA: { dataType: "Int32", defaultValue: 0 },
				idContTaxareInversa: { dataType: "Int32", defaultValue: 0 },
				idSiefTipTVA: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentFurnizorClientDetaliuTip: { dataType: "Int32" },
				eFacturaCodCategorieTva: { maxLength: 10, defaultValue: "" },
				eFacturaCotaTVA: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isDefault: { dataType: "Boolean", isNullable: false },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoareistoric.PlataNumerarTip": {
			shortName: "PlataNumerarTip",
			namespace: "w_nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				idContSinteticCheltuiala: { dataType: "Int32", defaultValue: 0 },
				idContSinteticCheltuialaCredit: { dataType: "Int32", defaultValue: 0 },
				idContSinteticPlataNumerarDebit: { dataType: "Int32", defaultValue: 0 },
				idContSinteticPlataNumerarCredit: { dataType: "Int32", defaultValue: 0 },
				idNaturaPlata: { dataType: "Int32", defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", defaultValue: 0 },
				isPlataDirecta: { dataType: "Boolean" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idPlataNumerarTip: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoareistoric.RetinereSalariiTip": {
			shortName: "RetinereSalariiTip",
			namespace: "w_nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				numeScurt: { maxLength: 150, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idRetinereSalariiTip: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				isTotalObligatii: { dataType: "Boolean", isNullable: false },
				isFNUASS: { dataType: "Boolean", isNullable: false },
				isRestPlata: { dataType: "Boolean", isNullable: false },
			}
		},
		"w_nomenclatoareistoric.ServiciuTip": {
			shortName: "ServiciuTip",
			namespace: "w_nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				idContSinteticCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isImobilizari: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idServiciuTip: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoareistoric.TipTva": {
			shortName: "TipTva",
			namespace: "w_nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				idTipTva: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_nomenclatoareistoric.UtilitatiTip": {
			shortName: "UtilitatiTip",
			namespace: "w_nomenclatoareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				idContSinteticCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idNaturaPlata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idUtilitatiTip: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
			}
		},
		"w_persoane.Comisia": {
			shortName: "Comisia",
			namespace: "w_persoane",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idComisiaTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 150, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_persoane.PersoanaIerarhie": {
			shortName: "PersoanaIerarhie",
			namespace: "w_persoane",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaIerarhieTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				treeLevel: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_persoaneistoric.ComisiaPersoana": {
			shortName: "ComisiaPersoana",
			namespace: "w_persoaneistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idComisia: { dataType: "Int32", defaultValue: 0 },
				idPersoana: { dataType: "Int32", defaultValue: 0 },
				idComisiaPersoana: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_persoaneistoric.Persoana": {
			shortName: "Persoana",
			namespace: "w_persoaneistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32" },
				isBanca: { dataType: "Boolean", isNullable: false },
				idPersoanaFormaOrganizare: { dataType: "Int32", defaultValue: 0 },
				codFormaOrganizare: { maxLength: 3, defaultValue: "" },
				codRol: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				coduriCaen: { maxLength: 200, defaultValue: "" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				idParinte: { dataType: "Int32", defaultValue: 0 },
				cui: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cuiNonRo: { maxLength: 20, defaultValue: "" },
				nume: { maxLength: 100, defaultValue: "" },
				prenume: { maxLength: 150, defaultValue: "" },
				numePrenume: { maxLength: 251, defaultValue: "" },
				prenumeMama: { maxLength: 150, defaultValue: "" },
				prenumeTata: { maxLength: 150, defaultValue: "" },
				sex: { maxLength: 1, defaultValue: "" },
				dataDeces: { dataType: "DateTime" },
				telefon: { maxLength: 50, defaultValue: "" },
				email: { maxLength: 50, defaultValue: "" },
				actTip: { maxLength: 50, defaultValue: "" },
				actSerieNumar: { maxLength: 50, defaultValue: "" },
				actDataEliberare: { dataType: "DateTime" },
				actDataExpirare: { dataType: "DateTime" },
				actEliberatDe: { maxLength: 50, defaultValue: "" },
				atributFiscal: { maxLength: 10, defaultValue: "" },
				idPersoanaContact: { dataType: "Int32", defaultValue: 0 },
				idPersoanaReprezentant: { dataType: "Int32", defaultValue: 0 },
				idPersoanaOcupatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				ocupatie: { maxLength: 50, defaultValue: "" },
				angajator: { maxLength: 50, defaultValue: "" },
				functieOcupata: { maxLength: 50, defaultValue: "" },
				idPersoanaNivelStudii: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nivelStudii: { maxLength: 50, defaultValue: "" },
				adresa: { maxLength: 250, defaultValue: "" },
				adresaDetaliu: { maxLength: 50, defaultValue: "" },
				idTara: { dataType: "Int32", defaultValue: 0 },
				idJudet: { dataType: "Int32", defaultValue: 0 },
				idLocalitate: { dataType: "Int32", defaultValue: 0 },
				idStrada: { dataType: "Int32", defaultValue: 0 },
				idNumarPostal: { dataType: "Int32", defaultValue: 0 },
				idBloc: { dataType: "Int32", defaultValue: 0 },
				idScara: { dataType: "Int32", defaultValue: 0 },
				idApartament: { dataType: "Int32", defaultValue: 0 },
				codPostal: { maxLength: 10, defaultValue: "" },
				idEtnie: { dataType: "Int32", defaultValue: 0 },
				idReligie: { dataType: "Int32", defaultValue: 0 },
				idNationalitate: { dataType: "Int32", defaultValue: 0 },
				cetatenie: { maxLength: 20, defaultValue: "" },
				vizibilImpotax: { dataType: "Boolean", isNullable: false },
				vizibilEcub: { dataType: "Boolean", isNullable: false },
				vizibilAgroregis: { dataType: "Boolean", isNullable: false },
				vizibilResum: { dataType: "Boolean", isNullable: false },
				bancaCodSwift: { maxLength: 10, defaultValue: "" },
				bancaCodBic: { maxLength: 10, defaultValue: "" },
				isPlatitorTVA: { dataType: "Boolean", isNullable: false },
				isSistemTVAIncasare: { dataType: "Boolean", isNullable: false },
				idTaraFapt: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idJudetFapt: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idLocalitateFapt: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idStradaFapt: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idNumarPostalFapt: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBlocFapt: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idScaraFapt: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idApartamentFapt: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codPostalFapt: { maxLength: 10, defaultValue: "" },
				adresaFapt: { maxLength: 250, defaultValue: "" },
				adresaDetaliuFapt: { maxLength: 50, defaultValue: "" },
				idTaraCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idJudetCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idLocalitateCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idStradaCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idNumarPostalCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBlocCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idScaraCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idApartamentCorespondenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codPostalCorespondenta: { maxLength: 10, defaultValue: "" },
				adresaCorespondenta: { maxLength: 250, defaultValue: "" },
				adresaDetaliuCorespondenta: { maxLength: 50, defaultValue: "" },
				dataExpirareFlotant: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idImpotax: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isEmitereFacturaSistemRoEfactura: { dataType: "Boolean", isNullable: false },
			}
		},
		"w_persoaneistoric.PersoanaBancaIban": {
			shortName: "PersoanaBancaIban",
			namespace: "w_persoaneistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", defaultValue: 0 },
				idPersoanaBanca: { dataType: "Int32", defaultValue: 0 },
				iBAN: { maxLength: 24, defaultValue: "" },
				isVenit: { dataType: "Boolean", isNullable: false },
				isPrincipal: { dataType: "Boolean", isNullable: false },
				isContPlatitor: { dataType: "Boolean", isNullable: false },
				isContBeneficiar: { dataType: "Boolean", isNullable: false },
				isContMandat: { dataType: "Boolean", isNullable: false },
				isExtrabugetar: { dataType: "Boolean", isNullable: false },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				idPersoanaBancaIban: { dataType: "Int32" },
				codBanca: { maxLength: 4, defaultValue: "" },
				numeBeneficiar: { maxLength: 250, defaultValue: "" },
				analiticContabilitate: { maxLength: 100, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_persoaneistoric.PersoanaIerarhie": {
			shortName: "PersoanaIerarhie",
			namespace: "w_persoaneistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoana: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaIerarhieTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				treeLevel: { dataType: "Int32", defaultValue: 0 },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				idPersoanaIerarhie: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_produs.ProdusRaportare": {
			shortName: "ProdusRaportare",
			namespace: "w_produs",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				cod: { maxLength: 50, defaultValue: "" },
				nume: { maxLength: 200, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_raportarifinanciare.ExtrasContTrezo": {
			shortName: "ExtrasContTrezo",
			namespace: "w_raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idDocument: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nrExtras: { maxLength: 50, defaultValue: "" },
				dataExtras: { dataType: "DateTime" },
				denumireEp: { maxLength: 250, defaultValue: "" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				continutXml: { defaultValue: "" },
				utilizatorAdaugare: { defaultValue: "" },
				dataAdaugare: { dataType: "DateTime", isNullable: false },
				utilizatorActualizare: { defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
			}
		},
		"w_raportarifinanciare.ExtrasContTrezoDetalii": {
			shortName: "ExtrasContTrezoDetalii",
			namespace: "w_raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idExtrasContTrezo: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nrOP: { maxLength: 50, defaultValue: "" },
				codIban: { maxLength: 50, defaultValue: "" },
				cont: { maxLength: 50, defaultValue: "" },
				soldPrecedentSumaD: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				soldPrecedentSumaC: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajZiSumaD: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				rulajZiSumaC: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				totalSumeSumaD: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				totalSumeSumaC: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				soldFinalSumaD: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				soldFinalSumaC: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", defaultValue: 0 },
				idClasificatieFunctionala: { dataType: "Int32", defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", defaultValue: 0 },
				tipIban: { dataType: "Int32", defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_raportarifinanciare.ExtrasContTrezoDetaliiMisc": {
			shortName: "ExtrasContTrezoDetaliiMisc",
			namespace: "w_raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idExtrasContTrezoDetalii: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarDoc: { maxLength: 50, defaultValue: "" },
				dataDoc: { dataType: "DateTime" },
				dataBan: { dataType: "DateTime" },
				nrRefDest: { maxLength: 50, defaultValue: "" },
				ibanBfPl: { maxLength: 50, defaultValue: "" },
				platitor: { maxLength: 50, defaultValue: "" },
				numePb: { maxLength: 50, defaultValue: "" },
				sumaD: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaC: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				codContract: { defaultValue: "" },
				randContract: { defaultValue: "" },
				explicatii: { maxLength: 250, defaultValue: "" },
				generareExecutieBugetara: { dataType: "Boolean", isNullable: false },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCompartiment: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSumaExecutie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentIncasare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isDeleted: { dataType: "Boolean", isNullable: false },
				codDocumentTipAsociat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentOPAsociat: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_raportarifinanciare.ExtrasContTrezoLichidari": {
			shortName: "ExtrasContTrezoLichidari",
			namespace: "w_raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idExtrasContTrezoDetaliiMisc: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentLichidare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_raportarifinanciare.ExtrasContTrezoSoldInitial": {
			shortName: "ExtrasContTrezoSoldInitial",
			namespace: "w_raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codIban: { maxLength: 50, defaultValue: "" },
				cont: { maxLength: 50, defaultValue: "" },
				sumaD: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaC: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				tipIban: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_raportarifinanciare.Formular": {
			shortName: "Formular",
			namespace: "w_raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { defaultValue: "" },
				codFormular: { defaultValue: "" },
				raportare: { defaultValue: "" },
				codFormularTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_raportarifinanciare.FormularStatus": {
			shortName: "FormularStatus",
			namespace: "w_raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anul: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				luna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormular: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codUnic: { maxLength: 1000, defaultValue: "" },
				uploadDate: { dataType: "DateTime" },
				downloadDate: { dataType: "DateTime" },
				idStatus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				errors: { defaultValue: "" },
				filepath: { defaultValue: "" },
				params: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_raportarifinanciare.LogGenerareXML": {
			shortName: "LogGenerareXML",
			namespace: "w_raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				xml: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_raportarifinanciare.RegistruSchemaXSD": {
			shortName: "RegistruSchemaXSD",
			namespace: "w_raportarifinanciare",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 100, defaultValue: "" },
				link: { maxLength: 200, defaultValue: "" },
				versiune: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				versiuneAnaf: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				dataVerificare: { dataType: "DateTime" },
				mail: { defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_raportarifinanciareistoric.ExtrasContTrezoSumaCredit": {
			shortName: "ExtrasContTrezoSumaCredit",
			namespace: "w_raportarifinanciareistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", defaultValue: 0 },
				cod: { maxLength: 50, defaultValue: "" },
				capitol: { maxLength: 2, defaultValue: "" },
				titlu: { maxLength: 2, defaultValue: "" },
				sumaCredit: { dataType: "Decimal", defaultValue: 0 },
				idExtrasContTrezoSumaCredit: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_registre.OperatiuneProprieTipDetaliu": {
			shortName: "OperatiuneProprieTipDetaliu",
			namespace: "w_registre",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOperatiuneProprieTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 250, defaultValue: "" },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContAnaliticDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContAnaliticCredit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idJurnal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				suma: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isObligatie: { dataType: "Boolean", isNullable: false },
				isGenerareExecutieBugetaraAL: { dataType: "Boolean", isNullable: false },
				isPlata: { dataType: "Boolean", isNullable: false },
				isGenerareExecutieBugetaraO: { dataType: "Boolean", isNullable: false },
				isGenerarePlataVirament: { dataType: "Boolean", isNullable: false },
				codTipInregistrare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_registreistoric.OperatiuneProprieTip": {
			shortName: "OperatiuneProprieTip",
			namespace: "w_registreistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 150, defaultValue: "" },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOperatiuneProprieTip: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_registreistoric.PlataNumerarTipDetaliu": {
			shortName: "PlataNumerarTipDetaliu",
			namespace: "w_registreistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPlataNumerarTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 150, defaultValue: "" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaCentruCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPlataNumerarTipDetaliu: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				analiticContabilitate: { maxLength: 100, defaultValue: "" },
				idContSinteticObligatieDebit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContSinteticObligatieCredit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isNotaContabilaPlataDirectaFaraObligatie: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_registreistoric.ProdusClasificare": {
			shortName: "ProdusClasificare",
			namespace: "w_registreistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				cod: { maxLength: 100, defaultValue: "" },
				nume: { maxLength: 250, defaultValue: "" },
				idProdusGrupTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusDetaliuTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idProdusClasificare: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				isClasaMagazieObiectInventar: { dataType: "Boolean", isNullable: false },
				durataMinima: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				durataMaxima: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContSinteticStoc: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContSinteticAmortizare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContSinteticCheltuiala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				analiticContabilitate: { maxLength: 100, defaultValue: "" },
			}
		},
		"w_registreistoric.VenitDetaliu": {
			shortName: "VenitDetaliu",
			namespace: "w_registreistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nume: { maxLength: 150, defaultValue: "" },
				actualizabilAnual: { dataType: "Boolean", isNullable: false },
				idVenitDetaliu: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				cuDobanda: { dataType: "Boolean", isNullable: false },
				cuDebit: { dataType: "Boolean", isNullable: false },
				cuFormula: { dataType: "Boolean", isNullable: false },
				valoareFixa: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				procentBonificatieFizice: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				procentBonificatieJuridice: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				cumulatFizice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cumulatJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				contTrezorerieFizice: { maxLength: 50, defaultValue: "" },
				idClasificatieFunctionalaFizice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				contTrezorerieJuridice: { maxLength: 50, defaultValue: "" },
				idClasificatieFunctionalaJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				uM: { maxLength: 5, defaultValue: "" },
				formula1: { maxLength: 254, defaultValue: "" },
				formula2: { maxLength: 255, defaultValue: "" },
				formula3: { maxLength: 254, defaultValue: "" },
				formula4: { maxLength: 254, defaultValue: "" },
				formula5: { maxLength: 254, defaultValue: "" },
				modDebitare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				grupDebitare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cuTVA: { dataType: "Boolean", isNullable: false },
				tipScadenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				pornireOprireCalcul: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				formulaAnAnteriorDiferentaIndexare: { maxLength: 100, defaultValue: "" },
				formulaAnCurentDiferentaIndexare: { maxLength: 100, defaultValue: "" },
				cuDiferentaIndexare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				taxareInversa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idTipValuta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenitBazaDetaliu: { dataType: "Int32", defaultValue: 0 },
				idDetaliuVenit: { dataType: "Int32", defaultValue: 0 },
				dataCreare: { dataType: "DateTime" },
				utilizatorCreare: { maxLength: 50, defaultValue: "" },
				cuFactura: { dataType: "Boolean" },
				tipDebitareAlteDebite: { dataType: "Int32", defaultValue: 0 },
				contIBANFizice: { maxLength: 50, defaultValue: "" },
				contIBANJuridice: { maxLength: 50, defaultValue: "" },
				valabilPosta: { dataType: "Boolean" },
				tVAProcent: { dataType: "Decimal", defaultValue: 0 },
				expValid: { maxLength: 15, defaultValue: "" },
				expExpir: { maxLength: 15, defaultValue: "" },
				faraDenumireVenitInFacturi: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cursDinValoriCalcul: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				denumireVirament1: { maxLength: 25, defaultValue: "" },
				denumireVirament2: { maxLength: 25, defaultValue: "" },
				procentVirament1: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				procentVirament2: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				categorieVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				debitareExpirate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				facturareSeparata: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				perioadaScadenta: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				perioadaScadentaLuna: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				perioadaScadentaZi: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCassaFizice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCassaMajorariFizice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCassaJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCassaMajorariJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cif2SiDen2: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cuLimitareDobanda: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				inactiv: { dataType: "Boolean", isNullable: false },
				minimBonificatieFizice: { dataType: "Decimal", defaultValue: 0 },
				minimBonificatieJuridice: { dataType: "Decimal", defaultValue: 0 },
				tVAPosesie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				calculDiferentaCursMoneda: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				calculDiferentaCurs: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idVenitMfpJuridice: { dataType: "Int32", defaultValue: 0 },
				idVenitMfpFizice: { dataType: "Int32", defaultValue: 0 },
				zileGratie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				faraScadentaFacturaZile: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				faraScadentaFactura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cif1SiCif2: { dataType: "Int32", defaultValue: 0 },
				verificareExistentaContracte: { dataType: "Int32", defaultValue: 0 },
				peLocatii: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				pausal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarZecimaleJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numarZecimaleFizice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				adresa2: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				valabilGhiseul: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				stingPeScad: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIbanFizice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaBancaIbanJuridice: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				modGenerareNotaContabilaIndividual: { dataType: "Boolean", isNullable: false },
				idJurnal: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idJurnalIncasareNumerar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idJurnalIncasareBanca: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSursaFinantare: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContVenit: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContObligatie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idContBanca: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isTertiDecontareUlterioara: { dataType: "Boolean", isNullable: false },
				idVenitDetaliuParinte: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				procentDecontareTerti: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				sumaDecontareTerti: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				codAnaliticContabilitateFizice: { maxLength: 50, defaultValue: "" },
				codAnaliticContabilitateJuridice: { maxLength: 50, defaultValue: "" },
				codAnaliticContabilitateFiziceBanca: { maxLength: 50, defaultValue: "" },
				codAnaliticContabilitateJuridiceBanca: { maxLength: 50, defaultValue: "" },
				isOperatiuneCheltuiala: { dataType: "Boolean", isNullable: false },
				isFaraClasificatieBugetara: { dataType: "Boolean", isNullable: false },
				isRefacturareUtilitati: { dataType: "Boolean", isNullable: false },
				idUtilitatiTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				zonaComerciala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				verificareRestante: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				verificareOnline: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				facturareCasierie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				bonitateOnlineFizice: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				bonitateOnlineJuridice: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				semnatura: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idDocumentTipGarantie: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isIncasareFacturaFurnizorRetur: { dataType: "Boolean", isNullable: false },
				dataMaxFacturareIncasare: { dataType: "DateTime" },
				isNCRestituireDiminuareSuprasolvire: { dataType: "Boolean", isNullable: false },
				isGenerareNotaObligatieDispozitieIncasareSiBanca: { dataType: "Boolean", isNullable: false },
				isGenerareNotaContObligatieFaraCodVenit: { dataType: "Boolean", isNullable: false },
				isGenerareNotaContBancaFaraClasificatie: { dataType: "Boolean", isNullable: false },
				isPenalitateContractRetinutaLaPlata: { dataType: "Boolean", isNullable: false },
			}
		},
		"w_resalistoric.Functia": {
			shortName: "Functia",
			namespace: "w_resalistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				cod: { maxLength: 50, defaultValue: "" },
				nume: { maxLength: 150, defaultValue: "" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				idFunctia: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_sief.TipTva": {
			shortName: "TipTva",
			namespace: "w_sief",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 50, defaultValue: "" },
				procent: { dataType: "Decimal", defaultValue: 0 },
				isVanzare: { dataType: "Boolean" },
				isCumparare: { dataType: "Boolean" },
				idUser: { dataType: "Int32", defaultValue: 0 },
				stamp: { dataType: "DateTime" },
				lunaInceput: { dataType: "Int32", defaultValue: 0 },
				lunaSfarsit: { dataType: "Int32", defaultValue: 0 },
				descriere: { maxLength: 200, defaultValue: "" },
				isVirtual: { dataType: "Boolean" },
				isCurent: { dataType: "Boolean", isNullable: false },
				isNedeductibil: { dataType: "Boolean", isNullable: false },
				idSaft: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codEfactura: { maxLength: 10, defaultValue: "" },
				codScutire: { maxLength: 50, defaultValue: "" },
				codSafT: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_situatiipersonal.Formular": {
			shortName: "Formular",
			namespace: "w_situatiipersonal",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nrrnd: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				nrrndf: { maxLength: 10, defaultValue: "" },
				denumire: { maxLength: 200, defaultValue: "" },
				cod: { maxLength: 20, defaultValue: "" },
				um: { maxLength: 50, defaultValue: "" },
				tip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				anexa: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				an: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				trimestru: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				sursa: { maxLength: 200, defaultValue: "" },
				titlu: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_unitati.OperatiuneActivitateEconomicaClasificatieBugetara": {
			shortName: "OperatiuneActivitateEconomicaClasificatieBugetara",
			namespace: "w_unitati",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				nume: { maxLength: 250, defaultValue: "" },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isProiect: { dataType: "Boolean", isNullable: false },
				dataStart: { dataType: "DateTime" },
				dataStop: { dataType: "DateTime" },
				dataStopOperare: { dataType: "DateTime" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_unitati.OperatiuneActivitateEconomicaClasificatieBugetaraDetaliu": {
			shortName: "OperatiuneActivitateEconomicaClasificatieBugetaraDetaliu",
			namespace: "w_unitati",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOperatiuneActivitateEconomicaClasificatieBugetara: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idFormularClasificatieEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				procent: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				isRetinereGarantieBunaExecutie: { dataType: "Boolean", isNullable: false },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				codModDistribuireClasificatieMultipla: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
		"w_unitati.OrganizareInstitutionalaBugetClasificatieFunctionala": {
			shortName: "OrganizareInstitutionalaBugetClasificatieFunctionala",
			namespace: "w_unitati",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idOrganizareInstitutionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBuget: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idBugetClasificatieFunctionala: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				codAnaliticContabilitate: { maxLength: 50, defaultValue: "" },
				idOrganizareInstitutionalaActivitateEconomica: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idPersoanaResponsabil: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_unitati.UnitateSectorBugetarOpcOsc": {
			shortName: "UnitateSectorBugetarOpcOsc",
			namespace: "w_unitati",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idSectorBugetar: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				idCodProgramBugetarPropus: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				cuiOpcOsc: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				numeOpcOsc: { maxLength: 250, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
			}
		},
		"w_unitatiistoric.OrganizareInstitutionala": {
			shortName: "OrganizareInstitutionala",
			namespace: "w_unitatiistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", defaultValue: 0 },
				hierarchyId: { maxLength: 892, defaultValue: "" },
				hierarchyIdPath: { maxLength: 4000, defaultValue: "" },
				treeLevel: { dataType: "Int32", defaultValue: 0 },
				parentHierarchyIdChar: { defaultValue: "" },
				nume: { maxLength: 150, defaultValue: "" },
				idOrganizareInstitutionala: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				isActivitateProprie: { dataType: "Boolean" },
				isConformHcl: { dataType: "Boolean" },
				isVizibilSalarii: { dataType: "Boolean" },
				isVizibilGestiuni: { dataType: "Boolean" },
				isVizibilMijloaceFixe: { dataType: "Boolean" },
				isVizibilContabilitate: { dataType: "Boolean" },
				isVizibilImpotax: { dataType: "Boolean" },
				isVizibilRegistruAgricol: { dataType: "Boolean" },
				isVizibilAsisoc: { dataType: "Boolean" },
				isVizibilInvatamant: { dataType: "Boolean" },
				isMagazie: { dataType: "Boolean", isNullable: false },
				isPost: { dataType: "Boolean", isNullable: false },
				isCasierie: { dataType: "Boolean", isNullable: false },
				isCentruCheltuiala: { dataType: "Boolean", isNullable: false },
				isCantina: { dataType: "Boolean", isNullable: false },
				idPostNume: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isPlatitorTVA: { dataType: "Boolean" },
				isSistemTVAIncasare: { dataType: "Boolean" },
				isRaportatFinanciar: { dataType: "Boolean" },
				isProiect: { dataType: "Boolean", isNullable: false },
				isComert: { dataType: "Boolean", isNullable: false },
				codSMIS: { maxLength: 50, defaultValue: "" },
				codFiscalTVA: { maxLength: 50, defaultValue: "" },
				alocareHierarchyIdInInsertTrigger: { dataType: "Boolean", isNullable: false },
				importDataJson: { defaultValue: "" },
				isOrdonatorPrincipal: { dataType: "Boolean", isNullable: false },
				importEcubMifixId: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				importEcubGestStocId: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				codFiscalImpozitSalar: { maxLength: 50, defaultValue: "" },
			}
		},
		"w_unitatiistoric.UnitateSetare": {
			shortName: "UnitateSetare",
			namespace: "w_unitatiistoric",
			autoGeneratedKeyType: AutoGeneratedKeyType.Identity,
			dataProperties: {
				id: { dataType: "Int32", isPartOfKey: true },
				idPersoanaUnitate: { dataType: "Int32", isNullable: false, defaultValue: 0 },
				isOrdonatorPrincipal: { dataType: "Boolean", isNullable: false },
				numeOrdonatorPrincipalCredite: { maxLength: 50, defaultValue: "" },
				numeDirectorGeneral: { maxLength: 50, defaultValue: "" },
				numeAdministratorPublic: { maxLength: 100, defaultValue: "" },
				sumaMaximaSemnatura: { dataType: "Decimal", isNullable: false, defaultValue: 0 },
				idUnitateSetare: { dataType: "Int32" },
				dataStartValabilitate: { dataType: "DateTime" },
				dataStopValabilitate: { dataType: "DateTime" },
				numeInstitutiePublicaIerarhicSuperioara: { maxLength: 50, defaultValue: "" },
				userNameActualizare: { maxLength: 256, defaultValue: "" },
				dataActualizare: { dataType: "DateTime" },
				grupDarsBilant: { maxLength: 50, defaultValue: "" },
				urlCID: { defaultValue: "" },
				codUnitateTip: { dataType: "Int32", isNullable: false, defaultValue: 0 },
			}
		},
	}