export const routes = [
  {
    "application": {
      "nume": "Încasări și plăți"
    },
    "module": {
      "nume": "Alte încasări"
    },
    "url": "ng/incasari/plati/alteincasari/incasare/epay",
    "icon": "fal fal fa-file",
    "options": {},
    "title": "Import GlobalPay.ro",
    "access": "/23/5/1/"
  },
  {
    "application": {
      "nume": "Încasări și plăți"
    },
    "module": {
      "nume": "Configurări"
    },
    "url": "ng/incasari/plati/alteincasari/config/gpay",
    "icon": "fal fal fa-file",
    "options": {},
    "title": "Setări GlobalPay.ro",
    "access": "/23/6/2/"
  },
  {
    "application": {
      "nume": "Dezvoltare"
    },
    "module": {
      "nume": "Dezvoltare"
    },
    "url": "ng/dev/resursa",
    "icon": "fal fal fa-file",
    "options": {},
    "title": "Resursa",
    "access": "/63/1/2/"
  },
  {
    "application": {
      "nume": "Dezvoltare"
    },
    "module": {
      "nume": "Dezvoltare"
    },
    "url": "ng/dev/resursa/control",
    "icon": "fal fal fa-file",
    "options": {},
    "title": "Resursa predefinite",
    "access": "/63/1/3/"
  },
  {
    "application": {
      "nume": "Dezvoltare"
    },
    "module": {
      "nume": "Dezvoltare"
    },
    "url": "ng/dev/component/samples",
    "icon": "fal fal fa-file",
    "options": {},
    "title": "Componente",
    "access": "/63/1/4/"
  },
  {
    "application": {
      "nume": "Dezvoltare"
    },
    "module": {
      "nume": "Dezvoltare"
    },
    "url": "ng/dev/relatie/tabele",
    "icon": "fal fal fa-file",
    "options": {},
    "title": "Relatii tabele",
    "access": "/63/1/5/"
  },
  {
    "application": {
      "nume": "Dezvoltare"
    },
    "module": {
      "nume": "Dezvoltare"
    },
    "url": "ng/dev/branch",
    "icon": "fal fal fa-file",
    "options": {},
    "title": "Branch",
    "access": "/63/1/6/"
  },
  {
    "application": {
      "nume": "Dezvoltare"
    },
    "module": {
      "nume": "Deploy"
    },
    "url": "ng/dev/deploy/database",
    "icon": "fal fal fa-file",
    "options": {},
    "title": "Baza de date",
    "access": "/63/2/1/"
  },
  {
    "application": {
      "nume": "Dezvoltare"
    },
    "module": {
      "nume": "Deploy"
    },
    "url": "ng/dev/deploy/database/validate",
    "icon": "fal fal fa-file",
    "options": {},
    "title": "Validare scripturi",
    "access": "/63/2/2/"
  },
  {
    "application": {
      "nume": "Dezvoltare"
    },
    "module": {
      "nume": "Deploy"
    },
    "url": "ng/dev/deploy/surse",
    "icon": "fal fal fa-file",
    "options": {},
    "title": "Deploy surse",
    "access": "/63/2/3/"
  },
  {
    "application": {
      "nume": "Dezvoltare"
    },
    "module": {
      "nume": "Deploy"
    },
    "url": "ng/dev/deploy/surse/validate",
    "icon": "fal fal fa-file",
    "options": {},
    "title": "Validare surse",
    "access": "/63/2/4/"
  },
  {
    "application": {
      "nume": "Dezvoltare"
    },
    "module": {
      "nume": "Deploy"
    },
    "url": "ng/dev/deploy/pachete/date",
    "icon": "fal fal fa-file",
    "options": {},
    "title": "Pachete de date",
    "access": "/63/2/5/"
  },
  {
    "application": {
      "nume": "Dezvoltare"
    },
    "module": {
      "nume": "Deploy"
    },
    "url": "ng/dev/deploy/changelog",
    "icon": "fal fal fa-file",
    "options": {},
    "title": "Changelog",
    "access": "/63/2/6/"
  },
  {
    "application": {
      "nume": "Dezvoltare"
    },
    "module": {
      "nume": "Deploy"
    },
    "url": "ng/dev/deploy",
    "icon": "fal fal fa-file",
    "options": {},
    "title": "Deploy",
    "access": "/63/2/7/"
  }
]