import { Injectable } from '@angular/core';
import {
    EntityManager, EntityQuery, Entity,
    LocalQueryComparisonOptions, NamingConvention,
    MetadataStore, DataService as breezeDS,
    EntityState, Predicate, SaveResult,
    MergeStrategy, QueryOptions, FetchStrategy, EntityType,
} from 'breeze-client';
import { Subject } from 'rxjs';

import { LogService } from './LogService';
import { DialogService } from './DialogService';
import { metadata } from '../constants/Metadata';
import { IQueryRequest, Query } from '../constants/Query';
import { IndividualConfig } from 'ngx-toastr';
import { DialogType } from '../controls/modal/dialog.confirm';

enum Format { Breeze, SqlServer, Name, Schema }

@Injectable({ providedIn: 'root' })
export class DataService {
    constructor(private log: LogService, private dialog: DialogService) {
        this.manager = new EntityManager({
            dataService: new breezeDS({ serviceName: '/', hasServerMetadata: false, }),
            metadataStore: new MetadataStore({
                localQueryComparisonOptions: LocalQueryComparisonOptions.caseInsensitiveSQL,
                namingConvention: NamingConvention.camelCase,
            })
        })
    }

    private manager: EntityManager;
    private storedWip: Entity[] = [];
    private isSaving: boolean = false;
    private runningQueries: number = 0;

    get isBusy(): boolean { return this.runningQueries > 0 || this.isSaving }
    get wipCount() { return this.storedWip.length }
    get wipSummary(): Entity[] { return this.storedWip }

    /** @deprecated, will be removed, DO NOT USE */
    clearWip() { this.rejectChanges() }

    rejectChanges() { return this.manager.rejectChanges() }
    hasChanges(entityTypes?: string | string[]) {
        if (entityTypes) {
            if (typeof entityTypes == 'string') {
                entityTypes = this.format(entityTypes, Format.Breeze)
            } else if (Array.isArray(entityTypes)) {
                entityTypes = entityTypes.map(item => this.format(item, Format.Breeze))
            }
        }
        return this.manager.hasChanges(entityTypes)
    }
    getChanges(typeName?: string) {
        return this.manager.getChanges(this.format(typeName, Format.Breeze) || undefined)
    }
    entityChanged(typeName?: string) {
        if (!typeName) {
            return this.manager.entityChanged
        } // else {
        //     var subKey = this.manager.entityChanged.subscribe((changeArgs) => {
        //         const entity = changeArgs.entity;
        //         if (entity.entityType.name === typeName) {
        //             this.manager.entityChanged.unsubscribe(subKey);
        //             return changeArgs;
        //         }
        //     });
        // }
    }

    createEntity<T extends Entity>(
        typeName: string, config?: { [prop: string]: any }, entityState?: EntityState
    ): T {
        return <any>this.manager.createEntity(this.format(typeName, Format.Breeze), config, entityState)
    }
    setDeleted(entity: Entity) {
        if (!(entity && entity.entityAspect)) {
            throw new Error('Stergerea nu se poate efectua. Entitate invalidă!')
        }
        entity.entityAspect.setDeleted();
    }
    deleteEntity(entity: Entity, localArray?: Entity[]) {
        this.setDeleted(entity);
        return this.saveChanges(entity).then(() => {
            if (Array.isArray(localArray) && localArray.indexOf(entity) >= 0) {
                localArray.splice(localArray.indexOf(entity), 1)
            }
        })
    }

    executeQueryWS(message: string, query: IQueryRequest, callback?: (result: any) => { message: string, progress: number }) {
        return this.dialog.progressWS({
            message: message, ws: {
                event: 'executeQuery', message: query, callback: callback || ((res) => {
                    const msg: any = Array.isArray(res) ? { message: res[0][''] || res[0].result || res[0].Result } : res || {};
                    return { message: msg.message || 'Operatie terminata.', progress: msg.progress || msg.procent || 100 }
                })
            }
        })
    }

    /** @deprecated, will be replaced by executeQueryWS */
    executeLongRunningQuery(query: Object): Promise<{ results: any[] }> {
        this.runningQueries += 1;

        return this.dialog.modalExecuteQuery(query).then((data) => {
            this.runningQueries -= 1;

            data.results = (data.results && data.results.length) ? data.results.map((item) => {
                const formatedItem = {};
                const originalProperties = Object.keys(item);
                for (var i = 0; i < originalProperties.length; i++) {
                    var prop = originalProperties[i].charAt(0).toLowerCase() + originalProperties[i].slice(1);
                    formatedItem[prop] = item[originalProperties[i]];
                }
                return formatedItem;
            }) : [];
            return data
        }, (error) => {
            return this.onQueryFailed(error);
        });
    }

    /** @deprecated, use executeQueryNew */
    executeQuery<T extends Entity = any>(
        query: IQueryRequest | string, typeName?: string
    ): Promise<{ results: T extends Entity ? T[] : any[] }> {
        const promise = this.execute(query, typeName);
        promise['splice'] = () => { };
        promise['$$state'] = { pending: promise };
        return promise;
    }

    executeQueryNew<T extends Entity = any>(
        query: IQueryRequest | string, typeName?: string
    ): Promise<T extends Entity ? T[] : any[]> {
        return this.execute(query, typeName).then(data => data.results)
    }

    /**
     * @param query 
     * @param progressName identificator in tabela de progres
     * @param timer timpul intre incercari, default 5sec
     * @param attempts nr de incercari pana gaseste ceva, default 6
     */
    progress(query: IQueryRequest, progressName: string, timer?: number, attempts?: number) {
        var sub: Subject<{ progress: number; message: string }> = new Subject();
        this.execute(query, null, true).then(() => {
            let progress = 0, notFound = 0, self = this;
            function checkProgress() {
                setTimeout(() => {
                    self.executeQueryNew(<any>{} /*Query.core.getProgress(progressName)*/).then((d) => {
                        if (!d.length) {
                            if (++notFound === (attempts || 6)) {
                                sub.next({ progress: progress, message: '' });
                                sub.complete();
                                return
                            }
                        } else {
                            if (progress != d[0].procent) {
                                progress = d[0].procent;
                                self.log.info(`Procesare......${progress}%`);
                                sub.next({ progress: progress, message: `Procesare......${progress}%` });
                            }
                        }
                        if (progress != 100) {
                            checkProgress()
                        } else {
                            if (d[0].eroare) {
                                self.log.error(d[0].eroare);
                                sub.next({ progress: 100, message: d[0].eroare });
                            } else {
                                self.log.error('Operatie incheiata cu success.');
                                sub.next({ progress: 100, message: 'Operatie incheiata cu success.' });
                            }
                            sub.complete();
                        }
                    })
                }, timer || 5000)
            }
            checkProgress()
        });
        return sub;
    }

    execute<T extends Entity>(
        query: IQueryRequest | string, typeName?: string, noWaitResponse?: boolean
    ): Promise<any> {
        this.runningQueries += 1;

        return EntityQuery.from('executeQuery')
            .withParameters({
                $method: 'POST', $encoding: 'JSON',
                $data: { noWaitResponse: noWaitResponse, query: query }
            })
            .toType(this.format(typeName, Format.Breeze) || '')
            .using(this.manager)
            .execute()
            .then((data) => {
                this.runningQueries -= 1;

                if (typeName) {
                    var entities = data.results;
                    var dbValues = data["httpResponse"].data;
                    var unmappedProperties: string[] = [];
                    var entProp: string[] = [], dbProp: string[] = [];

                    if (data.query['resultEntityType'].dataProperties) {
                        data.query['resultEntityType'].dataProperties.forEach(item => entProp.push(item.nameOnServer))
                    }
                    if (dbValues.length) { dbProp = Object.getOwnPropertyNames(dbValues[0]) }
                    for (var i = 0; i < dbProp.length; i++) {
                        if (entProp.indexOf(dbProp[i]) === -1 && dbProp[i] != "_$meta" && dbProp[i] != "entityType")
                            unmappedProperties.push(dbProp[i]);
                    }
                    if (unmappedProperties && entities && dbValues) {
                        for (var i = 0; i < entities.length; i++) {
                            for (var j = 0; j < dbValues.length; j++)
                                if (entities[i]["id"] === dbValues[j]["Id"])
                                    for (var k = 0; k < unmappedProperties.length; k++)
                                        entities[i][unmappedProperties[k].charAt(0).toLowerCase()
                                            + unmappedProperties[k].slice(1)] = dbValues[j][unmappedProperties[k]];
                        }
                        data.results = entities;
                    }
                }
                return data;

            }, (error) => {
                return this.onQueryFailed(error);
            })
    }

    private onQueryFailed(error) {
        this.runningQueries -= 1;

        let response = error.message;
        let message = response;
        let isAlert = false;
        if (typeof response === 'object') {
            isAlert = response.class != 16 && response.message != null && response.message.trim().length > 0;
            message = response.message;
        }

        if (!isAlert) {
            let toastrOptions: Partial<IndividualConfig> = { timeOut: 10000 };
            this.log.error(message || "", "", true, toastrOptions);
        } else {
            if (message != null) message = message.replace(/(?:\\r\\n|\\r|\\n)/g, '<br>');
            this.dialog.notify({ message: message, title: 'Atenție !', type: DialogType.error });
        }

        let data = {
            results: [],
            error: message,
            isErrorUser: isAlert
        };
        return data;
    }

    /** @deprecated, use getEntitiesNew */
    getEntities<T extends Entity>(
        typeName: string, entityState?: EntityState | EntityState[], where?: Predicate,
    ): Promise<T extends Entity ? { results: T[] } : any> {
        return <any>this.getEntitiesNew(typeName, entityState, where)
            .then(data => { return { results: data } })
    }

    getEntitiesNew<T extends Entity>(
        typeName: string, entityState?: EntityState | EntityState[], where?: Predicate,
    ): Promise<Array<T extends Entity ? T : any>> {

        var entities: Entity[];
        if (where) {
            const resourceName = typeName.indexOf('.') >= 0 ? typeName.split('.')[1] : typeName;
            const query = new EntityQuery(resourceName)
                .using(this.manager)
                .toType(this.format(typeName, Format.Breeze))
                .where(where);
            entities = this.manager.executeQueryLocally(query);

            if (entities.length) {
                let states: EntityState[];
                if (entityState) {
                    states = Array.isArray(entityState) ? entityState : [entityState]
                }
                if (states) {
                    entities = entities.filter((item) => states.indexOf(item.entityAspect.entityState) > -1)
                }
            }
        } else {
            entities = this.manager.getEntities(this.format(typeName, Format.Breeze), entityState)
        }

        if (entityState || (entities && entities.length)) {
            return Promise.resolve<any>(entities);
        } else {
            return this.execute(Query.comun.getEntities(typeName, where), typeName).then(data => data.results);
        }
    }

    /** @deprecated, use getEntityByKeyNew */
    getEntityByKey<T>(
        typeName: string,
        keyValues: number | string | Array<string | number>,
        where?: Predicate, ignoreCache?: boolean
    ) {
        return this.getEntityByKeyNew<T>(typeName, keyValues, where, ignoreCache)
            .then(data => { return { results: data.length ? data : [this.createEntity(typeName)] } })
    }

    /** 
     * will replace getEntityByKey, 
     * this version does not create new entity if !results 
     * */
    getEntityByKeyNew<T>(
        typeName: string,
        keyValues: number | string | Array<string | number>,
        where?: Predicate, ignoreCache?: boolean
    ): Promise<Array<T extends Entity ? T : any>> {

        return Promise.resolve().then(() => {
            if (+keyValues === 0) {
                let type = this.format(typeName, Format.Breeze) || undefined;
                return this.getEntitiesNew(type, [EntityState.Added, EntityState.Modified], where).then((res) => {
                    if (where) {
                        var pObj: any = where;
                        var config: any = {};
                        config[pObj.expr1Source] = pObj.expr2Source;
                        return res || [this.manager.createEntity(type, config)];
                    } else {
                        return res || [this.manager.createEntity(type)]
                    }
                })
            }

            let entity;
            if (!ignoreCache) {
                entity = this.manager.getEntityByKey(this.format(typeName, Format.Breeze), keyValues)
            }
            if (entity) {
                return Promise.resolve((Array.isArray(entity)) ? entity : [entity])
            } else {
                const ids = (Array.isArray(keyValues) ? keyValues.join(',') : keyValues);
                return this.executeQueryNew(Query.comun.getEntities(typeName, ids), typeName)
            }
        })
    }

    executeQueryLocally(query: EntityQuery): Entity[] {
        return this.manager.executeQueryLocally(query)
    }
    getLocalEntityByKey<T>(
        typeName: string, keyValues: number | string | Array<{ [key: string]: string | number }>
    ): Promise<Array<T extends Entity ? T : any>> {
        return Promise.resolve().then(() => {
            const entity: any = this.manager.getEntityByKey(this.format(typeName, Format.Breeze), keyValues);
            return Array.isArray(entity) ? entity : [entity];
        })
    }
    getLocalEntities<T extends Entity>(
        typeName: string, where?: Predicate, entityState?: EntityState | EntityState[],
    ): Promise<Array<T extends Entity ? T : any>> {

        return Promise.resolve().then(() => {
            var entities;
            if (where) {
                const query = new EntityQuery(typeName.indexOf('.') >= 0 ? typeName.split('.')[1] : typeName)
                    .using(this.manager)
                    .toType(this.format(typeName, Format.Breeze))
                    .where(where);
                entities = this.manager.executeQueryLocally(query);

                if (entities.length > 0) {
                    const states: EntityState[] = entityState && !Array.isArray(entityState) ? [entityState] : <EntityState[]>entityState;
                    for (var j = entities.length; j--;) {
                        let hasGoodState: boolean = false;
                        for (var i = 0; i < states.length; i++) {
                            if (entities[j].entityAspect.entityState === states[i])
                                hasGoodState = true
                        }
                        if (!hasGoodState) { entities.splice(j, 1) }
                    }
                }
            } else {
                entities = this.manager.getEntities(this.format(typeName, Format.Breeze), entityState)
            }
            return entities
        })
    }

    saveChanges(saveWhat?: Entity | Entity[], showSuccessToast?: boolean | string): Promise<SaveResult> {
        if (this.isSaving) {
            throw new Error('O altă salvare este în derulare! Asteptaţi căteva secunde şi apoi reincercaţi.')
        } else if (this.manager.hasChanges()) {
            try {
                this.isSaving = true;
                return this.manager.saveChanges(saveWhat ? (Array.isArray(saveWhat) ? saveWhat : [saveWhat]) : undefined).then((data) => {
                    this.isSaving = false;
                    if (showSuccessToast) {
                        this.log.success(typeof showSuccessToast == 'string' ? showSuccessToast : 'Datele au fost salvate.')
                    }
                    return data
                }, (error) => {
                    this.isSaving = false;
                    if (error.status === 401) { throw error }

                    // const errorMessage = error.entityErrors && error.entityErrors[0] && error.entityErrors[0].errorMessage;
                    // this.log.error(errorMessage ? `${error.entityErrors[0].errorName}: ${errorMessage}` : error.message, 'Nu se poate salva/sterge inregistrarea!', error);

                    // const message = `<br />ERROR: ${error.message}<br/>DETAILED ERROR: ${error.httpResponse && error.httpResponse.data.Errors[0].message}`;
                    // this.log.error('Eroare la salvare date.', 'Eroare la salvare date', error, { enableHtml: true });

                    const message = error.httpResponse && error.httpResponse.data.Errors && error.httpResponse.data.Errors[0].mesaj;
                    // this.log.error(message || 'Eroare la salvare date.');
                    throw message ? new Error(message) : error;
                })
            } catch (error) { // catch: Only entities in this entityManager may be saved
                this.isSaving = false;
                return Promise.reject(error);
            }
        } else {
            if (showSuccessToast) { this.log.info("Nimic de salvat !") }
            return Promise.resolve({ entities: [], keyMappings: [] })
        }
    }

    getEntityType(entityType: string) {
        var format = this.format(entityType, Format.Breeze);
        return this.manager.metadataStore.getAsEntityType(format)
    }

    private format(typeName: string, format: Format): string {
        if (!typeName) { return "" }
        var formatBreeze = typeName.indexOf(".") > -1
            ? typeName.substring(typeName.indexOf(".") + 1) + ":#" + typeName.substring(0, typeName.indexOf(".")).toLowerCase()
            : typeName;
        if (!this.manager.metadataStore.getAsEntityType(formatBreeze, true)) {
            this.fetchMetadata(typeName)
        }
        if (typeName.indexOf(".") > -1) {
            switch (format) {
                case Format.Schema: return typeName.substring(0, typeName.indexOf(".")).toLowerCase();
                case Format.Name: return typeName.substring(typeName.indexOf(".") + 1);
                case Format.Breeze: return formatBreeze;
                case Format.SqlServer: return typeName;
            }
        } else {
            return format === Format.Schema ? "" : typeName
        }
    }
    private fetchMetadata(typeName: string) {
        if (typeName.indexOf(".") >= 0) {
            return this.manager.metadataStore.addEntityType(metadata[typeName])
        }
        for (var prop in metadata) {
            if (prop.substring(prop.indexOf('.') + 1) === typeName)
                return this.manager.metadataStore.addEntityType(metadata[prop])
        }
    }
}