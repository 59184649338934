import { Inject, Injectable } from '@angular/core';
import { IRootScopeService } from "angular";

import { ConfigService, ConfigServiceNew } from './ConfigService';
import { StyleManager } from './StyleManager';
import { DataService } from './DataService';
import { UserService } from './UserService';
import { LogService } from './LogService';

import { Config, App, AppContext } from '../constants/Enum';
import { Query } from '../constants/Query';

const settingsKey = 'user.settings';

@Injectable({ providedIn: 'root' })
export class AppSettings {
    constructor(user: UserService, styleManager: StyleManager,
        private data: DataService, private log: LogService,
        private config: ConfigService, @Inject('$rootScope') private $rootScope: IRootScopeService,
    ) {
        // var timeout;
        // this.$rootScope.$on('configChanged', (data) => {
        //     if (timeout) { clearTimeout(timeout); timeout = null }
        //     timeout = setTimeout(() => this.get(), 1000);
        // });

        user.onLoginComplete.subscribe(pers => {
            if (pers) { this.get() }
        });
        return new Proxy(this, {
            get(target, prop: string, receiver) {
                if (typeof target[prop] === 'function') {
                    return new Proxy(target[prop], {
                        apply(applyTarget, thisArg, args) {
                            return Reflect.apply(applyTarget, thisArg, args);
                        }
                    })
                }
                switch (prop) {
                    case 'css': return { agTheme: styleManager.css.agTheme };
                    case 'editform': return styleManager.editForm;
                    default:
                        if (target.hasOwnProperty(prop)) { return target[prop] }
                        if (prop in Config) {
                            return target.setare[isNaN(<any>prop) ? prop : Config[prop]]
                        }
                        return undefined;
                }
            },
            set(target, prop: string, newValue: any, receiver: any) {
                if (typeof target[prop] === 'function') {
                    return false // do not allow to change functions
                } else if (target.hasOwnProperty(prop)) { // search in class
                    target[prop] = newValue
                } else if (prop in Config) { // search in db settings
                    // check if prop=cod in Config else prop must be value in Config
                    const cod: string = isNaN(<any>prop) ? prop : Config[prop];
                    target.setare[cod] = newValue;
                    config.set(Config[cod], newValue);
                }
                return true;
            },
        })
    }

    declare css: { agTheme: string };
    declare form: StyleManager['editForm'];

    private setare: { [key in keyof typeof Config]: any } = <any>{}; // db settings
    private stareLuna: { [key: string]: boolean } = {};

    loaded: boolean = false;
    isReportAutoOpen: boolean = true;

    /** TODO: make implementation or remove */
    isLunaInchisa(idAplicatie: App): boolean {
        // if (this.stareLuna[`${idAplicatie}.${this.setare.anLuna}`]) {
        //     this.log.warning('Nu se poate opera!', 'Luna este inchisa!')
        // }
        // return this.stareLuna[`${idAplicatie}.${this.setare.anLuna}`]
        return true
    }

    /** salveaza setarea (ca si configservice) */
    set(cod: Config, data: any, idUnitate: number) {
        return this.config.set(cod, data, idUnitate)
    }

    /** Get setari pentru un anumit modul sau pentru toate */
    get(idModul?: AppContext) {
        return this.data.executeQueryNew(Query.admin.getConfigs(idModul)).then(data => {
            if (!(data.length && data[0].configs)) { return false }
            try {
                var configs = JSON.parse(data[0].configs)
            } catch (ex) {
                this.log.error("JSON.parse(data.results[0].configs)", 'Load settings', data[0]);
                return false;
            }

            // TODO: keep settings in sync 

            for (var key in configs) {
                try {
                    this.setare[key] = JSON.parse(configs[key])
                } catch (ex) { this.setare[key] = configs[key] }
            }
            if (!idModul) { this.loaded = true }
            return idModul ? data : true;
        })
    }
}